// export const API_BASE_URL = "http://192.168.33.223:8001/api";
// export const API_BASE_URL = "http://192.168.50.80:8001/api";
// export const IMG_BASE_URL = "http://192.168.50.80:8001";

// # Development server kira configuration
// export const API_BASE_URL = "http://172.16.111.57:8000/api";
// export const IMG_BASE_URL = "http://172.16.111.57:8000";
// export const DOC_BASE_URL = "http://172.16.111.57:8000/api/";
// export const LOGOUT_API = "http://172.16.111.57:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.111.57:8000/api/login";

// // # Development server abrsh configuration
// export const API_BASE_URL = "http://172.16.32.111:8000/api";
// export const IMG_BASE_URL = "http://172.16.32.111:8000";
// export const DOC_BASE_URL = "http://172.16.32.111:8000/api/";
// export const LOGOUT_API = "http://172.16.32.111:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.111:8000/api/login";
// export const PM = "http://172.16.32.70:3000/v1/monthlyBudget/month/project";

//<--------Production server endpoints---------
// export const API_BASE_URL = "https://172.16.33.161:8000/api";
// export const IMG_BASE_URL = "https://172.16.33.202:8000/scm-back-test";
// export const DOC_BASE_URL = "https://172.16.33.202:8000/api/";
// export const LOGOUT_API = "https://172.16.33.202:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "https://172.16.33.202:8000/api/login";

//<------------------------scm-Main------------------->

// export const API_BASE_URL = "https://scm-back-test.ienetworks.co/api";
// export const IMG_BASE_URL = "https://scm-back-test.ienetworks.co";
// export const DOC_BASE_URL = "https://scm-back-test.ienetworks.co/api/";
// export const LOGOUT_API = "https://scm-back-test.ienetworks.co/api/logout";
// export const LOGIN_WITH_EMAIL = "https://scm-back-test.ienetworks.co/api/login";
// export const PM =
//   "https://pm-back.ienetworks.co/v1/monthlyBudget/month/project";

export const API_BASE_URL = "https://scm-back-test.ienetworks.co/api";
export const IMG_BASE_URL = "https://scm-back-test.ienetworks.co";
export const DOC_BASE_URL = "https://scm-back-test.ienetworks.co/api/";
export const LOGOUT_API = "https://scm-back-test.ienetworks.co/api/logout";
export const LOGIN_WITH_EMAIL = "https://scm-back-test.ienetworks.co/api/login";
export const PM =
  "https://pm-back-test.ienetworks.co/v1/monthlyBudget/month/project";
//<------------------------scm-test------------------->

// export const API_BASE_URL = "https://scm-test.ienetworks.co/api";
// export const IMG_BASE_URL = "https://scm-test.ienetworks.co";
// export const DOC_BASE_URL = "https://scm-test.ienetworks.co/api/";
// export const LOGOUT_API = "https://scm-test.ienetworks.co/api/logout";
// export const LOGIN_WITH_EMAIL = "https://scm-test.ienetworks.co/api/login";
// export const PM =
//   "https://pm-back.ienetworks.co/v1/monthlyBudget/month/project";

//<------------------------Abrshi-back------------------->
// export const API_BASE_URL = "http://172.16.32.87:8000/api";
// export const IMG_BASE_URL = "http://172.16.32.87:8000";
// export const DOC_BASE_URL = "http://172.16.32.87:8000/api/";
// export const LOGOUT_API = "http://172.16.32.87:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.87:8000/api/login";
// export const PM = "http://172.16.32.70:3000/v1/monthlyBudget/month/project";

// # Development server tesfu configuration
// export const API_BASE_URL = "http://172.16.32.87:8000/api";
// export const IMG_BASE_URL = "http://172.16.32.87:8000";
// export const DOC_BASE_URL = "http://172.16.32.87:8000/api/";
// export const LOGOUT_API = "http://172.16.32.87:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.87:8000/api/login";
// export const PM = "http://172.16.32.70:3000/v1/monthlyBudget/month/project";

// # Development server ephi configuration
// export const API_BASE_URL = "http://172.16.32.95:8000/scm-back/api";
// export const IMG_BASE_URL = "http://172.16.32.64:8000/scm-back";
// export const DOC_BASE_URL = "http://172.16.32.95:8000/scm-back/api/";
// export const LOGOUT_API = "http://172.16.32.95:8000/scm-back/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.95:8000/scm-back/api/login";
// export const PM = "http://172.16.32.70:3000/v1/monthlyBudget/month/project";

// SCM v2 API
// export const API_BASE_URL = "http://172.16.32.64/api";
// export const IMG_BASE_URL = "http://172.16.32.64";
// export const DOC_BASE_URL = "http://172.16.32.64/api/";
// export const LOGOUT_API = "http://172.16.32.64/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.64/api/login";
export const LOGIN_REDIRECT_URL =
  "https://scm-back-test.ienetworks.co/api/microsoft-graph/redirect";
export const LOGIN_CALLBACK =
  "https://scm-back-test.ienetworks.co/api/o365callback";
export const LOGIN_REDIRECT_URL_GOOGLE =
  "https://scm-back-test.ienetworks.co/api/google/redirect";
export const LOGIN_CALLBACK_GOOGLE =
  "https://scm-back-test.ienetworks.co/api/googlecallback";
