import { Tooltip } from "@mui/material";
import React from "react";
import { useGetTopSuppliersQuery } from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";

export default function TopSuppliers() {
  const { data: topSupplier } = useGetTopSuppliersQuery();
  console.log(topSupplier, "topSupplier");
  return (
    <div className="dashboard-card">
      <div className="dashboard-title ps-3 pt-2">Top Suppliers</div>
      <div className="divider"></div>
      {topSupplier !== undefined ? (
        <div className="topsuppliers-container">
          {topSupplier?.map((items) => (
            <Tooltip
              title={`Total Payed: ${items?.total_payed.toLocaleString()}`}
            >
              <div className="topsuppliers">{items?.distributor_name}</div>
            </Tooltip>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <NoRecord />
        </div>
      )}
    </div>
  );
}
