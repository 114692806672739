import React, { useEffect, useState } from "react";
import RecievableFilter from "../RecievableFilter";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import ConfirmUpcoming from "./confirmUpcoming";
import RecivableUpcomingDownload from "./recivableUpcomingDownload";
import Pagination from "@mui/material/Pagination";

function RecievableUpcomig() {
  let Counter = 0;
  const [productData, setProductData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptionProjectId, setSearchOptionProjectId] = useState("");
  const [searchOptionDate, setSearchOptionDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/incomingRevcievables`)
      .then((response) => {
        setProductData(response.data.data);
        console.log(
          response?.data,
          "response fromback end for incoming recivable"
        );
      })
      .catch((err) => {
        console.error("Error fetching incomingRevcievables data:", err);
      });
  }, []);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // reset to first page after search
  };

  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  let curentDate = searchOptionDate
    ? searchOptionDate
    : new Date().toLocaleDateString();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = productData?.filter((item) => {
    return (
      item.currency &&
      item.currency.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (searchOptionProjectId === "" ||
        item.projectID === searchOptionProjectId) &&
      (searchOptionDate === "" ||
        new Date(item.expectedPaymentDate) >= new Date(searchOptionDate))
    );
  });

  const numberOfPages = Math.ceil(filteredData?.length / itemsPerPage);

  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const shouldDisplayPagination = numberOfPages > 1;

  return (
    <div className="mb-3 bg-white rounded border">
      <div className="">
        <RecievableFilter
          searchQuery={searchQuery}
          searchOptionProjectId={searchOptionProjectId}
          searchOptionDate={searchOptionDate}
          handleSearchSubmit={handleSearchSubmit}
          setSearchQuery={setSearchQuery}
          setSearchOptionProjectId={setSearchOptionProjectId}
          setSearchOptionDate={setSearchOptionDate}
        />
      </div>
      <RecivableUpcomingDownload productData={filteredData} />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "2%" }}></div>
        <div
          style={{
            flex: "96%",
            overflow: "auto",
          }}
        >
          <table style={{ margin: "20px" }} className="prg recivableTable">
            <thead>
              <tr
                className="recivableTr"
                style={{ backgroundColor: "rgba(86, 184, 183, 0.1)" }}
              >
                <th className="recivableTh">NO</th>
                <th style={{ minWidth: "200px " }}>Project Name</th>
                <th className="recivableTh">Contract Date</th>
                <th className="recivableTh">Milestone</th>
                <th className="recivableTh">Amount</th>
                <th className="recivableTh">Currency</th>
                <th className="recivableTh">Payment Request Date</th>
                <th className="recivableTh">Payment Expected Date</th>
                <th className="recivableTh">Report Date</th>
                <th className="recivableTh">Status</th>
                <th className="recivableTh">Received</th>
              </tr>
            </thead>
            <tbody className="recivabletbody">
              {paginatedData?.map((item, index) => {
                Counter++;
                return (
                  <tr className="recivableTr" key={index}>
                    <td className="recivableTd">{Counter}</td>
                    <td className="recivableTd">{item.project}</td>
                    <td className="recivableTd">{item.contract_sign_date}</td>
                    <td className="recivableTd">{item.milestone}</td>
                    <td className="recivableTd" style={{ minWidth: "200px" }}>
                      {item.amount !== undefined
                        ? item.amount.toLocaleString()
                        : ""}
                    </td>

                    <td className="recivableTd">{item.currency}</td>
                    <td className="recivableTd">{item.paymentRequestDate}</td>
                    <td className="recivableTd">{item.expectedPaymentDate}</td>
                    <td className="recivableTd">{curentDate}</td>
                    <td className="recivableTd">{item.currentStatus}</td>
                    <td className="recivableTd">
                      <ConfirmUpcoming item={item} />
                    </td>
                  </tr>
                );
              })}
              {shouldDisplayPagination && (
                <tr className="recivableTr" style={{ background: "#fff" }}>
                  <td
                    className="recivableTd"
                    colSpan={13}
                    style={{ textAlign: "center" }}
                  >
                    <Pagination
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={numberOfPages}
                      page={currentPage}
                      onChange={(event, page) => handlePageChange(page)}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ flex: "2%" }}></div>
      </div>
    </div>
  );
}

export default RecievableUpcomig;
