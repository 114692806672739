import React, { useEffect } from "react";
import "./createProject.style.css";
import makeAnimated from "react-select/animated";
import {
  useCreateProjectMutation,
  useGetCurrenciesQuery,
  useGetProjectInputParamsQuery,
  useGetProjectRoleQuery,
  useGetSubSolutionsPerProjectMutation,
  useGetSubSolutionsQuery,
  useGetUserRoleQuery,
  useGetUsersQuery,
} from "../../features/SCMApi";
import { useState } from "react";
import MultipleSelect from "./MultipleSelect";
import { width } from "@mui/system";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import MultiSelect from "../common/MultiSelect";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ProjectMemberSelector from "../common/ProjectMemberSelector";
import CreateClientSettingModal from "../Settings/SettingModal/CreateClientSettingModal";
import Select from "react-select";

import ErrorModal from "../common/ErrorModal";

export default function CreateProject(props) {
  const animatedComponents = makeAnimated();
  const { data: inputPrams } = useGetProjectInputParamsQuery();
  const { data: subSolutions } = useGetSubSolutionsQuery();
  const [createProject, response] = useCreateProjectMutation();
  const { data: currency } = useGetCurrenciesQuery();
  const [createClientModal, setCreateClientModal] = useState();
  const [userRole, setUserRole] = useState();
  const { data: users } = useGetUsersQuery();
  let formData = new FormData();
  let project_solution = [];
  let projectManagers = [];
  let sourcingOfficers = [];
  let projectFinanceOfficers = [];
  let userList = [];
  let logisticsOfficers = [];
  let technicalLeaders = [];
  let fieldEngineers = [];
  let clients = [];
  let Departments = [];
  console.log(inputPrams?.data?.department, "key");
  inputPrams?.data?.department.map((items) =>
    Departments?.push({ value: items?.id, label: items?.department_name })
  );
  const [subSolutionTobeListed, setSolutionList] = useState([]);
  let [project_name, setProjectName] = useState();
  let [budget, setBudget] = useState();
  let [currency_id, setCurrency] = useState();

  let [lc_opening_date, setLcOpeningDate] = useState();
  let [advance_payment_date, setAdvancePaymentDate] = useState();
  let [end_date, setEndDate] = useState();
  let [contract_sign_date, setSignDate] = useState();
  let [milestone_amount, setMilestone] = useState("1");

  let [client_id, setClient] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  let [forex_resource, setForexResource] = useState(false);
  let [project_department_id, setDepartment] = useState([]);
  const project_members = [];
  console.log(errorMessage, "errorMessage");
  console.log(inputPrams?.data, "inputPrams");
  formData.append("client_id", client_id);
  formData.append("project_name", project_name);
  formData.append("budget", budget);
  formData.append("contract_sign_date", contract_sign_date);

  for (const b of project_department_id) {
    formData.append("project_department_id", b);
  }

  users?.data?.map((logisticsOfficer) =>
    userList.push({
      value: logisticsOfficer?.id,
      label: logisticsOfficer?.name,
    })
  );
  const fetchingData = () => {
    axios
      .get(`${API_BASE_URL}/view/project-roles`)
      .then((res) => {
        setUserRole(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchingData();
  }, []);
  function handleMembers(data) {
    if (project_members.length > 0) {
      for (let i = 0; i < project_members.length; i++) {
        if (project_members[i].role_id === data.role_id) {
          if (data?.user_id.length === 0) {
            console.log("delete");
          } else {
            if (project_members[i]?.role_id === data?.role_id) {
              project_members[i] = data;
              console.log("updated");
            }
          }
        }
      }
      const condition = project_members?.filter(
        (items) => items?.role_id == data?.role_id
      );
      console.log(condition, "condition");
      condition.length == 0 ? project_members.push(data) : <></>;
    } else project_members.push(data);

    console.log(project_members, "project_members");
    console.log(project_members.length, "project_members");
  }

  const HandleSubmit = (event) => {
    event.preventDefault();

    const params = {
      milestone_amount,
      forex_resource,
      client_id,
      project_name,
      budget,
      lc_opening_date,
      advance_payment_date,
      end_date,
      currency_id,
      contract_sign_date,
      project_department_id,
      project_solution,
      project_members,
    };

    console.log(params, "project params");

    axios
      .post(`${API_BASE_URL}/scm/project/projects`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        props?.setMessage("Project created successfully");
        props?.setSuccessMessage(true);
        HandleCancel();
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "rty");
        setErrorMessage(error?.response?.data?.message);
        props?.setErrorModalOpen(true);
      });
  };

  const subSolutionList = [];

  for (let i = 0; i < subSolutions?.data.length; i++) {
    for (let j = 0; j < project_department_id?.length; j++) {
      if (project_department_id[j] === subSolutions?.data[i]?.department_id) {
        subSolutionList.push(subSolutions?.data[i]);
      } else console.log(project_department_id[j], "false condition");
    }
  }

  function HandleCancel() {
    document.querySelector(".body-custom").classList.remove("disable-scroll");

    props.modal(false);
  }
  function handleChange(e) {
    const condition = project_solution.filter((items) => {
      return items === e.target.id;
    });
    condition.length !== 0
      ? (project_solution = project_solution.filter(
          (items) => items !== e.target.id
        ))
      : project_solution.push(e.target.id);
  }
  function handleDepartmentChange(selectedOption) {
    const selectedValue = [];
    Object.keys(selectedOption).map((key) => {
      selectedValue.push(selectedOption[key].value);
    });
    project_department_id = selectedValue;
  }
  const dataPush = [];
  if (Departments !== null) {
    for (let id in props?.list) {
      dataPush.push(props?.list[id]?.value);
    }
  }
  console.log(forex_resource, "forex_resource");

  console.log("userRole,", userRole);

  return (
    <div id="myModal" className="mini-modal " onClick={(e) => HandleCancel()}>
      {createClientModal ? (
        <CreateClientSettingModal modal={setCreateClientModal} />
      ) : (
        <></>
      )}
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>Create Project</div>
          <div className="close-modal-button" onClick={(e) => HandleCancel()}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>

        <form className="form-container" onSubmit={HandleSubmit}>
          <div className="form-card-container">
            <div className="form-card-information">
              <div className="project-information">
                <div className="form-card-title">Project Information</div>
                <div
                  className="card-center"
                  style={{ height: "fit-content", paddingBottom: "1rem" }}
                >
                  <div className="card-form">
                    <div className="input-labels">
                      <div className="input-label">Project Name</div>
                      <div className="input-label">Budget</div>

                      <div className="input-label">Contract Sign Date</div>
                      <div className="input-label">LC opening Date</div>
                      <div className="input-label">Advanced Payment Date</div>

                      <div className="input-label">Project end Date</div>
                      <div className="input-label">Select Client</div>
                      <div className="input-label">Number of milestone</div>
                      <div className="input-label"> Forex Resource by</div>
                    </div>
                    <div className="input-fields">
                      <input
                        required
                        type="text"
                        className="text-input"
                        placeholder="Project Name"
                        onChange={(event) =>
                          (project_name = event.target.value)
                        }
                      />

                      <div
                        className="d-flex flex-column"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            style={{ color: "black" }}
                            required
                            type="number"
                            name=""
                            id=""
                            min="0"
                            className="small-text-field text-dark "
                            onChange={(event) => (budget = event.target.value)}
                          />
                          <select
                            name=""
                            id=""
                            className="small-text-field text-dark "
                            required
                            onChange={(e) => (currency_id = e.target.value)}
                          >
                            <option selected="false" disabled="disabled">
                              Select Currency
                            </option>
                            {currency?.data?.map((curr) => (
                              <option value={curr?.id}>{curr?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <input
                        type="date"
                        className="text-input"
                        placeholder="Contract Sign Date"
                        onChange={(event) =>
                          (contract_sign_date = event.target.value)
                        }
                      />
                      <input
                        type="date"
                        className="text-input"
                        placeholder="Contract Sign Date"
                        onChange={(event) =>
                          (lc_opening_date = event.target.value)
                        }
                      />
                      <input
                        type="date"
                        className="text-input"
                        placeholder="Contract Sign Date"
                        onChange={(event) =>
                          (advance_payment_date = event.target.value)
                        }
                      />
                      <input
                        type="date"
                        className="text-input"
                        placeholder="Contract Sign Date"
                        onChange={(event) => (end_date = event.target.value)}
                      />

                      <div className="row-input-container">
                        <select
                          required
                          name=""
                          id=""
                          style={{ width: "100%" }}
                          className="select-option"
                          onChange={(event) => (client_id = event.target.value)}
                        >
                          <option selected="false" disabled="disabled">
                            Select Client
                          </option>
                          {inputPrams?.data?.clients.map((items) => (
                            <option value={items.id}>
                              {items?.client_name}
                            </option>
                          ))}
                        </select>
                        <FileUploadIcon
                          onClick={(e) => setCreateClientModal(true)}
                        />
                      </div>

                      <input
                        defaultValue={milestone_amount}
                        type="number"
                        className="text-input"
                        placeholder="Number of milestone"
                        min="1"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        onChange={(event) =>
                          (milestone_amount = event.target.value)
                        }
                      />
                      <div className="row-input-container">
                        <div className="forex-checkbox-group">
                          <div>
                            <input
                              defaultChecked={forex_resource === true}
                              type="radio"
                              id="Client"
                              name="Forex"
                              value="true"
                              onChange={(e) => setForexResource(true)}
                            />
                            <label for="Client"> Client</label>
                          </div>
                          <div>
                            <input
                              defaultChecked={forex_resource == false}
                              type="radio"
                              id="IE_Network_solutions"
                              name="Forex"
                              value="false"
                              onChange={(e) => setForexResource(false)}
                            />
                            <label for="IE_Network_solutions">
                              IE Network solutions
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-card-information">
              <div className="client-information sub-solutions">
                <div className="form-card-title">Project member</div>
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-fields">
                      {userRole?.data?.map((items) => (
                        <ProjectMemberSelector
                          role={items?.id}
                          list={userList}
                          setters={handleMembers}
                          label={items?.role_name}
                        />
                      ))}
                    </div>
                  </div>{" "}
                </div>
                {/* */}
              </div>
            </div>

            <div className="form-card-information">
              <div className="client-information sub-solutions">
                <div className="form-card-title">Involved Solutions</div>
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-fields">
                      <div
                        style={{
                          width: `${props?.width ? props.width : "250px"}`,
                        }}
                      >
                        <div>Departments</div>
                        <Select
                          required
                          options={Departments}
                          isSearchable={true}
                          isMulti
                          onChange={(e) => handleDepartmentChange(e)}
                          menuPlacement="auto"
                        />
                      </div>
                      {subSolutionList?.map((list) => (
                        <div className="check-box-field">
                          <input
                            type="checkbox"
                            id={list?.id}
                            className="checkbox-input"
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor={list?.id} className="v">
                            {list?.solution_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>{" "}
                </div>
                {/* */}
              </div>
            </div>

            <div className="form-buttons">
              <button
                type="cancel"
                className="form-cancel"
                onClick={HandleCancel}
              >
                {" "}
                Cancel
              </button>
              <button type="submit" className="form-save">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
