import * as React from "react";
import Grid from "@mui/material/Grid";
import "../../Logistics/logistics.css";
import { useParams } from "react-router-dom";
import { useGetForexDetailQuery } from "../../../features/SCMApi";
import TermAndConditionContainer from "./TermAndConditonContainer";
import ForexOrderItemListContainer from "./ForexApprovalDetailComponent/ForexOrderItemListContainer";
import ForexDetailsGridContainer from "./ForexApprovalDetailComponent/ForexDetailsGridContainer";
import ForexBankDetail from "./ForexApprovalDetailComponent/ForexBankDetail";
import ForexDetailPFO from "./ForexApprovalDetailComponent/ForexDetailPFO";
import ForexDetailPM from "./ForexApprovalDetailComponent/ForexDetailPM";
import ForexDetailStages from "./ForexApprovalDetailComponent/ForaxDetailStages";
import SuccessMessage from "../SuccessMessage";
import RemarkForex from "./RemarkForex";
import axios from "axios";

export default function ForexApprovalDetail() {
  const forexId = useParams();
  const { data: forexDetail } = useGetForexDetailQuery(forexId?.id);

  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [selectedId, setSelectedId] = React.useState(null);

  setTimeout(() => {
    setSuccessMessage(false);
  }, 3000);

  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const dataForForexDelete = {
    forexProject_id: "",
    forex_id: "",
    order_id: "",
  };

  const deleteResource = async (resourceId) => {
    try {
      const response = await axios.delete(
        `https://api.example.com/resources/${resourceId}`
      );

      console.log("Resource deleted successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error deleting resource:", error);
      throw error;
    }
  };

  console.log(forexDetail, forexId?.id, "forex data from backend");
  return (
    <>
      <Grid container spacing={2}>
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "10px",
                width: "97%",
                marginLeft: "20px",
                backgroundColor: "#52ffab96",
                mb: "-10px",
              }}
              Message={message}
            />
          </>
        ) : (
          <></>
        )}
        <>
          <ForexOrderItemListContainer
            successMessage={setSuccessMessage}
            message={setMessage}
            data={forexDetail?.data}
          />

          <Grid
            item
            container
            direction={{
              xs: "row",
              sm: "row",
              md: "column",
              lg: "column",
              xl: "column",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={4.5}
            xl={4.5}
          >
            <ForexDetailsGridContainer>
              <>
                <TermAndConditionContainer
                  style={{ height: "430px" }}
                  data={forexDetail?.data}
                  successMessage={setSuccessMessage}
                  message={setMessage}
                />
                <ForexBankDetail
                  data={forexDetail?.data}
                  successMessage={setSuccessMessage}
                  message={setMessage}
                />
              </>
            </ForexDetailsGridContainer>
            <ForexDetailsGridContainer>
              <ForexDetailPFO data={forexDetail?.data} />
              <ForexDetailPM data={forexDetail?.data} />
              <ForexDetailStages
                successMessage={setSuccessMessage}
                message={setMessage}
                summarizedPi={
                  forexDetail?.data[0]?.forex_summarized_pi_document
                }
                requestLetter={
                  forexDetail?.data[0]?.forex_request_letter_document
                }
                underTakingForms={
                  forexDetail?.data[0]?.forex_undertaking_document
                }
                bankSubmission={
                  forexDetail?.data[0]?.forex_bank_submission_document
                }
                swiftAndPermit={
                  forexDetail?.data[0]?.forex_bank_permit_document
                }
                consignBank={forexDetail?.data[0]?.forex_consignee_bank}
                summarizedPiId={
                  forexDetail?.data[0]?.forex_summarized_pi[0]?.id
                }
                projectId={forexDetail?.data[0]?.project_id}
              />
            </ForexDetailsGridContainer>
          </Grid>
        </>
      </Grid>
    </>
  );
}
