import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL, DOC_BASE_URL } from "../../../api/endPoint";

import { Tooltip } from "@mui/material";

export default function AddDeclaration(props) {
  const hasSecurityClearance =
    props?.previousData?.security_clearance?.length > 0 ? true : false;
  const hasTaxDocuments =
    props?.previousData?.tax_docs?.length > 0 ? true : false;

  const hasInsaApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.insa_approval != ""
  );
  const hasBroadcastApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.broadcast_approval != ""
  );
  const hasEcaApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.eca_approval != ""
  );
  const hasOriginalTax = props?.previousData?.tax_docs?.find(
    (item) => item?.original_tax != ""
  );
  const hasAdditionalTax = props?.previousData?.tax_docs?.find(
    (item) => item?.additional_tax != ""
  );
  const hasFinalDeclaration = props?.previousData?.tax_docs?.find(
    (item) => item?.final_declaration != ""
  );
  const hasPenalty = props?.previousData?.tax_docs?.find(
    (item) => item?.penalty != ""
  );
  const hasWarehouseFee = props?.previousData?.tax_docs?.find(
    (item) => item?.warehouse_fee != ""
  );

  console.log(
    // hasOriginalTax,
    // hasAdditionalTax,
    // hasFinalDeclaration,
    // hasPenalty,
    // hasWarehouseFee,
    props?.previousData?.import_customs_tax,
    "batch detail data for custom clearance"
  );
  console.log(props?.previousData, "props?.previousData");
  const [original_tax, setOriginalTax] = useState(
    hasOriginalTax !== undefined ? { name: "File Exist" } : ""
  );
  const [additional_tax, setAdditionalTax] = useState(
    hasAdditionalTax !== undefined ? { name: "File Exist" } : ""
  );
  const [additional_tax_amount, setAdditionalTaxAmount] = useState(
    props?.previousData?.additional_tax
  );
  const [broadcast_approval, setBroadcastApproval] = useState(
    hasBroadcastApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [penalty, setPenalty] = useState(
    hasPenalty !== undefined ? { name: "File Exist" } : ""
  );

  const [Commercial_Invoice, setCommercial] = useState();
  const { id } = useParams();
  const [insa_approval, setINSAApproval] = useState(
    hasInsaApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [eca_approval, setECAApproval] = useState(
    hasEcaApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [sure_tax, setSureTax] = useState(props?.previousData?.sure_tax);
  const [warehouse_fee, setWarehouseFee] = useState(
    hasWarehouseFee !== undefined ? { name: "File Exist" } : ""
  );
  const [import_customs_tax, setImportCustomsTax] = useState(
    props?.previousData?.import_customs_tax
  );
  const [excise_tax, setExciseTax] = useState(props?.previousData?.excise_tax);
  const [vat, setVat] = useState(props?.previousData?.vat);
  const [penalty_amount, setPenaltyAmount] = useState(
    props?.previousData?.penalty_amount
  );
  const [warehouse_fee_amount, setWarehouseFeeAmount] = useState(
    props?.previousData?.warehouse_fee_amount
  );
  const [withholding_tax, setWithholdingTax] = useState(
    props?.previousData?.withholding_tax
  );
  const [scanning_fee, setScanningFee] = useState(
    props?.previousData?.scanning_fee
  );
  // const [customs_tax, setCustomsTax] = useState(
  //   props?.previousData?.custom_tax
  // );
  const [customTax, setCustomTax] = useState(props?.previousData?.custom_tax);
  const [Custom_No, setCustom_No] = useState(props?.previousData?.Custom_No);
  const [EX_Rate_per_Declaretion, setEX_Rate_per_Declaretion] = useState(
    props?.previousData?.EX_Rate_per_Declaretion
  );
  const [IPI_USD_per_Declaretion, setIPI_USD_per_Declaretion] = useState(
    props?.previousData?.IPI_USD_per_Declaretion
  );
  const [Freight, setFreight] = useState(props?.previousData?.Freight);
  const [marin_insurance, setMarin_insurance] = useState(
    props?.previousData?.marin_insurance
  );
  const [Insurance, setInsurance] = useState(props?.previousData?.Insurance);
  const [other, setOther] = useState(props?.previousData?.other);
  const [customs_duty, setCustoms_duty] = useState(
    props?.previousData?.customs_duty
  );
  const [freight_sa, setFreight_sa] = useState(props?.previousData?.freight_sa);

  console.log(
    props?.previousData,

    "response from backend api for document prev data"
  );
  // props?.previousData?.batch_id
  function HandleSubmit(e) {
    e.preventDefault();
    const forms = {
      forex_id: id,
      additional_tax_amount,
      insa_approval:
        hasInsaApproval != undefined
          ? hasInsaApproval?.insa_approval
          : insa_approval,
      broadcast_approval,
      eca_approval,
      original_tax,
      additional_tax,
      sure_tax,
      penalty,
      warehouse_fee,
      import_customs_tax,
      excise_tax,
      vat,
      penalty_amount,
      warehouse_fee_amount,
      withholding_tax,
      scanning_fee,
      Custom_No,
      EX_Rate_per_Declaretion,
      IPI_USD_per_Declaretion,
      Freight,
      marin_insurance,
      Insurance,
      other,
      customs_duty,
      freight_sa,
      customTax,
      // security_clearance_id: props?.previousData?.security_clearance[0]?.id,
      tax_document_id: props?.previousData?.id,
    };

    console.log(
      // props?.previousData?.security_clearance?.length == 0 &&
      props?.previousData?.length,
      "this is update document for all data",
      props?.previousData
    );
    console.log(forms, "$$$%%%");
    // props?.previousData?.security_clearance?.length == 0 ||
    // props?.previousData?.length == 0
    // ?
    axios
      .post(
        `${API_BASE_URL}/scm/cost-build-up/showCostBuildUp_TaxDocument/${id}`,
        forms,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        props?.ToggleModal(false);
        console.log(
          response,
          forms,
          "response from backend api for document responde"
        );
        // props.ToggleModal(false);
        // window.location.reload(true);
      })
      .catch(function (error) {
        console.log(
          error,
          forms,
          "response from backend api for document responde err"
        );
      });
    //   : axios
    //       .post(
    //         `${API_BASE_URL}/scm/logistics/custom-clearance-documents/${id}`,
    //         form,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           },
    //         }
    //       )
    //       .then(function (response) {
    //         props?.ToggleModal(false);
    //         console.log(
    //           response,
    //           form,
    //           "response from backend api for document res"
    //         );
    //         // props.ToggleModal(false);
    //         window.location.reload(true);
    //       })
    //       .catch(function (error) {
    //         console.log(
    //           error,
    //           form,
    //           "response from backend api for document err"
    //         );
    //       });
  }
  console?.log(props, "propsjnkjdnkjnbdkj");
  return (
    <div className="mini-modal" onClick={(e) => props?.ToggleModal(false)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          Customs Clearance
          <div
            className="close-modal-button"
            onClick={(e) => props?.ToggleModal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="form-card-container mb-4"
          onSubmit={(e) => HandleSubmit(e)}
        >
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Tax</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Customs Number</div>
                      <input
                        defaultValue={Custom_No}
                        type="text"
                        className="text-input"
                        onChange={(e) => setCustom_No(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        EX Rate per Declaration
                      </div>
                      <input
                        defaultValue={EX_Rate_per_Declaretion}
                        type="number"
                        className="text-input"
                        onChange={(e) =>
                          setEX_Rate_per_Declaretion(e.target.value)
                        }
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        IPI USD per Declaration
                      </div>
                      <input
                        defaultValue={IPI_USD_per_Declaretion}
                        type="number"
                        className="text-input"
                        onChange={(e) =>
                          setIPI_USD_per_Declaretion(e.target.value)
                        }
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Freight</div>
                      <input
                        defaultValue={Freight}
                        type="number"
                        className="text-input"
                        onChange={(e) => setFreight(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        Freight Sea/Air
                      </div>
                      <input
                        disabled
                        defaultValue={freight_sa}
                        type="number"
                        className="text-input"
                        onChange={(e) => setFreight_sa(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Insurance</div>
                      <input
                        disabled
                        defaultValue={Insurance}
                        type="number"
                        className="text-input"
                        onChange={(e) => setInsurance(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Customs Duty</div>
                      <input
                        disabled
                        defaultValue={customs_duty}
                        type="number"
                        className="text-input"
                        onChange={(e) => setCustoms_duty(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Other</div>
                      <input
                        defaultValue={other}
                        type="number"
                        className="text-input"
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        Import Customs Taxs
                      </div>
                      <input
                        defaultValue={import_customs_tax}
                        type="number"
                        className="text-input"
                        onChange={(e) => setImportCustomsTax(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        Marin Insurance
                      </div>
                      <input
                        disabled
                        defaultValue={marin_insurance}
                        type="number"
                        className="text-input"
                        onChange={(e) => setMarin_insurance(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Customs Tax</div>
                      <input
                        defaultValue={customTax}
                        type="number"
                        className="text-input"
                        onChange={(e) => setCustomTax(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Excise Tax</div>
                      <input
                        defaultValue={excise_tax}
                        type="number"
                        className="text-input"
                        onChange={(e) => setExciseTax(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">VAT</div>
                      <input
                        defaultValue={vat}
                        type="number"
                        className="text-input"
                        onChange={(e) => setVat(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Surtax</div>
                      <input
                        defaultValue={sure_tax}
                        type="number"
                        className="text-input"
                        onChange={(e) => setSureTax(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">
                        Witholding Tax{" "}
                      </div>
                      <input
                        defaultValue={withholding_tax}
                        type="number"
                        className="text-input"
                        onChange={(e) => setWithholdingTax(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Scanning Tax </div>
                      <input
                        defaultValue={scanning_fee}
                        type="number"
                        className="text-input"
                        onChange={(e) => setScanningFee(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Additional Tax</div>
                      <input
                        defaultValue={additional_tax_amount}
                        type="number"
                        className="text-input"
                        onChange={(e) => setAdditionalTaxAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Payment</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">Penalty</div>
                        <div className="small-input-group">
                          <input
                            defaultValue={penalty_amount}
                            className="small-text-field"
                            type="number"
                            onChange={(e) => setPenaltyAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          Warehouse Fee
                        </div>
                        <div className="small-input-group">
                          <input
                            defaultValue={warehouse_fee_amount}
                            className="small-text-field"
                            type="number"
                            onChange={(e) =>
                              setWarehouseFeeAmount(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="cancel"
              className="form-cancel"
              style={{ width: "100px" }}
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              type="submit"
              className="form-save"
              style={{ width: "100px" }}

              // onClick={(event) => handleSubmit(event)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
