import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import profile from "../mule.jpg";
import { IMG_BASE_URL } from "../../../../api/endPoint";

const PM = styled(Paper)(({ theme }) => ({
  height: "90px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "60px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginBottom: "20px",
  },
  color: theme.palette.text.secondary,
  alignItems: "center",
  border: "1px solid #AEB8C9",
}));
const ForexDetailPM = (props) => {
  const roles = props?.data
    ?.map((data) => data?.forex_project?.users)
    ?.flat()
    ?.map((data) => data?.role)
    ?.filter((data) => data?.role_name === "Project Manager")
    ?.map((data) => data?.id)
    ?.flat();
  const users = props?.data?.map((data) => data?.forex_project?.users);

  const pmRole = roles && roles?.toString();
  const pm = users?.flat()?.find((data) => data?.role_id === pmRole);
  console.log(props?.data, pmRole, pm, "finally on pfo");

  return (
    <>
      <PM elevation={0} sx={{ marginBottom: "20px" }}>
        {pm && pm?.length !== 0 ? (
          <>
            <div className="logisticsRmaProfile">
              <Avatar
                alt={pm?.name}
                src={
                  pm?.avatar !== null
                    ? `${IMG_BASE_URL}${pm?.avatar}`
                    : pm?.avatar
                }
                sx={{
                  height: "55px",
                  width: "55px",
                  marginTop: "-8px",
                  // marginRight: "10px",
                }}
              />
              <div className="rmaUserName">
                {pm?.name}
                <div className="profilePositionTitle">PM</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="logisticsRmaProfile">
              <div className="rmaUserName">
                Not Assigned
                <div className="profilePositionTitle">PM</div>
              </div>
            </div>
          </>
        )}
        <></>
      </PM>
    </>
  );
};

export default ForexDetailPM;
