import React from "react";

export default function ItemsTracker(props) {
  console.log(props.activeTab, "activeTab");
  console.log(props, "props for project all data of orders");
  let list = [];
  if (props.activeTab == "rma") {
    list = props?.list?.filter(
      (items) => items.isRma == true && items.isDelivered == true
    );
  } else if (props.activeTab == "delivered") {
    list = props?.delivered;
  } else {
    list = props?.list;
  }
  console.log(list, "list");
  return (
    <div className="items-tracker">
      <div className="table-header">
        <div>no</div>
        <div>Part Number</div>
        <div>Item Description</div>
        <div>Qty</div>
        {props.activeTab == "all" ? <></> : <div>Failed items</div>}
      </div>
      <div className="tb-container">
        {list?.map((items) => (
          <div className="tables-content">
            <div className="table-data no">1</div>
            <div className="table-data item-part-number">
              {items?.item_part_number}
            </div>
            <div className="table-data item-description">
              {items?.item_description}
            </div>
            <div className="table-data">
              {props.activeTab == "rma" ? items?.failedQty : items?.qty}
            </div>
            <div className="table-data">
              {props.activeTab == "all" ? (
                <></>
              ) : items?.isRma === true && items?.isDelivered == true ? (
                <>Failed</>
              ) : (
                <>whole Sum</>
              )}
            </div>
            {props.activeTab == "all" ? (
              <></>
            ) : (
              <button
                className="edit-item"
                onClick={(e) => {
                  props.setUpdateStatusModal(true);
                  props.setItemID(items?.item_part_number);
                  props.setSelected(items);
                }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12H1.73333L9.11667 4.61667L8.38333 3.88334L1 11.2667V12ZM11.2333 3.90001L9.1 1.76667L9.8 1.06667C9.98889 0.877785 10.2222 0.78334 10.5 0.78334C10.7778 0.78334 11.0111 0.877785 11.2 1.06667L11.9333 1.80001C12.1222 1.9889 12.2167 2.22223 12.2167 2.50001C12.2167 2.77778 12.1222 3.01112 11.9333 3.20001L11.2333 3.90001ZM10.5333 4.60001L2.13333 13H0V10.8667L8.4 2.46667L10.5333 4.60001ZM8.75 4.25001L8.38333 3.88334L9.11667 4.61667L8.75 4.25001Z"
                    fill="#186569"
                  />
                </svg>
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
