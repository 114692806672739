import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreateGuaranteeMutation,
  useCreatePaymentCollectionMutation,
  useGetAllGuaranteeBalanceQuery,
  useGetAllGuaranteeTypeQuery,
  useGetBanksQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";

export default function CreateGuarantee(props) {
  const params = useParams();
  const [project_id, setProject] = useState(null);
  const [start_date, setStartDate] = useState(null);
  const [amount, setAmount] = useState(0);
  const [guarantee_type, setType] = useState(null);
  const [description, setDescription] = useState(null);
  const [bank_ref_number, setBankRef] = useState(null);
  const [ref_number, setIERef] = useState(null);
  const [period, setPeriod] = useState(null);
  const [rate, setRate] = useState(null);
  const [bank_id, setBank] = useState(null);

  const { data: projects } = useGetProjectsQuery();
  const { data: guarantee } = useGetAllGuaranteeTypeQuery();
  const { data: bank } = useGetAllGuaranteeBalanceQuery();
  const [createGuarantee] = useCreateGuaranteeMutation();
  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    project_id: props?.all == true ? project_id : params?.id,
    start_date,
    amount,
    guarantee_type,
    description,
    bank_ref_number,
    ref_number,
    period,
    rate,
    bank_id,
  };
  function handleSubmit(e) {
    e.preventDefault();
    createGuarantee(form)
      .unwrap()
      .then((response) => {
        props.modal(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  let cost = 0;
  cost = period * rate * amount;
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Create Guarantee</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <div className="d-flex justify-content-center gap-2 m-3">
            <div className="prg">Cost:</div>
            <div className="prg">{cost}</div>
          </div>
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                {props?.all == true ? (
                  <>
                    <div className="input-label">Project</div>
                    <select
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setProject(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Project
                      </option>
                      {projects?.data?.map((items) => (
                        <option value={items.id}>{items?.project_name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}
                <div className="input-label">Bank</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setBank(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Bank
                  </option>
                  {bank?.data?.map((items) => (
                    <option value={items.bank_id}>
                      {items?.bank?.bank_name}
                    </option>
                  ))}
                </select>

                <div className="supplier-input-label">Start Date</div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <div className="input-label">Type</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Type
                  </option>
                  {guarantee?.data?.map((items) => (
                    <option value={items.id}>
                      {items?.guarantee_type_name}
                    </option>
                  ))}
                </select>

                <div className="supplier-input-label">Amount</div>
                <input
                  value={amount}
                  type="number"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setAmount(e.target.value)}
                />

                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                  className="finance-input-field m-0"
                ></textarea>
                <div className="supplier-input-label">Bank Ref</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setBankRef(e.target.value)}
                />
                <div className="supplier-input-label">IE Ref</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setIERef(e.target.value)}
                />
                <div className="supplier-input-label">Period</div>
                <input
                  type="number"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setPeriod(e.target.value)}
                />
                <div className="supplier-input-label">Rate</div>
                <input
                  type="number"
                  step="any"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
            </div>

            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button type="submit" className={"form-save"}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
