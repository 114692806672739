import React from "react";
// import OrderCard from "../../components/Sourcing/OrderCard";
import "../../pages/Sourcing/orders.style.css";
// import NavBar from "../../Layout/NavBar";
import PoStage from "./PoStage";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { useState } from "react";

export default function PoStageTraning() {
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  const params = useParams();
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  console.log(Projectdata[0]?.project_name, "pastage after push");
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [order_type, setOrderType] = useState("Training");

  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="PO Stage"
      handleSearchChange={handleSearchChange}
      placeholder={"search PO order..."}
    >
      <PoStage
        name={Projectdata[0]?.project_name}
        search={search}
        order_type={order_type}
      />
    </SourcingIndex>
  );
}
