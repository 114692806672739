import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import StatusModal from "../../RMA/RmaModals/StatusModal";
import {
  useUpdateMissedItemStatusMutation,
  useGetMissedItemStatusTrackingQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../../../features/SCMApi";
import CheckProjectPermission from "../../../common/checkPermission/CheckProjectPermission";

const Item = styled(Paper)(({ theme }) => ({
  height: "90px",
  color: theme.palette.text.secondary,
  alignItems: "center",
  border: "1px solid #AEB8C9",
}));

const MissedItemStatus = (props) => {
  const { data: missedItemStatusHistory } = useGetMissedItemStatusTrackingQuery(
    props?.data?.missed_item_status?.id
  );
  const [updateMissedItemStatus] = useUpdateMissedItemStatusMutation();
  const { data: userinfo } = useGetUserInfoQuery();

  const [editStatus, setEditStatus] = React.useState(false);
  const [status, setStatus] = React.useState(
    props?.data?.missed_item_status?.status
  );
  const [updatedStatus, setUpdatedStatus] = React.useState(null);
  const [statusModal, setStatusModal] = React.useState(false);
  function StatusModalHandler() {
    setStatusModal(true);
  }

  const handleEditButton = (e) => {
    setEditStatus(true);
  };
  const handleClickHistoryButton = (e) => {
    StatusModalHandler();
  };

  const params = {
    status: status,
    id: props?.data?.missed_item_status?.id,
    created_by: userinfo?.id,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    updateMissedItemStatus(params)
      .unwrap()
      .then((response) => {
        props?.setSuccessMessage(true);
        props?.setMessage("Status updated successfully!!!");
        setUpdatedStatus(response?.data?.status);
        setEditStatus(false);
        console.log(response, params, "response from backend res");
      })
      .catch(function (response) {
        console.log(response, params,"response from backend err");
      });
  };

  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.data?.project_id
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.data?.project_id
  );

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={4.5}
        xl={4.5}
        xxl={4.5}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        <Item elevation={0}>
          <div className="rmaStatusHeading">
            <div className="rmaStatusLabel">
              Status
              {editStatus ? (
                <div>
                  <input
                    required
                    type="text"
                    className="rma-select-option status-updater"
                    placeholder="Missed item status"
                    defaultValue={
                      updatedStatus !== null
                        ? updatedStatus
                        : props?.data?.missed_item_status?.status !== null
                        ? props?.data?.missed_item_status?.status
                        : `No status added yet`
                    }
                    onChange={(event) => setStatus(event.target.value)}
                  />
                </div>
              ) : (
                <div>
                  {
                    <p className="statusName">
                      {updatedStatus !== null
                        ? updatedStatus
                        : props?.data?.missed_item_status?.status !== null
                        ? props?.data?.missed_item_status?.status
                        : `No status added yet`}
                    </p>
                  }
                </div>
              )}
            </div>
            <div className="missedItemRmaStatus">
              {CheckProjectPermission(
                "missed_item_update_status",
                projectmembers,
                userinfo,
                roleRoles
              ) ? (
                <IconButton
                  variant="text"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#064b4f",
                    "&:hover": {
                      backgroundColor: "#186569",
                      border: "1px solid #186569",
                    },
                    marginTop: "10px",
                    marginRight: "10px",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={
                    editStatus === false ? handleEditButton : HandleSubmit
                  }
                >
                  {editStatus ? (
                    <CheckIcon fontSize="10px" />
                  ) : (
                    <ModeEditOutlinedIcon sx={{ fontSize: "20px" }} />
                  )}
                </IconButton>
              ) : (
                <></>
              )}
              <IconButton
                variant="text"
                sx={{
                  color: "#fff",
                  backgroundColor: "#064b4f",
                  "&:hover": {
                    backgroundColor: "#186569",
                    border: "1px solid #186569",
                  },
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "25px",
                  height: "25px",
                }}
                onClick={handleClickHistoryButton}
              >
                <VisibilityOutlinedIcon sx={{ fontSize: "14px" }} />
              </IconButton>
            </div>
          </div>
        </Item>
        {statusModal ? (
          <StatusModal
            modal={setStatusModal}
            data={missedItemStatusHistory?.data}
            missedItemId={props?.data?.missed_item_status?.id}
            name="MissedItemStatus"
          />
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default MissedItemStatus;
