import React, { useState } from "react";
import NoRecord from "../../components/common/NoRecord";
import { useGetFaqQuestionsQuery } from "../../features/SCMApi";
import Faq from "./Faq";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RespondFaqVideo from "../../components/home/RespondFaqVideo";
export default function TotalQuestion() {
  const { data: faqQuestions } = useGetFaqQuestionsQuery();
  const [responedToFaqModal, setRespondToFaqModal] = useState(false);

  console.log(faqQuestions?.data, "faqQuestions");
  return (
    <Faq title={"Total Questions"} navlink={`/Faq/TotalQuestions`}>
      {responedToFaqModal ? (
        <RespondFaqVideo ToggleModal={setRespondToFaqModal} />
      ) : (
        <></>
      )}
      <div
        className="create-project-btn mb-2"
        onClick={(e) => setRespondToFaqModal(true)}
      >
        <QuestionAnswerIcon />
        Respond
      </div>
      <div className="faq-total-question-container">
        <div className="total-question-label">Total Questions</div>
        <div className="parent-questions-container">
          {faqQuestions?.data.length > 0 ? (
            faqQuestions?.data?.map((items) => (
              <div className="questions-container">
                <div className="faq-question-txt">{items?.question}</div>
                <div className="faq-controller">
                  <div>
                    {" "}
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.575 12.5998L7.575 10.5748C7.35834 10.3581 7.1 10.2498 6.8 10.2498C6.5 10.2498 6.24167 10.3665 6.025 10.5998C5.80834 10.7998 5.7 11.0581 5.7 11.3748C5.7 11.6915 5.80834 11.9498 6.025 12.1498L8.775 14.9248C8.99167 15.1415 9.25834 15.2498 9.575 15.2498C9.89167 15.2498 10.1583 15.1415 10.375 14.9248L15.975 9.3248C16.175 9.1248 16.275 8.8748 16.275 8.5748C16.275 8.2748 16.1667 8.01647 15.95 7.7998C15.7333 7.58314 15.4667 7.4748 15.15 7.4748C14.8333 7.4748 14.575 7.58314 14.375 7.7998L9.575 12.5998ZM11 21.1998C9.58334 21.1998 8.254 20.9331 7.012 20.3998C5.77067 19.8665 4.69167 19.1415 3.775 18.2248C2.85834 17.3081 2.13334 16.2291 1.6 14.9878C1.06667 13.7458 0.800003 12.4165 0.800003 10.9998C0.800003 9.58314 1.06667 8.2538 1.6 7.0118C2.13334 5.77047 2.85834 4.69147 3.775 3.7748C4.69167 2.85814 5.77067 2.13314 7.012 1.5998C8.254 1.06647 9.58334 0.799805 11 0.799805C12.4167 0.799805 13.746 1.06647 14.988 1.5998C16.2293 2.13314 17.3083 2.85814 18.225 3.7748C19.1417 4.69147 19.8667 5.77047 20.4 7.0118C20.9333 8.2538 21.2 9.58314 21.2 10.9998C21.2 12.4165 20.9333 13.7458 20.4 14.9878C19.8667 16.2291 19.1417 17.3081 18.225 18.2248C17.3083 19.1415 16.2293 19.8665 14.988 20.3998C13.746 20.9331 12.4167 21.1998 11 21.1998Z"
                        fill="#52FFAC"
                      />
                    </svg>
                  </div>
                  <div>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.92499 19.2004C3.30833 19.2004 2.77499 18.9797 2.32499 18.5384C1.87499 18.0964 1.64999 17.5587 1.64999 16.9254V4.07539C1.33333 4.07539 1.06666 3.96272 0.849994 3.73739C0.633327 3.51272 0.524994 3.24206 0.524994 2.92539C0.524994 2.60872 0.633327 2.34206 0.849994 2.12539C1.06666 1.90872 1.33333 1.80039 1.64999 1.80039H5.87499C5.87499 1.46706 5.98333 1.19206 6.19999 0.97539C6.41666 0.758724 6.68333 0.650391 6.99999 0.650391H11C11.3167 0.650391 11.5833 0.758724 11.8 0.97539C12.0167 1.19206 12.125 1.46706 12.125 1.80039H16.35C16.6667 1.80039 16.9333 1.90872 17.15 2.12539C17.3667 2.34206 17.475 2.60872 17.475 2.92539C17.475 3.24206 17.3667 3.51272 17.15 3.73739C16.9333 3.96272 16.6667 4.07539 16.35 4.07539V16.9254C16.35 17.5587 16.125 18.0964 15.675 18.5384C15.225 18.9797 14.6917 19.2004 14.075 19.2004H3.92499ZM5.89999 13.9254C5.89999 14.2254 5.99999 14.4797 6.19999 14.6884C6.39999 14.8964 6.64999 15.0004 6.94999 15.0004C7.24999 15.0004 7.50433 14.8964 7.71299 14.6884C7.92099 14.4797 8.02499 14.2254 8.02499 13.9254V7.07539C8.02499 6.77539 7.92099 6.52106 7.71299 6.31239C7.50433 6.10439 7.24999 6.00039 6.94999 6.00039C6.64999 6.00039 6.39999 6.10439 6.19999 6.31239C5.99999 6.52106 5.89999 6.77539 5.89999 7.07539V13.9254ZM9.97499 13.9254C9.97499 14.2254 10.0793 14.4797 10.288 14.6884C10.496 14.8964 10.75 15.0004 11.05 15.0004C11.35 15.0004 11.6 14.8964 11.8 14.6884C12 14.4797 12.1 14.2254 12.1 13.9254V7.07539C12.1 6.77539 12 6.52106 11.8 6.31239C11.6 6.10439 11.35 6.00039 11.05 6.00039C10.75 6.00039 10.496 6.10439 10.288 6.31239C10.0793 6.52106 9.97499 6.77539 9.97499 7.07539V13.9254Z"
                        fill="#FF5252"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </Faq>
  );
}
