import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import {
  useAddToStoreToolMutation,
  useGetStoreQuery,
  useAddToStoreToInventoryMutation,
  useAddPoToStoreToInventoryMutation,
  useAddPoToStoreToolMutation,
  useGetAssetCatagoriesQuery,
  useAddPoToStoreAssetMutation,
  useAddToStoreAssetsMutation,
} from "../../../../features/SCMApi";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Selector from "../../../Payment/ForexApproval/Select";
import IncomingInventories from "../../../Stock/Incoming/IncomingInventory";
import { Tooltip } from "@mui/material";

export default function SendToInventoryModal(props) {
  const [addToolToStore] = useAddToStoreToolMutation();
  const [addInventoryToStore] = useAddToStoreToInventoryMutation();
  const [addPoInventoryToStore] = useAddPoToStoreToInventoryMutation();
  const [addPoToolToStore] = useAddPoToStoreToolMutation();
  const [addPoAssetToStore] = useAddPoToStoreAssetMutation();
  const [addAssetsToStore] = useAddToStoreAssetsMutation();

  const { data: storesList } = useGetStoreQuery();
  const { data: assetList } = useGetAssetCatagoriesQuery();
  const [isChecked, setIsChecked] = useState(false);
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let ids = "";
    const length = 8; // Specify the desired length of the ID

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      ids += characters[randomIndex];
    }

    return ids;
  }
  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const [store, setStore] = useState(null);
  const [fixed_asset_category_id, setCategory] = useState(null);
  const [tools, setTools] = useState(true);
  const [inventory, setInventory] = useState("tools");

  // const addToInventory =
  //   inventory == "items" && props?.name == "batch"
  //     ? addInventoryToStore
  //     : inventory == "items" && props?.name == "po"
  //     ? addPoInventoryToStore
  //     : inventory == "tools" && props?.name == "batch"
  //     ? addToolToStore
  //     : addPoToolToStore;
  let addToInventory;

  if (inventory === "items" && props?.name === "batch") {
    addToInventory = addInventoryToStore;
  } else if (inventory === "items" && props?.name === "po") {
    addToInventory = addPoInventoryToStore;
  } else if (inventory === "tools" && props?.name === "batch") {
    addToInventory = addToolToStore;
  } else if (inventory === "tools" && props?.name === "po") {
    addToInventory = addPoToolToStore;
  } else if (inventory === "assets" && props?.name === "po") {
    addToInventory = addPoAssetToStore;
  } else {
    addToInventory = addAssetsToStore;
  }
  console.log(tools && props?.name == "batch", "addToInventory");
  let itemsInventory = [];
  props?.items?.map((item, index) =>
    itemsInventory?.push({ ...item, ids: generateRandomId() })
  );

  itemsInventory = [
    ...itemsInventory
      ?.filter((item) => item?.missed_item == false && item?.rma == false)
      ?.filter((item) =>
        item?.is_inventory ? item?.is_inventory == false : item
      ),
  ];
  const [selectedInventoryItems, setInventoryItems] = useState([]);
  let allInventoryItems = [];
  itemsInventory?.map((items, index) => allInventoryItems?.push(index));
  const handleChangeInventory = (e, items) => {
    const { checked } = e.target;

    if (items == "all") {
      // Select All option is checked

      if (checked) {
        // Add all permissions to the permissions array
        setInventoryItems(allInventoryItems);
      } else {
        // Uncheck Select All and remove all permissions from the array
        setInventoryItems([]);
      }
    } else {
      if (checked) {
        // Add the permission to the permissions array
        setInventoryItems((prevInventoryItem) => [...prevInventoryItem, items]);
      } else {
        // Remove the permission from the permissions array
        setInventoryItems((prevInventoryItem) =>
          prevInventoryItem.filter((id, index) => index != items)
        );
      }
    }
  };

  const items = itemsInventory?.filter((item, index) =>
    selectedInventoryItems?.includes(index)
  );

  const [newItems, setNewItems] = useState(items);

  // useEffect(() => {
  //   // Update newItems based on items
  //   setNewItems(items);
  // }, [items]);
  // useEffect(() => {
  //   try {
  //     setNewItems(items);
  //   } catch (error) {
  //     console.error("Error in useEffect:", error);
  //   }
  // }, [items]);
  const [formData, setFormData] = useState(itemsInventory);

  function handleItemQty(e, item, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...formData];
    const itemnew = [...items];
    itemnew[i] = { ...item, send_qty: value };
    tools[i] = { ...item, send_qty: value };
    setFormData(tools);
    setNewItems(itemnew);
  }

  function HandleCancel() {
    props.modal(false);
  }

  function handleSubmit(e, className) {
    e.preventDefault();
    const params = {
      batch_id: props?.batchId,
      po_id: props?.batchId,
      is_countable: isChecked,
      fixed_asset_category_id: assetList?.data
        ?.filter((filterStore) => filterStore?.name == fixed_asset_category_id)
        ?.map((store) => store?.id)[0],
      store_id: storesList?.data
        ?.filter((filterStore) => filterStore?.store_name == store)
        ?.map((store) => store?.id)[0],
      items: JSON.stringify(items),
    };
    addToInventory(params)
      .unwrap()
      .then((response) => {
        console.log(
          response,
          params,
          "response from backend api for group item res"
        );
        props.modal(false);
      })
      .catch((error) => {
        console.log(error, "response from backend api for group item err");
      });
  }
  console.log(newItems, items, "inventoryItems");
  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div className="previousDocumnetTitle">sssss to Inventory</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <div style={{ marginTop: "-10px" }}>
            <div className="d-flex gap-2 justify-content-center">
              <div className="text-center">
                <label className="d-flex gap-2 align-items-baseline justify-content-center">
                  <input
                    type="checkbox"
                    className="createGroup-items"
                    checked={isChecked}
                    onChange={handleChange}
                    required
                  />
                  <span className="h5">Countable</span>
                </label>
              </div>
              <div className="rmaShipmentArrangement">
                <FormControl sx={{ marginTop: "-30px", marginLeft: "15px" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue="tools"
                  >
                    <FormControlLabel
                      sx={{
                        color: "#AEB8C9",
                        ".MuiFormControlLabel-label": tools === "tools" && {
                          color: "#186569",
                        },
                      }}
                      value="tools"
                      control={
                        <Radio
                          onChange={(e) => {
                            setTools(true);
                            setInventory("tools");
                          }}
                          sx={{
                            color: "#AEB8C9",
                            "&.Mui-checked": {
                              color: "#186569",
                            },
                          }}
                        />
                      }
                      label={
                        <div className="shipment-arra-radio-button-lable">
                          Tools
                        </div>
                      }
                    />

                    <FormControlLabel
                      sx={{
                        color: "#AEB8C9",
                        ".MuiFormControlLabel-label": inventory === "items" && {
                          color: "#186569",
                        },
                      }}
                      value="items"
                      control={
                        <Radio
                          onChange={(e) => {
                            setTools(false);
                            setInventory("items");
                          }}
                          sx={{
                            color: "#AEB8C9",
                            "&.Mui-checked": {
                              color: "#186569",
                            },
                          }}
                        />
                      }
                      label={
                        <div className="shipment-arra-radio-button-lable">
                          Items
                        </div>
                      }
                    />
                    <FormControlLabel
                      sx={{
                        color: "#AEB8C9",
                        ".MuiFormControlLabel-label": inventory ===
                          "assets" && {
                          color: "#186569",
                        },
                      }}
                      value="assets"
                      control={
                        <Radio
                          onChange={(e) => {
                            setTools(false);
                            setInventory("assets");
                          }}
                          sx={{
                            color: "#AEB8C9",
                            "&.Mui-checked": {
                              color: "#186569",
                            },
                          }}
                        />
                      }
                      label={
                        <div className="shipment-arra-radio-button-lable">
                          Assets
                        </div>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="d-flex gap-2 justify-content-center mb-2">
              <span style={{ marginTop: "10px" }}>Store</span>
              <Selector
                name={storesList?.data?.map((store) => store?.store_name)}
                onChange={setStore}
                required={true}
              />
            </div>
            {inventory === "assets" ? (
              <div className="d-flex gap-2 justify-content-center mb-2">
                <span style={{ marginTop: "10px" }}> category</span>
                <Selector
                  name={assetList?.data?.map((items) => items?.name)}
                  onChange={setCategory}
                  required={true}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              marginLeft: "-1px",
              marginRight: "-1px",
              marginTop: "10px",
              borderBottom: "1px solid #ececec",
            }}
          ></div>
          {itemsInventory?.length != 0 ? (
            <label className="d-flex justify-content-start mb-2 ms-5 gap-2">
              <input
                type="checkbox"
                onChange={(e) => handleChangeInventory(e, "all")}
              />
              <span className="prg-pri">Select All</span>
            </label>
          ) : (
            ""
          )}
          <form
            className="form-container"
            onSubmit={(e) => handleSubmit(e, "createGroup-items")}
          >
            <div className="form-card-container">
              <div className="creategroup-card-information ms-0">
                <div className="card-center">
                  <div className="supplier-input-labels">
                    {itemsInventory?.map((item, index) => (
                      <div className="text-center">
                        <label className="d-flex gap-2 align-items-center ">
                          {items[index] ? (
                            <input
                              defaultValue={item?.qty}
                              type="number"
                              className="store-amount-input"
                              onChange={(e) => handleItemQty(e, item, index)}
                            />
                          ) : (
                            ""
                          )}
                          <input
                            type="checkbox"
                            checked={selectedInventoryItems?.includes(index)}
                            onChange={(e) => handleChangeInventory(e, index)}
                          />

                          <Tooltip title={item?.item_description}>
                            <div className="prg text-start">
                              {item?.item_description?.length >= 150
                                ? item?.item_description?.slice(0, 150) + "..."
                                : item?.item_description}
                              {items?.qty}
                            </div>
                          </Tooltip>
                        </label>
                        {formData[index]?.send_qty > item?.qty ||
                        formData[index]?.send_qty <= 0 ? (
                          <span className="prg-error text-danger">
                            Please enter correct value
                          </span>
                        ) : (
                          items?.requested_amount
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2">
              <button
                type="cancel"
                className="form-cancel"
                onClick={(e) => HandleCancel(e)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="form-save"
                style={{ width: "105px" }}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
