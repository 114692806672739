import React, { useState } from "react";
import { useAddClientMutaion } from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import attachmentIcon from "../../../../assets/SVG/attach.svg";

export default function RmaSCModal(props) {
  // const [addClient, response] = useAddClientMutaion();
  const [loadingBill, setLoadingBill] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let params = {
    batch_id: props?.batchId,
    rma_id: props?.rmaId,
    loading_bill: loadingBill,
  };

  const handleLoadingBill = (e) => {
    setLoadingBill(e?.target?.files[0]);
    setIsFileSelected(true);
  };

  console.log(params, "response from database");
  function HandleCancel() {
    props.modal(false);
  }

  const handleClick = () => {
    isFileSelected === true
      ? setErrorMessage(true)
      : props?.previousData?.length !== 0
      ? setErrorMessage(true)
      : setErrorMessage(false);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    isFileSelected ? (
      props?.previousDataEx?.length === 0 ||
      props?.previousDataIm?.length === 0 ? (
        axios
          .post(
            props.name === "awbEx"
              ? `${API_BASE_URL}/scm/rma/export/billing-document`
              : props.name === "awbIm"
              ? `${API_BASE_URL}/scm/rma/import/billing-document`
              : "",
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            props?.successMessage(true);
            props?.message("Airway bill document attached successfully!!!");
            console.log(response, "response from database");
            HandleCancel();
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      ) : (
        axios
          .post(
            props?.name === "awbEx" ? (
              `${API_BASE_URL}/scm/rma/export/billing-document/${props?.previousDataEx[0]?.id}`
            ) : props?.name === "awbIm" ? (
              `${API_BASE_URL}/scm/rma/import/billing-document/${props?.previousDataIm[0]?.id}`
            ) : (
              <></>
            ),

            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response, "response from database");
            HandleCancel();
            props?.successMessage(true);
            props?.message("Airway bill document updated successfully!!!");
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      )
    ) : (
      <></>
    );
  };

  console.log(
    props?.previousDataEx,
    props?.previousDataIm,
    "rma export previous attached documents data"
  );

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="logisticsRma-projectModal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div
              className="previousDocumnetTitle"
              style={{ marginRight: "57%" }}
            >
              {props.name === "awbEx" ? (
                "Attach AWB"
              ) : props.name === "awbIm" ? (
                "Collect AWB"
              ) : (
                <></>
              )}
            </div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {errorMessage === true ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "-30px", marginBottom: "60px" }}
                    >
                      Please select a file !!!
                    </p>
                  </>
                ) : props?.name === "awbEx" ? (
                  props?.previousDataEx?.length !== 0 ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{
                          marginTop: "-30px",
                          marginBottom: "60px",
                        }}
                      >
                        Airway bill document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : props?.name === "awbIm" ? (
                  props?.previousDataIm?.length !== 0 ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{ marginTop: "-30px", marginBottom: "60px" }}
                      >
                        Airway bill document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form" style={{ marginTop: "-30px" }}>
                    <div className="input-labels inputLabelsHolder">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginLeft: "-30px" }}
                      >
                        Bill of Loading / AWB
                      </div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.name === "awbEx"
                              ? props?.previousDataEx?.length === 0
                                ? true
                                : false
                              : props?.name === "awbIm"
                              ? props?.previousDataIm?.length === 0
                                ? true
                                : false
                              : null
                          }
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleLoadingBill(e)}
                        />
                        <div className="rmaFileUploader">
                          {loadingBill
                            ? loadingBill?.name?.slice(0, 10) + "...."
                            : props?.name === "awbEx"
                            ? props?.previousDataEx?.length !== 0
                              ? "File exist"
                              : "File"
                            : props?.name === "awbIm"
                            ? props?.previousDataIm?.length !== 0
                              ? "File exist"
                              : "File"
                            : " File"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel"
                  onClick={HandleCancel}
                  style={{ width: "112px", height: "32px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save"
                  style={{ width: "112px", height: "32px" }}
                  onClick={
                    props?.name === "awbEx"
                      ? props?.previousDataEx?.length !== 0
                        ? handleClick
                        : ""
                      : props?.name === "awbIm"
                      ? props?.previousDataIm?.length !== 0
                        ? handleClick
                        : ""
                      : ""
                  }
                >
                  {props?.name === "awbEx"
                    ? props?.previousDataEx?.length !== 0
                      ? "Update"
                      : "Save"
                    : props?.name === "awbIm"
                    ? props?.previousDataIm?.length !== 0
                      ? "Update"
                      : "Save"
                    : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
