import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RegisterItem from "../StockModal/RegisterItem";
import {
  useGetInventoryQuery,
  useInventoryRequestMutation,
  useGetUserInfoQuery,
  useGetStoreQuery,
  useGetItemCategorysQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import StockDialog from "../../common/StockDialog";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IMG_BASE_URL } from "../../../api/endPoint";
import ConfirmRequestInventory from "../StockModal/ConfirmRequestInventory";
import EditInventory from "../StockModal/EditInventory";
import usePagination from "../../Pagination/Pagination";
import { Pagination, Tooltip } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import InventoryReport from "../DownloadReports/InventoryReport";
import DownloadIcon from "@mui/icons-material/Download";
export default function Inventories(props) {
  const { data: userinfo } = useGetUserInfoQuery();
  const [inventoryRequest] = useInventoryRequestMutation();
  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  // userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  let SA = userinfo?.role?.role_name == "Super Admin";
  const [inventoriesupdate, setInventory] = useState(false);
  const [registerItem, setRegisterItem] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: inventory } = useGetInventoryQuery();
  function HandleItem() {
    setInventory(true);
  }
  function HandleItemCreate() {
    setRegisterItem(true);
  }
  function HandleRequest() {
    setConfirm(true);
  }

  // console.log(dialog, "dialog");
  console.log(inventory?.data, "check inventory list");
  console.log(SK, "check inventory list");
  const [search, setSearch] = useState("");
  const [requestedProject, setRequestedProject] = useState("");

  function HandleInventoryRequest(e, id) {
    console.log(id, "request success response from backend");
    let requested = {
      id: id,
      quantity: Number(quantity),
      requested_project: requestedProject,
    };

    inventoryRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        console.log(response, id, "request success response from backend res");
        // props?.setMessages("Inventory Requested successfully");
      })
      .then((error) => {
        console.log(error, id, "request success response from backend err");
      });
  }
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  console.log(store, "storeStore");
  const storeData = store?.data?.filter((item) => item?.is_permanent == 1);
  let notRequest = [];
  let inventories = [];
  inventory?.data?.forEach((items) => {
    const isNotRequested = items?.is_requested == 0;
    const isPermanentStore = items?.store?.is_permanent == 1;
    if ((isNotRequested || items?.is_requested === 1) && isPermanentStore) {
      notRequest.push(items);
    }
  });

  console.log(store, "storestore");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    console.log(search, "search");
  };
  const HandleFragment = (inventory) => {
    props?.setFragment("detail");
    props?.setData(inventory);
  };
  console?.log(notRequest, "notRequest");
  notRequest?.filter((items) => {
    if (store_id == "" || category == "") {
      inventories.push(items);
    } else if (items?.store_id == store_id && items?.category_id == category) {
      inventories.push(items);
    }
  });
  console.log(requestedProject, "in test inventory");
  console.log(inventory, "inventorysss");
  const [updateInventory, setupdateInventory] = useState(null);

  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined && element.requested_amount != "";
  });
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  console.log(filteredData(search, category, store_id), "confirmRequestItems");
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(data, "confirmRequestItems?.length");
  return (
    <div>
      {SK ? (
        <div className="d-flex justify-content-center mb-3 mt-3">
          <div className="create-project-btn" onClick={HandleItemCreate}>
            <AddIcon
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#186569",
                },
              }}
            />
            Register Item
          </div>
        </div>
      ) : (
        ""
      )}

      <>
        <div className="inventory-header-container">
          <InventoryReport
            data={filteredData(search, category, store_id)}
            type={"Items Lists"}
          />

          <Tooltip title="Batch Request">
            <Inventory2Icon
              sx={{ color: "#064b4f" }}
              onClick={HandleConfirmRequest}
            />
          </Tooltip>

          <input
            onChange={handleSearchChange}
            placeholder="search"
            type="search"
            className="inventory-search-input"
          />
          <select
            type="search"
            className="inventory-search-input"
            onChange={(e) => setStore_id(e.target.value)}
          >
            <option value="">Store</option>
            {storeData?.map((items) => (
              <option value={items?.id}>{items?.store_name}</option>
            ))}
          </select>

          <select
            type="search"
            className="inventory-search-input"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Item Group</option>
            {ItemCategory?.data?.map((items) => (
              <option value={items?.id}>{items?.category}</option>
            ))}
          </select>

          <div className="items-counter">
            <div className="text-center">
              {filteredData(search, category, store_id)?.length}
            </div>
            <div>Items Count</div>
          </div>
        </div>
        {_DATA?.currentData() != 0 ? (
          <div className="finance-table-container">
            <table
              className="table table-striped table-bordered "
              style={{
                width: "auto",
                minWidth: "2000px",
                borderCollapse: "collapse",
              }}
            >
              <thead className="stock-table-header">
                <tr>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "14%" }}>Item Name</th>
                  <th style={{ width: "15%" }}>Model</th>
                  <th style={{ width: "17%" }}>Type</th>
                  <th style={{ width: "10%" }}>Store</th>
                  <th style={{ width: "10%" }}>Quantity</th>
                  <th style={{ width: "10%" }}>Project</th>
                  <th style={{ width: "10%" }}>Status</th>
                  <th style={{ width: "20%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {_DATA
                  ?.currentData()

                  .map((items, index) => (
                    <tr className="stock-table-row">
                      <td className="">
                        <img
                          className="stock-img"
                          src={`${IMG_BASE_URL}${items?.image}`}
                          alt=""
                        />
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.item_name}
                      </td>

                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.model}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.inventory_type_id === null
                          ? "-"
                          : items?.type?.inventory_type}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.store_id === null
                          ? "-"
                          : items?.store?.store_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {`${items?.amount === null ? "-" : items?.amount} ${
                          items?.uom_id == null ? "-" : items?.uom?.name
                        }`}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.project_id === null
                          ? "-"
                          : items?.project?.project_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(items);
                        }}
                      >
                        {items?.status_id === null
                          ? "-"
                          : items?.status?.status_name}
                      </td>

                      <td className="text-start d-flex justify-content-center gap-3 align-items-center">
                        {items?.amount != 0 ? (
                          <>
                            {items?.store?.is_permanent === 1 && (
                              <div className="d-grid gap-3">
                                <button
                                  className="form-save"
                                  onClick={() => {
                                    HandleRequest();
                                    setDialog(items);
                                  }}
                                >
                                  Request
                                </button>
                              </div>
                            )}

                            {PM && items?.is_group === 1 && (
                              <div className="d-grid gap-3">
                                <button
                                  className="form-save"
                                  onClick={() => {
                                    HandleRequest();
                                    setDialog(items);
                                  }}
                                >
                                  Request
                                </button>
                              </div>
                            )}

                            {(SK || SA) && true && (
                              <div className="d-flex justify-content-center gap-2 align-items-center">
                                <ModeEditOutlineOutlinedIcon
                                  onClick={() => {
                                    HandleItem(true);
                                    setupdateInventory(items);
                                  }}
                                  sx={{
                                    color: "#52FFAC",
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                      color: "#52FFAC",
                                      border: "1px solid #52FFAC",
                                      borderRadius: 25,
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          `${items?.item_name} has no amount`
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <NoRecord />
        )}
      </>

      {registerItem ? (
        <RegisterItem modal={setRegisterItem} />
      ) : confirmRequest ? (
        <ConfirmRequestInventory data={data} modal={setConfirmRequest} />
      ) : (
        ""
      )}
      {inventoriesupdate ? (
        <EditInventory data={updateInventory} modal={setInventory} />
      ) : (
        ""
      )}

      {confirm ? (
        <StockDialog
          setDialog={setConfirm}
          projectId={setRequestedProject}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          name="request"
          action={(e) => HandleInventoryRequest(e, dialog?.id)}
          setQuantity={setQuantity}
          quantity={quantity}
          is_return={false}
          requestedProject={requestedProject}
        />
      ) : (
        ""
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
