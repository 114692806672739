import axios from "axios";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../api/endPoint";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetInventoryRequestedQuery,
  useGetToolRequestedQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
} from "../../../../features/SCMApi";
import NavBar from "../../../../Layout/NavBar";
import StockIndex from "../../../../pages/Stocks/StockIndex";
import RequestInventoryItemDescription from "./RequestInventoryItemDescription";

export default function RequestInventoryDetail(props) {
  const params = useParams();
  const { data: InventoryDetail } = useGetInventoryRequestedQuery(params?.id);
  console.log(InventoryDetail, "InventoryDetail");

  return (
    <StockIndex>
      <RequestInventoryItemDescription
        setFragment={props?.setFragment}
        data={InventoryDetail?.data}
      />
    </StockIndex>
  );
}
