import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useEditUnitOfMeasurementMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "./modal.css";

export default function EditUnitofMeasurenment(props) {
  const [updateToolType, res] = useEditUnitOfMeasurementMutation();
  const [name, setTool_type] = useState(props?.data?.name);
  const [is_countable, setCountable] = useState(props?.data?.is_countable);
  const [description, setDescription] = useState(props?.data?.description);
  let toolType = {
    id: props?.data?.id,
    is_countable,
    name,
    description,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    updateToolType(toolType)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }
  const { data: response } = useGetProjectsQuery();
  const { data: users } = useGetUsersQuery();
  const CheckBoxHandel = () => {
    setCountable(true);
  };
  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Edit Tool Type</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="d-grid gap-3">
              <div className="d-flex gap-0 align-items-baseline justify-content-center mt-2">
                <label className="d-flex gap-2 ">
                  <input
                    type="checkbox"
                    className="checkmark"
                    onChange={CheckBoxHandel}
                  />
                  <span className="h6">is Countable</span>
                </label>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Name</div>
                <input
                  value={name}
                  onChange={(e) => setTool_type(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>
              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
