import React, { useState } from "react";
import "../../logistics.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  useGetRmaMissedItemFrightForwarderListQuery,
  useGetRmaMissedItemVendorListQuery,
  useRmaShipmentArrangementMutation,
} from "../../../../features/SCMApi";

export default function RmaShipmentArrangementModal(props) {
  const { data: frightForwarderList } =
    useGetRmaMissedItemFrightForwarderListQuery();
  const { data: VendorList } = useGetRmaMissedItemVendorListQuery();
  const [attachShipmentArrangement] = useRmaShipmentArrangementMutation();

  const [freightForwarderController, setFreightForwarderController] =
    useState(true);
  const [vendorController, setVendorController] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  const [freightForwarderId, setFreightForwarderId] = useState(null);
  const [distributerId, setDistributerId] = useState(null);

  let params =
    freightForwarderController === true
      ? {
          batch_id: "01a61f6d-e0d7-4df3-9c36-93f4336d8899",
          freight_forwarder_id: freightForwarderId,
        }
      : {
          batch_id: "01a61f6d-e0d7-4df3-9c36-93f4336d8899",
          vendor_id: distributerId,
        };

  console.log(params, "shipment arrangement parameter data ");

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    attachShipmentArrangement(params)
      .unwrap()
      .then((response) => {
        props?.successMessage(true);
        props?.message("Customs clearance document attached successfully!!!");
        HandleCancel();
        console.log(response, "response  database");
      })
      .then((error) => {
        console.log(error, "error from backend");
      });
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="previousDocumnetTitleShipment">
              Shipment Arrangement
            </div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <div className="rmaShipmentArrangement">
            <FormControl sx={{ marginTop: "-45px" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                defaultValue="frightForwarder"
              >
                <FormControlLabel
                  sx={{
                    color: "#AEB8C9",
                    ".MuiFormControlLabel-label": checked1 === true && {
                      color: "#186569",
                    },
                  }}
                  value="frightForwarder"
                  control={
                    <Radio
                      onChange={(e) => {
                        setChecked1(true);
                        setChecked2(false);
                        setFreightForwarderController(true);
                      }}
                      sx={{
                        color: "#AEB8C9",
                        "&.Mui-checked": {
                          color: "#186569",
                        },
                      }}
                    />
                  }
                  label={
                    <div className="shipment-arra-radio-button-lable">
                      By Freight Forwarder
                    </div>
                  }
                />

                <FormControlLabel
                  sx={{
                    color: "#AEB8C9",
                    ".MuiFormControlLabel-label": checked2 === true && {
                      color: "#186569",
                    },
                  }}
                  value="byVendor"
                  control={
                    <Radio
                      onClick={(e) => setFreightForwarderController(false)}
                      onChange={(e) => {
                        setChecked1(false);
                        setChecked2(true);
                        setVendorController(true);
                      }}
                      sx={{
                        color: "#AEB8C9",
                        "&.Mui-checked": {
                          color: "#186569",
                        },
                      }}
                    />
                  }
                  label={
                    <div className="shipment-arra-radio-button-lable">
                      By Vendor
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          <form
            className="form-container rmaMissedItemFormShipment forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              {freightForwarderController === true ? (
                <div
                  className="row align-items-center justify-content-center"
                  style={{ marginTop: "3%" }}
                >
                  <select
                    name=""
                    id=""
                    className="select-option-logistics"
                    onChange={(e) => setFreightForwarderId(e.target.value)}
                  >
                    <option value="ola">Select Freight Forwarder</option>
                    {frightForwarderList?.data?.map((forwarder) => (
                      <option key={forwarder?.id} value={forwarder?.id}>
                        {forwarder?.frieght_forwarder_name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : vendorController === true ? (
                <div
                  className="row align-items-center justify-content-center"
                  style={{ marginTop: "3%" }}
                >
                  <select
                    name=""
                    id=""
                    className="select-option-logistics"
                    onChange={(e) => setDistributerId(e.target.value)}
                  >
                    <option value="ola">Select Vendor</option>
                    {VendorList?.data?.map((vendor) => (
                      <option key={vendor?.id} value={vendor?.id}>
                        {vendor?.distributor_name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <></>
              )}
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
