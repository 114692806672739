import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddStatus from "./SettingModal/AddStatus";
import EditStatus from "./SettingModal/EditStatus";
import {
  useDeleteStatusSettingMutation,
  useGetStatusQuery,
  useGetStatusuery,
} from "../../features/SCMApi";
export default function StatusSetting() {
  const [addstatus, setAddStatus] = useState(false);
  const [editstatus, setEditStatus] = useState(false);

  const HandleStatus = () => {
    setAddStatus(true);
  };
  const HandleEditStatus = () => {
    setEditStatus(true);
  };
  const { data: status } = useGetStatusQuery();
  console.log(status, "status");
  const [updateStatus, setStatus] = useState();
  const [DeleteStore, src] = useDeleteStatusSettingMutation();

  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  return (
    <div className="pcard shipment-destination">
      <section className="pi-header">
        <h3>Status</h3>
      </section>
      {status?.data?.map((items) => (
        <section className="pi-body">
          <p>{items?.status_name}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                HandleEditStatus(true);
                setStatus(items);
              }}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={(e) => {
                HandleDelete(e, items.id);
              }}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      {/* {count > 1 ? (
      <Pagination
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />
    ) : (
      ""
    )} */}
      <section className="pi-footer">
        <div onClick={HandleStatus} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addstatus ? <AddStatus modal={setAddStatus} /> : ""}
      {editstatus ? (
        <EditStatus modal={setEditStatus} data={updateStatus} />
      ) : (
        ""
      )}
    </div>
  );
}
