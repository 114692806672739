import React, { useState } from "react";
import { IMG_BASE_URL } from "../../api/endPoint";
import {
  useDeleteSolutionMutation,
  useDeleteSubSolutionMutation,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import CheckPermission from "../common/checkPermission/checkPermission";
import ConfirmDelete from "../common/ConfirmDelete";
import AddNewSubSolution from "./AddNewSubSolution";
import ChangePmrModal from "./ChangePmrModal";
import EditSolution from "./EditSolution";
import EditSubSolutionModal from "./EditSubSolutionModal";

export default function ViewSolution(props) {
  const [optionModal, setModal] = useState(false);
  const [abbreviation, setAbbreviation] = useState();
  const [deleteDialog, setDialog] = useState(false);

  const [deleteSubSolutionDialog, setSubSolutionDialog] = useState(false);
  const [selectedSubSolution, setSubSolution] = useState("");
  const [deleteSolution, response] = useDeleteSolutionMutation();
  const [deleteSubSolution] = useDeleteSubSolutionMutation();
  const [isConfirmed, setConfirmation] = useState(false);

  const [editSubSolutionModal, setEditSubSolutionModal] = useState(false);
  const [editSolution, setEditSolutionModal] = useState(false);
  const [params, setParams] = useState("");
  const [addSubSolutionModal, setAddSubSolutionModal] = useState(false);
  const [changePmrModal, setChangePmrModal] = useState(false);

  let avatar = [];
  const actionRemoveSubSolution = () => {
    deleteSubSolution(selectedSubSolution)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
  };
  const actionRemove = () => {
    deleteSolution(props?.solutions?.id)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        props?.ToggleModal(false);
        props?.setSuccessMessage(true);
        props?.setMessage("Solution deleted successfully");
        // window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
  };
  const { data: userinfo } = useGetUserInfoQuery();
  return (
    <>
      <div
        id="myModal"
        className="mini-modal"
        onClick={(e) => props?.ToggleModal(false)}
      >
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div className="d-flex align-items-center gap-2 cursor-pointer">
              {" "}
              {props?.solutions?.department_name}{" "}
              {CheckPermission("product_edit_pmr", userinfo) ? (
                <svg
                  onClick={(e) => {
                    console.log(setEditSolutionModal(true));
                  }}
                  width="25"
                  height="25"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.625 10.3749H2.26667L8.72708 3.91447L8.08542 3.2728L1.625 9.73322V10.3749ZM10.5792 3.28738L8.7125 1.42072L9.325 0.808216C9.49028 0.642938 9.69688 0.56273 9.94479 0.567591C10.1927 0.572452 10.3993 0.657522 10.5646 0.822799L11.1917 1.44988C11.3569 1.61516 11.4396 1.81933 11.4396 2.06238C11.4396 2.30544 11.3569 2.50961 11.1917 2.67488L10.5792 3.28738ZM1.1875 11.2499C1.06111 11.2499 0.956597 11.2086 0.873958 11.1259C0.79132 11.0433 0.75 10.9388 0.75 10.8124V9.55822C0.75 9.49988 0.759722 9.44641 0.779167 9.3978C0.798611 9.34919 0.832639 9.30058 0.88125 9.25197L8.1 2.03322L9.96667 3.89988L2.74792 11.1186C2.69931 11.1672 2.65069 11.2013 2.60208 11.2207C2.55347 11.2402 2.5 11.2499 2.44167 11.2499H1.1875ZM8.40625 3.59363L8.08542 3.2728L8.72708 3.91447L8.40625 3.59363Z"
                    fill="#186569"
                  />
                </svg>
              ) : (
                ""
              )}
            </div>

            <div
              className="close-modal-button"
              onClick={(e) => props?.ToggleModal(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                  fill="#353945"
                />
              </svg>
            </div>
          </div>
          <div className="solution-profile">
            <div className="top-profile">
              <div className="pmr-container">
                {props.solutions?.pmr?.avatar != null ? (
                  <img
                    src={`${IMG_BASE_URL}${props.solutions?.pmr?.avatar}`}
                    alt=""
                  />
                ) : (
                  <div className="default-avatar">
                    <div style={{ display: "none" }}>
                      {(avatar = props?.solutions?.pmr?.name.split(" "))}
                    </div>
                    {props.solutions?.pmr == null ? "" : avatar[0]?.charAt(0)}
                    {props.solutions?.pmr == null ? "" : avatar[1]?.charAt(0)}
                  </div>
                )}
                <div className="centered-content-row">
                  <div className="solution-pmr-name">
                    {props.solutions?.pmr?.name}
                  </div>
                  {CheckPermission("product_edit_pmr", userinfo) ? (
                    <svg
                      onClick={(e) => {
                        console.log(setChangePmrModal(true));
                      }}
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.625 10.3749H2.26667L8.72708 3.91447L8.08542 3.2728L1.625 9.73322V10.3749ZM10.5792 3.28738L8.7125 1.42072L9.325 0.808216C9.49028 0.642938 9.69688 0.56273 9.94479 0.567591C10.1927 0.572452 10.3993 0.657522 10.5646 0.822799L11.1917 1.44988C11.3569 1.61516 11.4396 1.81933 11.4396 2.06238C11.4396 2.30544 11.3569 2.50961 11.1917 2.67488L10.5792 3.28738ZM1.1875 11.2499C1.06111 11.2499 0.956597 11.2086 0.873958 11.1259C0.79132 11.0433 0.75 10.9388 0.75 10.8124V9.55822C0.75 9.49988 0.759722 9.44641 0.779167 9.3978C0.798611 9.34919 0.832639 9.30058 0.88125 9.25197L8.1 2.03322L9.96667 3.89988L2.74792 11.1186C2.69931 11.1672 2.65069 11.2013 2.60208 11.2207C2.55347 11.2402 2.5 11.2499 2.44167 11.2499H1.1875ZM8.40625 3.59363L8.08542 3.2728L8.72708 3.91447L8.40625 3.59363Z"
                        fill="#186569"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                <div className="solution-pmr-label">PRM</div>
              </div>
              {props.solutions?.solution?.map((items) => (
                <div key={items.solution_name} className="solution-card">
                  <div className="sub-solution px-2">
                    <div>{items?.solution_name}</div>
                    <div className="sub-solution-controller d-flex gap-2 px-3 align-items-center ">
                      {CheckPermission(
                        "product_delete_subsoulution",
                        userinfo
                      ) ? (
                        <svg
                          onClick={(e) => {
                            setSubSolutionDialog(true);
                            setSubSolution(items?.id);
                            setAbbreviation(items.solution_name);
                          }}
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.89375 13.75C1.59375 13.75 1.33125 13.6375 1.10625 13.4125C0.88125 13.1875 0.76875 12.925 0.76875 12.625V1.9375H0V0.8125H3.525V0.25H8.475V0.8125H12V1.9375H11.2313V12.625C11.2313 12.925 11.1187 13.1875 10.8937 13.4125C10.6687 13.6375 10.4062 13.75 10.1063 13.75H1.89375ZM10.1063 1.9375H1.89375V12.625H10.1063V1.9375ZM3.88125 11.0125H5.00625V3.53125H3.88125V11.0125ZM6.99375 11.0125H8.11875V3.53125H6.99375V11.0125ZM1.89375 1.9375V12.625V1.9375Z"
                            fill="#FF5252"
                          />
                        </svg>
                      ) : (
                        ""
                      )}

                      {CheckPermission(
                        "product_edit_subsoulution",
                        userinfo
                      ) ? (
                        <svg
                          onClick={(e) => {
                            setEditSubSolutionModal(true);
                            setParams(items);
                          }}
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.375 12.625H2.2L10.5062 4.31877L9.68125 3.49377L1.375 11.8V12.625ZM12.8875 3.51252L10.4875 1.11252L11.275 0.325024C11.4875 0.112524 11.7531 0.00939872 12.0719 0.0156487C12.3906 0.0218987 12.6563 0.131274 12.8688 0.343774L13.675 1.15002C13.8875 1.36252 13.9938 1.62502 13.9938 1.93752C13.9938 2.25002 13.8875 2.51252 13.675 2.72502L12.8875 3.51252ZM0.8125 13.75C0.65 13.75 0.515625 13.6969 0.409375 13.5906C0.303125 13.4844 0.25 13.35 0.25 13.1875V11.575C0.25 11.5 0.2625 11.4313 0.2875 11.3688C0.3125 11.3063 0.35625 11.2438 0.41875 11.1813L9.7 1.90002L12.1 4.30002L2.81875 13.5813C2.75625 13.6438 2.69375 13.6875 2.63125 13.7125C2.56875 13.7375 2.5 13.75 2.425 13.75H0.8125ZM10.0938 3.90627L9.68125 3.49377L10.5062 4.31877L10.0938 3.90627Z"
                            fill="#186569"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="view-solution-btn-group">
                {CheckPermission("product_delete_solution", userinfo) ? (
                  <button
                    className="delete-circle-btn"
                    onClick={(e) => {
                      setDialog(true);
                      setAbbreviation(props?.solutions?.department_name);
                      // props?.ToggleModal(false);
                    }}
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.525 18C2.125 18 1.775 17.85 1.475 17.55C1.175 17.25 1.025 16.9 1.025 16.5V2.25H0V0.75H4.7V0H11.3V0.75H16V2.25H14.975V16.5C14.975 16.9 14.825 17.25 14.525 17.55C14.225 17.85 13.875 18 13.475 18H2.525ZM13.475 2.25H2.525V16.5H13.475V2.25ZM5.175 14.35H6.675V4.375H5.175V14.35ZM9.325 14.35H10.825V4.375H9.325V14.35ZM2.525 2.25V16.5V2.25Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
                {CheckPermission("product_add_subsoulution", userinfo) ? (
                  <button
                    className="add-client-representative"
                    onClick={(e) => setAddSubSolutionModal(true)}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.25 14V7.75H0V6.25H6.25V0H7.75V6.25H14V7.75H7.75V14H6.25Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteDialog ? (
        <ConfirmDelete
          action={actionRemove}
          setters={setConfirmation}
          setDialog={setDialog}
          dialog={`Delete ${abbreviation} ?`}
        />
      ) : null}
      {deleteSubSolutionDialog ? (
        <ConfirmDelete
          action={actionRemoveSubSolution}
          setters={setConfirmation}
          setDialog={setSubSolutionDialog}
          dialog={`Delete ${abbreviation} ?`}
        />
      ) : null}
      {editSubSolutionModal ? (
        <EditSubSolutionModal
          params={params}
          ToggleModal={setEditSubSolutionModal}
          ToggleParentModal={props?.ToggleModal}
          setSuccessMessage={props?.setSuccessMessage}
          setMessage={props?.setMessage}
        />
      ) : (
        <></>
      )}
      {addSubSolutionModal ? (
        <AddNewSubSolution
          params={props.solutions}
          ToggleModal={setAddSubSolutionModal}
          ToggleParentModal={props?.ToggleModal}
          setSuccessMessage={props?.setSuccessMessage}
          setMessage={props?.setMessage}
        />
      ) : null}
      {editSolution ? (
        <EditSolution
          params={props.solutions}
          ToggleModal={setEditSolutionModal}
          ToggleParentModal={props?.ToggleModal}
          setSuccessMessage={props?.setSuccessMessage}
          setMessage={props?.setMessage}
        />
      ) : null}

      {changePmrModal ? (
        <ChangePmrModal
          solution={props?.solutions?.id}
          ToggleModal={setChangePmrModal}
          ToggleParentModal={props?.ToggleModal}
          setSuccessMessage={props?.setSuccessMessage}
          setMessage={props?.setMessage}
        />
      ) : null}
    </>
  );
}
