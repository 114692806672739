import React, { useState } from "react";
import {
  useAddPaymentInformationMutation,
  useGetAirLoadingPortsQuery,
  useGetCurrenciesQuery,
  useGetIncotermsQuery,
  useGetLoadingPortalsQuery,
  useGetManufacturersQuery,
  useGetPaymentsModeQuery,
  useGetPaymentTermsQuery,
  useGetProductsQuery,
  useGetSeaLoadingPortsQuery,
  useGetShipmentDestinationsQuery,
  useGetShipmentModesAddQuery,
  useGetSupplierOriginsQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { useParams } from "react-router-dom";
export default function AddPaymentModal(props) {
  function HandleCancel() {
    props.modal(false);
    console.log("closepeeee");
  }
  console.log(props.value, "bank info value");
  const { data: paymentterm } = useGetPaymentTermsQuery();
  const { data: incoterm } = useGetIncotermsQuery();
  const { data: paymentmode } = useGetPaymentsModeQuery();
  const { data: mode } = useGetShipmentModesAddQuery();
  const { data: loading } = useGetLoadingPortalsQuery();
  const { data: air } = useGetAirLoadingPortsQuery();
  const { data: sea } = useGetSeaLoadingPortsQuery();
  const { data: origin } = useGetSupplierOriginsQuery();
  const { data: portdestination } = useGetShipmentDestinationsQuery();
  console.log(paymentmode, "paymentmode");
  console.log(paymentterm, "paymentterm");
  const params = useParams();

  const [order_id, setOrder_id] = useState(params.id);
  const [payment_mode_id, setPaymentMode] = useState(null);
  const [delivery_time, setDeliverTime] = useState(null);
  const [handover_to, setHandoverTo] = useState(null);
  const [payment_term_id, setPaymentTerm] = useState(null);
  const [incoterm_id, setIncoterm] = useState(null);
  const [advance_percentage, setAdvanceRequired] = useState(false);
  const [shipment_mode_id, setShipmentMode] = useState(null);
  const [countryLoading, setCountry] = useState(null);
  // const [air_loading_id, setAirLoading] = useState(null);
  const [sea_loading_id, setSeaLoading] = useState(null);
  const [partial_shipment, setPartialShipment] = useState(null);
  const [trans_shipment, setTransshipment] = useState(null);
  const [shipment_destination_id, setShipmentDestination] = useState(null);
  const [loading_port_id, setAirLoading] = useState(null);
  const [origin_id, setOrigin] = useState();
  const portLoading = [];
  let smid = shipment_mode_id === null ? [] : shipment_mode_id?.split(",");
  smid[1] === "Sea" ? (
    portLoading.push(
      ...sea?.data?.filter((items) => items?.origin_id === countryLoading)
    )
  ) : smid[1] === "Air" ? (
    portLoading.push(
      ...air?.data?.filter((items) => items?.origin_id === countryLoading)
    )
  ) : (
    <></>
  );
  let paymentinfo = {
    purchase_order_id: props.poid,
    order_id,
    payment_mode_id,
    payment_term_id,
    advance_percentage,
    incoterm_id,
    loading_port_id: smid[1] === "Air" ? loading_port_id : null,
    partial_shipment,
    trans_shipment,
    shipment_destination_id,
    shipment_mode_id: smid[0],
    sea_loading_id: smid[1] === "Sea" ? loading_port_id : null,
    origin_id: countryLoading,
    handover_to,
    delivery_time,
  };
  const [CreatePayment, res] = useAddPaymentInformationMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();

    CreatePayment(paymentinfo)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        props.setMessages(
          "Payment & Shipment information created successfully"
        );
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };

  // bank detail
  console.log(paymentinfo, "loading_port_id");
  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>Add Payment Information</div>
            <div type="cancel" onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container ">
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Payment Information</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Time of Delivery
                          </div>
                          <input
                            name=""
                            id=""
                            defaultValue={props?.value?.bank_country}
                            className="supplier-select-option"
                            onChange={(e) => setDeliverTime(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Handover To
                          </div>
                          <input
                            name=""
                            id=""
                            value={handover_to}
                            className="supplier-select-option"
                            onChange={(e) => setHandoverTo(e.target.value)}
                          />
                        </div>
                        {/* <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Currency</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            onChange={(e) => setBankcurrency(e.target.value)}
                          >
                            <option value="" >
                              Select Currency
                            </option>
                            {currencies?.data?.map((items) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </select>
                        </div> */}
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Mode of Payment
                          </div>
                          <select
                            name=""
                            id=""
                            defaultValue={props?.value?.bank_country}
                            className="supplier-select-option"
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            <option value="">Select Mode of Payment</option>

                            {paymentmode?.data?.map((items) => (
                              <option value={items.id}>
                                {items.payment_mode}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Terms of Payment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            defaultValue={props?.value?.bank_currency}
                            onChange={(e) => setPaymentTerm(e.target.value)}
                          >
                            <option value="">Select Terms of Payment</option>
                            {paymentterm?.data?.map((items) => (
                              <option value={items.id}>
                                {items?.payment_term}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Incoterm</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            defaultValue={props?.value?.bank_currency}
                            onChange={(e) => setIncoterm(e.target.value)}
                          >
                            <option value="">Select Incoterm</option>
                            {incoterm?.data?.map((items) => (
                              <option value={items.id}>
                                {items.incoterm_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Advance Percentage
                          </div>
                          <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            min="0"
                            max="100"
                            required
                            className="supplier-text-input"
                            onChange={(e) => setAdvanceRequired(e.target.value)}
                          />
                        </div>
                        {/* <div className="text-center">
                          <label className="d-flex gap-2 align-items-baseline justify-content-center">
                            <input
                              type="checkbox"
                              value={true}
                              onChange={(e) =>
                                setAdvanceRequired(e.target.value)
                              }
                            />
                            <span className="h5 text-muted">Advance Paid</span>
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Shipment Information</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Mode of Shipment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            onChange={(e) => setShipmentMode(e.target.value)}
                          >
                            <option value="">Select Mode of Shipment</option>
                            {mode?.data?.map((items) => (
                              <option
                                value={`${items.id},${items.shipment_mode}`}
                              >
                                {items.shipment_mode}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Loading Origin
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option value="">Select Loading Origin</option>
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Port of Loading
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            onChange={(e) => {
                              setAirLoading(e.target.value);
                            }}
                          >
                            <option value="">Select Port of Loading</option>

                            {portLoading?.map((items) => (
                              <option value={items?.id}>
                                {items?.port_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Port of Destination
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            defaultValue={props?.value?.bank_currency}
                            onChange={(e) =>
                              setShipmentDestination(e.target.value)
                            }
                          >
                            <option value="">Select Port of Destination</option>
                            {portdestination?.data?.map((items) => (
                              <option value={items.id}>
                                {items.port_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Partial Shipment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            defaultValue={props?.value?.bank_currency}
                            onChange={(e) => setPartialShipment(e.target.value)}
                          >
                            <option value="">Select Partial Shipment</option>
                            <option value="Allowed">Allowed</option>
                            <option value=" Not Allowed">Not Allowed</option>
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Transshipment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            defaultValue={props?.value?.bank_currency}
                            onChange={(e) => setTransshipment(e.target.value)}
                          >
                            <option value="">Select Transshipment</option>
                            <option value="Allowed">Allowed</option>
                            <option value="Not Allowed">Not Allowed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2">
              <button
                type="cancel"
                className="form-cancel"
                onClick={HandleCancel}
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="form-save"
                onClick={HandleSubmit}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
