import * as React from "react";
import { NavLink } from "react-router-dom";
import InitiateForexButton from "../../../components/Payment/ForexApproval/InitiateForexButton";
import ForexCard from "../../../components/Payment/ForexApproval/ForexCard";
import "../forexPages.style.css";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination, Tooltip } from "@mui/material";
import {
  useGetForexQuery,
  useGetForexProjectIeInitiatedQuery,
  useGetUserInfoQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";
import CheckPermission from "../../../components/common/checkPermission/checkPermission";
import DelinquentReport from "../../../components/Payment/ForexPlanning/ForexReport/DelinquentReport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
export default function ForexIeIndex(props) {
  const { data: forexApprovalIe } = useGetForexQuery();
  const { data: projectList } = useGetForexProjectIeInitiatedQuery();
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projects } = useGetProjectsQuery();
  const [filter, setFilter] = React.useState(false);
  let initiatedForexLists = [];
  forexApprovalIe?.data?.filter((forex) =>
    forex?.forex_project?.forex_resource == 0
      ? initiatedForexLists.push(forex)
      : ""
  );
  const [project, setProject] = React.useState("");
  const [clear, setClear] = React.useState("");
  const filteredData = (clear, project) => {
    return initiatedForexLists
      ?.filter((items) => {
        if (clear === "") {
          return items;
        } else if (items?.NBE_cleared == clear) {
          return items;
        }
      })
      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.project_id == project) {
          return items;
        }
      })

      .map((items) => items);
  };
  function HandleFilter() {
    setFilter(!filter);
    setClear("");
    setProject("");
  }
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(filteredData(clear, project)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(clear, project), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  console.log(forexApprovalIe, "forexApprovalIe");

  return (
    <>
      {CheckPermission("initiate_forex", userinfo) ? (
        <div className="initiateForexButton">
          <InitiateForexButton
            name={"InitiateforexIe"}
            title={"Initiate Forex"}
            projectIe={projectList?.data}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-end mt-3">
        {filteredData(clear, project)?.length != 0 ? (
          <DelinquentReport data={filteredData(clear, project)} />
        ) : (
          ""
        )}
        <NavLink to="Reports" className="form-save py-2 text-white">
          Reports
        </NavLink>
        {initiatedForexLists?.length > 1 ? (
          <div className="d-flex gap-4 ms-5 align-items-start ">
            {filter ? (
              <Tooltip title="Filter On">
                <FilterAltIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Filter Off">
                <FilterAltOffIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            )}
            {filter ? (
              <>
                <select
                  className="report-filter-selector border"
                  onChange={(e) => setProject(e.target.value)}
                >
                  <option value="" selected>
                    Project
                  </option>
                  {projects?.data?.map((items) => (
                    <option value={items?.id}>{items?.project_name}</option>
                  ))}
                </select>

                <select
                  className="report-filter-selector border"
                  onChange={(e) => setClear(e.target.value)}
                >
                  <option value="" selected>
                    Status
                  </option>

                  <option value={1}>Cleared</option>
                  <option value={0}>Not Cleared</option>
                </select>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mt-4 forex-detail-container">
        {_DATA
          .currentData()
          .filter((items) => {
            if (props.search == "") {
              return items;
            } else if (items.forex_name?.toLowerCase().includes(props.search)) {
              return items;
            }
          })
          .map((forex) => (
            <NavLink to={`Detail/${forex?.id}`}>
              <ForexCard data={forex} clear={true} />
            </NavLink>
          ))}
      </div>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
