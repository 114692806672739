import * as React from "react";
import { NavLink } from "react-router-dom";
import InitiateForexButton from "../../../../components/Payment/ForexApproval/InitiateForexButton";
import ForexCard from "../../../../components/Payment/ForexApproval/ForexCard";
import "../../forexPages.style.css";
import usePagination from "../../../../components/Pagination/Pagination";
import { Pagination, Tooltip } from "@mui/material";
import {
  useGetForexQuery,
  useGetForexProjectIeInitiatedQuery,
  useGetUserInfoQuery,
  useGetProjectsQuery,
} from "../../../../features/SCMApi";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { date, totalDates } from "../../../../components/dateFormator";
import ForexSideBar from "../../../../components/Payment/ForexApproval/PaymentSideBar";
import DelinquentReport from "../../../../components/Payment/ForexPlanning/ForexReport/DelinquentReport";
import DeliquentItems from "./DeliquentItems";

export default function ForexExpiration() {
  const { data: forexApprovalIe } = useGetForexQuery();
  const [achieved, setAchieved] = React.useState(false);

  const { data: projects } = useGetProjectsQuery();
  const [filter, setFilter] = React.useState(false);
  let initiatedForexLists = [];
  forexApprovalIe?.data?.filter((forex) =>
    forex?.forex_project?.forex_resource == 0
      ? initiatedForexLists.push(forex)
      : ""
  );
  const [project, setProject] = React.useState("");
  const [clear, setClear] = React.useState("");
  const filteredData = (clear, project) => {
    return forexApprovalIe?.data

      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.project_id == project) {
          return items;
        }
      })
      ?.filter((items) => achieved == items?.EXP_achieved)
      ?.map((items) => items);
  };
  function HandleFilter() {
    setFilter(!filter);
    setClear("");
    setProject("");
  }
  console.log(forexApprovalIe, project, "filteredData");

  console.log(achieved, "forexApprovalIe");
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(filteredData(clear, project)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(clear, project), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const itemsTotal = [];
  initiatedForexLists?.map((itemsdata, index) =>
    itemsTotal.push(initiatedForexLists[index].items)
  );
  let counts = 0;
  const [showPi, setShowPi] = React.useState(false);
  const [data, setData] = React.useState(null);
  function handleDeliquent(items) {
    setData(items);
    setShowPi(true);
  }
  console.log("Filtered Data:", filteredData(clear, project));
  console.log("Current Page Data:", _DATA.currentData());

  return (
    <ForexSideBar>
      <div className="payment-collection-container">
        <div className="h5-pri text-center py-2">
          Project LC /CAD / TT Approval Tracking Sheet
        </div>

        <div className="d-flex gap-5 align-items-center mt-2 px-5">
          <div className="d-flex justify-content-end mt-3">
            {filteredData(clear, project)?.length != 0 ? (
              <DelinquentReport data={filteredData(clear, project)} />
            ) : (
              ""
            )}
            <div className="d-flex align-items-center gap-2 me-3">
              <button
                value={0}
                onClick={(e) => setAchieved(0)}
                className={
                  achieved == 0
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Not Achieved</label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <button
                value={1}
                onClick={(e) => setAchieved(1)}
                className={
                  achieved == 1
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Achieved</label>
            </div>
            <select
              style={{ width: "18rem" }}
              className="report-filter-selector border ms-3"
              onChange={(e) => setProject(e.target.value)}
            >
              <option value="" selected>
                Project
              </option>
              {projects?.data?.map((items) => (
                <option value={items?.id}>{items?.project_name}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div class="form-check form-switch d-flex justify-content-center gap-2">
          <input
            class="form-check-input"
            type="checkbox"
            onChange={(e) => setAchieved(!achieved)}
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">
            Achieved
          </label>
        </div> */}
        <div className="p-2 finance-table-container">
          <table
            className="table table-striped table-bordered "
            style={{
              width: "auto",
              minWidth: "2000px",
              borderCollapse: "collapse",
            }}
          >
            <thead className="stock-table-header table-dark text-white">
              <tr>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "50px" }}
                >
                  No
                </th>

                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Project
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: " 250px" }}
                >
                  Items Type
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Responsible
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Bank
                </th>

                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Payment Term
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Amount
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Approval Date
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Expiry Date
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Duration
                </th>
                <th className="stock-table-header-text text-start text-white">
                  Remaining Date
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Status
                </th>
                <th
                  className="stock-table-header-text text-start text-white"
                  style={{ width: "250px" }}
                >
                  Remark
                </th>
              </tr>
            </thead>
            <tbody>
              {_DATA
                .currentData()

                ?.map((items, index) => (
                  <tr
                    className={`stock-table-row ${
                      totalDates(items?.planed_collection_date) <= 0 &&
                      items?.is_collected == 0
                        ? "bg-danger text-white"
                        : items?.is_collected == 1
                          ? "bg-success text-white"
                          : ""
                    }`}
                    onClick={() => handleDeliquent(items)}
                  >
                    <td className="text-start">{(counts += 1)}</td>
                    <td className="text-start">
                      {items?.forex_project?.project_name}
                    </td>
                    <td className="text-start">{items?.project_name}</td>
                    <td className="text-start">
                      {items?.forex_project?.users
                        ?.filter(
                          (res) =>
                            res?.role?.role_name == "Project Finance Officer" ||
                            res?.role?.role_name == "Project Manager"
                        )
                        ?.map((res) => res?.name)
                        ?.join(",")}
                    </td>
                    <td className="text-start">
                      {items?.forex_bank?.abbreviation
                        ? items?.forex_bank?.abbreviation
                        : items?.forex_bank?.bank_name}
                    </td>
                    <td className="text-start">
                      {items?.forex_term[0]?.forex_payment_mode?.payment_mode
                        ? items?.forex_term[0]?.forex_payment_mode?.payment_mode
                        : "-"}
                    </td>
                    <td className="text-start">
                      {items?.is_actual == 1
                        ? Number(items?.amount)?.toLocaleString()
                        : Number(
                            items?.items
                              ?.map((row) =>
                                row?.total_cost ? row?.total_cost : 0
                              )
                              .reduce((acc, val) => acc + val, 0)
                          )?.toLocaleString()}
                    </td>
                    <td className="text-start">
                      {items?.forex_term[0]?.approval_date
                        ? items?.forex_term[0]?.approval_date
                        : "-"}
                    </td>
                    <td className="text-start">
                      {items?.forex_term[0]?.expiration_date
                        ? items?.forex_term[0]?.expiration_date
                        : "-"}
                    </td>
                    <td className="text-start">
                      {items?.forex_term[0]?.validity_date
                        ? items?.forex_term[0]?.validity_date
                        : "-"}
                    </td>
                    <td className="text-start">
                      {items?.forex_term[0]?.remaining_days
                        ? items?.forex_term[0]?.remaining_days
                        : "-"}
                    </td>
                    <td className="text-start">
                      {items?.expiration_status?.length
                        ? items?.expiration_status[
                            items?.expiration_status?.length - 1
                          ]?.status
                        : "-"}
                    </td>

                    <td className="text-start">
                      {" "}
                      {items?.expiration_status?.length
                        ? items?.expiration_status[
                            items?.expiration_status?.length - 1
                          ]?.remark
                        : "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="p-2"
          />
        ) : (
          ""
        )}
      </div>
      {showPi ? (
        <DeliquentItems isExpiration={true} data={data} modal={setShowPi} />
      ) : (
        ""
      )}
    </ForexSideBar>
  );
}
