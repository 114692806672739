import React from "react";
import { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import ToolDetail from "./ToolDetail";
import Tools from "./Tools";

export default function Index(props) {
  const [fragment, setFragment] = useState("index");
  const [data, setData] = useState();
  console.log(props?.Message, "hhaprops");
  return (
    <StockIndex>
      {fragment === "index" ? (
        <Tools
          setMessages={props?.setMessage}
          setFragment={setFragment}
          setData={setData}
        />
      ) : fragment === "detail" ? (
        <ToolDetail setFragment={setFragment} data={data} />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
