import React from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { IconButton } from "@mui/material";
import { useGetToolHistoryQuery } from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { IMG_BASE_URL } from "../../../api/endPoint";
import { format } from "date-fns";

export default function AssetsHistory(props) {
  let tools = [
    {
      img: "https://media.istockphoto.com/id/144349803/photo/cables.jpg?s=612x612&w=0&k=20&c=7fht36zJSEXu3j1XQCEC9bMGt4n95AGGkC5GafmHd5o=",
      id: 1,
      item_name: "HP Laptop",
      model: "HP Note Pad",
      type: "New purchased",
      store: "tafo",
      project: "ERA MOTL",
      status: "Usable",
      is_field_engineer: 1,
      purchase_data: "Dec-31-2022",
      description:
        "Officia et qui eius excepturi iste ullam omnis eum facilis. Mollitia tenetur corrupti vel officiis deserunt maiores. Velit officia accusamus cumque voluptas qui ducimus. Autem et in dignissimos. Eveniet in at quos delectus rerum aut.",
    },
    {
      id: 2,
      img: "https://media.istockphoto.com/id/144349803/photo/cables.jpg?s=612x612&w=0&k=20&c=7fht36zJSEXu3j1XQCEC9bMGt4n95AGGkC5GafmHd5o=",
      item_name: "HP Laptop",
      model: "HP Note Pad",
      type: "New purchased",
      store: "tafo",
      project: "ERA MOTL",
      status: "Usable",
      is_field_engineer: 1,
      purchase_data: "Dec-31-2022",
      description:
        "Officia et qui eius excepturi iste ullam omnis eum facilis. Mollitia tenetur corrupti vel officiis deserunt maiores. Velit officia accusamus cumque voluptas qui ducimus. Autem et in dignissimos. Eveniet in at quos delectus rerum aut.",
    },
    {
      id: 3,
      img: "https://media.istockphoto.com/id/144349803/photo/cables.jpg?s=612x612&w=0&k=20&c=7fht36zJSEXu3j1XQCEC9bMGt4n95AGGkC5GafmHd5o=",
      item_name: "HPE Laptop",
      model: "HPE Note Pad",
      type: "Used",
      store: "Saris",
      project: "Ethswitch",
      status: "New",
      is_field_engineer: 0,
      purchase_data: "Dec-31-2022",
      description:
        "Officia et qui eius excepturi iste ullam omnis eum facilis. Mollitia tenetur corrupti vel officiis deserunt maiores. Velit officia accusamus cumque voluptas qui ducimus. Autem et in dignissimos. Eveniet in at quos delectus rerum aut.",
    },
    {
      id: 4,
      img: "https://media.istockphoto.com/id/144349803/photo/cables.jpg?s=612x612&w=0&k=20&c=7fht36zJSEXu3j1XQCEC9bMGt4n95AGGkC5GafmHd5o=",
      item_name: "HPE Laptop",
      model: "HPE Note Pad",
      type: "Used",
      store: "Saris",
      project: "Ethswitch",
      status: "New",
      is_field_engineer: 0,
      purchase_data: "Dec-31-2022",
      description:
        "Officia et qui eius excepturi iste ullam omnis eum facilis. Mollitia tenetur corrupti vel officiis deserunt maiores. Velit officia accusamus cumque voluptas qui ducimus. Autem et in dignissimos. Eveniet in at quos delectus rerum aut.",
    },
  ];
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    props?.setFragment("detail");
    props?.setData(tool);
    console.log(tool, "toolssssss");
  };
  const { data: ToolHistory } = useGetToolHistoryQuery(props?.data?.id);
  console.log(ToolHistory, "ToolHistory");
  const Formatter = new Intl.DateTimeFormat("en-US");

  return (
    <div>
      {ToolHistory?.data?.length != 0 ? (
        <>
          <div className="tool-header-container ">
            <input
              placeholder="From"
              type="date"
              className="inventory-search-input"
            />
            <input
              placeholder="To"
              type="date"
              className="inventory-search-input"
            />
          </div>
          <table style={{ width: "100%" }}>
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}>Item Name</th>
                <th style={{ width: "8%" }}>Status</th>
                <th style={{ width: "8%" }}>Store</th>
                <th style={{ width: "26%" }}>Remark</th>
                <th style={{ width: "10%" }}>Updated Date</th>
                <th style={{ width: "10%" }}>Updated By</th>
              </tr>
            </thead>
            <tbody>
              {ToolHistory?.data
                ?.filter((items) => {
                  if (search === "") {
                    return items;
                  } else if (items?.item_name?.toLowerCase().includes(search)) {
                    return items;
                  }
                })
                .map((tool) => (
                  <tr className="stock-table-row">
                    <td className="text-start">
                      {tool?.image != null ? (
                        <img
                          className="stock-img"
                          src={`${IMG_BASE_URL}${tool?.image}`}
                          alt=""
                        />
                      ) : (
                        <div className="prg">image not found</div>
                      )}
                    </td>
                    <td className="text-start">{tool?.item_name}</td>

                    <td className="text-start">
                      {" "}
                      {tool?.status_id === null
                        ? "-"
                        : tool?.status?.status_name}
                    </td>
                    <td className="text-start">
                      {" "}
                      {tool?.store_id === null ? "-" : tool?.store?.store_name}
                    </td>
                    <td className="text-start">
                      {tool?.remark === null ? "-" : tool?.remark}
                    </td>
                    <td className="text-start">
                      {tool?.updated_at == null
                        ? "-"
                        : tool?.updated_at.slice(0, 10)}
                    </td>
                    <td className="text-start">
                      {tool?.updated_by == null
                        ? "Unkown"
                        : tool?.updated_by?.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
