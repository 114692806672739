import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import FinanceProjectContainer from "../../../components/Payment/Finance/FinanceProjectContainer";
import AllVarianceAnalysis from "../../../components/Payment/Finance/AllPaymentCollection";

import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";

import "./finance.style.css";
import AllVarianceAnalyses from "../../../components/Payment/Finance/AllVarianceAnalysis";
export default function VarianceAnalysisIndex() {
  return (
    <ForexSideBar>
      <div className="forex-page">
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
        >
          <Tab eventKey="all" title="All">
            <AllVarianceAnalyses />
          </Tab>
          <Tab eventKey="project" title="Project">
            <FinanceProjectContainer />
          </Tab>
        </Tabs>
      </div>
    </ForexSideBar>
  );
}
