import { Tooltip } from "bootstrap";
import React, { useState } from "react";
import {
  useDeleteStoreSettingMutation,
  useGetStoreQuery,
} from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Pagination } from "@mui/material";
import AddStore from "./SettingModal/AddStore";
import EditStore from "./SettingModal/EditStore";
import usePagination from "../Pagination/Pagination";

export default function StoreSetting() {
  const { data: store } = useGetStoreQuery();
  const [addStore, setStore] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const handleAddStoreModal = () => {
    setStore(true);
  };
  const handleEditStoreModal = () => {
    setEditStore(true);
  };
  console.log(store, "store");
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(store?.data?.length / PER_PAGE);
  const _DATA = usePagination(store?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [stores, setStores] = useState();
  const [DeleteStore, src] = useDeleteStoreSettingMutation();
  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  return (
    <div className="po-summary-table ">
      {store?.data?.length !== 0 ? (
        <>
          <div className="Store-header-row">
            <div>Name</div>
            <div>Location</div>
            <div>Project</div>
            <div>Store Keeper</div>
            <div>Description</div>
            <div></div>
          </div>
          {store?.data?.map((items) => (
            <div className="Store-row mb-2">
              <div className="label-container">
                <div className="pa-label">Name</div>
                <div>{items?.store_name}</div>
              </div>
              <div className="label-container">
                <div className="pa-label">Location</div>
                <div>{items?.location}</div>
              </div>
              <div className="label-container">
                <div className="pa-label">Project</div>
                {items.project_id === null
                  ? "Unknown"
                  : items?.project?.project_name}
              </div>
              <div className="label-container">
                <div className="pa-label">Project</div>
                {items.store_keeper_id === null ? "Unknown" : items?.user?.name}
              </div>
              <div className="label-container">
                <div className="pa-label">Description</div>
                <div>
                  {items?.description === "" ? "-" : items?.description}
                </div>
              </div>
              <div class="setting-representative-container">
                <div class="prg representative-header">Action</div>
                <span>
                  <ModeEditOutlineOutlinedIcon
                    onClick={() => {
                      handleEditStoreModal(true);
                      setStores(items);
                    }}
                    sx={{
                      color: "#52FFAC",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#52FFAC",
                        border: "1px solid #52FFAC",
                        borderRadius: 25,
                      },
                    }}
                  />
                  <DeleteOutlineOutlinedIcon
                    onClick={(e) => {
                      HandleDelete(e, items.id);
                    }}
                    sx={{
                      color: "#FF5252",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#FF5252",
                        border: "1px solid #FF5252",
                        borderRadius: 25,
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <NoRecord />
      )}
      <div className="d-flex justify-content-between align-items-center my-3">
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        ) : (
          ""
        )}
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: "#064b4f",
            color: "#ececec",
            "&:hover": {
              backgroundColor: "#186569",
              color: "#ececec",
            },
          }}
          onClick={handleAddStoreModal}
        >
          <AddIcon style={{ fontSize: 25 }} />
        </IconButton>
      </div>
      {addStore ? <AddStore modal={setStore} /> : ""}
      {editStore ? <EditStore modal={setEditStore} data={stores} /> : ""}
    </div>
  );
}
