// function OrderTranings()
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetProjectItemQuery,
  useGetProjectsQuery,
} from "../../features/SCMApi";

import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { Tab, Tabs } from "react-bootstrap";
import OrderServiceProduct from "./OrderServiceProduct";
import OrderServiceTraning from "./OrderServiceTraning";

export default function OrderService(props) {
  const params = useParams();
  const { data: projectdetail } = useGetProjectItemQuery(params.id);
  const order = [];
  order.push({ data: projectdetail?.data?.order });
  console.log(order, "jnjksguiiu");

  console.log(Array.isArray(projectdetail?.data?.order), "visit ethiopia");

  let componentData = projectdetail?.data?.order?.map((items) => items);

  console.log(componentData, "visit ethiopias");
  const { data: responses } = useGetProjectsQuery();
  const Projectdata = [];

  responses?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  console.log(componentData, "componentData");

  return (
    <SourcingIndex
      SourcingIndex
      name={Projectdata[0]?.project_name}
      names="Orders"
      handleSearchChange={handleSearchChange}
      placeholder={"search Order..."}
    >
      <Tabs
        defaultActiveKey="Product"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="Product" title="Service Product">
          <OrderServiceProduct searchz={search} />
        </Tab>
        <Tab eventKey="Service" title="Service traning">
          <OrderServiceTraning searchz={search} />
        </Tab>
      </Tabs>
    </SourcingIndex>
  );
}
