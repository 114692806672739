import React, { useState } from "react";
import {
  useChangePmrMutation,
  useGetProjectInputParamsQuery,
} from "../../features/SCMApi";

export default function ChangePmrModal(props) {
  const { data: params } = useGetProjectInputParamsQuery();
  const [department_id, setDepartment] = useState(props?.solution);
  const [pmr, setPmr] = useState(props?.solution);
  const [changePmr] = useChangePmrMutation();
  let pmrList = params?.data?.pmrs;

  function handleSubmit() {
    // console.log({ department_id, pmr }, "ola");
    changePmr({ department_id, pmr })
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        props?.ToggleParentModal(false);
        props?.setSuccessMessage(true);
        props?.setMessage("PMR changed successfully");
        // window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);
        }}
      >
        <div
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Change PRM</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">PRM</div>
                <select
                  name=""
                  id=""
                  className="select-option"
                  onClick={(e) => {
                    setPmr(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Select PRM
                  </option>
                  {pmrList?.map((item) => (
                    <option value={item?.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="confirm-dialog-controller">
              <button
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
