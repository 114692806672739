import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import { useEditClientRepresentativeMutation } from "../../features/SCMApi";

export default function EditClientRepresentative(props) {
  const [full_name, setFullName] = useState(
    props?.selectedRepresentative?.full_name
  );
  const [count, setCount] = useState([]);

  const [email, setEmail] = useState(props?.selectedRepresentative?.email);
  const [role, setRole] = useState(props?.selectedRepresentative?.role);
  const [client_id, setClient] = useState(
    props?.selectedRepresentative?.client_id
  );
  console.log(props?.selectedRepresentative, "c");
  const [id, setID] = useState(props?.selectedRepresentative?.id);

  const [updateClientRepresentative, response] =
    useEditClientRepresentativeMutation();
  let phonenumbers = [];
  props?.selectedRepresentative?.c_r_phone_number.map((items) => {
    phonenumbers.push(items.phone_number);
  });
  const [phone_numbers, setphone] = useState(phonenumbers);

  const form = { full_name, email, phone_numbers, role, client_id, id };
  const HandleCancel = () => {
    props?.ModalToggle(false);
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    // axios
    //   .put(
    //     `${API_BASE_URL}/scm/settings/clientrepresentatives/${form?.id}`,
    //     form,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then(function (response) {
    //     console.log(response);
    //     console.log(form, "inputs");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    updateClientRepresentative(form)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        console.log(form, "input");
        HandleCancel();
        props?.setMessage("client representative updated successfully");
        props?.setSuccessMessage(true);
      })
      .then((error) => {
        console.log(error);
      });
  };
  const handleChange = (e, index) => {
    const value = e.target.value;
    const phoneList = [...phone_numbers];
    phoneList[index] = value;
    setphone(phoneList);
  };
  console.log(props?.selectedRepresentative?.c_r_phone_number, "cr");

  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Edit Representative </div>
            <div className="close-modal-button" onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Representative Person</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Full Name</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            defaultValue={full_name}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Email</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Role</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            defaultValue={role}
                            onChange={(e) => setRole(e.target.value)}
                          ></input>
                        </div>

                        <div className="d-flex flex-column gap-2 align-items-baseline">
                          {" "}
                          {props?.selectedRepresentative?.c_r_phone_number?.map(
                            (items, index) => (
                              <>
                                {items?.phone_number == undefined ? null : (
                                  <div className="d-flex gap-2 align-items-baseline">
                                    <div className="supplier-input-label">
                                      Phone Number
                                      {console.log(
                                        items?.phone_number?.length,
                                        "empty"
                                      )}
                                    </div>
                                    <input
                                      type="tel"
                                      pattern="[0-9]{10}"
                                      required
                                      className="supplier-text-input"
                                      defaultValue={items.phone_number}
                                      onChange={(event) =>
                                        handleChange(event, index)
                                      }
                                    />
                                  </div>
                                )}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="cancel"
                  className="form-cancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save"
                  // onClick={(e) => HandleSubmit(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
