import { Pagination, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../api/endPoint";
import {
  useGetApproverQuery,
  useGetProjectItemQuery,
  useGetProjectsQuery,
  useGetOrderItemQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";
import CreateOrder from "./CreateOrder";
import { API_BASE_URL } from "../../api/endPoint";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecommendIcon from "@mui/icons-material/Recommend";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ReactPaginate from "react-paginate";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
export default function BcIndex(props) {
  const params = useParams();
  const { data: projectdetail } = useGetProjectItemQuery(params.id);
  const order = [];
  order.push({ data: projectdetail?.data?.order });

  let componentData = projectdetail?.data?.pa?.map((items) => items);
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  const { data: suppliers } = useGetSuppliersQuery();
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });

  let avatar = [];

  const [Messages, setMessages] = useState(null);
  const [filter, setFilter] = React.useState(false);
  const [order_type, setOrderType] = useState("");
  const [supplier, setSupplier] = useState("");
  function HandleFilter() {
    setFilter(!filter);
    setSupplier("");
    setOrderType("");
  }
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = (supplier, order_type) => {
    return componentData
      ?.filter((items) => {
        return items?.is_budget === 1;
      })
      ?.filter((items) => {
        if (order_type == "") {
          return items;
        } else if (items?.pa_order?.order_type == order_type) {
          return items;
        }
      })

      ?.filter((items) => {
        if (supplier == "") {
          return items;
        } else if (items?.pa_order?.supplier_id == supplier) {
          return items;
        }
      })
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items?.pa_order?.order_name
            ?.toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return items;
        }
      })

      .map((items) => items);
  };
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 12;

  const count = Math.ceil(
    filteredData(supplier, order_type)?.length / PER_PAGE
  );

  const _DATA = usePagination(filteredData(supplier, order_type), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const newww = componentData?.filter(
    (item) => item.order_id == "049500cf-395a-420b-b6db-249d20ad35c8"
  );
  console.log(newww, "newwwnewww");
  console.log(projectdetail, "paooooo");
  console.log(componentData, "paooooocc");
  console.log(filteredData(supplier, order_type), "paooooofil");
  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names={"BC Stage"}
      handleSearchChange={handleSearchChange}
      placeholder={"search PA Order..."}
    >
      <div className="page">
        <div className="d-flex gap-4 ms-5 align-items-center ">
          {filter ? (
            <Tooltip title="Filter On">
              <FilterAltIcon
                onClick={HandleFilter}
                style={{ fontSize: "32px", color: "#064b4f" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Filter Off">
              <FilterAltOffIcon
                onClick={HandleFilter}
                style={{ fontSize: "32px", color: "#064b4f" }}
              />
            </Tooltip>
          )}
          {filter ? (
            <>
              <select
                className="select-option-create-order border"
                onChange={(e) => setSupplier(e.target.value)}
              >
                <option value="" selected>
                  Supplier
                </option>
                {suppliers?.data?.map((items) => (
                  <option value={items?.id}>{items?.distributor_name}</option>
                ))}
              </select>

              <select
                name=""
                id=""
                className="select-option-create-order border"
                onChange={(e) => setOrderType(e.target.value)}
              >
                <option value="" selected>
                  Order Type
                </option>
                <option value="Training">Training</option>
                <option value="Service">Service</option>
                <option value="Product">Product</option>
              </select>
            </>
          ) : (
            ""
          )}
        </div>

        {projectdetail?.data?.pa?.length !== 0 ? (
          <div className="order-card-container mt-3">
            {_DATA.currentData()?.map((items) => (
              <div className="order-card">
                <div className="ms-2 mt-2">
                  {items?.pa_approval == 0 ? (
                    <Tooltip title={"Pending"}>
                      <PendingActionsIcon sx={{ color: "#ffcc00" }} />
                    </Tooltip>
                  ) : items?.pa_approval == 1 ? (
                    <Tooltip title={"Approved"}>
                      <RecommendIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : items?.pa_approval == null ? (
                    <Tooltip title={"Waiting to send for approver"}>
                      <HourglassTopIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>

                <NavLink
                  to={`/sourcing/BcStage/detail/${items?.order_id}`}
                  state={{
                    navlink: `/sourcing/orders/${params.id}`,
                  }}
                >
                  <div className="order-info">
                    <div className="order-project">
                      <div>
                        <Tooltip
                          title={items?.pa_order?.supplier?.distributor_name}
                        >
                          {items?.pa_order?.supplier_id != null ? (
                            <div className="project-name">
                              {items?.pa_order?.supplier?.distributor_name
                                ?.length >= 19
                                ? items?.pa_order?.supplier?.distributor_name?.slice(
                                    0,
                                    19
                                  ) + "..."
                                : items?.pa_order?.supplier?.distributor_name}
                            </div>
                          ) : (
                            <div className="project-name">
                              {items?.pa_order?.bill_to?.distributor_name
                                ?.length >= 19
                                ? items?.pa_order?.bill_to?.distributor_name?.slice(
                                    0,
                                    19
                                  ) + "..."
                                : items?.pa_order?.bill_to?.distributor_name}
                            </div>
                          )}
                        </Tooltip>
                        <div className="project-label">
                          {items?.pa_order?.supplier_id != null
                            ? "Supplier"
                            : "Bill to"}
                        </div>
                      </div>

                      <div>
                        <div className="project-name">
                          {items?.pa_order?.order_type}
                        </div>
                        <div className="project-label">Order Type</div>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                </NavLink>
                <div className="order-supplier">
                  <Tooltip title={items?.pa_order?.order_name}>
                    <div className="order-supplier">
                      {items?.pa_order?.order_name?.length >= 60
                        ? items?.pa_order?.order_name?.slice(0, 60) + "..."
                        : items?.pa_order?.order_name}
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoRecord className="bg-white" />
        )}
        <div className="mt-3">
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </SourcingIndex>
  );
}
