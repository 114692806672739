import React from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { IconButton } from "@mui/material";
import {
  useGetBatchToolRequestQuery,
  useGetStoreQuery,
  useBatchToolQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetApproverQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useItemsRequestMutation,
  useGetBatchAssetRequestQuery,
  useAssetsReceiveMutation,
} from "../../../../features/SCMApi";

import AddIcon from "@mui/icons-material/Add";
import RegisterTool from "../../StockModal/RegisterTool";
import ConfirmDialog from "../../../common/ConfirmDialog";
import axios from "axios";
import { Toll } from "@mui/icons-material";
import NoRecord from "../../../common/NoRecord";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import StockDialog from "../../../common/StockDialog";
import StockIndex from "../../../../pages/Stocks/index";
import NavBar from "../../../../Layout/NavBar";
import { NavLink, useParams } from "react-router-dom";
import InventoryApprovalModal from "../../../common/InventoryApprovalModal";
import InventoryApprovalRejectModal from "../../../common/InventoryApprovalRejectModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BatchReturn from "../../StockModal/BatchReturn";

export default function ReceivedBatchAssets(props) {
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    props?.setFragment("detail");
    props?.setData(tool);
    console.log(tool, "toolssssss");
  };
  const params = useParams();
  const { data: BatchTool } = useGetBatchAssetRequestQuery(params?.id);
  console.log(BatchTool, "BatchTool");
  const [registerTool, setRegisterTool] = useState(false);
  function HandleItem() {
    setRegisterTool(true);
    updateTool(null);
  }
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);
  console.log(BatchTool, "BatchTool");
  const [toolRequest, src] = useToolRequestMutation();
  const [requested_quantity, setQuantity] = useState(null);
  const [requestedProject, setRequestedProject] = useState("");
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [approvalRejected, setApprovalRejected] = useState(false);
  const [hideApprovalButton, setHideApprovalButton] = useState(false);
  const [feedBack, setFeedBack] = useState(null);
  const [confirmReturn, setConfirmReturn] = useState(false);

  function HandleReceive() {
    setConfirm(true);
  }
  function HandleReturn() {
    setConfirmReturn(true);
  }
  const { data: userinfo } = useGetUserInfoQuery();
  console.log(params.id, "params to co");
  function HandleToolRequest(e, id) {
    let requested = {
      id: params?.id,
    };
    ItemConfirm(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props?.setMessages("Tool Requested successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error, "errorrrrrrrrrrrrrrr");
    //   });
  }

  console.log(BatchTool, "notRequest tool");
  const { data: store } = useGetStoreQuery();
  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;

  const [updateTool, setupdateTool] = useState(null);
  const HandleEditTool = () => {
    setRegisterTool(true);
  };
  const [Accept, setAccept] = useState(false);
  const [remark, setRemark] = useState(null);
  function HandleRequest() {
    setConfirm(true);
  }
  function HandleAccept() {
    setAccept(true);
  }
  const [ItemConfirm, srcs] = useInStoreItemsRequestMutation();
  const [ItemAccept, acce] = useItemsAcceptMutation();

  console.log(requestedProject, "in test tools");
  const [amount, setAmount] = useState();
  const [error, setError] = useState();
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);

  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined;
  });
  let items =
    BatchTool?.data == undefined ? "[]" : BatchTool?.data?.requested_items;
  let BatchItems = JSON.parse(items);
  console.log(BatchItems, BatchTool?.data, "batch Items");
  const { data: getApprover } = useGetApproverQuery({
    module_name: "Tool",
    id: params?.id,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelection = (id, items) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(id);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };
  const getSelectedItems = () => {
    return BatchItems?.filter((item) => selectedItems.includes(item.id));
  };
  let itemsReceived = getSelectedItems().flat(1);
  console.log(itemsReceived, "itemsReceived");
  const { data: loggedUser } = useGetUserInfoQuery();
  const [ItemRequest] = useAssetsReceiveMutation();
  function HandleToolReceive(e, dialog) {
    console.log(dialog, "suraaa");
    let requested = {
      id: params?.id,
      received_by: userinfo?.id,
      tool_id: dialog?.tool_id,
      requested_quantity: dialog?.requested_quantity,
    };
    console.log(requested, "dialog");

    ItemRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
      })
      .catch((error) => {
        setError(error?.data?.message);
        console.log(error, "erorrrrr");
      });
  }
  let request_items =
    getApprover?.data.current[0]?.requested_items == undefined
      ? "[]"
      : JSON.parse(getApprover?.data.current[0]?.requested_items);
  console.log(getApprover, "dialogdialog");
  let isRequested =
    getApprover?.data.current[0]?.is_returned == undefined
      ? 0
      : getApprover?.data.current[0]?.is_returned;
  let isConfirmed =
    getApprover?.data.current[0]?.is_confirmed == undefined
      ? 0
      : getApprover?.data.current[0]?.is_confirmed;
  let isStockKeeper =
    request_items[0]?.store?.store_keeper_id == undefined
      ? true
      : loggedUser?.id == request_items[0]?.store?.store_keeper_id;
  console.log("surafel check data", selectedItems);
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="page">
        <div className="d-flex justify-content-start my-2">
          <NavLink to={`/stocks/requests`} className="price-comparison">
            <span className="d-flex justify-content-center gap-4 px-3 py-3 align-items-center">
              <ArrowBackIcon />
              Go Back
            </span>
          </NavLink>
        </div>
        {BatchItems?.length !== 0 ? (
          <>
            <div className="">
              <div className="d-flex justify-content-between align-items-center bg-white px-3 py-2">
                <div className="tool-item-status">
                  {getApprover?.data?.current?.length == 0
                    ? "No need for approval"
                    : getApprover?.data?.current[0]?.is_approved == 0
                    ? `${getApprover?.data?.current[0]?.role_name} approval pending`
                    : getApprover?.data?.current[0]?.is_approved == 2
                    ? "Rejected"
                    : "Approved"}
                </div>
                <div className="items-counter">
                  <div className="text-center">{BatchItems?.length}</div>
                  <div>Items Count</div>
                </div>
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead className="stock-table-header">
                <tr>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "3%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "17%" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "14%" }}
                  >
                    Model
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Store
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Amount
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "11%" }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {BatchItems?.map((tool, index) =>
                  tool?.is_returned == 0 ? (
                    <tr className="stock-table-row">
                      <td>
                        {BatchTool?.data?.is_requester_received == 1 ? (
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(tool.id)}
                            onChange={() => toggleSelection(tool.id, tool)}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        className=""
                        onClick={() => {
                          HandleFragment(tool);
                          setupdateTool(null);
                        }}
                      >
                        <img
                          className="stock-img"
                          src={`${IMG_BASE_URL}${tool?.image}`}
                          alt=""
                        />
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.item_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.model === null ? "-" : tool?.model}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.tool_type_id === null
                          ? "-"
                          : tool?.tool_type?.tool_type}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.store_id === null
                          ? "-"
                          : tool?.store?.store_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {`${tool?.amount === null ? "-" : tool?.amount} ${
                          tool?.uom_id == null ? "-" : tool?.uom?.name
                        }`}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.status_id == null
                          ? "-"
                          : tool?.status?.status_name}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>

            <div
              className="batch-header-container text-start mt-2"
              style={{ display: "flex" }}
            >
              {true ? (
                BatchTool?.data?.is_requester_received == 0 &&
                userinfo?.id == BatchTool?.data?.requester_id ? (
                  <button
                    className="form-save"
                    onClick={() => {
                      HandleReceive();
                      setDialog(BatchTool?.data);
                    }}
                  >
                    Receive
                  </button>
                ) : userinfo?.id == BatchTool?.data?.requester_id ? (
                  <button
                    disabled={selectedItems?.length <= 0 ? true : false}
                    className={
                      selectedItems?.length <= 0
                        ? "form-save bg-secondary text-white"
                        : "form-save"
                    }
                    onClick={() => {
                      HandleReturn();
                    }}
                  >
                    Return
                  </button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <NoRecord />
        )}

        {confirm ? (
          <StockDialog
            setDialog={setConfirm}
            dialog={`Number of items: ${BatchItems?.length} `}
            data={dialog}
            error={error}
            name={"Receive"}
            action={(e) => HandleToolReceive(e, dialog)}
          />
        ) : (
          ""
        )}
        {confirmReturn ? (
          <BatchReturn
            modal={setConfirmReturn}
            data={itemsReceived}
            isAssets={true}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
