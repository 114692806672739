import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, Tooltip } from "@mui/material";
import { API_BASE_URL, IMG_BASE_URL } from "../../../../api/endPoint";
function SOAReport({ data, po_number, pi_number, id }) {
  function generatePDF() {
    const doc = new jsPDF();
    function date(dates) {
      const dateStr = dates;
      const date = new Date(dateStr);
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

      return formattedDate;
    }
    const headers = [["Debit", "Credit", "Closing Balance", "Payment Date"]]; // Table headers
    const datas = data?.map((items) => [
      Number(items?.debit)?.toLocaleString(),
      Number(items?.credit)?.toLocaleString(),
      Number(items?.closing_balance)?.toLocaleString(),

      date(items?.created_at),
    ]);

    const header = "Statement Of Account";
    const po_num = "po_number";
    const pi_num = "pi_number";
    const headerX = doc.internal.pageSize.getWidth() / 2;
    const headerY = 10;
    const pi_numX = doc.internal.pageSize.getWidth() / 2;
    const pi_numY = 20;
    const po_numX = doc.internal.pageSize.getWidth() / 2;
    const po_numY = 40;
    doc.setFontSize(14);
    doc.text(header, headerX, headerY, { align: "center" });
    doc.text(po_num, po_numX, po_numY, { align: "center" });
    doc.text(pi_num, pi_numX, pi_numY, { align: "center" });
    doc.setFontSize(14);

    // Footer
    const footer = "Page " + doc.internal.getNumberOfPages();
    const footerX = doc.internal.pageSize.getWidth() / 2;
    const footerY = doc.internal.pageSize.getHeight() - 10;
    doc.setFontSize(10);
    doc.text(footer, footerX, footerY, { align: "center" });
    doc.autoTable({
      head: headers,
      body: datas,
    });

    doc.save("SOA.pdf"); // Save the PDF
  }

  return (
    <div>
      <Tooltip title={data?.length != 0 ? "Download SOA" : "NO Record"}>
        <a type="link" href={`${IMG_BASE_URL}/soapolevel/${id}`}>
          <IconButton
            variant="text"
            size="small"
            disabled={data?.length != 0 ? false : true}
            sx={{
              backgroundColor: "#eff2f3",
              color: "#064B4F",
              "&:hover": {
                backgroundColor: "#186569",
                color: "#fff",
              },
            }}
          >
            <DownloadIcon sx={{ fontSize: "14px" }} />
          </IconButton>
        </a>
      </Tooltip>
    </div>
  );
}

export default SOAReport;
