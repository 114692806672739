import React, { useEffect, useState } from "react";
import div from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useApprovePrfBudgetMutation,
  useBudgetApprovalMutation,
  useGetPrfBudgetQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { date } from "../../../components/dateFormator";
import { Box, Modal } from "@mui/material";
import ConfirmDelete from "../../../components/common/ConfirmDelete";

export default function ApprovedBudget({ data }) {
  const [ApprovalBudget] = useBudgetApprovalMutation();
  const [open, setOpen] = useState(false);

  const sampleData = {
    project1: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
    ],
    project2: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
    ],
    project3: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
    ],
  };
  const projectTotals = Object.keys(sampleData).flatMap((projectName) => {
    const projectData = sampleData[projectName];
    const totalUSD = projectData.reduce((acc, data) => acc + data.b_usd, 0);
    const totalETB = projectData.reduce((acc, data) => acc + data.b_etb, 0);

    return {
      projectName,
      totalUSD,
      totalETB,
    };
  });
  let totalBudgetUSD = projectTotals.reduce(
    (acc, data) => acc + data.totalUSD,
    0
  );
  const [dialog, setDialog] = useState(false);
  const [dialogTransfer, setDialogTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  let totalBudgetETB = projectTotals.reduce(
    (acc, data) => acc + data.totalETB,
    0
  );
  const [selectedProjects, setSelectedProjects] = useState({}); // State to manage selected projects
  // Function to handle individual project checkbox
  const handleSelectItem = (projectName, index, data) => {
    // Update the selectedProjects state to mark the individual item as selected
    setSelectedProjects((prevSelectedProjects) => ({
      ...prevSelectedProjects,
      [`${projectName}-${index}`]:
        !prevSelectedProjects[`${projectName}-${index}`],
    }));
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = (e, projectName, data) => {
    const isChecked = e.target.checked;

    // Update the selectedProjects state for the current project
    setSelectedProjects((prevSelectedProjects) => ({
      ...prevSelectedProjects,
      [projectName]: isChecked,
    }));

    // Update the selectedProjects state for all items in the current project
  };
  const updateData = Object.fromEntries(
    Object.keys(data ? data : {}).map((projectNames) => [
      projectNames,
      (data[projectNames] || []).map((items) => ({
        ...items,
        approved: selectedProjects[projectNames] || false,
      })),
    ])
  );
  const { data: userinfo } = useGetUserInfoQuery();

  const budget = Object.keys(updateData).flatMap((projectName) =>
    (updateData[projectName] || []).map((e) => ({
      id: e.id,
      project_id: e.project_id,
      budget_category_id: "budgetcategoryid",
      budget_type_id: e.budget_type_id,
      amount: e.amount,
      currency_id: e.currency_id,
      is_certain_budget: true,
      approved: e.approved,
    }))
  );
  console.log(updateData, budget, "dialogTransfer");
  let params = {
    user_id: userinfo?.id,
    tobe_approved_budgets: budget,
  };

  const HandleApprovalSubmit = async () => {
    setLoading(true);
    ApprovalBudget(params)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setDialog(false);
        setLoading(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
        setLoading(false);
      });
  };
  console.log(budget, "selectedProjects");
  return (
    <div title={"Budget"} name={"Budget"} navlink={`/payment`}>
      <div className="d-flex justify-content-between align-items-end py-2">
        <div className="card mb-3" style={{ width: "18rem" }}>
          <div
            style={{
              backgroundColor: "#064b4f",
              color: "#fff",
              letterSpacing: "0.105em",
              fontWeight: "bold",
              fontSize: "14px",
              padding: "5px",
            }}
          >
            Total Budget cost
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item prg">
              {Number(totalBudgetUSD)?.toLocaleString()} in USD
            </li>
            <li className="list-group-item prg">
              {Number(totalBudgetETB)?.toLocaleString()} in ETB
            </li>
          </ul>
        </div>
        <div className="d-flex gap-3 ">
          <button className="form-save" onClick={() => setDialog(true)}>
            Approve
          </button>
          {/* <button className="form-save" onClick={() => setDialogTransfer(true)}>
            Transfer to Next Month
          </button> */}
        </div>
      </div>
      <table
        className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%" }}
      >
        <thead
          style={{
            fontSize: "10px",
            backgroundColor: "#000",
            letterSpacing: "0.105em",
            fontWeight: "400",
            color: "#fff ",
          }}
        >
          <tr>
            <th> </th>
            <th>BUDGET NO.</th>
            <th>ACCOUNT NO.</th>
            <th>ACCOUNT DESCRIPTION</th>
            <th>MILESTONE LINKED TO THE BUDGET</th>
            <th>EXPECTED DATE OF COLLECTION WITHIN THE MONTH</th>
            <th>BUDGET HOLDER</th>
            <th>BUDGET Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            <>
              {Object?.keys(updateData)?.map((projectName, index) => (
                <React.Fragment key={projectName}>
                  <tr
                    style={{
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td className="d-flex align-items-center gap-2" colSpan="1">
                      <input
                        type="checkbox"
                        checked={selectedProjects[projectName] || false}
                        onClick={(e) => handleSelectAll(e, projectName, data)}
                      />
                      Select All
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                      colSpan="6"
                    >
                      {projectName}{" "}
                    </td>

                    {/* <td></td> */}
                    <td
                      colSpan="4"
                      style={{ borderTopRightRadius: "6px", cursor: "pointer" }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      View Detail
                    </td>
                  </tr>

                  {updateData[projectName].flat().map((e, index) => {
                    return (
                      <tr
                        className="text-start"
                        key={`${projectName}-${index}`}
                        style={{
                          backgroundColor: "#ddd",
                          color: "#000",
                          letterSpacing: "0.105em",
                          fontWeight: "400",
                          fontSize: "14px",
                          border: "1px solid #e2e7f0",
                        }}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={e?.approved}
                            onChange={() =>
                              handleSelectItem(projectName, index, data)
                            }
                          />
                        </td>
                        <td>{`IE-${projectName}-00${index}`}</td>
                        <td>{e.a_no}</td>
                        <td>{e.a_description}</td>
                        <td>
                          {e?.order?.milestone_id
                            ? `Milestone ${e?.order.milestone_id}`
                            : "-"}
                        </td>
                        <td>{date(e?.created_at)}</td>
                        <td>
                          {e?.project
                            ? e.project[0]?.project_participants?.find(
                                (items) =>
                                  items?.pivot?.role_id ==
                                  "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
                              )?.name
                            : "-"}
                        </td>
                        <td>
                          {Number(e.amount ? e.amount : 0)?.toLocaleString()}
                        </td>
                        <td>{e?.currency_id}</td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      borderBottomLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "end",
                        borderBottomLeftRadius: "6px",
                        width: "auto",
                      }}
                      colSpan="5"
                    >
                      Total cost of {projectName}
                    </td>
                    {[
                      { id: 1, name: "USD" },
                      { id: 2, name: "AUD" },
                      { id: 4, name: "Euro" },
                      { id: 7, name: "Birr" },
                    ].map((i) => {
                      let proj = data[projectName]
                        .flat()
                        .filter((c) => c?.currency_id === i.id)
                        .map((m) => {
                          return m.amount;
                        })
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          0
                        );

                      if (proj > 0) {
                        return (
                          <td>
                            {Number(proj)?.toLocaleString()}
                            {i.name}{" "}
                          </td>
                        );
                      }
                    })}

                    {/* <td style={{ borderBottomRightRadius: "6px" }}>
                      {Number(projectTotals[index]?.totalETB)?.toLocaleString()}
                    </td> */}
                  </tr>
                  <br />
                </React.Fragment>
              ))}
            </>
          ) : (
            <td
              colSpan="8"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <div className="d-flex gap-3">
                <div
                  className="spinner-border"
                  style={{ color: "#064b4f" }}
                  role="status"
                ></div>
                <span className="visually">Wait a few second</span>
              </div>
            </td>
          )}
        </tbody>
      </table>
      {dialog && (
        <ConfirmDelete
          action={HandleApprovalSubmit}
          setters={setDialog}
          setDialog={setDialog}
          loading={loading}
          dialog={`Are you to Approve the selected Project Budgets `}
          buttonTitle={"Yes"}
        />
      )}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        ></Box>
      </Modal>
    </div>
  );
}
