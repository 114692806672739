import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import PerformanceGuarantee from "./PerformanceGuarantee";
import AdvancePaymentGuarantee from "./Summary";
import CreateGuarantee from "./CreateGuarantee";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useGetProjectGuaranteeQuery } from "../../../features/SCMApi";
import { NavLink, useParams } from "react-router-dom";
import NoRecord from "../../common/NoRecord";
import WestIcon from "@mui/icons-material/West";

export default function GuaranteeProjectDetail() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [createGuarantee, setCreateGuarantee] = React.useState(false);
  function handleCreateGuarantee() {
    setCreateGuarantee(true);
  }
  const params = useParams();
  const { data: guarantee } = useGetProjectGuaranteeQuery(params.id);
  console.log(guarantee, "guarantee");
  return (
    <ForexSideBar>
      <NavLink to={`/payment/guarantee`} className="create-project-btn mb-3">
        <WestIcon
          sx={{
            "&:hover": {
              backgroundColor: "#fff",
              color: "#186569",
            },
          }}
        />
        Go Back
      </NavLink>
      <div className="guarantee-container p-3">
        <div
          style={{ width: "180px" }}
          className="create-project-btn mb-3"
          onClick={handleCreateGuarantee}
        >
          <AddIcon />
          Create Guarantee
        </div>

        {guarantee?.data.length != 0 ? (
          <>
            {" "}
            {Object.keys(guarantee == undefined ? [] : guarantee?.data).map(
              (key, index) => (
                <Accordion
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  sx={{ background: "#EFF2F3" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {key}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PerformanceGuarantee data={guarantee?.data[key]} />
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </>
        ) : (
          <NoRecord />
        )}
      </div>
      {createGuarantee ? <CreateGuarantee modal={setCreateGuarantee} /> : ""}
    </ForexSideBar>
  );
}
