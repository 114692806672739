import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddItemsCategory from "./SettingModal/AddItemsCategory";
import EditItemsCategory from "./SettingModal/EditItemCategory";
import {
  useDeleteItemCategoryMutation,
  useGetItemCategorysQuery,
} from "../../features/SCMApi";
export default function ItemCategory() {
  const [addItemCategory, setItemCategory] = useState(false);
  const [editItemCategory, setEditItemCategory] = useState(false);
  const HandleToolType = () => {
    setItemCategory(true);
  };
  const HandleEditToolType = () => {
    setEditItemCategory(true);
  };
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const [updateStatus, setStatus] = useState();
  const [DeleteStore, src] = useDeleteItemCategoryMutation();

  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  const [updateToolType, setupdateToolType] = useState();
  console.log(ItemCategory, "ItemCategory");
  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Item Category</h3>
      </section>
      {ItemCategory?.data?.map((items) => (
        <section className="pi-body">
          <p>{items?.category}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                HandleEditToolType(true);
                setupdateToolType(items);
              }}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={(e) => {
                HandleDelete(e, items.id);
              }}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={HandleToolType} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addItemCategory ? <AddItemsCategory modal={setItemCategory} /> : ""}
      {editItemCategory ? (
        <EditItemsCategory modal={setEditItemCategory} data={updateToolType} />
      ) : (
        ""
      )}
    </div>
  );
}
