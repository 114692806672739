import { AddIcCallOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { IMG_BASE_URL } from "../../../api/endPoint";
import {
  useDeletePurchasedRequestMutation,
  useGetPurchasedRequestQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import AddPurchaseRequest from "../StockModal/AddPurchaseRequest";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditPurchaseRequest from "../StockModal/EditPurchasedRequest";
import NoRecord from "../../common/NoRecord";
import StockIndex from "../../../pages/Stocks/StockIndex";
export default function PurchasedRequest() {
  const { data: purchased } = useGetPurchasedRequestQuery();
  console.log(purchased, "test one");
  const [createRequest, setCreateRequest] = useState(false);
  function HandleRequest() {
    setCreateRequest(true);
  }
  const [updateRequest, setUpdateRequest] = useState(false);
  const [updatePurchasedRequest, setupdatePurchasedRequest] = useState();
  function HandleEditRequest(items) {
    setUpdateRequest(true);
  }
  const { data: userinfo } = useGetUserInfoQuery();
  console.log(userinfo, "useroooooo");
  const [DeletePurchasedRequest, rep] = useDeletePurchasedRequestMutation();
  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeletePurchasedRequest(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  console.log(purchased, "purchased");
  return (
    <StockIndex>
      <div className="stock-header-container">
        <button onClick={HandleRequest} className="stock-btn">
          Request Purchase
        </button>
      </div>
      {purchased?.data?.length !== 0 ? (
        <table style={{ width: "100%" }}>
          <thead className="stock-table-header">
            <tr>
              <th style={{ width: "10%" }}></th>
              <th style={{ width: "17%" }}>Item Name</th>
              <th style={{ width: "15%" }}>Project</th>
              <th style={{ width: "10%" }}>Request By</th>
              <th style={{ width: "30%" }}>Description</th>
              <th style={{ width: "4%" }}></th>
            </tr>
          </thead>
          <tbody>
            {purchased?.data?.map((items) => (
              <tr className="stock-table-row">
                <td className="">
                  <img
                    className="stock-img"
                    src={`${IMG_BASE_URL}${items?.image}`}
                    alt=""
                  />
                </td>
                <td className="text-start">{items?.item_name}</td>
                <td className="text-start">{items?.project?.project_name}</td>
                <td className="text-start">{items?.requested_by?.name}</td>
                <td className="text-start">{items?.description}</td>
                {userinfo?.role?.role_name === "Sourcing" ? (
                  <td className="text-start">
                    <button className="form-save">Purchased</button>
                  </td>
                ) : true ? (
                  <td>
                    <ModeEditOutlineOutlinedIcon
                      onClick={() => {
                        HandleEditRequest(true);
                        setupdatePurchasedRequest(items);
                      }}
                      sx={{
                        color: "#52FFAC",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#52FFAC",
                          border: "1px solid #52FFAC",
                          borderRadius: 25,
                        },
                      }}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={(e) => {
                        HandleDelete(e, items.id);
                      }}
                      sx={{
                        color: "#FF5252",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          border: "1px solid #FF5252",
                          borderRadius: 25,
                        },
                      }}
                    />
                  </td>
                ) : (
                  ""
                )}
                {/* {items?.requested_by?.id === userinfo?.id ? (
                <div>
                  <ModeEditOutlineOutlinedIcon
                    // onClick={() => {
                    //   HandleEditToolType(true);
                    //   setupdateToolType(items);
                    // }}
                    sx={{
                      color: "#52FFAC",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#52FFAC",
                        border: "1px solid #52FFAC",
                        borderRadius: 25,
                      },
                    }}
                  />
                  <DeleteOutlineOutlinedIcon
                    // onClick={(e) => {
                    //   HandleDelete(e, items.id);
                    // }}
                    sx={{
                      color: "#FF5252",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#FF5252",
                        border: "1px solid #FF5252",
                        borderRadius: 25,
                      },
                    }}
                  />
                </div>
              ) : (
                ""
              )} */}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoRecord />
      )}

      {createRequest ? <AddPurchaseRequest modal={setCreateRequest} /> : ""}
      {updateRequest ? (
        <EditPurchaseRequest
          data={updatePurchasedRequest}
          modal={setUpdateRequest}
        />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
