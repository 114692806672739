import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  useGetAllPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetpaymentCollectionAllQuery,
  useGetpaymentCollectionQuery,
} from "./../../../../features/SCMApi";

const ForexCollectionReport = ({ data }) => {
  const { data: paymentCollection } = useGetAllPaymentCollectionsQuery();
  const { data: project } = useGetProjectQuery(
    "0179ef4b-3aa7-45ee-858a-1370bb839c48"
  );
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("All");
  const titleCell = worksheet.getCell("A1");
  titleCell.alignment = { vertical: "middle", horizontal: "center" };
  titleCell.border = {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  };
  titleCell.font = { size: 12, name: "Times New Roman", bold: true };
  titleCell.value = `Forex Payment Collection Report`;
  titleCell.alignment = { horizontal: "center" };
  titleCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "00B0F0" },
  };
  worksheet.mergeCells("A1:J1");
  const result = data?.reduce((acc, curr) => {
    // {console.log(curr?.variance_project[0]?.id,"curr.variance_project[0].project_id")}
    const group = acc?.find((g) => g.project_id === curr.project_id);
    if (group) {
      group.actual_amount += Number(curr.actual_amount);
      group.planned_amount += Number(curr.planned_amount);
      group.events.push(curr);
    } else {
      acc.push({
        project_id: curr.project_id,
        actual_amount: Number(curr.actual_amount),
        planned_amount: Number(curr.planned_amount),
        events: [curr],
      });
    }
    return acc;
  }, []);
  console.log(result, "by date");

  const mapData = [
    {
      lat: "222",
      lng: "33",
      title: "KKKKK",
      description: "hhhhhhh",
    },
  ];

  let count = 0;
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(project?.data?.project_name);
    const headersCost = result?.map((items) => `${items?.p} Planned Cost `);
    const headersRevenue = result?.map(
      (items) => `${items?.start_date} Planned Revenue `
    );

    const project_names = result?.map(
      (items, index) =>
        result[index]?.events[0]?.collection_project[0].project_name
    );
    const headersActualCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.actual_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );
    const headersPlannedCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.planned_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );

    let PlannedTotalCost = 0;
    headersPlannedCost.map((item) => (PlannedTotalCost += item));

    let ActualTotalCost = 0;
    headersActualCost.map((item) => (ActualTotalCost += item));

    console.log(PlannedTotalCost, "headersPlannedCost");
    // Add title cell above the table headers

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Project",
      "Item",
      "CI value",
      "LC No",
      "Bill Reference",
      "Shipment Document collection date ",
      "Payment Status",
      "PFO",
      "LO",
      "Remark",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 25;
    // Set the header row to bold
    worksheet.getRow(1).font = { bold: true };

    // Populate the table with data from the map

    function date(dates) {
      const dateStr = dates;
      const date = new Date(dateStr);
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

      return formattedDate;
    }
    let TotalPlannedAmount = 0;
    let TotalActualAmount = 0;
    let TotalUncollectedAmount = 0;

    data?.map(
      (items, index) => (TotalPlannedAmount += Number(items?.ci_value))
    );
    data?.map(
      (items, index) => (TotalActualAmount += Number(items?.actual_amount))
    );
    data?.map(
      (items, index) =>
        (TotalUncollectedAmount += Number(
          items?.planned_amount - items?.actual_amount
        ))
    );

    data?.map((items, index) => {
      const DataRow = worksheet?.addRow([
        index,
        items?.collection_project[0]?.project_name,
        items?.items,
        Number(items?.ci_value)?.toLocaleString(),
        items?.lc_no,
        items?.bill_reference,
        date(items?.shipment_document_colletion_date),
        items?.payment_status,

        items?.collection_project[0]?.users?.filter(
          (item) =>
            item?.pivot?.role_id == "0e324e94-6f2c-415c-9a46-a359a96fea7f"
        )[0]?.name,
        items?.collection_project[0]?.users?.filter(
          (item) =>
            item?.pivot?.role_id == "6b829425-8842-42c4-b68c-cc001a22985f"
        )[0]?.name,
        items?.remark,
      ]);
      DataRow.eachCell((cell) => {
        if (cell.address.includes("H")) {
          if (items?.is_collected == 1) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00B050" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF" },
            };
          }
        }
      });
      DataRow.eachCell((cell) => {
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
        };
      });
    });

    const FooterRow = worksheet.addRow([
      " ",
      "Total",
      TotalPlannedAmount?.toLocaleString(),
      "",
      "",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
    ]);
    FooterRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Payment Collection.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      {" "}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default ForexCollectionReport;
