import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import SuccessMessage from "../SuccessMessage";
import { useGetUserInfoQuery } from "../../../features/SCMApi";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "45px",
  padding: "0 0 0 5px",
  // textAlign: "left",
}));
const DayDisplay = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  color: "#353945",
  // width: "50px",
  // padding: "0 0 0 5px",
  // // textAlign: "left",
}));
const TCellForOrder = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "150px",
  padding: "0 5px 0 0",
}));

const TCellHeader = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
  padding: "1px",
}));
const GrandTotal = styled(TableCell)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  borderBottom: "#fff",
}));

const Pagination = styled(TablePagination)(({ theme }) => ({
  "& .css-pdct74-MuiTablePagination-selectLabel": {
    display: "none",
  },
  "& .css-levciy-MuiTablePagination-displayedRows": {
    marginTop: "19px",
  },
  height: "55px",
  marginBottom: "-25px",
  ".MuiInputBase-root": {
    marginTop: "4.5px",
    marginRight: "10px",
  },
  "& .MuiTablePagination-actions": {
    marginTop: "2px",
    marginLeft: "8px",
  },
}));

export default function ReceivableHistory(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  const rows = props?.data !== undefined ? props?.data : [];
  const { data: userinfo } = useGetUserInfoQuery();

  console.log(rows, props, "this is row data for the receivable history");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0NO\xa0\xa0\xa0",
    },
    {
      id: "vendor",
      numeric: true,
      disablePadding: true,
      label:
        "VENDOR\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "product",
      numeric: true,
      disablePadding: false,
      label:
        "PRODUCT/SERVICE\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label:
        "DESCRIPTION\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "expectedDate",
      numeric: true,
      disablePadding: false,
      label:
        "PAYED DATE\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label:
        "AMOUNT\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "wayOfPayment",
      numeric: true,
      disablePadding: false,
      label:
        "PAYED TO\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          backgroundColor: "#ececec",
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
            >
              {headCell.label}
            </TCellHeader>
          ))}
          <TCellHeader></TCellHeader>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let counter = 0;
  const currentDate = new Date();

  return (
    <>
      <Table
        sx={{ width: "103.4%", marginBottom: "-5px" }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <>
          {successMessage === true ? (
            <>
              <SuccessMessage
                style={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  backgroundColor: "#52ffab96",
                  width: "362%",
                }}
                Message={message}
              />
            </>
          ) : (
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
          )}
        </>

        <TableBody>
          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {(counter += 1)}
                    </TableCell>
                    <TCell>
                      {row?.forex_receivable_manufacturer?.manufacturer_name}
                    </TCell>
                    <TCell>{row?.order_type}</TCell>

                    <TableCell
                      align="left"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                        // width: "100px",
                        padding: "0 0 0 5px",

                        // flexDirection: "column",
                        // justifyContent: "center",
                        // padding: "0 0px 0 10px",
                      }}
                    >
                      {row?.description}
                    </TableCell>

                    <TCell>
                      {new Date(row?.created_at)
                        ?.toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        ?.replace(",", "")}
                    </TCell>

                    <TCell>{Number(row?.amount).toLocaleString()}</TCell>
                    <TCell>
                      {row?.receivable_history_forex_receivable
                        ?.forex_receivable_supplier !== null
                        ? row?.receivable_history_forex_receivable
                            ?.forex_receivable_supplier?.distributor_name
                        : row?.receivable_history_forex_receivable
                              ?.forex_receivable_bank !== null
                          ? row?.receivable_history_forex_receivable
                              ?.forex_receivable_bank?.bank_name
                          : "NA"}
                    </TCell>
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
      <Pagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
