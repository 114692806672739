import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import PerformanceGuarantee from "./PerformanceGuarantee";
import AdvancePaymentGuarantee from "./Summary";
import CreateGuarantee from "./CreateGuarantee";
import {
  useGetAllGuaranteeQuery,
  useGetBanksQuery,
  useGetSummaryGuaranteeQuery,
} from "../../../features/SCMApi";
import MuiSelect from "../../common/MuiSelect";
import NoRecord from "../../common/NoRecord";
import Summary from "./Summary";
import GuaranteeReport from "./Report/GuaranteeReport";

export default function GuaranteeSummary() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? true : true);
  };
  const [createGuarantee, setCreateGuarantee] = React.useState(false);
  function handleCreateGuarantee() {
    setCreateGuarantee(true);
  }
  const [bank_id, setBank] = React.useState(11);

  const { data: guarantee } = useGetSummaryGuaranteeQuery(bank_id);
  const { data: Banks } = useGetBanksQuery();
  let list = [];
  Banks?.data?.map((items) => {
    list.push({
      value: items?.id,
      label: items?.bank_name,
    });
  });

  const mergedData = Object.keys(guarantee == undefined ? [] : guarantee)?.map(
    (key, index) => guarantee[key].concat(guarantee[key]) // Using concat()
  );
  console.log(mergedData, "mergedData");

  return (
    <div className="guarantee-container p-3">
      <div className="m-2 d-flex gap-5 align-items-center">
        <div className="d-grid">
          <div className="prg">Bank</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setBank(e.target.value)}
            value={bank_id}
          >
            <option selected="true" disabled="disabled">
              Choose Bank
            </option>
            {Banks?.data?.map((items) => (
              <option value={items?.id}>{items?.bank_name}</option>
            ))}
          </select>
        </div>
        <GuaranteeReport data={guarantee} />
      </div>
      {guarantee?.length != 0 ? (
        <>
          <Accordion
            expanded={expanded == true}
            onChange={handleChange(true)}
            sx={{ background: "#EFF2F3" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Guarantee Summary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Summary data={guarantee} />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <NoRecord />
      )}

      {createGuarantee ? (
        <CreateGuarantee modal={setCreateGuarantee} all={true} />
      ) : (
        ""
      )}
    </div>
  );
}
