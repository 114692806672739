import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./tabstyle.css";
import PoDragFeature from "../../Settings/features/PoDragFeature";

export default function ComparisonApproval({ comparison, setMessage, type }) {
  console.log(comparison, "comparison");
  return (
    <>
      <Tabs
        defaultActiveKey={0}
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center gap-4"
      >
        {comparison?.map((items, index) => (
          <Tab
            eventKey={index}
            title={
              items.formula_type == 1
                ? "Profit > 0 & Profit< 10K"
                : items.formula_type == 0
                ? "Profit < 0"
                : "Profit > 0 & Profit > 10K"
            }
          >
            <PoDragFeature
              setMessage={setMessage}
              range_stages={items?.approval_stage}
              index={index}
              id={items?.id}
              type={type}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
