import React from "react";
import {
  useGetPRFQuery,
  useGetPoPRFQuery,
  useShowPRFQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../../api/endPoint";

export default function PrfContainer(props) {
  const tables = {
    width: " 100%",
    borderCollapse: "collapse",
    border: "1px solid black",
  };
  const tableheader = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
    fontSize: 12,
  };
  const content = {
    fontSize: 14,
    fontFamily: "Times New Roman",
  };
  const params = useParams();

  const { data: prf } = useShowPRFQuery(
    props?.prf_id ? props?.prf_id : params?.prf_id
  );
  let total_cost = prf?.data?.total_payment;
  let vat = 0;
  let withholding = 0;
  let cash_bank = 0;
  let tot = 0;
  // prf?.data?.items?.map((items) => (total_cost += items?.total_payment));
  withholding =
    prf?.data?.with_out_withholding == 0
      ? prf?.data?.order?.order_type == "Product" && total_cost >= 10000
        ? total_cost * 0.02
        : prf?.data?.order?.order_type == "Service" && total_cost >= 3000
          ? total_cost * 0.02
          : 0
      : 0;

  if (prf?.data?.with_out_vat == 0) {
    tot = 0.15;
  } else if (prf?.data?.is_tot == 1) {
    tot = 0.02;
  } else if (prf?.data?.is_tot == 2) {
    tot = 0.1;
  } else {
    tot = 0;
  }

  vat = total_cost * tot;
  cash_bank = total_cost + vat - withholding;
  console.log(prf, "prfprf");
  return (
    <div
      className="bg-white"
      style={{ width: "70%", border: "1px solid black" }}
    >
      <div className="">
        <img
          src="https://ienetworks.co/pms/images/logos.png"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "0px",
          }}
        />
      </div>
      <div className="d-grid justify-content-center gap-1 ">
        <div
          style={{
            fontFamily: "Perpetua",
            fontSize: "10pt",
            letterSpacing: "-0.05pt",
          }}
        >
          IE NETWORK SOLUTIONS PLC
        </div>
        <div
          style={{
            fontFamily: "Perpetua",
            fontSize: "10pt",
            letterSpacing: "-0.05pt",
          }}
          className="text-center"
        >
          Payment Request Form
        </div>
      </div>
      <div className="d-grid justify-content-start gap-1 ms-2 mt-3">
        <div
          style={{
            fontFamily: "Perpetua",
            fontSize: "10pt",
            letterSpacing: "-0.05pt",
          }}
        >
          Payment Mode: {prf?.data?.payment_mode?.payment_mode}
        </div>
        <div
          style={{
            fontFamily: "Perpetua",
            fontSize: "10pt",
            letterSpacing: "-0.05pt",
          }}
        >
          Reason For: {prf?.data?.reason_for}
        </div>
        <div
          style={{
            fontFamily: "Perpetua",
            fontSize: "10pt",
            letterSpacing: "-0.05pt",
          }}
        >
          Payable To: {prf?.data?.order?.supplier?.distributor_name}
        </div>
      </div>
      <table style={tables}>
        <thead style={tableheader}>
          <tr style={tableheader}>
            <th style={tableheader}>Budget Number</th>
            <th style={tableheader}>Project Name</th>
            <th style={tableheader}>Description</th>
            <th style={tableheader}>Unit Price</th>
            <th style={tableheader}>Quantity</th>
            <th style={tableheader}>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {prf?.data?.items?.map((items) => (
            <tr>
              <td style={tableheader}>{prf?.data?.budget_number}</td>
              <td style={tableheader}>{prf?.data?.project?.project_name}</td>
              <td style={tableheader}>
                {items?.item_description
                  ? items?.item_description
                  : items?.course_description}
              </td>
              <td style={tableheader}>
                {Number(items?.pi_unit_cost)?.toLocaleString()}
              </td>
              <td style={tableheader}>{items?.qty}</td>
              <td style={tableheader}>
                {Number(items?.pi_total_cost)?.toLocaleString()}
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>

            <td style={tableheader} colspan="3">
              {prf?.data?.order?.supplier?.id ==
              "8b93d1fd-9406-4c9c-99f7-0995b87481c1"
                ? "Total with out margin"
                : "Total"}
            </td>
            {prf?.data?.order?.supplier?.id ==
            "8b93d1fd-9406-4c9c-99f7-0995b87481c1" ? (
              <td style={tableheader}>
                {Number(total_cost / 1.1)?.toLocaleString()}
              </td>
            ) : (
              <td style={tableheader}>
                {Number(total_cost)?.toLocaleString()}
              </td>
            )}
          </tr>

          {prf?.data?.order?.supplier?.id ==
          "8b93d1fd-9406-4c9c-99f7-0995b87481c1" ? (
            <tr>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={tableheader} colspan="3">
                Marign
              </td>
              {prf?.data?.order?.supplier?.id ==
              "8b93d1fd-9406-4c9c-99f7-0995b87481c1" ? (
                <td style={tableheader}>
                  {Number((total_cost / 1.1) * 0.1)?.toLocaleString()}
                </td>
              ) : (
                <td style={tableheader}>
                  {Number(total_cost)?.toLocaleString()}
                </td>
              )}
            </tr>
          ) : (
            <>
              <tr>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={tableheader} colspan="3">
                  VAT Receivable
                </td>

                <td style={tableheader}>{Number(vat)?.toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={tableheader} colspan="3">
                  Witholding Payable
                </td>

                <td style={tableheader}>
                  {Number(withholding)?.toLocaleString()}
                </td>
              </tr>
            </>
          )}
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={tableheader} colspan="3">
              Cash at Bank
            </td>
            {prf?.data?.order?.supplier?.id ==
            "8b93d1fd-9406-4c9c-99f7-0995b87481c1" ? (
              <td style={tableheader}>
                {Number(total_cost)?.toLocaleString()}
              </td>
            ) : (
              <td style={tableheader}>{Number(cash_bank)?.toLocaleString()}</td>
            )}
          </tr>
        </tbody>
      </table>
      {!props?.prf_id ? (
        <div>
          <div className="d-grid gap-2 mx-2 my-2">
            <div className="d-flex gap-2">
              <div style={content}>Requested by:</div>
              <div className="d-grid gap-1">
                <div style={content}>
                  <strong>Name:</strong> {prf?.data?.requested_by?.name}
                </div>
                <div style={content}>
                  <strong>positions:</strong>{" "}
                  {prf?.data?.requested_by?.role?.role_name}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div style={content}>Prepared by:</div>
              <div className="d-grid gap-1">
                <div style={content}>
                  <strong>Name:</strong> {prf?.data?.prepared_by?.name}
                </div>
                <div style={content}>
                  <strong>positions:</strong>{" "}
                  {prf?.data?.prepared_by?.role?.role_name}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div style={content}>Prepared by:</div>
              <div className="d-grid gap-1">
                <div style={content}>
                  <strong>Name:</strong> {prf?.data?.prepared_by?.name}
                </div>
                <div style={content}>
                  <strong> positions:</strong>{" "}
                  {prf?.data?.prepared_by?.role?.role_name}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div style={content}>Approved by:</div>
              <div className="d-grid gap-1">
                <div style={content}>
                  <strong>Name: </strong>
                  {prf?.data?.approved_by?.name}
                </div>
                <div style={content}>
                  <strong> positions:</strong>{" "}
                  {prf?.data?.approved_by?.role?.role_name}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div style={content}>Paid by:</div>
              <div className="d-grid gap-1">
                <div style={content}>
                  <strong>Name:</strong> {prf?.data?.paid_by?.name}
                </div>
                <div style={content}>
                  <strong>positions:</strong>
                  {prf?.data?.paid_by?.role?.role_name}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 mt-2 ms-1 mb-2">
            <strong>Signature:</strong>
            <img
              style={{ width: "60.6pt", height: "30.6pt" }}
              src={`${IMG_BASE_URL}${prf?.data?.approved_by?.signature}`}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-end">
        <img
          src="https://dl.dropboxusercontent.com/s/xqnjfu29ofvnmwj/stamp.png"
          style={{ width: "123.75pt", height: "120pt" }}
          alt="Stamp"
        />
      </div>
    </div>
  );
}
