import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useCreatePaymentCollectionOffshoreMutation,
  useGetBanksQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectPaymentTermQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useUpdatePaymentCollectionOffshoreMutation,
} from "../../../features/SCMApi";
import { Collections } from "@mui/icons-material";

export default function UpdateForexCollection({ data, modal }) {
  const params = useParams();
  const [items, setItem] = useState(data?.items);
  const [project_payment_term_id, setPaymentTerm] = useState(
    data?.project_payment_term_id
  );
  const [ci_value, setCiValue] = useState(data?.ci_value);
  const [lc_no, setLcNo] = useState(data?.lc_no);
  const [bill_reference, setBillReference] = useState(data?.bill_reference);
  const [shipment_document_colletion_date, setShipmentDocument] = useState(
    data?.shipment_document_colletion_date
  );
  const [payment_status, setPaymentStatus] = useState(data?.payment_status);
  const [remark, setRemark] = useState(data?.remark);
  const [is_collected, setCollection] = useState(false);
  const [bank_id, setBank] = useState(null);
  const pro_id = params?.id;
  const { data: projectid } = useGetProjectQuery(pro_id);
  const { data: banks } = useGetBanksQuery();
  function HandleCancel() {
    modal(false);
  }

  let form = {
    id: data?.id,
    is_collected: is_collected == false ? 0 : 1,
    project_id: params?.id,
    project_payment_term_id,
    items,
    ci_value,
    lc_no,
    bill_reference,
    shipment_document_colletion_date,
    payment_status,
    remark,
    bank_id: is_collected == false ? null : bank_id,
  };
  const [updatePaymentCollection] =
    useUpdatePaymentCollectionOffshoreMutation();
  const { data: terms } = useGetProjectPaymentTermQuery(params?.id);
  function handleSubmit(e) {
    e.preventDefault();
    updatePaymentCollection(form)
      .unwrap()
      .then((response) => {
        modal(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  console.log(data, "formform");

  let offshoreTerms = [];
  terms?.data?.map((items) => {
    if (items?.is_offshore == 1) {
      offshoreTerms.push(items);
    }
  });

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Edit Payment Collection</div>
            <CloseIcon onClick={HandleCancel} />
          </div>

          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="d-flex  justify-content-center gap-2 px-2">
                <input
                  type="checkbox"
                  className="stock-checkmark"
                  onChange={() => setCollection(!is_collected)}
                />
                <span className="h6">Final Collection</span>
              </div>
              <div className="input-groups">
                {is_collected == true ? (
                  <>
                    <div className="input-label">Bank</div>
                    <select
                      value={bank_id}
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Bank
                      </option>
                      {banks?.data.map((items) => (
                        <option value={items.id}>{items?.bank_name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}

                <div className="supplier-input-label">items</div>
                <input
                  value={items}
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setItem(e.target.value)}
                />
                <div className="supplier-input-label">CI Value</div>
                <input
                  value={ci_value}
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setCiValue(e.target.value)}
                />
                <div className="supplier-input-label">LC Number</div>
                <input
                  value={lc_no}
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setLcNo(e.target.value)}
                />
                <div className="supplier-input-label">Bill Reference</div>
                <input
                  value={bill_reference}
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setBillReference(e.target.value)}
                />

                <div className="supplier-input-label w-100">
                  Shipment Document Date
                </div>
                <input
                  value={shipment_document_colletion_date}
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setShipmentDocument(e.target.value)}
                />

                <div className="input-label">Payment Term</div>
                <select
                  value={project_payment_term_id}
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setPaymentTerm(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Payment Term
                  </option>
                  {offshoreTerms.map((items) => (
                    <option value={items.id}>{items?.reason}</option>
                  ))}
                </select>

                <div className="input-label">Payment Status</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  className="finance-input-field m-0"
                >
                  {payment_status}
                </textarea>
                <div className="input-label">Remark</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setRemark(e.target.value)}
                  className="finance-input-field m-0"
                >
                  {remark}
                </textarea>
              </div>
            </div>

            {/* {Number(planned_amount) < Number(actual_amount) ? (
              <label className="prg-error text-danger d-flex">
                Please enter valid Actual Amount
              </label>
            ) : (
              ""
            )} */}
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                // disabled={
                //   Number(planned_amount) < Number(actual_amount) ? true : false
                // }
                type="submit"
                className={
                  // Number(planned_amount) < Number(actual_amount)
                  // ? "form-save bg-secondary text-white"
                  "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
