import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DropdownButton, Dropdown } from "react-bootstrap";
import {
  useDownloadRequestedGrnByGrnIdMutation,
  useGenerateGrnForOffshoreItemsByRequestIdMutation,
  useGenerateGrnForPrfMutation,
  useGetStoreQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { Link, Navigate } from "react-router-dom";
import SuccessMessage from "../../components/common/SuccessMessage";
import AutoClosePopup from "./SuccessMessage/AutoClosePopup ";
import { Tooltip } from "@mui/material";
function GRNSidebar({
  open,
  onclose,
  selectedItem,
  setSelectedItem,
  originalItems,
  setMessage,
  setIsOpen,
  setType,
}) {
  const { data: userinfo } = useGetUserInfoQuery();
  // const [userId,setUserId]=useState('');
  const [downloadRequestedGrnByGrnId] =
    useDownloadRequestedGrnByGrnIdMutation();
  const [generateGrnForPrf] = useGenerateGrnForPrfMutation();
  const [generateGrnForOffshoreItemsByRequestId] =
    useGenerateGrnForOffshoreItemsByRequestIdMutation();
  const [requestedGrn, setRequestedGrn] = useState([]);
  const [items, setItems] = useState(selectedItem || []);
  const [editQty, setEditQty] = useState([]);
  const [maxQtyValue, setMaxQtyValue] = useState(null);
  const [selectedStore, setSelectedStore] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [isConsumeAble, setIsConsumeAble] = useState([]);
  const userId = userinfo?.id;
  const [warningMessage, setWarningMessage] = useState([]);
  console.log(originalItems, "originalItems");
  // const handleDownload=()=>{

  //                       Navigate('/download/grn-pdf')
  // }
  const handleGenerateGRn = () => {
    const checkedItems = selectedItem.items?.filter(
      (item) => item.isChecked === true
    );
    const newArrayData = { ...selectedItem, items: checkedItems };
    console.log(newArrayData, "checkedItems");
    //  const newData={...newArrayData,userId:userId};
    //  console.log(newData,"ephrem");
    // setSelectedItem(newData);
    if (selectedItem?.is_onshore == 1) {
      console.log(newArrayData, "newData");
      generateGrnForPrf(newArrayData)
        .unwrap()
        .then((response) => {
          console.log(response, "*****************");
          onclose();
          setIsOpen(true);
          setMessage(response?.message);
          setType("success");
        })
        .catch((error) => {
          console.log(error, "*****************");
          setIsOpen(true);
          setMessage(error?.data?.message);
          setType("danger");
        });
    }
    if (selectedItem?.is_onshore == 0) {
      generateGrnForOffshoreItemsByRequestId(newArrayData)
        .unwrap()
        .then((response) => {
          console.log(response, "eph");
          onclose();
        })
        .catch((error) => {
          console.log(error, "error response");
        });
    }
  };
  const handleIsConsumable = (index) => {
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedIsConsumeAble = [...isConsumeAble];
      updatedIsConsumeAble[index] =
        updatedIsConsumeAble[index] === undefined ||
        updatedIsConsumeAble[index] === false
          ? true
          : false;
      setIsConsumeAble(updatedIsConsumeAble); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        isConsumeAble: updatedIsConsumeAble[index],
      };

      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      }); // to update the selected item
    }
  };
  const handleStoreSelect = (option, index) => {
    console.log(option, index, "option, index");
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedStoreType = [...selectedStore];
      updatedStoreType[index] = option;
      setSelectedStore(updatedStoreType); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        store: option,
      };
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      }); // to update the selected item
    }
  };
  const handleItemSelect = (option, index) => {
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedItemType = [...itemType];
      updatedItemType[index] = option;

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        ItemType: option,
      };

      setItemType(updatedItemType);
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      });
    }
  };
  const handleQuantityChange = (subItem, value) => {
    if (selectedItem?.is_onshore == 1) {
      let newArray = [];
      const index = selectedItem?.items?.findIndex(
        (item) => item.item_part_number === subItem.item_part_number
      );
      const updatesItems = [...selectedItem.items];
      if (index !== -1) {
        const updatedItem = { ...updatesItems[index] };
        // if (action === 'increase') {
        updatedItem.qty = value;
        // } else {
        //     updatedItem.qty = (updatedItem.qty ?? 0) - 1;
        // }
        updatedItem.pi_total_cost = updatedItem.qty * updatedItem.pi_unit_cost;
        updatesItems[index] = updatedItem;
      }
      setSelectedItem({ ...selectedItem, items: updatesItems });
    } else {
      let newArray = [];
      const index = selectedItem?.items?.findIndex(
        (item) => item.item_part_number === subItem.item_part_number
      );
      const updatesItems = [...selectedItem.items];
      if (index !== -1) {
        const updatedItem = { ...updatesItems[index] };
        // if (action === 'increase') {
        updatedItem.delivered_qty = value;
        // } else {
        //   updatedItem.delivered_qty = (updatedItem.delivered_qty ?? 0) - 1;
        // }
        // updatedItem.total_price=(updatedItem.delivered_qty)*(updatedItem.unit_price);
        updatesItems[index] = updatedItem;
      }
      setSelectedItem({ ...selectedItem, items: updatesItems });
    }
  };
  const handleIsChecked = (index) => {
    // const updatedEditQty = [...editQty]; // Create a copy of the array
    // updatedEditQty[index] = !updatedEditQty[index]; // Toggle the value at the specified index
    // setEditQty(updatedEditQty); // Update the state with the modified array
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedIsChecked = [...editQty];
      updatedIsChecked[index] = !updatedIsChecked[index];
      setEditQty(updatedIsChecked); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        isChecked: updatedIsChecked[index],
        isConsumeAble: false,
        store: "HQ Store",
        ItemType: "item",
      };
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
        userId: userId,
      }); // to update the selected item
    }
  };
  const { data: store } = useGetStoreQuery();
  console.log(store, "irem samii");

  return (
    <>
      <div
        id="myModal"
        className={`mini-modal ${open ? "show" : ""}`}
        style={{ display: open ? "block" : "none" }}
      >
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
          style={{ marginLeft: "45%", width: "55%" }}
        >
          <div className="create-project-modal-title">
            <div>Items in PRF</div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setSelectedItem([]);
                setIsConsumeAble([]);
                setSelectedStore([]);
                setItemType([]);
                setEditQty([]);
                onclose();
              }}
            ></button>
          </div>
          <div className="modal-body task-order-name font-serif mb-5 rounded">
            <div
              className="p-2 rounded"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div>
                <h4>{"Purchased By : " + selectedItem.purchased_by}</h4>
                <p>
                  {"Purpose of purchased : " +
                    selectedItem?.purpose_of_purchase}
                </p>
                <p>{"Supplier  : " + selectedItem?.supplier}</p>
                <p>{"For Project  : " + selectedItem?.department_or_project}</p>
              </div>
              <div className="sidebar-body">
                {selectedItem?.optional_flag == 0 && (
                  <ul className="list-group" style={{ maxHeight: "600px" }}>
                    {selectedItem?.items?.map((subItem, index) => (
                      <li
                        key={subItem.id}
                        className="list-group-item"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className=" d-flex align-items-center justify-content-around">
                          <div>
                            {editQty[index] &&
                              selectedItem?.optional_flag !== 1 && (
                                <div className="d-grid p-2 gap-2 ">
                                  <input
                                    type="number"
                                    value={
                                      selectedItem?.is_onshore == 1
                                        ? subItem.qty
                                        : subItem.delivered_qty
                                    }
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );

                                      if (!isNaN(value)) {
                                        if (
                                          (selectedItem?.is_onshore == 1
                                            ? value <= originalItems[index].qty
                                            : value <=
                                              originalItems[index]
                                                .delivered_qty) &&
                                          value >= 0
                                        ) {
                                          const updatedWarningMessages = [
                                            ...warningMessage,
                                          ];
                                          updatedWarningMessages[index] = "";
                                          setWarningMessage(
                                            updatedWarningMessages
                                          );
                                          handleQuantityChange(subItem, value);
                                        } else {
                                          const updatedWarningMessages = [
                                            ...warningMessage,
                                          ];
                                          updatedWarningMessages[index] =
                                            "can't insert above original or less than 0  qty";
                                          setWarningMessage(
                                            updatedWarningMessages
                                          );
                                        }
                                      }
                                    }}
                                    min="0"
                                    style={{ width: "50px" }}
                                  />
                                  {warningMessage[index] && (
                                    <span className="alert alert-warning p-1 italic fs-6">
                                      {warningMessage[index]}
                                    </span>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="quantity-controls p-2">
                            {selectedItem?.is_onshore == 1 ? (
                              <span>{subItem.qty}</span>
                            ) : (
                              <span>{subItem?.delivered_qty}</span>
                            )}
                          </div>
                          <div className="quantity-controls p-2">
                            <input
                              type="checkbox"
                              checked={subItem?.isChecked || editQty[index]}
                              disabled={selectedItem?.optional_flag == 1}
                              onChange={() => handleIsChecked(index)}
                            />
                          </div>
                          <Tooltip title={subItem.item_description}>
                            <span>
                              {subItem.item_description?.slice(0, 20)}
                            </span>
                          </Tooltip>

                          <div className="quantity-controls p-2 d-flex gap-2">
                            <input
                              type="checkbox"
                              checked={
                                subItem?.isConsumeAble || isConsumeAble[index]
                              }
                              disabled={selectedItem?.optional_flag == 1}
                              onChange={() => handleIsConsumable(index)}
                            />
                            <label className=" d-flex">Is Consumeable</label>
                          </div>
                          <div className="d-grid">
                            <div className="d-flex font-bold">Item Type</div>

                            <select
                              name=""
                              id=""
                              className="select-option-create-order "
                              onChange={(e) =>
                                handleItemSelect(e.target.value, index)
                              }
                              style={{ width: "100px" }}
                            >
                              <option selected="true" disabled="disabled">
                                Select Item Types
                              </option>

                              <option value="Training">Item</option>
                              <option value="Service">Tool</option>
                              <option value="Product">Asset</option>
                            </select>
                          </div>
                          <div className="d-grid ">
                            <div className="d-flex font-bold">Select Store</div>

                            <select
                              type="search"
                              className="select-option-create-order"
                              onChange={(e) =>
                                handleStoreSelect(e.target.value, index)
                              }
                              style={{ width: "100px" }}
                            >
                              <option value="">Store</option>
                              {store?.data
                                ?.filter((i) => i?.is_permanent == 1)
                                ?.map((items) => (
                                  <option value={items?.id}>
                                    {items?.store_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="d-flex  justify-content-center ">
                  {selectedItem?.optional_flag == 0 && (
                    <button
                      type="button"
                      class="form-save btn-success py-2 px-4  "
                      onClick={handleGenerateGRn}
                    >
                      Generate GRN
                    </button>
                  )}
                  {selectedItem?.optional_flag == 1 && (
                    <Link to={`/download/grn-pdf/${selectedItem?.id}`}>
                      <button
                        type="button"
                        class="form-save py-2 px-4 mt-3 "
                        onClick={handleGenerateGRn}
                      >
                        Download GRN
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {show && <div className="modal-backdrop fade show"></div>} */}
    </>
  );
}

export default GRNSidebar;
