import React, { useState } from "react";
import {
  useEditPaymentInformationMutation,
  useGetAirLoadingPortsQuery,
  useGetCurrenciesQuery,
  useGetIncotermsQuery,
  useGetLoadingPortalsQuery,
  useGetManufacturersQuery,
  useGetPaymentsModeQuery,
  useGetPaymentTermsQuery,
  useGetProductsQuery,
  useGetSeaLoadingPortsQuery,
  useGetShipmentDestinationsQuery,
  useGetShipmentModesAddQuery,
  useGetSupplierOriginsQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { useParams } from "react-router-dom";
export default function EditPaymentModal(props) {
  const [show, setShow] = useState(false);
  const [bank_name, setBank_name] = useState(props?.value?.bank_name);
  const [account_number, setAccount] = useState(props?.value?.account_number);
  const [swift_code, setSwift] = useState(props?.value?.swift_code);
  const [iban, setIban] = useState(props?.value?.iban);
  const [bank_country, setBankcountry] = useState(props?.value?.bank_country);
  const [bank_currency, setBankcurrency] = useState(
    props?.value?.bank_currency
  );
  const manufacturer = props?.value?.manufacturer_id;
  const supplier = props?.value?.supplier_id;
  const freightforwarder = props?.value?.frieght_forwarder_id;
  // contact person

  function HandleCancel() {
    props.modal(false);
    console.log("closepeeee");
  }
  console.log(props.value, "bank info value");
  const { data: paymentterm } = useGetPaymentTermsQuery();
  const { data: incoterm } = useGetIncotermsQuery();
  const {
    data: paymentmode,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetPaymentsModeQuery();
  const { data: solutions } = useGetProductsQuery();
  const { data: currencies, isErrorcurrency } = useGetCurrenciesQuery();
  console.log(paymentmode, "paymentmode");
  console.log(paymentterm, "paymentterm");
  const { data: loading } = useGetLoadingPortalsQuery();
  const { data: air } = useGetAirLoadingPortsQuery();
  const { data: sea } = useGetSeaLoadingPortsQuery();
  const { data: origin } = useGetSupplierOriginsQuery();
  const { data: portdestination } = useGetShipmentDestinationsQuery();
  const { data: mode } = useGetShipmentModesAddQuery();

  const params = useParams();

  const [order_id, setOrder_id] = useState(params.id);
  const [delivery_time, setDeliverTime] = useState(
    props.value?.data[0]?.payment_information_po.delivery_time
  );
  const [handover_to, setHandoverTo] = useState(
    props.value?.data[0]?.payment_information_po.handover_to
  );

  const [payment_mode_id, setPaymentMode] = useState(
    props.value?.data[0]?.payment_information_po.payment_mode_id
  );
  const [payment_term_id, setPaymentTerm] = useState(
    props.value?.data[0]?.payment_information_po.payment_term_id
  );
  const [incoterm_id, setIncoterm] = useState(
    props.value?.data[0]?.payment_information_po.incoterm_id
  );
  const [advance_percentage, setAdvanceRequired] = useState(
    props.value?.data[0]?.payment_information_po.advance_percentage
  );
  const [shipment_mode_id, setShipmentMode] = useState(
    props.value?.data[0]?.payment_information_po?.shipment_mode_id
  );
  const [countryLoading, setCountry] = useState(
    props.value?.data[0]?.payment_information_po?.origin?.id
  );
  // const [air_loading_id, setAirLoading] = useState(null);
  const [sea_loading_id, setSeaLoading] = useState(
    props.value?.data[0]?.payment_information_po.loading_port_id
  );
  const [partial_shipment, setPartialShipment] = useState(
    props.value?.data[0]?.payment_information_po.partial_shipment
  );
  const [trans_shipment, setTransshipment] = useState(
    props.value?.data[0]?.payment_information_po.trans_shipment
  );
  const [shipment_destination_id, setShipmentDestination] = useState(
    props.value?.data[0]?.payment_information_po.shipment_destination_id
  );
  const [loading_port_id, setAirLoading] = useState(
    props.value?.data[0]?.payment_information_po.loading_port_id
  );
  const [origin_id, setOrigin] = useState();
  const portLoading = [];
  // let smid =
  //   shipment_mode_id === undefined
  //     ? []
  //     : props.value?.data[0]?.payment_information_po.shipment_destination_id;
  // shipment_mode_id ===
  // props.value?.data[0]?.payment_information_po.shipment_mode_id ? (
  //   portLoading.push(
  //     ...sea?.data?.filter((items) => items?.origin_id === countryLoading)
  //   )
  // ) : shipment_mode_id === smid ? (
  //   portLoading.push(
  //     ...air?.data?.filter((items) => items?.origin_id === countryLoading)
  //   )
  // ) : (
  //   <></>
  // );

  // props.value?.data[0]?.payment_information_po?.shipment_mode_id &&
  // props.value?.data[0]?.payment_information_po?.shipment_mode_id ===
  //   shipment_mode_id
  //   ? portLoading.push(
  //       ...(sea?.data !== undefined
  //         ? sea?.data.filter((items) => items?.origin_id === countryLoading)
  //         : "")
  //     )
  //   : portLoading.push(
  //       ...(air?.data !== undefined
  //         ? air?.data.filter((items) => items?.origin_id === countryLoading)
  //         : "")
  //     );
  let smid = shipment_mode_id === null ? [] : shipment_mode_id?.split(",");
  smid[1] === "Air" ? (
    portLoading.push(
      ...air?.data?.filter((items) => items?.origin_id === countryLoading)
    )
  ) : smid[1] === "Sea" ? (
    portLoading.push(
      ...sea?.data?.filter((items) => items?.origin_id === countryLoading)
    )
  ) : (
    <></>
  );
  let paymentinfo = {
    id: props.value.data[0]?.payment_information_id,
    purchase_order_id: props.poid,
    order_id,
    payment_mode_id,
    payment_term_id,
    advance_percentage,
    incoterm_id,
    loading_port_id: smid[1] === "Air" ? loading_port_id : null,
    partial_shipment,
    trans_shipment,
    shipment_destination_id,
    shipment_mode_id: smid[0],
    sea_loading_id: smid[1] === "Sea" ? loading_port_id : null,
    origin_id: countryLoading,
    handover_to: handover_to,
    delivery_time,
  };
  const [UpdatePayment, res] = useEditPaymentInformationMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/settings/payment/informations/${props.value.data[0]?.payment_information_id}`,
    //     paymentinfo,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response, "axiosssssssss");
    //     console.log(bank_currency, "bank currency");

    //     // window.location.reload(true);
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //     console.log(manufacturer, "manuerr");
    //     console.log(supplier, "supperrr");
    //     console.log(freightforwarder, "freree");
    //   });
    UpdatePayment(paymentinfo)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        console.log(paymentinfo, "paymentinfo");
        props.modal(false);
        props.setMessages(
          "Payment & Shipment information Updated Successfully"
        );
      })
      .catch((error) => {
        console.log(error, "erorrrrr");
      });
  };

  // bank detail
  console.log(handover_to, "shipment_mode_id");
  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>Edit Payment Information</div>
            <div type="cancel" onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container ">
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Payment Information</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Time of Delivery
                          </div>
                          <input
                            name=""
                            id=""
                            value={delivery_time}
                            className="supplier-select-option"
                            onChange={(e) => setDeliverTime(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Handover To
                          </div>
                          <input
                            name=""
                            id=""
                            value={handover_to}
                            className="supplier-select-option"
                            onChange={(e) => setHandoverTo(e.target.value)}
                          />
                        </div>
                        {/* <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Currency</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            onChange={(e) => setBankcurrency(e.target.value)}
                          >
                            <option value="" >
                              Select Currency
                            </option>
                            {currencies?.data?.map((items) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </select>
                        </div> */}
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Mode of Payment
                          </div>
                          <select
                            name=""
                            id=""
                            value={payment_mode_id}
                            className="supplier-select-option"
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            <option value="">Select Mode of Payment</option>
                            {paymentmode?.data?.map((items) => (
                              <option value={items.id}>
                                {items.payment_mode}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Terms of Payment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={payment_term_id}
                            onChange={(e) => setPaymentTerm(e.target.value)}
                          >
                            <option value="">Select Terms of Payment</option>
                            {paymentterm?.data?.map((items) => (
                              <option value={items.id}>
                                {items?.payment_term}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Incoterm</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={incoterm_id}
                            onChange={(e) => setIncoterm(e.target.value)}
                          >
                            <option value="">Select Incoterm</option>
                            {incoterm?.data?.map((items) => (
                              <option value={items.id}>
                                {items.incoterm_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Advance Paid
                          </div>
                          <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            className="supplier-text-input"
                            value={advance_percentage}
                            min="0"
                            max="100"
                            onChange={(e) => setAdvanceRequired(e.target.value)}
                          />
                        </div>
                        {/* <div className="text-center">
                          <label className="d-flex gap-2 align-items-baseline justify-content-center">
                            <input
                              type="checkbox"
                              value={true}
                              onChange={(e) =>
                                setAdvanceRequired(e.target.value)
                              }
                            />
                            <span className="h5 text-muted">Advance Paid</span>
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Shipment Information</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Mode of Shipment
                          </div>
                          <select
                            name=""
                            id=""
                            value={shipment_mode_id}
                            className="supplier-select-option"
                            onChange={(e) => setShipmentMode(e.target.value)}
                          >
                            <option value="">Select Mode of Shipment</option>
                            {mode?.data?.map((items) => (
                              <option
                                value={`${items.id},${items.shipment_mode}`}
                              >
                                {items.shipment_mode}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Loading Origin
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={countryLoading}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option value="">Select Loading Origin</option>
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Port of Loading
                          </div>
                          <select
                            name=""
                            id=""
                            value={loading_port_id}
                            className="supplier-select-option"
                            onChange={(e) => {
                              setAirLoading(e.target.value);
                            }}
                          >
                            <option value="">Select Port of Loading</option>

                            {portLoading?.map((items) => (
                              <option value={items?.id}>
                                {items?.port_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Port of Destination
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={shipment_destination_id}
                            onChange={(e) =>
                              setShipmentDestination(e.target.value)
                            }
                          >
                            <option value="">Select Port of Destination</option>
                            {portdestination?.data?.map((items) => (
                              <option value={items.id}>
                                {items.port_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Partial Shipment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={partial_shipment}
                            onChange={(e) => setPartialShipment(e.target.value)}
                          >
                            <option value="">Select Partial Shipment</option>
                            <option value="Allowed">Allowed</option>
                            <option value=" Not Allowed">Not Allowed</option>
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Transshipment
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            value={trans_shipment}
                            onChange={(e) => setTransshipment(e.target.value)}
                          >
                            <option value="">Select Transshipment</option>
                            <option value="Allowed">Allowed</option>
                            <option value="Not Allowed">Not Allowed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2">
              <button
                type="cancel"
                className="form-cancel"
                onClick={HandleCancel}
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="form-save"
                onClick={HandleSubmit}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
