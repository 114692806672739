import { Pagination, Tooltip } from "@mui/material";
import React, { useState } from "react";
import NoRecord from "../../common/NoRecord";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import UpdateForexCollection from "./UpdateForexCollection";
import { date } from "../../dateFormator";
import {
  useDeleteForexPaymentCollectionMutation,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import { MdDelete } from "react-icons/md";
import ConfirmDelete from "../../common/ConfirmDelete";

export default function OffShore({ _DATA, p_count, page, handleChange }) {
  let count = 0;
  console.log(_DATA.currentData(), "_DATA.currentData()");
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(null);
  const params = useParams();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(params.id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(params.id);
  const { data: userinfo } = useGetUserInfoQuery();
  let financeAccess =
    userinfo?.role?.role_name == "Super Admin" ||
    userinfo?.role?.role_name == "Project Finance Lead";
  console.log("financeAccess", financeAccess);
  const [Remove, setRemove] = useState(false);
  const [RemovePayment] = useDeleteForexPaymentCollectionMutation();
  function handleSubmitRemove(e, data) {
    e?.preventDefault();
    RemovePayment({ id: data?.id })
      .unwrap()
      .then((response) => {
        setRemove(false);
        window?.location?.reload(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  console.log(data, "datadata");
  return (
    <div className="p-2 finance-table-container">
      {_DATA.currentData()?.length != 0 ? (
        <table
          style={{
            width: "auto",
            minWidth: "1400px",
            borderCollapse: "collapse",
          }}
        >
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "3%" }}
              >
                No
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Project
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Items
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                CI value
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Bill Reference
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Shipment Document Collection Date
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Payment Status
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                PFO
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                LO
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              >
                Remark
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "5%" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {_DATA.currentData()?.map((items) => (
              <tr
                className={
                  items?.is_collected == 1
                    ? "stock-table-row bg-success text-white"
                    : "stock-table-row"
                }
              >
                <td className="text-start">{(count += 1) + (page - 1) * 8}</td>
                <td className="text-start">
                  {items?.collection_project[0]?.project_name}
                </td>
                <td className="text-start">{items?.items}</td>
                <td className="text-start">
                  {Number(items?.ci_value)?.toLocaleString()}
                </td>

                <td className="text-start">{items?.bill_reference}</td>

                <td className="text-start">
                  {date(items?.shipment_document_colletion_date)}
                </td>
                <td className="text-start">{items?.payment_status}</td>

                <td className="text-start">
                  {
                    items?.collection_project[0]?.users?.filter(
                      (item) =>
                        item?.pivot?.role_id ==
                        "0e324e94-6f2c-415c-9a46-a359a96fea7f"
                    )[0]?.name
                  }
                </td>
                <td className="text-start">
                  {
                    items?.collection_project[0]?.users?.filter(
                      (item) =>
                        item?.pivot?.role_id ==
                        "6b829425-8842-42c4-b68c-cc001a22985f"
                    )[0]?.name
                  }
                </td>
                <td className="text-start">
                  <Tooltip title={items?.remark}>
                    <div>
                      {items?.remark?.length >= 20
                        ? items?.remark?.slice(0, 10) + "..."
                        : items?.remark}
                    </div>
                  </Tooltip>
                </td>
                {financeAccess ? (
                  <td className="text-start">
                    {items?.is_collected == 0 ? (
                      <div className="d-flex align-items-center gap-2">
                        <Tooltip title="Edit">
                          <button
                            onClick={(e) => (setEdit(true), setData(items))}
                            className="form-save"
                          >
                            <ModeEditOutlinedIcon sx={{ fontSize: 20 }} />
                          </button>
                        </Tooltip>
                        <button
                          onClick={(e) => (setRemove(true), setData(items))}
                          className="form-save"
                        >
                          <MdDelete size={20} />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoRecord />
      )}
      {p_count > 1 ? (
        <Pagination
          count={p_count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
      {edit ? <UpdateForexCollection modal={setEdit} data={data} /> : ""}
      {Remove && (
        <ConfirmDelete
          action={(e) => handleSubmitRemove(e, data)}
          setters={setRemove}
          setDialog={setRemove}
          dialog={`Are you to Remove ${data?.items} Forex Payment Collection `}
          buttonTitle={"Yes"}
        />
      )}
    </div>
  );
}
