import "../Recievable.css";
import React, { useEffect, useState } from "react";
import RecievableFilter from "../RecievableFilter";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import RecivableHistoryDownload from "./recivableHistoryDownload";

function RecievableHistory() {
  let Counter_150 = 0;
  let Counter_120_150 = 0;
  let Counter_90_120 = 0;
  let Counter_60_90 = 0;
  let Counter_30_60 = 0;
  let Counter_0_30 = 0;
  let Counter_0 = 0;
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/getHistory`)
      .then((response) => {
        console.log("API Response:", response.data.data);
        setProductData(response.data.data);
      })
      .catch((err) => {
        console.error("Error fetching agingHistoryRecievable data:", err);
      });
  }, []);
  // console.log("%", productData);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptionProjectId, setSearchOptionProjectId] = useState("");
  const [searchOptionDate, setSearchOptionDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };

  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  let curentDate = new Date().toLocaleDateString();
  const filteredData = productData?.filter((item) => {
    return (
      item.currency &&
      item.currency.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (searchOptionProjectId === "" ||
        item.projectID === searchOptionProjectId) &&
      (searchOptionDate === "" ||
        new Date(item.plannedCollectionDate) <= new Date(searchOptionDate))
    );
  });
  console.log(filteredData, "filteredData");

  return (
    <div className="mb-3 bg-white ">
      <div className="">
        <RecievableFilter
          searchQuery={searchQuery}
          searchOptionProjectId={searchOptionProjectId}
          searchOptionDate={searchOptionDate}
          handleSearchSubmit={handleSearchSubmit}
          setSearchQuery={setSearchQuery}
          setSearchOptionProjectId={setSearchOptionProjectId}
          setSearchOptionDate={setSearchOptionDate}
        />
      </div>
      <RecivableHistoryDownload productData={filteredData} />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "2%" }}></div>
        <div
          style={{
            flex: "96%",
            overflow: "auto",
          }}
        >
          {" "}
          <table
            style={{ margin: "20px" }}
            className="recivableTable prg rounded border"
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "rgba(86, 184, 183, 0.1)",
                  height: "60px",
                }}
              >
                <th className="receivableTh">NO</th>
                <th style={{ minWidth: "200px" }}>Project Name</th>
                <th className="receivableTh">Planned Amount</th>
                <th className="receivableTh">Actual Amount</th>
                <th className="receivableTh">Planned Collection Date</th>
                <th className="receivableTh">Actual Collection Date</th>
                <th className="receivableTh">Previous Status</th>
                <th className="receivableTh">Current Status</th>
                <th className="receivableTh">Age</th>
                <th className="receivableTh">Reason</th>
              </tr>
            </thead>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    color: "rgb(255,255,255)",
                    backgroundColor: "rgba(0,0,0, 1)",
                  }}
                >
                  More Than 150
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;

                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 150
                ) {
                  Counter_150++;
                  return (
                    <tr key={index} className="">
                      {/* <td className="recivableTd">{filterDate}</td> */}
                      <td className="recivableTd">{Counter_150}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      {/* <td className="recivableTd">{filterDate}</td> */}
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    color: "rgb(255,255,255)",
                    backgroundColor: "rgba(107, 25, 18, 1)",
                  }}
                >
                  120-150
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) <= 150 &&
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 120
                ) {
                  Counter_120_150++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_120_150}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    backgroundColor: "rgba(235, 51, 35, 1)",
                  }}
                >
                  91-120
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) <= 120 &&
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 90
                ) {
                  Counter_90_120++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_90_120}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    backgroundColor: "rgba(246, 193, 66, 1)",
                  }}
                >
                  61-90
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) <= 90 &&
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 60
                ) {
                  Counter_60_90++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_60_90}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    backgroundColor: "rgba(255, 253, 84, 1)",
                  }}
                >
                  31-60
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) <= 60 &&
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 30
                ) {
                  Counter_30_60++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_30_60}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>

            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    backgroundColor: "rgba(76, 175, 234, 1)",
                  }}
                >
                  1-30
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) <= 30 &&
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) > 0
                ) {
                  Counter_0_30++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_0_30}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>
            <tbody className="recivabletbody">
              <tr>
                <th
                  colSpan={13}
                  style={{
                    textAlign: "left",
                    paddingLeft: "50px",
                    backgroundColor: "rgba(0, 255, 153, 1)",
                  }}
                >
                  before aging
                </th>
              </tr>
              {filteredData?.map((item, index) => {
                let CollectionDate =
                  item.actualCollectionDate === null
                    ? curentDate
                    : item.actualCollectionDate;

                let filterDate = searchOptionDate
                  ? searchOptionDate
                  : CollectionDate;
                if (
                  calculateDateDifference(
                    filterDate,
                    item.plannedCollectionDate
                  ) < 0
                ) {
                  Counter_0++;
                  return (
                    <tr key={index} className="">
                      <td className="recivableTd">{Counter_0}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.plannedAmmount !== null
                          ? item.plannedAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.actualAmmount !== null
                          ? item.actualAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.plannedCollectionDate}
                      </td>
                      <td className="recivableTd">{CollectionDate}</td>
                      <td className="recivableTd">{item.previousStatus}</td>
                      <td className="recivableTd">{item.currentStatus}</td>
                      <td className="recivableTd">
                        {calculateDateDifference(
                          filterDate,
                          item.plannedCollectionDate
                        )}
                      </td>
                      <td className="recivableTd">{item.reason}</td>{" "}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
        <div style={{ flex: "2%" }}></div>
      </div>
      {/* <div
        className="filterd"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          paddingLeft: "10px",
        }}
      ></div> */}
    </div>
  );
}

export default RecievableHistory;
