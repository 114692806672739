import Close from "@mui/icons-material/Close";
import React, { useState } from "react";
import {
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useGetToolsQuery,
} from "../../../features/SCMApi";
import Transferred from "./Trasferred";

export default function TransferredStore(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [modal, setModal] = useState(false);

  const { data: userinfo } = useGetUserInfoQuery();
  const { data: getTools } = useGetToolsQuery();
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();

  const condition = getTools?.data?.filter(
    (item) =>
      item?.project_id == props?.projectID && item?.transfer_requested == 0
  );
  console.log(condition, "condition");
  console.log(getTools?.data, "getTools");
  console.log(props?.projectID, "IDS");

  let notRequest = [];

  props?.assets === undefined
    ? condition?.map((items) =>
        items?.amount !== 0 ? notRequest.push(items) : ""
      )
    : props?.assets?.map((items) =>
        items?.is_requested === 0 ||
        (items?.is_requested === 1 && items?.amount !== 0)
          ? notRequest.push(items)
          : ""
      );

  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id === category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id === store_id) {
          return items;
        }
      });
  };
  const HandleRequest = () => {
    setModal(true);
  };
  const toggleSelection = (id, items) => {
    const selectedIndex = selectedItems.findIndex((item) => item.id === id);
    let newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(items);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredData(search, category, store_id));
    }
    setSelectAll(!selectAll);
  };
  const filterdStore = store?.data?.filter(
    (storeData) => storeData?.project?.id == props?.projectID
  );
  console.log(selectedItems, "itemsitemsitems");

  return (
    <div id="mymodal" className="modal" onClick={() => props.modal(false)}>
      <div className="modal-content-lg" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <h5>Transfer Store to Store Tools</h5>
          <Close onClick={() => props.modal(false)} />
        </div>
        <div className="d-flex justify-content-center gap-3">
          <div className="d-grid gap-1">
            <div className="project-name">Search</div>
            <input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="search"
              type="search"
              className="inventory-search-input"
            />
          </div>
          <div className="d-grid gap-1">
            <div className="project-name">Store </div>
            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setStore_id(e.target.value)}
            >
              <option value="">Store</option>
              {filterdStore?.map((items) => (
                <option key={items?.id} value={items?.id}>
                  {items?.store_name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-grid gap-1">
            <div className="project-name">Item category</div>
            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Item Group</option>
              {ItemCategory?.data?.map((items) => (
                <option key={items?.id} value={items?.id}>
                  {items?.category}
                </option>
              ))}
            </select>
          </div>
          <div className="pi-modal-footer d-flex justify-content-center">
            <button onClick={() => props.modal(false)}>Cancel</button>
            <button
              disabled={selectedItems?.length < 1}
              type="submit"
              className={
                selectedItems?.length >= 1
                  ? "form-save"
                  : "form-save bg-secondary text-white"
              }
              onClick={HandleRequest}
            >
              Next
            </button>
          </div>
        </div>
        <div className="px-5 pt-2">
          <label className="d-flex gap-2 align-items-baseline ">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <span>Select All</span>
          </label>
        </div>
        <div
          className="d-grid p-5"
          style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
        >
          {filteredData(search, category, store_id)?.map((items) => (
            <label key={items.id} className="d-flex gap-2 align-items-baseline">
              <input
                type="checkbox"
                checked={selectedItems.some(
                  (selectedItem) => selectedItem.id === items.id
                )}
                onChange={() => toggleSelection(items.id, items)}
              />
              <span className="group-prg">{items.item_name}</span>
            </label>
          ))}
        </div>
        {modal && (
          <Transferred item={"2"} modal={setModal} data={selectedItems} />
        )}
      </div>
    </div>
  );
}
