import { Pagination, Tooltip } from "@mui/material";
import React, { useState } from "react";
import NoRecord from "../../common/NoRecord";
import { AiOutlineFileAdd } from "react-icons/ai";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import VarianceCollection from "./VarianceCollection";
import UpdatePaymentCollectionModal from "./UpdatePaymentCollections";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddCircleOutline } from "@mui/icons-material";
import LessonLearnedModal from "./LessonLearnedModal";
import LessonLearned from "./LessonLearned";
import { date, totalDates } from "../../dateFormator";
import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import ConfirmPaymentRequest from "./ConfirmPaymentRequest";

import {
  useDeletePaymentCollectionMutation,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import Revenue from "./Revenue";
import { MdDelete } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import ConfirmDelete from "../../common/ConfirmDelete";

export default function OnShore({ _DATA, p_count, page, handleChange, all }) {
  let count = 0;
  const [edit, setEdit] = useState(false);
  const [revenue, setRevenue] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [lesson, setLessonLearned] = useState(false);
  const [viewLesson, setViewLessonLearned] = useState(false);
  const [data, setData] = useState(null);
  const [variance, setVariance] = useState(null);
  const [varianceModal, setVarianceModal] = useState(false);
  function HandleVariance(e, items) {
    setVariance(items);
    setVarianceModal(true);
  }
  function HandleLessonLearned(e, items) {
    setLessonLearned(true);
    setData(items);
  }
  function HandleViewLessonLearned(e, items) {
    setViewLessonLearned(true);
    setData(items);
  }
  function HandleViewRevenue(e, items) {
    setRevenue(true);
    setData(items);
  }
  function Payment_Request(e, items) {
    setConfirm(true);
    setData(items);
  }
  console.log(_DATA.currentData(), "totalDatestotalDates");
  const params = useParams();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(params.id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(params.id);
  const { data: userinfo } = useGetUserInfoQuery();
  console.log(projectmembers, "projectmembers");
  let financeAccess =
    userinfo?.role?.role_name == "Super Admin" ||
    userinfo?.role?.role_name == "Project Finance Lead";
  const [Remove, setRemove] = useState(false);
  const [RemovePayment] = useDeletePaymentCollectionMutation();
  function handleSubmitRemove(e, data) {
    e?.preventDefault();
    RemovePayment({ id: data?.id })
      .unwrap()
      .then((response) => {
        setRemove(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };
  return (
    <div className="p-2 finance-table-container">
      {_DATA.currentData()?.length != 0 ? (
        <table
          style={{
            width: "auto",
            minWidth: "2500px",
            borderCollapse: "collapse",
          }}
        >
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "50px" }}
              >
                No
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Task
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Project
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Milestone
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Planned Amount
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Actual Amount
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                UnCollected Balance
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Todays progress
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Next Action
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Responsible Person
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Updated Date
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Planned Date
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "200px" }}
              >
                Actual collection Date
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Variance
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "100px" }}
              >
                Advanced Payment
              </th>

              <th
                className="stock-table-header-text text-start"
                style={{ width: "50px" }}
              >
                Action
              </th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "50px" }}
              ></th>
              <th
                className="stock-table-header-text text-start"
                style={{ width: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {_DATA.currentData()?.map((items) => (
              <tr
                className={`stock-table-row ${
                  totalDates(items?.planed_collection_date) <= 0 &&
                  items?.is_collected == 0
                    ? "bg-danger text-white"
                    : items?.is_collected == 1
                      ? "bg-success text-white"
                      : ""
                }`}
              >
                <td className="text-start">{(count += 1) + (page - 1) * 8}</td>
                <td className="text-start">{items?.name}</td>
                <td className="text-start">
                  {items?.collection_project[0]?.project_name}
                </td>

                <td className="text-start">{items?.milestone}</td>

                <td className="text-start">
                  {Number(items?.planned_amount)?.toLocaleString()}
                </td>
                <td className="text-start">
                  {Number(items?.actual_amount)?.toLocaleString()}
                </td>

                <td className="text-start">
                  {Number(
                    items?.planned_amount - items?.actual_amount
                  )?.toLocaleString()}
                </td>
                <td className="text-start">
                  <Tooltip title={items?.todays_progress || "-"}>
                    <div>
                      {items?.todays_progress?.length >= 100
                        ? items?.todays_progress?.slice(0, 100) + "..."
                        : items?.todays_progress || "-"}
                    </div>
                  </Tooltip>
                </td>
                <td className="text-start">
                  <Tooltip title={items?.next_action}>
                    <div>
                      {items?.next_action?.length >= 100
                        ? items?.next_action?.slice(0, 100) + "..."
                        : items?.next_action || "-"}
                    </div>
                  </Tooltip>
                </td>
                <td className="text-start">
                  {
                    items?.collection_project[0]?.users?.filter(
                      (item) =>
                        item?.pivot?.role_id ==
                        "0e324e94-6f2c-415c-9a46-a359a96fea7f"
                    )[0]?.name
                  }
                </td>
                <td className="text-start">{date(items?.updated_at)}</td>
                <td className="text-start">
                  {items?.expected_date
                    ? date(items?.expected_date)
                    : items?.planed_collection_date
                      ? date(items?.planed_collection_date)
                      : "-"}
                </td>
                <td className="text-start">
                  {items?.actual_collection_date
                    ? date(items?.actual_collection_date)
                    : "-"}
                </td>
                <td className="text-start">
                  {items?.expected_date
                    ? items?.actual_collection_date
                      ? calculateDateDifference(
                          items?.actual_collection_date,
                          items?.expected_date
                        )
                      : calculateDateDifference(
                          new Date().toLocaleDateString(),
                          items?.expected_date
                        )
                    : items?.planed_collection_date
                      ? items?.actual_collection_date
                        ? calculateDateDifference(
                            items?.actual_collection_date,
                            items?.planed_collection_date
                          )
                        : calculateDateDifference(
                            new Date().toLocaleDateString(),
                            items?.planed_collection_date
                          )
                      : "-"}
                </td>
                <td className="text-start">
                  {items?.isAdvance ? "Yes" : "No"}
                </td>
                <td className="text-center px-2">
                  {items?.is_revenue == 1 ? (
                    <div className="d-flex ailgn-items-center gap-1">
                      {financeAccess ||
                      CheckProjectPermission(
                        "edit_payment_information",
                        projectmembers,
                        userinfo,
                        roleRoles
                      ) ? (
                        <>
                          {Number(items?.actual_amount) == 0 ? (
                            <Tooltip title="Edit">
                              <ModeEditOutlinedIcon
                                sx={{ fontSize: 20 }}
                                onClick={(e) => (setEdit(true), setData(items))}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {items?.is_collected == 1 &&
                          items?.is_variance == 0 ? (
                            <Tooltip title="Create Variance">
                              <AiOutlineFileAdd
                                style={{ fontSize: 20 }}
                                onClick={(e) => HandleVariance(e, items)}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          <Tooltip title="Create Lesson Learned">
                            <AddCircleOutline
                              sx={{ fontSize: 20 }}
                              onClick={(e) => HandleLessonLearned(e, items)}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                      <Tooltip title="View Lesson Learned">
                        <LocalLibraryIcon
                          style={{ fontSize: 20 }}
                          onClick={(e) => HandleViewLessonLearned(e, items)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Add Revenue">
                      <button
                        onClick={(e) => HandleViewRevenue(e, items)}
                        type="button"
                        className="form-save"
                        style={{ fontSize: "10px" }}
                      >
                        Revenues
                      </button>
                    </Tooltip>
                  )}
                </td>
                <td className="text-center ">
                  {items?.payment_request_confirmed == 1 ? (
                    <div className="d-flex ailgn-items-center gap-1">
                      Confirmed
                    </div>
                  ) : (
                    <Tooltip title="Confirm Payment request">
                      <button
                        onClick={(e) => Payment_Request(e, items)}
                        type="button"
                        className="form-save"
                        style={{ fontSize: "10px" }}
                      >
                        <FaListCheck size={20} />
                      </button>
                    </Tooltip>
                  )}
                </td>
                {financeAccess ? (
                  <td className="text-center ">
                    <div className="d-flex align-items-center gap-2">
                      <button
                        onClick={(e) => (setRemove(true), setData(items))}
                        className="form-save"
                      >
                        <MdDelete size={20} />
                      </button>
                      <button
                        onClick={(e) => (setEdit(true), setData(items))}
                        className="form-save"
                      >
                        <Tooltip title="Edit">
                          <ModeEditOutlinedIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                      </button>
                    </div>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoRecord />
      )}
      {p_count > 1 ? (
        <Pagination
          count={p_count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
      {edit ? <UpdatePaymentCollectionModal modal={setEdit} data={data} /> : ""}
      {revenue ? <Revenue modal={setRevenue} data={data} /> : ""}
      {confirm ? <ConfirmPaymentRequest modal={setConfirm} data={data} /> : ""}
      {lesson ? (
        <LessonLearnedModal modal={setLessonLearned} data={data} />
      ) : (
        ""
      )}
      {viewLesson ? (
        <LessonLearned modal={setViewLessonLearned} data={data?.lessons} />
      ) : (
        ""
      )}
      {varianceModal ? (
        <VarianceCollection data={variance} modal={setVarianceModal} />
      ) : (
        ""
      )}
      {Remove && (
        <ConfirmDelete
          action={(e) => handleSubmitRemove(e, data)}
          setters={setRemove}
          setDialog={setRemove}
          dialog={`Are you to Remove ${data?.name} Payment Collection `}
          buttonTitle={"Yes"}
        />
      )}
    </div>
  );
}
