import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import IconButtons from "./IconButtons";
import {
  useGetForexDetailQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import CheckPermission from "../../common/checkPermission/checkPermission";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";

const TermAndConditions = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "395px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    // margin: "auto",
    marginTop: "-240px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
}));

const TermAndConditionContainer = (props) => {
  const forexId = useParams();
  const { data: forexDetail } = useGetForexDetailQuery(forexId?.id);

  const termAndConditions = [
    {
      paymentMode: "Not Available",
      validityDate: "Not Available",
      openingDate: "Not Available",
      referenceNumber: "Not Available",
      openingTo: "Not Available",
      modeOfShipment: "Not Available",
      transShipment: "Not Available",
      partialShipment: "Not Available",
      countryOfOrigin: "Not Available",
      portOfLoading: "Not Available",
      portOfDischarge: "Not Available",
      freight: "Not Available",
      incoterm: "Not Available",
    },
  ];
  function getTermAndCondition() {
    console.log(
      props.data?.map((data) =>
        data?.forex_term.length === 0 ? termAndConditions : data?.forex_term
      ),
      "this is all data for forex term"
    );
    return props.data?.map((data) =>
      data?.forex_term.length === 0 ? termAndConditions : data?.forex_term
    );
  }
  const hasTerm = props?.data?.map((data) => data?.forex_term).flat()?.length;
  console.log(props.data && props.data, "hasTerm");
  const { data: userinfo } = useGetUserInfoQuery();
  const approval_date = forexDetail?.data[0]?.forex_term[0]?.approval_date;
  const expiration_date = forexDetail?.data[0]?.forex_term[0]?.expiration_date;
  console.log(approval_date, expiration_date, "forTermsAndCondition");

  return (
    <>
      {hasTerm !== 0 ? (
        <div style={{ height: "425px" }}>
          <TermAndConditions style={{ height: "425px" }} elevation={0}>
            <div className="forexTermAndConditions">Term & Condition</div>
            <div className="devider"></div>
            <div className="forextermAndConditions">
              <ul className="paymentTermAndConditionsLabel">
                <li>
                  <span className="paymentTcName">Payment Mode</span>
                </li>
                <li>
                  <span className="paymentTcName">Refc. Number</span>
                </li>
                <li>
                  <span className="paymentTcName">Validity Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Approval Date</span>
                </li>
                <li>
                  <span className="paymentTcName">PI Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Expiration Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Remaining Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening to</span>
                </li>
                <li>
                  <span className="paymentTcName">Mode of Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Trans Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Partial Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Country of Origin</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Loading</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Discharge</span>
                </li>
                {/* <li>
                  <span className="paymentTcName">Freight</span>
                </li> */}
                <li>
                  <span className="paymentTcName">Incoterm</span>
                </li>
              </ul>
              {getTermAndCondition()
                ?.flat()
                ?.map((values) => (
                  <ul className="paymentTermAndConditionsValue">
                    <Tooltip
                      title={values?.forex_payment_mode?.payment_mode}
                      arrow
                    >
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_payment_mode?.payment_mode !== null ? (
                            values?.forex_payment_mode?.payment_mode.length >
                            15 ? (
                              values?.forex_payment_mode?.payment_mode?.slice(
                                0,
                                15
                              ) + "..."
                            ) : (
                              values?.forex_payment_mode?.payment_mode
                            )
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.reference_number} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.reference_number !== null ? (
                            values?.reference_number.length > 15 ? (
                              values?.reference_number?.slice(0, 15) + "..."
                            ) : (
                              values?.reference_number
                            )
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.validity_date} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.validity_date !== null ? (
                            values?.validity_date + " " + "days"
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={approval_date} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {approval_date !== null ? (
                            approval_date
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.opening_date} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.opening_date !== null ? (
                            values?.opening_date
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={expiration_date} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {expiration_date !== null ? (
                            expiration_date
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>

                    <Tooltip
                      title={props.data[0]?.forex_term[0]?.remaining_days}
                      arrow
                    >
                      <li>
                        <span
                          className={`${
                            props.data[0]?.forex_term[0]?.remaining_days < 30
                              ? "paymentTcValue text-danger"
                              : "text-success paymentTcValue"
                          }`}
                        >
                          {props.data[0]?.forex_term[0]?.remaining_days !==
                          null ? (
                            props.data[0]?.forex_term[0]?.remaining_days
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip
                      title={values?.forex_opening_to?.distributor_name}
                      arrow
                    >
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_opening_to?.distributor_name !==
                          null ? (
                            values?.forex_opening_to?.distributor_name?.length >
                            15 ? (
                              values?.forex_opening_to?.distributor_name?.slice(
                                0,
                                15
                              ) + "..."
                            ) : (
                              values?.forex_opening_to?.distributor_name
                            )
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip
                      title={values?.forex_shipment_mode?.shipment_mode}
                      arrow
                    >
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_shipment_mode?.shipment_mode !==
                          null ? (
                            values?.forex_shipment_mode?.shipment_mode
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.trans_shipment} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.trans_shipment !== null ? (
                            values?.trans_shipment
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.partial_shipment} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.partial_shipment !== null ? (
                            values?.partial_shipment
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip title={values?.forex_origin?.name} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_origin?.name !== "null" ? (
                            values?.forex_origin?.name > 15 ? (
                              values?.forex_origin?.name?.slice(0, 15) + "..."
                            ) : (
                              values?.forex_origin?.name
                            )
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip
                      title={
                        values?.sea_loading_id !== null
                          ? values?.forex_sea_loading?.port_name
                          : values?.air_loading_id !== null
                            ? values?.forex_air_loading?.port_name
                            : "Unspecified"
                      }
                      arrow
                    >
                      <li>
                        <span className="paymentTcValue">
                          {values?.sea_loading_id !== null ? (
                            values?.forex_sea_loading?.port_name?.length >
                            15 ? (
                              values?.forex_sea_loading?.port_name?.slice(
                                0,
                                15
                              ) + "..."
                            ) : (
                              values?.forex_sea_loading?.port_name
                            )
                          ) : values?.air_loading_id !== null ? (
                            values?.forex_air_loading?.port_name?.length >
                            15 ? (
                              values?.forex_air_loading?.port_name?.slice(
                                0,
                                15
                              ) + "..."
                            ) : (
                              values?.forex_air_loading?.port_name
                            )
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                    <Tooltip
                      title={values?.forex_discharge_port?.port_name}
                      arrow
                    >
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_discharge_port?.port_name !== null ? (
                            values?.forex_discharge_port?.port_name
                          ) : (
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>

                    {/* <li>
                      <span className="paymentTcValue">
                        {values?.freight !== null ? (
                          values?.freight?.length > 21 ? (
                            values?.freight?.slice(0, 20) + "..."
                          ) : (
                            values?.freight
                          )
                        ) : (
                          <span className="paymentTcName forexBold">
                            Unspecified
                          </span>
                        )}
                      </span>
                    </li> */}
                    <Tooltip title={values?.incoterms?.incoterm_name} arrow>
                      <li>
                        <span className="paymentTcValue">
                          {values?.incoterms?.incoterm_name !== null ? (
                            // values?.incoterms?.incoterm_name?.length > 15 ? (
                            //   values?.incoterms?.incoterm_name?.slice(0, 15) +
                            //   "..."
                            // ) : (
                            values?.incoterms?.incoterm_name
                          ) : (
                            // console.log(
                            //   values?.incoterms?.incoterm_name,
                            //   "values?.incoterms?.incoterm_name"
                            // )
                            // values?.incoterms?.incoterm_name
                            // )
                            <span className="paymentTcName forexBold">
                              Unspecified
                            </span>
                          )}
                        </span>
                      </li>
                    </Tooltip>
                  </ul>
                ))}
            </div>
            <div className="paymentTcEditButton">
              {CheckPermission("edit_term_and_condition", userinfo) ? (
                <IconButtons
                  name={hasTerm !== 0 ? "termCondition" : "nOTermCondition"}
                  previousData={getTermAndCondition()
                    ?.flat()
                    .map((values) => values)}
                  setSuccessMessage={props?.successMessage}
                  setMessage={props?.message}
                />
              ) : (
                ""
              )}
            </div>
          </TermAndConditions>
        </div>
      ) : (
        <>
          <TermAndConditions style={{ height: "425px" }} elevation={0}>
            <div className="forexTermAndConditions">Term & Condition</div>
            <div className="devider"></div>
            <div className="forextermAndConditions">
              <ul className="paymentTermAndConditionsLabel">
                <li>
                  <span className="paymentTcName">Payment Mode</span>
                </li>
                <li>
                  <span className="paymentTcName">Validity Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Refc. Number</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening to</span>
                </li>
                <li>
                  <span className="paymentTcName">Mode of Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Trans Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Partial Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Country of Origin</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Loading</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Discharge</span>
                </li>
                {/* <li>
                  <span className="paymentTcName">Freight</span>
                </li> */}
                <li>
                  <span className="paymentTcName">Incoterm</span>
                </li>
              </ul>

              {termAndConditions?.map((values) => (
                <ul className="paymentTermAndConditionsValue">
                  <li>
                    <span className="paymentTcValue">
                      {values?.paymentMode || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.validityDate || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.openingDate || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.referenceNumber || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.openingTo || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.modeOfShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.transShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.partialShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.countryOfOrigin || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.portOfLoading || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.portOfLoading || "-"}
                    </span>
                  </li>
                  {/* <li>
                 <span className="paymentTcValue">{values?.freight || "-"}</span>
               </li> */}
                  <li>
                    <span className="paymentTcValue">
                      {values?.incoterm || "-"}
                    </span>
                  </li>
                </ul>
              ))}
            </div>
            <div className="paymentTcEditButton">
              {CheckPermission("add_term_and_condition", userinfo) ? (
                <IconButtons
                  name={hasTerm !== 0 ? "termCondition" : "nOTermCondition"}
                  previousData={getTermAndCondition()
                    ?.flat()
                    .map((values) => values)}
                  setSuccessMessage={props?.successMessage}
                  setMessage={props?.message}
                />
              ) : (
                ""
              )}
            </div>
          </TermAndConditions>
        </>
      )}
    </>
  );
};

export default TermAndConditionContainer;
