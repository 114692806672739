import React, { useEffect, useState } from "react";
import Reorder, { reorder } from "react-reorder";
import { useUpdateApprovalModuleMutation } from "../../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddPaModal from "../SettingModal/AddPaModal";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
function PoDragFeature({ range_stages, index, type, id, setMessage }) {
  const [items, setItems] = useState([]);
  const [data, setData] = useState(null);
  const [showPa, setShowPa] = useState(false);

  useEffect(() => {
    setItems(range_stages);
  }, [range_stages]);

  const handleReorder = (event, previousIndex, nextIndex) => {
    const reorderedItems = Array.from(items);

    // Swap the items at the previous and next index
    const temp = reorderedItems[previousIndex];
    reorderedItems[previousIndex] = reorderedItems[nextIndex];
    reorderedItems[nextIndex] = temp;

    const approval_stages = reorderedItems.map((item, index) => {
      return {
        level: index + 1,
        role_id: item.role?.id,
      };
    });

    // Update the state with the reordered items
    setItems(reorderedItems);
    setData({ approval_stages });
    // Now you can use approval_stages in your code as needed.
    console.log({ approval_stages }, "check datasd");
  };
  const [update] = useUpdateApprovalModuleMutation();
  let params = {
    id: id,
    approval_module: type,
    data,
  };
  function HandleSubmit(e) {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/scm/approval_modules/${params?.approval_module}/${params?.id}`,
        params?.data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response, "ghjkl,mhj");
        setMessage("Updated successfully!!!");
      })
      .catch(function (err) {
        console.log(err, "response from");
      });
  }
  function handleRemove(e, role) {
    const updatedItems = items?.filter((items, i) => items?.role?.id !== role);
    setItems(updatedItems);
    setData({ approval_stages: updatedItems });
  }
  function handleModal() {
    setShowPa(true);
    handleReorder();
  }
  console.log(data, "range_stages");
  return (
    <div>
      <div className="drag-and-drop-container">
        <Reorder
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragging" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true}
          reorderId={`my-list + ${index}`}
          itemKey="text"
          holdTime={300}
          onReorder={handleReorder}
        >
          {items?.map((item, index) => (
            <div className="draggable-item" key={index}>
              <span>{`${item?.level}. ${item?.role?.role_name}`}</span>
              <div
                onClick={(e) => handleRemove(e, item?.role?.id)}
                className="pi-footer-icon"
              >
                <RemoveIcon />
              </div>
            </div>
          ))}
        </Reorder>
        <section className="pi-footer">
          <div onClick={(e) => handleModal()} className="pi-footer-icon">
            <AddIcon />
          </div>
        </section>
      </div>
      {data && (
        <section className="pi-footer">
          <div className="confirm-dialog-controller">
            <button onClick={(e) => HandleSubmit(e)} className={"form-save"}>
              Save
            </button>
          </div>
        </section>
      )}
      <AddPaModal
        onClose={() => setShowPa(false)}
        showPa={showPa}
        items={items}
        data={data}
        id={id}
        type={type}
        setMessage={setMessage}
      />
    </div>
  );
}

export default PoDragFeature;
