import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import Button from "@mui/material/Button";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import { DOC_BASE_URL } from "../../../../api/endPoint";
import { useGetRmaMissedItemDocumentsQuery,useGetMissedItemImportDeclarationDocumentsQuery } from "../../../../features/SCMApi";

export default function PDModal(props) {
  // const [addClient, response] = useAddClientMutaion();
  const { data: previousDocumentData } = useGetMissedItemImportDeclarationDocumentsQuery(props?.batchId);
  const [emailCommunication, setEmailCommunication] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let params = {
    email_communication: emailCommunication,
    batch_id: props?.batchId,
    missed_item_id: props.missedItemId,
  };

  console.log(
    props?.batchId,
    previousDocumentData?.data[0]?.final_declaration,
    "data for attached previous document (***)"
  );

  const documentData =
    props?.previousData !== undefined ? props?.previousData : [];

  function HandleCancel() {
    props.modal(false);
  }
  const handleEmailCommunication = (e) => {
    setEmailCommunication(e?.target?.files[0]);
    setIsFileSelected(true);
  };

  const handleClick = () => {
    isFileSelected === true ? setErrorMessage(false) : setErrorMessage(true);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    isFileSelected ? (
      documentData?.length === 0 ? (
        axios
          .post(`${API_BASE_URL}/scm/missed-item/previous-document`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            props?.successMessage(true);
            props?.message("Previous document attached successfully!!!");
            console.log(response, "response from database");
            HandleCancel();
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      ) : (
        axios
          .post(
            `${API_BASE_URL}/scm/missed-item/previous-document/${documentData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            props?.successMessage(true);
            props?.message("Previous document updated successfully!!!");
            console.log(response, "response from database");
            HandleCancel();
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      )
    ) : (
      <></>
    );
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="previousDocumnetTitle">Previous Document</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <div className="rmaDownloadBox">
            <span className="rmaDownloadLabel">Import Declaration</span>
            <a
              href={`${DOC_BASE_URL}scm/document/download?file_path=${previousDocumentData?.data[0]?.final_declaration}`}
            >
              <Button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#186569"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Button>
            </a>
          </div>

          <div className="rmaFileAttachLabel">Attach Email Communication</div>
          <div className="rmaFileAttachLabelDivider" />

          <form
            className="form-container rmaMissedItemForm"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {errorMessage === true ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "-10px", marginBottom: "20px" }}
                    >
                      Please select a file !!!
                    </p>
                  </>
                ) : documentData?.length !== 0 ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "-20px", marginBottom: "20px" }}
                    >
                      Previous document already attached !!!
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form">
                    <div className="rmaFileInputLabelContainer inputLabelsHolder">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginLeft: "-20px" }}
                      >
                        Email Communication
                      </div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleEmailCommunication(e)}
                        />
                        <div className="rmaFileUploader">
                          {emailCommunication
                            ? emailCommunication?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={handleClick}
                >
                  {documentData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
