import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Supplier from "../../pages/Supplier";
import SpsMapping from "../../pages/Supplier/SpsMapping";
import MappingCountry from "./MappingCountry";
import MappingManufaturer from "./MappingManufacturer";
import SuppliermappingIndex from "./SuppliermappingIndex";

export default function SupplierMapping() {
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    console.log(search, "search");
  };
  return (
    <Supplier
      handleSearchChange={handleSearchChange}
      placeholder={"search SPMS..."}
      title={"SPMS Mapping"}
    >
      <div className="page">
        {" "}
        <Tabs
          defaultActiveKey="supplier"
          id="uncontrolled-tab-example"
          className=" mb-3 d-flex justify-content-center bg-white rounded gap-4 border"
        >
          <Tab eventKey="supplier" title="Supplier">
            <SuppliermappingIndex search={search} />
          </Tab>
          <Tab eventKey="manufacturer" title="Manufacturer" className="">
            <MappingManufaturer search={search} />
          </Tab>

          <Tab eventKey="country" title="Country">
            <MappingCountry search={search} />
          </Tab>
          <Tab eventKey="solutions" title="Solutions">
            <SpsMapping />
          </Tab>
        </Tabs>
      </div>
    </Supplier>
  );
}
