import React from "react";
import WestIcon from "@mui/icons-material/West";
import { IMG_BASE_URL } from "../../../api/endPoint";
export default function ItemDescription(props) {
  function HandleBack() {
    props?.setFragment("index");
  }
  console.log(props?.data, "rppppp");
  return (
    <div className="tool-item-description">
      <div className="create-project-btn mb-3" onClick={HandleBack}>
        <WestIcon
          sx={{
            "&:hover": {
              backgroundColor: "#fff",
              color: "#186569",
            },
          }}
        />
        Go Back
      </div>
      <div className="d-flex align-items-center gap-5">
        {props?.data?.image == null ? (
          <img
            className="tool-item-image"
            src="https://flowbite.com/docs/images/examples/image-1@2x.jpg"
            alt={props?.data?.item_name}
          />
        ) : (
          <img
            className="tool-item-image"
            src={`${IMG_BASE_URL}${props?.data?.image}`}
            alt={props?.data?.item_name}
          />
        )}

        <div className="">
          <div className="h4">{props?.data?.item_name}</div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Purchase Date:</div>
            <div className="small mt-0">
              {props?.data?.date_of_purchased == null
                ? "Unknown"
                : props?.data?.date_of_purchased}
            </div>
          </div>
          <div className="d-grid  gap-3 mt-3 mb-4">
            <div className="tool-item-title">Description</div>
            <div className="small mt-0">
              {props?.data?.description == null
                ? "-"
                : props?.data?.description}
            </div>
          </div>
          {props?.data?.status != null ? (
            <div className="d-flex  gap-1 mt-3 align-items-baseline">
              <div className="tool-item-title">Status</div>
              <div className="tool-item-status">
                {props?.data?.status?.status_name}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="h5 mt-3">Inventory History</div>
    </div>
  );
}
