import * as React from "react";
import NavBar from "../../../Layout/NavBar";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import DeliveredItemDetailsHeading from "../../../components/Logistics/DeliveredItem/DeliveredItemDetailsHeading";
import DeliveredItemBatchDetail from "../../../components/Logistics/DeliveredItem/DeliveredItemBatchDetail";
import { useLocation, useParams } from "react-router-dom";
import SuccessMessage from "../../../components/Payment/SuccessMessage";
import { useGetDeliveredGroupByBatchIdMutation, useGetDeliveredItemDetailQuery } from "../../../features/SCMApi";

export default function DeliveredBatchDetails(props) {
  const location = useLocation();
  const { state } = location;
  const [getDeliveredGroupByBatchId]=useGetDeliveredGroupByBatchIdMutation();
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [deliveredItemGroup,setDeliveredItemGroup]=React.useState([]);
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
    const batchId=useParams()?.deliverdItems;
  // old implemetnation by sura {
        // const { data: deliveredItemData } = useGetDeliveredItemDetailQuery(
        //   state?.data
        // );
        // console.log(
        //   deliveredItemData,
        //   state?.data,
        //   "delivered item data new api for data"
        // );
  // }
  React.useEffect(()=>{
    const fetchDeliverGroups=()=>{
    getDeliveredGroupByBatchId(batchId).unwrap()
                                       .then((response)=>{
                                        console.log(response,"hilufff deliveredItemData");
                                        setDeliveredItemGroup(response.data);
                                       })
                                       .then((error)=>{
                                        console.log(error,"error on deliver batch")
                                       });
                                      }
                                      fetchDeliverGroups();
  },[]);
  console.log(batchId,"deliveredItemGroup***********");
  return (
    <>
      <NavBar />
      <LogisticsBreadCramp />
      <div className="page">
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "10px",
                width: "100%",
                marginLeft: "0px",
                backgroundColor: "#52ffab96",
                mb: "-10px",
              }}
              Message={message}
            />
          </>
        ) : (
          <></>
        )}
       
       
              <>
                <DeliveredItemDetailsHeading data={deliveredItemGroup} />
                  <DeliveredItemBatchDetail
                      data={deliveredItemGroup}
                      successMessage={setSuccessMessage}
                      message={setMessage}
                  /> 
              </>
         {/* {deliveredItemGroup?.map((item)=>
              <BatchGroupItems
                  totalItems={item?.item?.length}
                  name={item?.group_name}
                  number={item?.group_number}
              />
        )}; */}
      </div>
    </>
  );
}
