import React, { useEffect } from "react";
import { LOGIN_CALLBACK, LOGIN_CALLBACK_GOOGLE } from "../../api/endPoint";
import axios from "axios";
import LoadingScreen from "react-loading-screen";

const LoginSuccessGoogle = () => {
  useEffect(() => {
    fetchingData();
  }, []);

  const fetchingData = () => {
    axios
      .get(`${LOGIN_CALLBACK_GOOGLE}${window.location.search}`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("accessToken", res.data.access_token);
          window.location.replace("/");
          console.log(res, "response from backend");
        }
      })
      .catch((err) => console.log(err, "response from backend err"));
  };

  return (
    <LoadingScreen
      loading={true}
      bgColor="#f1f1f1"
      spinnerColor="#186569"
      textColor="#353945"
      text="wait..."
    />
  );
};

export default LoginSuccessGoogle;
