import React from "react";
import { useState } from "react";
import { useGetProjectsQuery } from "../../features/SCMApi";
export default function StockDialog(props) {
  console.log(
    Number(props?.quantity) >
      Number(
        props?.name == "return"
          ? props?.data?.requested_quantity
          : props?.data?.amount
      ),
    "this is all data for tools and inventories"
  );
  const { data: project } = useGetProjectsQuery();
  let dif = 0;
  dif = props?.data?.requested_quantity - props?.data?.returned_quantity;
  console.log(props, "!!!!!!");

  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to {props?.name}</div>
        <div className="dialog-info">
          {props?.data?.requested_quantity ? (
            <div className="prg">{`${props?.data?.requested_quantity} ${
              props?.data?.uom?.name == undefined ? "-" : props?.data?.uom?.name
            } ${props?.dialog}`}</div>
          ) : (
            <div className="prg">
              {props?.dialog == undefined ? "" : props?.dialog}
            </div>
          )}
          <div className="prg-error text-danger">{props?.error}</div>
        </div>

        {props?.name != "request" ? (
          <></>
        ) : props?.data?.project_id && props?.data?.store?.is_permanent == 0 ? (
          <></>
        ) : (
          <div
            className="d-flex gap-2 align-items-baseline"
            style={{ justifyContent: "center", marginRight: "140px" }}
          >
            <div className="supplier-input-label">Project</div>

            <select
              name=""
              id=""
              className="select-option-create-order "
              onChange={(e) => props?.projectId(e.target.value)}
            >
              <option selected="true" disabled="disabled">
                Choose Project
              </option>
              {project?.data?.map((items) => (
                <option value={items.id}>{items?.project_name}</option>
              ))}
            </select>
          </div>
        )}
        {/* { ? <h1>Lalalalalal</h1> : ""} */}
        {props?.data?.uom?.is_countable == 1 ? (
          <>
            <div
              className="d-flex gap-2 align-items-baseline"
              style={{ justifyContent: "center", marginRight: "140px" }}
            >
              <div className="supplier-input-label">Quantity</div>
              <input
                onChange={(e) => props?.setQuantity(e.target.value)}
                type="number"
                min="0"
                className={`stock-text-input`}
                required
              />
            </div>
            {props?.quantity < 0 ||
            Number(props?.quantity) >
              Number(
                props?.name == "return"
                  ? props?.data?.requested_quantity
                  : props?.data?.amount
              ) ? (
              <label className="prg-error text-danger">
                {`Please add the correct amount the remaining amount is 
                 ${props?.data?.amount}`}
              </label>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {props?.data?.returned_quantity != null ? (
          <div className="prg">{`Returned ${props?.data?.returned_quantity} ${props?.data?.uom_name}`}</div>
        ) : (
          ""
        )}

        {props?.is_return == true ? (
          <div className="d-flex gap-2 align-items-baseline">
            <div className="supplier-input-label"> Quantity to Return</div>
            <input
              onChange={(e) => props?.setQuantity(e.target.value)}
              type="number"
              className={`stock-text-input`}
              required
            />
          </div>
        ) : (
          ""
        )}
        {props?.setRemark != undefined ? (
          <div className="d-grid gap-1 align-items-baseline">
            <div className="ms-2 prg">Remark</div>
            <textarea
              cols="30"
              rows="5"
              onChange={(e) => props?.setRemark(e.target.value)}
            ></textarea>
          </div>
        ) : (
          ""
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              props.setDialog(false);
              props.action(e, props?.dialog?.id);
            }}
            disabled={
              props?.quantity < 1 ||
              Number(props?.quantity) >
                Number(props?.name == "return" ? dif : props?.data?.amount)
                ? true
                : false
            }
            className={
              props?.quantity < 1 ||
              Number(props?.quantity) >
                Number(props?.name == "return" ? dif : props?.data?.amount)
                ? "form-save bg-secondary text-white"
                : "form-save"
            }
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
