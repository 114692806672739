import axios from "axios";
import React from "react";
import { useState } from "react";
import { API_BASE_URL } from "../../../api/endPoint";
import { useGetRolesQuery } from "../../../features/SCMApi";

export default function UserAccessPermission(props) {
  const { data: roles } = useGetRolesQuery();
  const [role_id, setRole] = useState(null);

  let permission;
  roles?.data?.map((items) =>
    items.id == role_id ? (permission = items.permissions) : null
  );
  let allPermission = [];
  permission?.map((items) => allPermission?.push(items?.id));
  let permissionChecked = [];
  props?.user?.permissions?.map((items) => permissionChecked?.push(items?.id));
  const [permissions, setPermission] = useState([]);
  const [select, setSelect] = useState(false);
  const handleChangepermission = (e, permissionId) => {
    const { checked } = e.target;
    setSelect(!select);
    if (permissionId == "all") {
      // Select All option is checked

      if (checked) {
        // Add all permissions to the permissions array
        setPermission(allPermission);
      } else {
        // Uncheck Select All and remove all permissions from the array
        setPermission([]);
      }
    } else {
      if (checked) {
        // Add the permission to the permissions array
        setPermission((prevPermissions) => [...prevPermissions, permissionId]);
      } else {
        // Remove the permission from the permissions array
        setPermission((prevPermissions) =>
          prevPermissions.filter((id) => id !== permissionId)
        );
      }
    }
  };

  let useraccess = {
    user_id: props?.user?.id,
    role_id,
    permissions,
  };
  const HandleSubmit = async (e) => {
    e?.preventDefault();
    axios
      .post(`${API_BASE_URL}/assigne-role`, useraccess, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response, "axiosssssssss");
        window.location.reload(true);
        props.setMessages("Permission assigned successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //   });
  };
  console.log(select, "select");
  return (
    <div id="Modal" className="edit-mini-modal">
      <div className="edit-user-permission-modal-content">
        <div className="edit-dialog-info">User Access</div>
        <div className="prg">({props?.user?.name})</div>
        <div className="edit-modal-divider"></div>
        <div className="row gap-3 centered-content-column">
          <div className="col-12  ">
            <div className="d-flex gap-2 align-items-baseline">
              <div className="prg">Role</div>
              <select
                name=""
                id=""
                required
                className="supplier-select-option mb-3"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Select Role
                </option>
                {roles?.data?.map((items) => (
                  <option value={items.id}>{items.role_name}</option>
                ))}
              </select>
            </div>
            <div className="prg text-center">Permission</div>
            <div className="divider mb-2"></div>
            <label className="d-flex justify-content-start mb-2 gap-2">
              <input
                type="checkbox"
                onChange={(e) => handleChangepermission(e, "all")}
              />
              <span className="prg-pri">
                {!select ? "Select All" : "Un Select All"}
              </span>
            </label>
            <div className="edit-permission">
              {role_id !== null ? (
                permission?.length !== 0 ? (
                  permission?.map((items, index) => (
                    <label className="d-flex justify-content-start ms-5 gap-2">
                      <input
                        name={items.permission_name}
                        type="checkbox"
                        checked={permissions.includes(items?.id)}
                        onChange={(e) => handleChangepermission(e, items?.id)}
                      />
                      <span className="prg-pri">{items?.permission_name}</span>
                    </label>
                  ))
                ) : (
                  <p className="text-center prg">
                    There is no Permissions for{" "}
                    <strong>{permission?.resource_name}</strong> yet
                  </p>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="confirm-dialog-controller">
            <button
              className="form-cancel"
              onClick={(e) => {
                props?.modal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="form-save"
              type={"submit"}
              onClick={(e) => {
                HandleSubmit(e);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
