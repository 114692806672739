import React from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useState } from "react";
import VarianceAnalysesModal from "./VarianceAnalysesModal";
import {
  useGetProjectQuery,
  useGetVarianceAnalysisQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import NoRecord from "../../common/NoRecord";
import VarianceAnalysisUpdateModal from "./VarianceAnalysisUpdate";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../../Pagination/Pagination";
import VarianceAnalysisReportDownload from "./DownloadReports/VarianceAnalysesDownload";
import VarianceAnalysesProject from "./DownloadReports/VarianceAnalysesProject";
export default function VarianceAnalysis() {
  const params = useParams();
  const { data: varianceanalysis } = useGetVarianceAnalysisQuery(params?.id);
  const { data: project } = useGetProjectQuery(params?.id);
  let count = 0;

  let PFO = "";
  let PM = "";

  PFO = project?.data?.users?.map((items) =>
    items?.pivot?.role_id == "0e324e94-6f2c-415c-9a46-a359a96fea7f"
      ? `${items?.name},`
      : ""
  );
  PM = project?.data?.users?.map((items) =>
    items?.pivot?.role_id == "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
      ? `${items?.name},`
      : ""
  );
  const [filter, setFilter] = useState(false);
  function HandleFilter() {
    setFilter(!filter);
    setStatus("");
    setStartDate("");
    setEndDate("");
  }
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  function HandleAdd() {
    setAdd(true);
  }
  const [status, setStatus] = useState("");
  const filteredData = (status) => {
    return varianceanalysis?.data
      ?.filter((items, index) => {
        if (status === "") {
          return items;
        } else if (
          status == "Favorable" &&
          items?.planned_cost - items?.actual_cost > 0
        ) {
          return items;
        } else if (
          status == "Unfavorable" &&
          items?.planned_cost - items?.actual_cost < 0
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        const itemDate = new Date(items.start_date);
        if (endDate === "") {
          return items;
        } else if (
          itemDate >= new Date(startDate) &&
          itemDate <= new Date(endDate)
        ) {
          return items;
        }
      })
      .map((items) => items);
  };
  const datass = [
    { name: "Item 1", date: "2023-04-05" },
    { name: "Item 2", date: "2023-05-05" },
    { name: "Item 3", date: "2023-06-05" },
    { name: "Item 4", date: "2023-07-05" },
  ];
  const filteredDatas = filteredData(status)?.filter((item) => {
    const itemDate = new Date(item.start_date);
    console.log(itemDate, "");
    return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
  });
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;
  console.log(filteredDatas, "filteredDatas");
  const p_count = Math.ceil(filteredData(status)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(status), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(varianceanalysis?.data, "varianceanalysis");
  return (
    <ForexSideBar>
      <div className="forex-page">
        <div className="payment-collection-container">
          <div className="d-flex gap-1 p-4 align-items-center">
            <button onClick={HandleAdd} className="add-client-representative">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                  fill="white"
                />
              </svg>
            </button>
            <VarianceAnalysesProject
              start_date={startDate}
              end_date={endDate}
              data={varianceanalysis?.data}
            />

            <div className="d-grid">
              <div className="h5-pri">{project?.data?.project_name}</div>
              <div className="d-flex gap-4">
                <div className="d-grid">
                  <div className="project-label">Project Manager</div>
                  <div className="lc-value">
                    {PM == "" ? "Not assigned" : PM}
                  </div>
                </div>
                <div className="d-grid">
                  <div className="project-label">Project Finance Officer</div>
                  <div className="lc-value">
                    {PFO == "" ? "Not assigned" : PFO}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {varianceanalysis?.data?.length > 1 ? (
            <div className="d-flex gap-4 ms-5 align-items-start">
              {filter ? (
                <Tooltip title="Filter On">
                  <FilterAltIcon
                    onClick={HandleFilter}
                    style={{ fontSize: "32px", color: "#064b4f" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Filter Off">
                  <FilterAltOffIcon
                    onClick={HandleFilter}
                    style={{ fontSize: "32px", color: "#064b4f" }}
                  />
                </Tooltip>
              )}
              {filter ? (
                <>
                  <div className="d-grid">
                    <span className="project-label">Start Date</span>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="report-filter-selector"
                    />
                  </div>
                  <div className="d-grid">
                    <span className="project-label">End Date</span>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="report-filter-selector"
                    />
                  </div>

                  <select
                    style={{ marginTop: "11px" }}
                    className="report-filter-selector"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Status</option>
                    <option value="Favorable">Favorable</option>
                    <option value="Unfavorable"> Unfavorable</option>
                  </select>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {_DATA?.currentData()?.length != 0 ? (
            <div className="p-3">
              <table style={{ width: "100%" }}>
                <thead className="stock-table-header">
                  <tr>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "3%" }}
                    >
                      No
                    </th>

                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "15%" }}
                    >
                      Task
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      Start Date
                    </th>

                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      End Date
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      Planned Cost
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      Actual Cost
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      Variance
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    >
                      Status
                    </th>
                    <th
                      className="stock-table-header-text text-start"
                      style={{ width: "5%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {_DATA?.currentData()?.map((items, orderindex) => (
                    <tr className="stock-table-row">
                      <td className="text-start">{(count += 1)}</td>
                      <td className="text-start">{items?.task_name}</td>
                      <td className="text-start">{items?.start_date}</td>
                      <td className="text-start">{items?.end_date}</td>
                      <td className="text-start">
                        {Number(items?.planned_cost)?.toLocaleString()}
                      </td>
                      <td className="text-start">
                        {Number(items?.actual_cost)?.toLocaleString()}
                      </td>
                      <td className="text-start">
                        {(
                          items?.planned_cost - items?.actual_cost
                        )?.toLocaleString()}
                      </td>
                      <td className="text-start">
                        {items?.planned_cost - items?.actual_cost > 0
                          ? "Favorable"
                          : "Unfavorable"}
                      </td>
                      <td className="text-center">
                        <ModeEditOutlinedIcon
                          onClick={(e) => (setEdit(true), setData(items))}
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <NoRecord />
          )}
          {p_count > 1 ? (
            <Pagination
              count={p_count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              className="p-2"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {add ? <VarianceAnalysesModal modal={setAdd} /> : ""}
      {edit ? <VarianceAnalysisUpdateModal modal={setEdit} data={data} /> : ""}
    </ForexSideBar>
  );
}
