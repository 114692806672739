import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import { useAttachDocumentMutation } from "../../features/SCMApi";
import FileChooser from "../common/FileChooser";

export default function AttachDocumentModal(props) {
  const { projectId } = useParams();
  const [contract_document, setContractDocument] = useState("");
  const [technical_document, setTechnicalDocument] = useState("");
  const [attachDocument] = useAttachDocumentMutation();
  console.log({ contract_document, technical_document }, "status");
  function handleSubmit(e) {
    const formData = new FormData();
    e.preventDefault();
    formData.append("contract_document", contract_document);

    formData.append("technical_document", technical_document);
    // attachDocument({ contract_document, technical_document })
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "RTK");
    //     // window.location.reload();
    //   })
    //   .then((error) => {
    //     console.log(error);
    //   });
    // const form = {
    //   contract_document,
    //   technical_document,
    // };

    axios
      .post(
        `${API_BASE_URL}/scm/project/projects/attach-documents/${projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        // window.location.reload();
        props?.ToggleModal(false);
        props?.setMessage("document attached successfully");
        props?.setSuccessMessage(true);
        // console.log(boq, solutions, project_id, boq_type_id, "inputs");
      })
      .catch(function (error) {
        console.log(error);
        // console.log(boq, solutions, project_id, boq_type_id, "inputs");
      });
  }
  return (
    <div className="mini-modal" onClick={(e) => props?.ToggleModal(false)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>Attach Documents</div>
          <div
            className="close-modal-button"
            onClick={(e) => props?.ToggleModal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="form-card-container mb-4"
          onSubmit={(event) => handleSubmit(event)}
        >
          <div className="input-group-fine">
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Contract Document</div>
              <FileChooser
                id={"contract_document"}
                state={contract_document}
                setters={setContractDocument}
              />
            </div>

            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Technical Document</div>
              <FileChooser
                id={"technical_document"}
                state={technical_document}
                setter={setTechnicalDocument}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="cancel"
              className="form-cancel"
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="form-save">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
