import React, { useEffect, useState } from "react";
import Settingsidebar from "../../pages/Settings/SettingIndex";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import AddSupplierCategory from "./SettingModal/AddSupplierCategory";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Pagination } from "@mui/material";
import usePagination from "../Pagination/Pagination";
import { API_BASE_URL } from "../../api/endPoint";
import EditCategoryModal from "./EditCategoryModal";

export default function SettingSupplierCategory() {
  const [supplierCategoryData, setSupplierCategoryData] = useState([]);
  const [addSupplierCategory, setAddSupplierCategory] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(null);

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const clearMessages = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(supplierCategoryData?.length / PER_PAGE);
  const _DATA = usePagination(supplierCategoryData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  //===========> Get Category <==========

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/supplier/supplier_category`)
      .then((response) => setSupplierCategoryData(response?.data?.data))
      .catch((error) => console.log(error));
  }, []);

  //===========> Delete Category <==========

  const handleDelete = (id) => {
    openDeleteModal();
    setDeletingCategory(id);
  };

  const confirmDelete = () => {
    axios
      .delete(
        `${API_BASE_URL}/scm/supplier/supplier_category/${deletingCategory}`
      )
      .then((response) => {
        setSupplierCategoryData((prevCategory) =>
          prevCategory.filter((item) => item.id !== deletingCategory)
        );
        setSuccessMessage("Category deleted successfully.");
        setErrorMessage(null);
        setTimeout(clearMessages, 5000);
        closeDeleteModal();
      })
      .catch((error) => {
        setErrorMessage("Error deleting category. Please try again.");
        setSuccessMessage(null);
        setTimeout(clearMessages, 5000);
        closeDeleteModal();
      });
  };

  //===========> Update Category <==========

  const handleUpdate = (id, name, description) => {
    const updatedData = {
      name: name,
      description: description,
    };

    axios
      .patch(
        `${API_BASE_URL}/scm/supplier/supplier_category/${id}`,
        updatedData
      )
      .then((response) => {
        setSupplierCategoryData((prevCategory) =>
          prevCategory.map((item) =>
            item.id === id ? { ...item, ...updatedData } : item
          )
        );
        console.log("updatedData", updatedData);

        setSuccessMessage("Category updated successfully.");
        setErrorMessage(null);
        setTimeout(clearMessages, 5000);
        console.log("Backend Response:", response.data);
        setEditingCategory(null);
      })
      .catch((error) => {
        setErrorMessage("Error updating category. Please try again.");
        setSuccessMessage(null);
        setTimeout(clearMessages, 5000);
      });
  };

  return (
    <Settingsidebar name={"Supplier Category"}>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      {showDeleteModal && (
        <div className="modal-container">
          <div class="modal-dialog  modal-dialog-centered " role="document">
            <div class="modal-content" style={{ minHeight: "0" }}>
              <div class="modal-header">
                <h5 class="modal-title">Confirm Delete</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeDeleteModal}
                ></button>
              </div>
              <div class="modal-body">
                {" "}
                <p>Are you sure you want to delete this category?</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="form-save"
                  onClick={confirmDelete}
                >
                  Yes
                </button>
                <button
                  className="form-cancel"
                  type="button"
                  data-dismiss="modal"
                  onClick={closeDeleteModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <AddSupplierCategory
        onClose={() => setAddSupplierCategory(false)}
        addSupplierCategory={addSupplierCategory}
        editingCategory={editingCategory}
        onEditComplete={() => setEditingCategory(null)}
      />
      {editingCategory && (
        <EditCategoryModal
          category={editingCategory}
          editedName={name}
          onClose={() => setEditingCategory(null)}
          editedDescription={description}
          onUpdate={(id, name, description) =>
            handleUpdate(id, name, description)
          }
        />
      )}
      <div className="pcard shipment-destination">
        <section className="pi-header">
          <h3>Supplier Category</h3>
        </section>
        {_DATA?.currentData()?.map((category) => (
          <section className="pi-body">
            <p>{category.name}</p>

            <div>
              <div onClick={() => setEditingCategory(category)}>
                <ModeEditOutlineOutlinedIcon
                  sx={{ color: "#52FFAC", cursor: "pointer" }}
                />
              </div>

              <div onClick={() => handleDelete(category.id)}>
                <DeleteOutlineOutlinedIcon
                  sx={{ color: "#FF5252", cursor: "pointer" }}
                />
              </div>
            </div>
          </section>
        ))}

        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px",
              marginRight: "40px",
            }}
          />
        ) : (
          ""
        )}
        <section className="pi-footer">
          <div
            onClose={() => setAddSupplierCategory(false)}
            onClick={() => setAddSupplierCategory(true)}
            className="pi-footer-icon"
            style={{ cursor: "pointer" }}
          >
            <AddIcon />
          </div>
        </section>
      </div>
    </Settingsidebar>
  );
}
