import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

export default function Selector(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const style = {
    width: 250,
    height: 32,
    backgroundColor: "#ececec",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: props?.style?.fontSize ? props?.style?.fontSize : "11px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    color: "#353945",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      marginTop: props?.style?.marginTop,
    },
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: props?.width ? props?.width : 226,
        color: "#186569",
        marginTop: -10,
      },
    },
    disableScrollLock: true,
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
    props?.onChange(event.target.value);
  };

  const paymentProject = props?.name !== undefined ? props?.name : [];
  let paymentTrackingData;
  props?.componentName === "paymentTracking" ? (
    (paymentTrackingData = ("All" + "," + paymentProject?.toString()).split(
      ","
    ))
  ) : (
    <></>
  );
  const data =
    props?.componentName === "paymentTracking"
      ? paymentTrackingData
      : props?.name;

  console.log(props?.passedValue?.toString(), "passed value");

  const selectValue =
    value !== ""
      ? value
      : props?.passedValue?.toString() !== undefined
      ? props?.passedValue
      : "";

  return (
    <div>
      <FormControl
        sx={{
          "& .css-67xo4a-MuiFormLabel-root-MuiInputLabel-root": {
            marginTop: "-7px",
          },
          "& .css-1qicbd3-MuiFormLabel-root-MuiInputLabel-root": {
            color: "#fff",
          },
          marginTop: 1,
          width: 226,
          color: "#186569",
        }}
      >
        {value === "" ? (
          <InputLabel
            sx={{
              color: "#AEB8C9",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: props?.style?.fontSize
                ? props?.style?.fontSize
                : "11px",
              lineHeight: "14px",
              letterSpacing: "0.105em",
            }}
            disabled={true}
            id="demo-simple-select-label"
          >
            {props?.placeHolder}
          </InputLabel>
        ) : (
          <></>
        )}

        <Select
          // defaultValue={props?.passedValue}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          sx={style}
          value={selectValue}
          displayEmpty
          required={props?.required}
        >
          {data?.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
