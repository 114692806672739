import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useCreatePaymentCollectionOffshoreMutation,
  useGetPaymentCollectionsQuery,
  useGetProjectPaymentTermQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";

export default function PaymentCollectionModalOffshore(props) {
  const params = useParams();
  const [items, setItem] = useState(null);
  const [project_payment_term_id, setPaymentTerm] = useState(null);
  const [ci_value, setCiValue] = useState(null);
  const [lc_no, setLcNo] = useState(null);
  const [bill_reference, setBillReference] = useState(null);
  const [shipment_document_colletion_date, setShipmentDocument] =
    useState(null);
  const [payment_status, setPaymentStatus] = useState(null);
  const [remark, setRemark] = useState(null);
  const pro_id = params?.id;
  const { data: projectid } = useGetProjectQuery(pro_id);
  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    project_id: params?.id,
    project_payment_term_id,
    items,
    ci_value,
    lc_no,
    bill_reference,
    shipment_document_colletion_date,
    payment_status,
    remark,
  };
  const [CreatePaymentCollection] =
    useCreatePaymentCollectionOffshoreMutation();
  const { data: terms } = useGetProjectPaymentTermQuery(params?.id);
  function handleSubmit(e) {
    e.preventDefault();
    CreatePaymentCollection(form)
      .unwrap()
      .then((response) => {
        props.modal(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  console.log(form, "formform");
  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  let offshoreTerms = [];
  terms?.data?.map((items) => {
    if (items?.is_offshore == 1) {
      offshoreTerms.push(items);
    }
  });
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Add Payment Collection</div>
            <CloseIcon onClick={HandleCancel} />
          </div>

          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                <div className="supplier-input-label">items</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setItem(e.target.value)}
                />
                <div className="supplier-input-label">CI Value</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setCiValue(e.target.value)}
                />
                <div className="supplier-input-label">LC Number</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setLcNo(e.target.value)}
                />
                <div className="supplier-input-label">Bill Reference</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setBillReference(e.target.value)}
                />

                <div className="supplier-input-label w-100">
                  Shipment Document Date
                </div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setShipmentDocument(e.target.value)}
                />

                <div className="input-label">Payment Term</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setPaymentTerm(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Payment Term
                  </option>
                  {offshoreTerms.map((items) => (
                    <option value={items.id}>{items?.reason}</option>
                  ))}
                </select>
                <div className="input-label">Payment Status</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  className="finance-input-field m-0"
                ></textarea>
                <div className="input-label">Remark</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setRemark(e.target.value)}
                  className="finance-input-field m-0"
                ></textarea>
              </div>
            </div>

            {/* {Number(planned_amount) < Number(actual_amount) ? (
              <label className="prg-error text-danger d-flex">
                Please enter valid Actual Amount
              </label>
            ) : (
              ""
            )} */}
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                // disabled={
                //   Number(planned_amount) < Number(actual_amount) ? true : false
                // }
                type="submit"
                className={
                  // Number(planned_amount) < Number(actual_amount)
                  // ? "form-save bg-secondary text-white"
                  "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
