import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useGetPaymentCollectionsQuery,
  useGetProjectPaymentTermQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";

export default function VarianceCollection(props) {
  const params = useParams();
  const [name, setName] = useState();
  const [planed_collection_date, setPlannedCollectionDate] = useState();
  const [milestone, setMilestone] = useState(null);

  const { data: projectid } = useGetProjectQuery(params?.id);

  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    project_id: params?.id,
    payment_collection_id: props?.data?.id,
    name,
    planed_collection_date,
    by_payment_term: false,
    milestone,
  };

  const [CreatePaymentCollection] = useCreatePaymentCollectionMutation();
  const { data: terms } = useGetProjectPaymentTermQuery(params?.id);
  function handleSubmit(e) {
    e.preventDefault();
    CreatePaymentCollection(form)
      .unwrap()
      .then((response) => {
        props.modal(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  console.log(props?.data, "formform");
  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  const [final_paymnet, setFinalPayment] = useState();

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Create Variance Payment Collection</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                <div className="supplier-input-label">Name</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setName(e.target.value)}
                />

                <div className="supplier-input-label w-100">
                  Planned Collection Date
                </div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setPlannedCollectionDate(e.target.value)}
                />
                <div className="input-label">Millstone</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setMilestone(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Millstone
                  </option>
                  {milestonepush?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* {Number(planned_amount) < Number(actual_amount) ? (
              <label className="prg-error text-danger d-flex">
                Please enter valid Actual Amount
              </label>
            ) : (
              ""
            )} */}
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                // disabled={
                //   Number(planned_amount) < Number(actual_amount) ? true : false
                // }
                type="submit"
                className={
                  // Number(planned_amount) < Number(actual_amount)
                  // ? "form-save bg-secondary text-white"
                  "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
