import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import NoRecord from './NoRecord';
import { Level } from 'docx';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ApprovalHistory({handleClose,open,data}) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
console.log(data,"data approval")

  return (
    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Approval History
          </Typography>
          {data?.length!=0?
          data.map((value, index) => (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {value?.name}
          <div>{`Level: ${value?.level}`}</div> 
          </Typography>
          )) :<NoRecord/>}
          
        </Box>
      </Modal>
    </div>
  );
}
