import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetVarianceAnalysisAllQuery,
  useGetVarianceAnalysisQuery,
} from "./../../../../features/SCMApi";
import { useParams } from "react-router-dom";

const VarianceAnalysesProject = ({ data, start_date, end_date }) => {
  const params = useParams();
  const { data: varianceanalysis } = useGetVarianceAnalysisAllQuery();
  const { data: payment_collection } = useGetPaymentCollectionsQuery(
    params?.id
  );
  const { data: project } = useGetProjectQuery(params?.id);
  console.log(payment_collection?.data, "payment_collection");
  const result = data?.reduce((acc, curr) => {
    // {console.log(curr?.variance_project[0]?.id,"curr.variance_project[0].project_id")}
    const group = acc?.find((g) => g.project_id === curr.project_id);
    if (group) {
      group.actual_cost += curr.actual_cost;
      group.planned_cost += curr.planned_cost;
      group.events.push(curr);
    } else {
      acc.push({
        project_id: curr.project_id,
        actual_cost: curr.actual_cost,
        planned_cost: curr.actual_cost,
        events: [curr],
      });
    }
    return acc;
  }, []);
  console.log(result, "by date");

  const mapData = [
    {
      lat: "222",
      lng: "33",
      title: "KKKKK",
      description: "hhhhhhh",
    },
  ];

  function startDate(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  function endDate(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = "Time Phase Budget Analysis";
  let title3 =
    start_date == "" ? "" : `${startDate(start_date)} - ${endDate(end_date)}`;
  let count = 0;
  console.log(startDate, endDate, "endDate");
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(project?.data?.project_name);
    const headersCost = result?.map((items) => `${items?.p} Planned Cost `);
    const headersRevenue = result?.map(
      (items) => `${items?.start_date} Planned Revenue `
    );
    const headersActualCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.actual_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );
    const headersPlannedCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.planned_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );

    let PlannedTotalCost = 0;
    headersPlannedCost?.map((item) => (PlannedTotalCost += item));

    let ActualTotalCost = 0;
    headersActualCost?.map((item) => (ActualTotalCost += item));
    let plannedRevenue = 0;
    let actualRevenue = 0;
    payment_collection?.data?.map(
      (items) => (plannedRevenue += Number(items?.planned_amount))
    );
    payment_collection?.data?.map(
      (items) => (actualRevenue += Number(items?.actual_amount))
    );

    console.log(plannedRevenue, "headersPlannedCost");
    // Add title cell above the table headers
    worksheet.mergeCells("A1:K1");
    worksheet.mergeCells("A2:K2");
    worksheet.mergeCells("A3:K3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    titleCell2.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
      bold: true,
    };
    titleCell.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
      bold: true,
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Task Name",
      "Project Lists",
      "Planned Cost",
      "Planned Revenue",
      "Actual Revenue",
      "Actual Cost",
      "Revenue Variance",
      "Status",
      "Cost Variance",
      "Status",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Calibri",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 25;
    const worksheets = workbook?.getWorksheet("Sheet1");

    // Select the range of cells to merge
    const mergeRange = worksheets?.getCell("E5:E8");

    // Set the merge option to merge vertically

    mergeRange?.merge({ across: false });
    const cellF1 = worksheet.getCell("F5");
    const cellF2 = worksheet.getCell("E5");
    const cellF3 = worksheet.getCell("H5");
    const cellF4 = worksheet.getCell("I5");
    cellF1.value = plannedRevenue?.toLocaleString();
    cellF2.value = actualRevenue?.toLocaleString();
    cellF3.value = Number(plannedRevenue - actualRevenue)?.toLocaleString();
    cellF4.value =
      plannedRevenue - actualRevenue == 0 ? "Favorable" : "Unfavorable";
    // Center the text horizontally and vertically
    cellF1.alignment = { horizontal: "center", vertical: "middle" };
    cellF2.alignment = { horizontal: "center", vertical: "middle" };
    cellF3.alignment = { horizontal: "center", vertical: "middle" };
    cellF4.alignment = { horizontal: "center", vertical: "middle" };
    // Set the header row to bold
    // Populate the table with data from the map
    data?.map((items, index) => {
      worksheet?.addRow([
        (count += 1),
        items?.task_name,
        items?.project?.project_name,
        Number(items?.planned_cost)?.toLocaleString(),
        "",
        "",
        Number(items?.actual_cost)?.toLocaleString(),
        "",
        "",
        Number(items?.planned_cost - items?.actual_cost)?.toLocaleString(),
        headersPlannedCost[index] - headersActualCost[index] >= 0
          ? "Favorable"
          : "Unfavorable",
      ]);
    });
    const FooterRow = worksheet.addRow([
      " ",
      "Total",
      " ",
      PlannedTotalCost?.toLocaleString(),
      " ",
      " ",
      ActualTotalCost?.toLocaleString(),
      " ",
      " ",
      " ",
      " ",
    ]);
    FooterRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Calibri",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "All project variance.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default VarianceAnalysesProject;
