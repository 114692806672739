import React from "react";
import Checkbox from "././CheckBox";

const InitiateForexIeItemList = (props) => {
  console.log(props?.orderData?.pis, "orderData....");
  return (
    <div>
      {props?.orderData !== null ? (
        <>
          {props?.orderData?.map((order) => (
            <>
              <div className="rmaFileAttachLabel">{order?.name}</div>
              <div className="rmaFileAttachLabelDivider orderListDivider" />
              <div className="orderListCheckBox">
                <Checkbox
                  data={order?.pis?.map((pis) => pis?.pi)?.flat()}
                  allOrderItemList={props?.allOrderItemList}
                  setOrderList={props?.setOrderList}
                  isChecked={props?.isSelected}
                />
              </div>
            </>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InitiateForexIeItemList;
