import React from "react";
import { useGetPoGroupQuery } from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";

export default function ExistingGroups(props) {
  const { data: pogroup } = useGetPoGroupQuery(props.poid);
  console.log(pogroup, "poid={props.po_id}");
  return (
    <div className="d-grid justify-content-center gap-2">
      {pogroup?.data?.length !== 0 ? (
        pogroup?.data?.map((items) => (
          <>
            <div className="h5-pri text-center">{items?.group_name}</div>
            <div className="divider"></div>
            <ul>
              {items.items.map((grpitems) => (
                <li className="prg mb-1">{grpitems?.item_description}</li>
              ))}
            </ul>
          </>
        ))
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
