import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import RequestLetterModal from "../Modals/RequestLetterModal";
import UndertakingFormsModal from "../Modals/UndertakingFormsModal";
import BankSubmissionModal from "../Modals/BankSubmissionModal";
import SwiftAndPermitModal from "../Modals/SwiftAndPermitModal";
import { useGetUserInfoQuery } from "../../../../features/SCMApi";
import CheckPermission from "../../../common/checkPermission/checkPermission";
import SummarizedPi from "../Modals/SummrizedPi";

const Stages = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "230px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "60px",
    marginRight: "-45px",
    marginBottom: "-70%",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginBottom: "-30px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ForaxDetailStages = (props) => {
  //Forex approval Modal states
  const [summarizedPi, setSummarizedPi] = React.useState(false);
  const [requestLetterModal, setRequestLetterModal] = React.useState(false);
  const [undertakingFormsModal, setUndertakingFormsModal] =
    React.useState(false);
  const [bankSubmissionModal, setBankSubmissionModal] = React.useState(false);
  const [swiftAndPermitsModal, setSwiftAndPermitsModal] = React.useState(false);
  const [componentName, setComponentName] = React.useState("");

  //Forex approval Modals handlers
  function SummarizedPiModalHandler() {
    setSummarizedPi(true);
  }
  function RequestLetterModalHandler() {
    setRequestLetterModal(true);
  }
  function UndertakingFormsModalHandler() {
    setUndertakingFormsModal(true);
  }
  function BankSubmissionModalHandler() {
    setBankSubmissionModal(true);
  }
  function SwiftAndPermitModalHandler() {
    setSwiftAndPermitsModal(true);
  }

  let ForexApprovalNames = [
    { name: "Summarized PI" },
    { name: "Forex Approval Request Letter" },
    { name: "Undertaking Forms" },
    { name: "Bank Submission" },
    { name: "Swift & Permit" },
  ];

  const handleStagesButtonClick = (e, name) => {
    return name === "Forex Approval Request Letter" ? (
      (RequestLetterModalHandler(), setComponentName("requestLetter"))
    ) : name === "Undertaking Forms" ? (
      (UndertakingFormsModalHandler(), setComponentName("undertakingForms"))
    ) : name === "Bank Submission" ? (
      (BankSubmissionModalHandler(), setComponentName("bankSubmission"))
    ) : name === "Swift & Permit" ? (
      (SwiftAndPermitModalHandler(), setComponentName("swiftAndPermit"))
    ) : name === "Summarized PI" ? (
      (SummarizedPiModalHandler(), setComponentName("summarizedPi"))
    ) : (
      <></>
    );
  };
  const { data: userinfo } = useGetUserInfoQuery();
  console.log(props?.summarizedPi, "props?.summarizedPi");
  return (
    <>
      <Stages elevation={0}>
        <div
          className="forexApprovalStageValue"
          style={{
            color: CheckPermission("forex_approval_stages", userinfo)
              ? "#186569"
              : "#aeb8c9",
          }}
        >
          Stages
        </div>
        <div className="rmaImportExportContainer">
          {ForexApprovalNames?.map((item) => (
            <>
              <Button
                sx={{
                  width: "100%",
                  color: CheckPermission("forex_approval_stages", userinfo)
                    ? "#353945"
                    : "#aeb8c9",
                  justifyContent: "flex-start",
                  "&:hover": {
                    color: "#186569",
                  },
                }}
                onClick={(e) => handleStagesButtonClick(e, item?.name)}
                disabled={
                  CheckPermission("forex_approval_stages", userinfo)
                    ? false
                    : true
                }
              >
                <LaunchIcon className="rmaExportImportLinkIcon" />
                <div className="forexApprovalStageLabels">{item?.name}</div>
              </Button>
            </>
          ))}
        </div>
      </Stages>
      {requestLetterModal && (
        <RequestLetterModal
          modal={setRequestLetterModal}
          name={componentName}
          successMessage={props?.successMessage}
          message={props?.message}
          previousData={props?.requestLetter}
        />
      )}
      {undertakingFormsModal && (
        <UndertakingFormsModal
          modal={setUndertakingFormsModal}
          name={componentName}
          successMessage={props?.successMessage}
          message={props?.message}
          previousData={props?.underTakingForms}
        />
      )}
      {bankSubmissionModal && (
        <BankSubmissionModal
          modal={setBankSubmissionModal}
          name={componentName}
          successMessage={props?.successMessage}
          message={props?.message}
          previousData={props?.bankSubmission}
        />
      )}
      {swiftAndPermitsModal && (
        <SwiftAndPermitModal
          modal={setSwiftAndPermitsModal}
          name={componentName}
          successMessage={props?.successMessage}
          message={props?.message}
          previousData={[...props?.swiftAndPermit, ...props?.consignBank]}
        />
      )}
      {summarizedPi && (
        <SummarizedPi
          modal={setSummarizedPi}
          name={componentName}
          successMessage={props?.successMessage}
          message={props?.message}
          projectId={props?.projectId}
          previousData={props?.summarizedPi}
          summarizedPiId={props?.summarizedPiId}
        />
      )}
    </>
  );
};

export default ForaxDetailStages;
