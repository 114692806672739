import * as React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import DeliveredItemDetailOrderList from "./DeliveredItemDetailComponent/DeliveredItemDetailOrderList";
import DeliveredItemDetailStages from "./DeliveredItemDetailComponent/DeliveredItemDetailStages";
import BatchGroupItems from "../../../pages/Logistics/DeliveredItem/BatchGroupItems";

export default function DeliveredItemBatchInfo(props) {
  console.log(props?.data?.id, "is there any batch id");
  return (
    <>
      {/* <Grid
        container
        spacing={2}
        direction={{ xs: "row", sm: "row", md: "row" }}
      > */}
      {/* <DeliveredItemDetailOrderList data={props?.data} />
        {console.log(props?.data, "this is the data for data")}
        <DeliveredItemDetailStages
          missedItemId={props?.data?.id}
          stageData={props?.data}
          batchId={props?.data?.batch?.id}
          projectId={props?.data?.project_id}
          successMessage={props.successMessage}
          message={props.message}
        /> */}
      <div className="container mt-4">
      <div className="row">
        {props?.data?.map((item, index) => (
          <div key={index} className="col-md-3 mb-4 p-10">
            {/* Assuming col-md-4 to display three components in a row on medium-sized screens */}
            <BatchGroupItems
              itemData={item}
              totalItems={item?.item?.length}
              name={item?.group_name}
              number={item?.group_number}
            />
          </div>
        ))}
      </div>
    </div>
      {/* </Grid> */}
    </>
  );
}
