import axios from "axios";
import React from "react";
import { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import { useUploadFaqVideoMutation } from "../../features/SCMApi";
import FileChooser from "../common/FileChooser";
import Spinner from "../common/Spinner";

export default function VideoFaq(props) {
  const [uploadFaqVideo] = useUploadFaqVideoMutation();

  const [title, setTitle] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [video, setVideo] = useState();
  const [uploading, setUploading] = useState(false);
  function handleCancel(e) {
    e.preventDefault();
    props?.ToggleModal(false);
  }
  function handleSubmit(e) {
    e.preventDefault();
    const faqVideosForm = { title, thumbnail, video };
    setUploading(true);

    console.log(faqVideosForm, "faqVideosForm");
    // uploadFaqVideo(faqVideosForm)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "RTK");
    //     setUploading(false);
    //     props?.ToggleModal(false);
    //   })
    //   .then((error) => {
    //     setUploading(false);
    //     console.log(error, "erorr from backend");
    //   });
    axios
      .post(`${API_BASE_URL}/scm/faq/videos`, faqVideosForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log(response);
        console.log(faqVideosForm, "inputs");
        handleCancel();
      })
      .catch(function (error) {
        console.log(error);
        // handleCancel();
      });
  }
  return (
    <form className="form-container" onSubmit={(e) => handleSubmit(e)}>
      <div className="inputs-group " style={{ width: "55%" }}>
        <div className="input-label">Title</div>
        <input
          required
          type="text"
          name=""
          id=""
          className="file-chooser "
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="inputs-group " style={{ width: "55%" }}>
        <div className="input-label">Thumbnail</div>
        <FileChooser
          notRequired={false}
          state={thumbnail}
          setters={setThumbnail}
          id={"thumbnail"}
        />
      </div>
      <div className="inputs-group " style={{ width: "55%" }}>
        <div className="input-label">Video</div>
        <FileChooser
          notRequired={false}
          state={video}
          setters={setVideo}
          id={"videoSelector"}
        />
      </div>
      {uploading ? <Spinner /> : <></>}
      <div className="form-buttons mt-4">
        <button
          type="cancel"
          className="form-cancel"
          onClick={(e) => handleCancel(e)}
        >
          {" "}
          Cancel
        </button>
        <button type="submit" className="form-save">
          Save
        </button>{" "}
      </div>
    </form>
  );
}
