import NavBar from "../../Layout/NavBar";
import "./accountSettings.css";
import index from "../../Layout/index";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { Avatar, IconButton, Input, TextField } from "@mui/material";
import { useState } from "react";
import { useGetUserInfoQuery } from "../../features/SCMApi";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import { Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import Signature from "./Signature";

const Account = () => {
  const { data: userinfo } = useGetUserInfoQuery();
  const [file, setFile] = useState(null);
  const [image, setImage] = useState({ preview: "", data: "" });

  const handleChange = function loadFile(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const HandleSubmit = async (e) => {
    let form = new FormData();
    form.append("avatar", image?.data);
    e?.preventDefault();
    axios
      .post(`${API_BASE_URL}/change-profile/${userinfo?.id}`, form, {})
      .then((response) => {
        console.log(response, "axiosssssssss");
        //window.location.reload(true);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // // createSupplier(params)
    // //   .unwrap()
    // //   .then((response) => {
    // //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    // //   })
    // //   .then((error) => {
    // //     console.log(error, "erorrrrr");
    // //   });
  };

  //hh
  const UserName = userinfo?.name.split(" ");
  let name = UserName === undefined ? [] : UserName;
  console.log(userinfo, "hahahhaah");
  const [old_password, setOldPassword] = useState();
  const [showold_password, setShowOldPassword] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState();
  const [password_confirmation, setConfirmPassword] = useState();
  const [message, setMessage] = useState();
  const HandleChangePassword = (e) => {
    const changepassword = new FormData();
    changepassword.append("old_password", old_password);
    changepassword.append("password", password);
    changepassword.append("password_confirmation", password_confirmation);

    e?.preventDefault();
    axios
      .post(`${API_BASE_URL}/update/user/${userinfo?.id}`, changepassword, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((response) => {
        console.log(response, "axiosssssssss");
        window.location.reload(true);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
        setMessage(error?.response?.data?.message?.message);
      });
  };
  const togglePassword = () => {
    setShowOldPassword(!showold_password);
  };
  const NewtogglePassword = () => {
    setShowPassword(!showpassword);
  };
  const ConfirmtogglePassword = () => {
    setShowConfirmPassword(!showconfirmpassword);
  };
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="profile">
          <div id="image">
            {image?.preview !== null ? (
              <Avatar
                alt={`${userinfo?.name}`}
                src={`${IMG_BASE_URL}${userinfo?.avatar}`}
                sx={{ width: "122px", height: "122px" }}
              />
            ) : (
              <img src={image?.preview} />
            )}

            <div id="camera--icon">
              <input
                name="Select File"
                type="file"
                onChange={handleChange}
                accept="image/*"
              ></input>
              <CameraAltRoundedIcon />
            </div>
          </div>
          <div>
            <h5 className="">{userinfo?.name}</h5>
            <p>
              {userinfo?.role === null
                ? "Not available"
                : userinfo?.role?.role_name}
            </p>
            <p>{userinfo?.email}</p>
          </div>
        </div>
        <div className="account">
          <div className="header">
            <h4>Account</h4>
          </div>
          <div>
            <Tabs
              defaultActiveKey="account"
              id="uncontrolled-tab-example"
              className="mt-3 d-flex justify-content-center bg-white rounded gap-4 border-none"
            >
              <Tab eventKey="account" title="Account">
                <form>
                  <div className="name">
                    <section>
                      <input
                        type="text"
                        placeholder="First Name"
                        required
                        className="account-text-input"
                        defaultValue={name[0]}
                        disabled
                      />
                    </section>
                    <section>
                      <input
                        type="text"
                        placeholder="Last Name"
                        required
                        className="account-text-input"
                        size="small"
                        value={name[1]}
                        disabled
                      />
                    </section>
                  </div>
                  <div className="email">
                    <section>
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        className="account-text-input"
                        value={userinfo?.email}
                        disabled
                      />
                    </section>
                  </div>
                  <button
                    type="button"
                    className="form-save clientSave"
                    style={{ width: "112px", height: "32px" }}
                    onClick={HandleSubmit}
                  >
                    Save
                  </button>
                </form>
              </Tab>
              <Tab eventKey="changepassword" title="Signature" className="">
                {/* <form onSubmit={HandleChangePassword}>
                  <div className="account-input-fields">
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="label  prg ">Old password</div>
                      <input
                        type={showold_password ? "text" : "password"}
                        placeholder="Password"
                        required
                        className="account-text-input"
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      {showold_password === false ? (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                            onClick={togglePassword}
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </div>
                      ) : (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                            onClick={togglePassword}
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="label  prg">New password</div>
                      <input
                        type={showpassword ? "text" : "password"}
                        id=""
                        required
                        placeholder="Password"
                        className="account-text-input"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {showpassword === false ? (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                            onClick={NewtogglePassword}
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </div>
                      ) : (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                            onClick={NewtogglePassword}
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-2 align-items-baseline ">
                      <div className="label  prg">Confirm password</div>
                      <input
                        type={showconfirmpassword ? "text" : "password"}
                        id=""
                        required
                        placeholder="Confirm Password"
                        className="account-text-input"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {showconfirmpassword === false ? (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                            onClick={ConfirmtogglePassword}
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </div>
                      ) : (
                        <div className="showpassword">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                            onClick={ConfirmtogglePassword}
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="form-save mb-3"
                    style={{ width: "242px", height: "32px" }}
                  >
                    Change Password
                  </button>
                </form> */}
                <Signature />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
