import React, { useEffect } from "react";
import { useState } from "react";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import {
  useGetInventoryQuery,
  useGetInventorysRequestsQuery,
  useGetToolsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useItemsInventoryAcceptMutation,
  useItemsInventoryConfirmMutation,
  useGetApproverQuery,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetItemCategorysQuery,
  useGetStoreQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import StockDialog from "../../common/StockDialog";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import axios from "axios";
import InventoryApprovalModal from "../../common/InventoryApprovalModal";
import InventoryApprovalRejectModal from "../../common/InventoryApprovalRejectModal";
import { IconButton, Pagination } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import usePagination from "../../Pagination/Pagination";
import BatchItems from "../../../assets/SVG/Batchitems.png";
import DispatchedTools from "../DownloadReports/DispatchedTools";
import ReturnedTools from "../DownloadReports/ReturnedTools";
import StockIndex from "../../../pages/Stocks/StockIndex";

export default function InStoreItemsInventory({ inventory }) {
  const { data: RequestedInventory } = useGetInventorysRequestsQuery();
  console.log(RequestedInventory, "RequestedInventory");
  const { data: loggedUser } = useGetUserInfoQuery();
  let notRequest = [];
  RequestedInventory?.data?.map((items) =>
    items.is_returned == 1 && items?.is_confirmed == 1
      ? notRequest.push(items)
      : ""
  );
  const userdata = notRequest?.filter(
    (items) =>
      items?.requester_id == loggedUser?.id ||
      items?.store_keeper_id == loggedUser?.id
  );
  const [search, setSearch] = useState("");
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store_id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(notRequest, "notRequest tools");

  return (
    <StockIndex>
      <div className="tool-header-container d-flex gap-3 justify-content-center">
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {store?.data?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>

        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>
        {/* <div className="d-grid gap-2 justify-content-center mb-1">
          <DispatchedTools items={true} />
          <span className="prg-pri">Dispatched Items</span>
        </div> */}
        <div className="d-grid gap-2 mb-1  justify-content-center">
          <ReturnedTools items={true} />
          <span className="prg-pri">Returned Items</span>
        </div>
      </div>
      {_DATA?.currentData() !== 0 ? (
        <>
          <table style={{ width: "100%" }}>
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}>Item Name</th>
                <th style={{ width: "10%" }}>Model</th>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "10%" }}>store</th>
                <th style={{ width: "10%" }}>Project</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "10%" }}>Requester</th>
                <th style={{ width: "10%" }}>Approval status </th>
                <th style={{ width: "15%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((items) => (
                <tr className="stock-table-row">
                  <td className="">
                    {items?.image != null ? (
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${items?.image}`}
                        alt=""
                      />
                    ) : (
                      <img
                        sx={{ width: 50, height: 50, borderRadius: 0 }}
                        src={BatchItems}
                        alt=""
                      />
                    )}
                  </td>
                  <td className="text-start">{items?.item_name}</td>
                  <td className="text-start">{items?.model}</td>
                  <td className="text-start">
                    {items?.inventory_type_id === null
                      ? "-"
                      : items?.inventory_type}
                  </td>
                  <td className="text-start">
                    {items?.store_name === null ? "-" : items?.store_name}
                  </td>
                  <td className="text-start">
                    {console.log(items, "this is the requestd item")}
                    {items?.project_name === null ? "-" : items?.project_name}
                  </td>
                  <td className="text-start">
                    {items?.status_name === null ? "-" : items?.status_name}
                  </td>
                  <td className="text-start">{items?.name}</td>

                  <td className="text-start">
                    {items?.is_approved == 0
                      ? "Pending"
                      : items?.is_approved == 1
                        ? "Approved"
                        : "Rejected"}
                  </td>

                  {items?.is_batch == 1 ? (
                    <td className="text-start">
                      <NavLink
                        className="form-save p-2"
                        to={`/stocks/requestbatchinventory/${items?.id}`}
                      >
                        View More
                      </NavLink>
                    </td>
                  ) : (
                    <td className="text-start">
                      <NavLink to={`/stocks/requests/inventory/${items?.id}`}>
                        <ArrowCircleRightTwoToneIcon
                          sx={{ color: "#186569" }}
                        />
                      </NavLink>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <NoRecord />
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
