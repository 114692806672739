import React from "react";
import ProjectDocumentComponent from "../../components/documents/ProjectDocument";
import NavBar from "./../../Layout/NavBar";
import Utilities from "../../components/documents/Utilities";
import { BatchDocument } from "../../components/documents/BatchDocument";
import { OrderDocument } from "../../components/documents/OrderDocument";
import Documentindex from "../../pages/Documents";
import {
  useGetProjectDocumentQuery,
  useGetProjectQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import { ForexDocument } from "../../components/documents/ForexDocument";

export default function ProjectDocument() {
  const params = useParams();
  const { data: DocumentDetail } = useGetProjectQuery(params.id);
  console.log(DocumentDetail?.data?.project_name, "docc projcet");
  return (
    <Documentindex name={DocumentDetail?.data?.project_name + " Documents"}>
      <div className="page">
        <ProjectDocumentComponent />
        <BatchDocument />
        <OrderDocument />
        <ForexDocument />
      </div>
    </Documentindex>
  );
}
