import React from "react";
import "../forex.style.css";
import { Tooltip } from "@mui/material";

export default function ForexCard(props) {
  console.log(props, "this is also data");
  return (
    <div className="forex-detail-card">
      <div className="">
        <div
          className={
            props?.data?.remaining_days < 30 ||
            props?.data?.remaining_days == "Unknown"
              ? "logistics-batch-name text-danger"
              : "logistics-batch-name text-success"
          }
        >
          {props?.data?.remaining_days}
        </div>
        <div className="origin-label">ED</div>
      </div>

      <div className="forex-detail-card-sub">
        <div className="forex-card-left-grid">
          <div className="batch-container">
            <div className="logistics-batch-name">
              {props?.data?.forex_name?.length > 30
                ? props?.data?.forex_name.slice(0, 30) + "..."
                : props?.data?.forex_name}
            </div>
          </div>
        </div>
      </div>
      <div className="forex-bank-and-item-container">
        <div className="logistics-batch-item forexProjectBankName">
          <div className="origin-group">
            <Tooltip title={props?.data?.forex_bank?.bank_name}>
              <div className="origin-value">
                {props?.data?.forex_bank?.bank_name?.length > 20
                  ? props?.data?.forex_bank?.bank_name.slice(0, 20) + "..."
                  : props?.data?.forex_bank?.bank_name}
              </div>
            </Tooltip>
            <div className="origin-label">Bank</div>
          </div>
        </div>
        <div className="logistics-batch-item">
          <div className="origin-group">
            <div className="origin-value">{props?.data?.items?.length}</div>
            <div className="origin-label">item</div>
          </div>
        </div>
      </div>
      {props?.clear == true ? (
        <div className="d-flex justify-content-between">
          <div
            style={{ borderRadius: "2.25rem" }}
            className={
              props?.data?.isApproved == 1
                ? "bg-success  mt-3"
                : "bg-warning  mt-3"
            }
          >
            <div className={"prg-error text-white my-1 mx-2"}>
              {props?.data?.isApproved == 1 ? "Approved" : "Pending"}
            </div>
          </div>
          <div
            style={{ borderRadius: "2.25rem" }}
            className={
              props?.data?.NBE_cleared == 1
                ? "bg-success  mt-3 mt-3"
                : "bg-danger  mt-3 mt-3"
            }
          >
            <div className="prg-error text-white my-1 mx-2">
              {props?.data?.NBE_cleared == 1 ? "Cleared" : "Not Cleared"}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
