import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "../../../Settings/Settings.css";
import "../../forex.style.css";
import usePagination from "../../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import Select from "../../ForexApproval/Select";
import DataTable from "./SupplierCredit";
import {
  useGetPaymentTrackingForVendorQuery,
  useGetForexPaymentTrackingVendorQuery,
  useGetProductsQuery,
  useGetProjectsQuery,
  useGetSupplierCreditQuery,
} from "../../../../features/SCMApi";
import AddSupplierCreditButton from "../Modal/AddCreditContainer";
import SuccessMessage from "../../SuccessMessage";
export default function SupplierCreditContainer(props) {
  const [show, setShow] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log(eventKey, "totally custom!")
    );

    return (
      <p
        className="d-flex justify-content-between my-2"
        onClick={decoratedOnClick}
      >
        {children}
      </p>
    );
  }
  const { data: VendorList } = useGetForexPaymentTrackingVendorQuery();
  let data;
  let vendorId;
  const [supplierId, setSupplier] = React.useState(0);

  const { data: PaymentTrackingVendor } = useGetSupplierCreditQuery(supplierId);
  const { data: projects } = useGetProjectsQuery();
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [project_id, setProject] = React.useState("");
  const [project_ids, setProjects] = React.useState("");
  const datas = PaymentTrackingVendor?.data;
  const groupedsupplier = datas?.reduce((acc, supplier) => {
    if (!acc[supplier?.supplier_id]) {
      acc[supplier?.supplier_id] = [];
    }
    acc[supplier?.supplier_id].push(supplier);
    return acc;
  }, {});
  let supplier_lenght = 0;
  for (const key in groupedsupplier) {
    if (groupedsupplier.hasOwnProperty(key)) {
      supplier_lenght++;
    }
  }
  const mappedObject = Object?.keys(
    groupedsupplier == undefined ? {} : groupedsupplier
  )?.map((key, index) => groupedsupplier[key]);
  const supplier_list = [];
  mappedObject?.map((supplier, index) =>
    supplier_list.push({
      id: supplier[0]?.id,
      distributor_name: supplier[0]?.supplier_name,
    })
  );
  console.log(supplier_list, "supplier_list");
  vendorId =
    selectedProject === "All" || null
      ? ""
      : supplier_list !== []
        ? supplier_list
            ?.filter((vendor) => vendor?.distributor_name === selectedProject)
            ?.map((vendor) => vendor?.id)
            .flat()
            .toString()
        : "";

  selectedProject === "All"
    ? (data = supplier_list !== undefined ? supplier_list : [])
    : selectedProject === null
      ? (data = supplier_list !== undefined ? supplier_list : [])
      : (data =
          supplier_list !== undefined
            ? supplier_list?.filter(
                (vendor) => vendor.distributor_name === selectedProject
              )
            : []);
  const filteredData = mappedObject
    ?.filter((items) => {
      if (vendorId === "") {
        return items;
      } else if (items[0]?.id == vendorId) {
        return items;
      }
    })
    .map((items) => {
      return items;
    });
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(filteredData?.length / PER_PAGE);
  const _DATA = usePagination(filteredData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  console.log(filteredData, "filteredData");

  return (
    <div className="card paymentTrackingContainer">
      <>
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "5px",
                marginLeft: "10px",
                backgroundColor: "#52ffab96",
                width: "100%",
              }}
              Message={message}
            />
          </>
        ) : (
          ""
        )}
      </>
      <div className="card-body">
        <div className="paymentTrackingSelectProject">
          <Select
            componentName={"paymentTracking"}
            name={
              supplier_list !== []
                ? supplier_list?.map((vendor) => vendor?.distributor_name)
                : []
            }
            placeHolder={"Select Supplier..."}
            onChange={setSelectedProject}
            style={{ width: 300, fontSize: 11, marginTop: 1.5 }}
          />
        </div>
        {_DATA?.currentData()?.map((vendor) => (
          <Accordion>
            <Card className="mb-2 mt-0 paymentTrackingAccordionCard">
              <div className="card-header">
                <CustomToggle eventKey="0">
                  <span className="prg">{vendor[0]?.supplier_name}</span>

                  <span className="prg">
                    TOTAL CREDIT:
                    {(
                      Math.round(
                        (vendor
                          ?.filter((items) => {
                            if (project_ids == "") {
                              return vendor;
                            } else if (items?.project_id == project_ids) {
                              return vendor;
                            }
                          })
                          ?.map((totalPayment) =>
                            Number(totalPayment?.credit_amount)
                          )
                          ?.filter((e) => typeof e == "number")
                          ?.reduce((prev, acc) => prev + acc, 0) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    )?.toLocaleString()}
                  </span>
                </CustomToggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="paymentTrackingUnderline"></div>

                  <DataTable
                    trackingSheet={vendor}
                    name={"paymentTrackingVendor"}
                    setProject={setProject}
                    project_id={project_id}
                    supplier_id={vendor[0]?.supplier_id}
                    totalPayment={
                      Math.round(
                        (vendor
                          ?.filter((items) => {
                            if (project_id == "") {
                              return items;
                            } else if (items?.project_id == project_id) {
                              return items;
                            }
                          })
                          ?.map((totalPayment) =>
                            Number(totalPayment?.credit_amount)
                          )
                          ?.filter((e) => typeof e == "number")
                          ?.reduce((prev, acc) => prev + acc, 0) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }
                    // paidAmount={
                    //   Math.round(
                    //     (vendor
                    //       ?.filter((items) => {
                    //         if (project_ids == "") {
                    //           return vendor;
                    //         } else if (items?.project_id == project_id) {
                    //           return vendor;
                    //         }
                    //       })
                    //       ?.map((totalPayment) =>
                    //         Number(totalPayment?.paid_amount)
                    //       )
                    //       ?.filter((e) => typeof e == "number")
                    //       ?.reduce((prev, acc) => prev + acc, 0) +
                    //       Number.EPSILON) *
                    //       100
                    //   ) / 100
                    // }
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))}

        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
        <div className="d-flex justify-content-end align-items-center">
          <AddSupplierCreditButton
            name="paymentTracking"
            editData={[]}
            isSupplier={true}
            supplier_id={props?.supplier_id}
            message={setMessage}
            successMessage={setSuccessMessage}
            totalPayment={props?.totalPayment}
          />
        </div>
      </div>
    </div>
  );
}
