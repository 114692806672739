import React from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Pagination, Tooltip } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import {
  useGetItemCategorysQuery,
  useGetStoreQuery,
  usegetAssetsQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
  useGetFixedAssetsQuery,
  useAssetRequestMutation,
} from "../../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import RegisterTool from "../StockModal/RegisterTool";
import NoRecord from "../../common/NoRecord";
import ConfirmDialog from "../../common/ConfirmDialog";
import StockDialog from "../../common/StockDialog";
import axios from "axios";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import { Toll } from "@mui/icons-material";
import ConfirmRequest from "../StockModal/ConfirmRequest";
import InventoryReport from "../DownloadReports/InventoryReport";
import usePagination from "../../Pagination/Pagination";
import EditAssets from "../StockModal/EditAssets";

export default function AssetTools(props) {
  function HandleRequest() {
    setConfirm(true);
  }
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    // props?.setFragment("detail");
    props?.setData(tool);
    console.log(tool, "toolssssss");
  };
  const { data: getAssets } = useGetFixedAssetsQuery();
  console.log(getAssets, "getAssets");
  const [registerTool, setRegisterTool] = useState(false);
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  function HandleItem() {
    setRegisterTool(true);
    updateTool(null);
  }
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);
  console.log(dialog, "dialog");
  const [toolRequest, src] = useAssetRequestMutation();
  const [requested_quantity, setQuantity] = useState(null);
  const [requestedProject, setRequestedProject] = useState("");

  const { data: userinfo } = useGetUserInfoQuery();

  function HandleToolRequest(e, id) {
    let requested = {
      id: id,
      requested_quantity,
      requester_id: userinfo?.id,
      requested_project: requestedProject,
    };
    toolRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // props?.setMessages("Tool Requested successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error, "errorrrrrrrrrrrrrrr");
    //   });
  }

  let notRequest = [];
  getAssets?.data?.map((items) =>
    items?.is_requested == 0 || items?.is_requested == 1
      ? notRequest.push(items)
      : ""
  );
  console.log(getAssets, "notRequest tool");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const storeData = store?.data?.filter((item) => item?.is_permanent == 1);

  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  // userinfo?.role?.role_name == "Super Admin"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;

  const [updateTool, setupdateTool] = useState(null);
  const HandleEditTool = () => {
    setRegisterTool(true);
  };
  console.log(requestedProject, "in test tools");
  const [amount, setAmount] = useState();
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined;
  });
  console.log(data, "confirmRequestItems");
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  console.log(filteredData(search, category, store_id), "confirmRequestItems");
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <div>
      {SK ? (
        <div className="d-flex justify-content-center mb-3 mt-3">
          <div className="create-project-btn" onClick={HandleItem}>
            <AddIcon
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#186569",
                },
              }}
            />
            Register Tool
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="inventory-header-container">
        <InventoryReport
          data={filteredData(search, category, store_id)}
          type="Asset Tool Lists"
          is_tool={true}
        />

        <Tooltip title="Batch Request">
          <Inventory2Icon
            sx={{ color: "#064b4f" }}
            onClick={HandleConfirmRequest}
          />
        </Tooltip>
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {storeData?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>

        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>

        <div className="items-counter">
          <div className="text-center">
            {filteredData(search, category, store_id)?.length}
          </div>
          <div>Items Count</div>
        </div>
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <>
          <table style={{ width: "100%" }}>
            <thead className="stock-table-header">
              <tr>
                <th
                  className="stock-table-header-text"
                  style={{ width: "10%" }}
                ></th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "17%" }}
                >
                  Item Name
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "14%" }}
                >
                  Model
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "10%" }}
                >
                  Type
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "15%" }}
                >
                  Store
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "10%" }}
                >
                  Quantity
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "11%" }}
                >
                  Status
                </th>
                <th
                  className="stock-table-header-text"
                  style={{ width: "20%" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((tool, index) => (
                <tr className="stock-table-row">
                  <td
                    className=""
                    onClick={() => {
                      HandleFragment(tool);
                      setupdateTool(null);
                    }}
                  >
                    <img
                      className="stock-img"
                      src={`${IMG_BASE_URL}${tool?.image}`}
                      alt=""
                    />
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {tool?.item_name}
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {tool?.model === null ? "-" : tool?.model}
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {tool?.tool_type_id === null
                      ? "-"
                      : tool?.tool_type?.tool_type}
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {" "}
                    {tool?.store_id === null ? "-" : tool?.store?.store_name}
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {`${tool?.amount === null ? "-" : tool?.amount} ${
                      tool?.uom_id == null ? "-" : tool?.uom?.name
                    }`}
                  </td>
                  <td
                    className="text-start"
                    onClick={() => {
                      HandleFragment(tool);
                    }}
                  >
                    {tool?.status_id == null ? "-" : tool?.status?.status_name}
                  </td>
                  <td className="text-start">
                    {tool?.amount != 0 ? (
                      <>
                        {tool?.store?.is_permanent == 1 ? (
                          <div className="d-grid gap-3">
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleRequest();
                                setDialog(tool);
                              }}
                            >
                              Request
                            </button>
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                HandleEditTool(true);
                                setupdateTool(tool);
                              }}
                              sx={{
                                color: "#52FFAC",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#52FFAC",
                                  border: "1px solid #52FFAC",
                                  borderRadius: 25,
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {PM ? (
                          tool?.is_group_item == 1 ? (
                            <div className="d-grid gap-3">
                              <button
                                className="form-save"
                                onClick={() => {
                                  HandleRequest();
                                  setDialog(tool);
                                }}
                              >
                                Request
                              </button>
                            </div>
                          ) : (
                            ""
                          )
                        ) : SK ? (
                          tool?.created_by == userinfo?.id ? (
                            <div className="d-flex justify-content-center gap-2 align-items-center">
                              <ModeEditOutlineOutlinedIcon
                                onClick={() => {
                                  HandleEditTool(true);
                                  setupdateTool(tool);
                                }}
                                sx={{
                                  color: "#52FFAC",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#52FFAC",
                                    border: "1px solid #52FFAC",
                                    borderRadius: 25,
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      `${tool?.item_name} has no amount`
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data?.length >= 2 ? (
            <div className="inventory-header-container">
              <button className="form-save " onClick={HandleConfirmRequest}>
                Batch Request
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <NoRecord />
      )}

      {registerTool ? (
        <RegisterTool
          modal={setRegisterTool}
          data={updateTool}
          isFixed={true}
        />
      ) : confirmRequest ? (
        <ConfirmRequest
          assets={notRequest}
          data={data}
          modal={setConfirmRequest}
          isTools={false}
          isAsset={true}
        />
      ) : (
        ""
      )}
      {registerTool ? (
        <EditAssets modal={setRegisterTool} data={updateTool} />
      ) : (
        ""
      )}
      {confirm ? (
        <StockDialog
          setDialog={setConfirm}
          projectId={setRequestedProject}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          name="request"
          action={(e) => HandleToolRequest(e, dialog?.id)}
          setQuantity={setQuantity}
        />
      ) : (
        ""
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
