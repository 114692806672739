import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { useViewSupplierCreditHistoryQuery } from "./../../../../features/SCMApi";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const SupplierCreditReport = ({
  data,
  type,
  is_tool,
  setCredit,
  credit_id,
}) => {
  const { data: paymentHistory } = useViewSupplierCreditHistoryQuery(
    credit_id?.id
  );
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = `${credit_id?.order_name}-${credit_id?.project_name}`;
  let title3 = "Supplier Credit By Order Level";
  let count = 0;
  console.log(paymentHistory, "Datasssss");
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(credit_id?.supplier_name);

    // Add title cell above the table headers
    worksheet.mergeCells("A1:G1");
    worksheet.mergeCells("A2:G2");
    worksheet.mergeCells("A3:G3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Times New Roman",
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell2.font = {
      name: "Times New Roman",
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",

      "PAYMENT MODE",
      "CREDIT AMOUNT",
      "PAID AMOUNT",
      "REMAINING AMOUNT ",
      "CURRENCY",
      "DESCRIPTION",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30;
    worksheet.getColumn(11).width = 30;
    worksheet.getColumn(12).width = 35;
    // Set the header row to bold
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(4);

    // Populate the table with data from the map
    paymentHistory?.data?.map((items) => {
      const dataRow = worksheet?.addRow([
        (count += 1),
        items?.payment_mode == null ? "-" : items?.payment_mode,
        items?.credit_amount == null ? "-" : items?.credit_amount,
        items?.paid_amount == null ? "-" : items?.paid_amount,
        Number(items?.credit_amount - items?.paid_amount)?.toLocaleString(),
        items?.currency_id != null ? items?.currency_id : "-",
        items?.description == null ? "-" : items?.description,
      ]);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    // const FooterRow = worksheet.addRow([
    //   " ",
    //   "Total",
    //   PlannedTotalCost,
    //   " ",
    //   " ",
    //   ActualTotalCost,
    //   " ",
    //   " ",
    //   " ",
    //   " ",
    //   ]);
    //   FooterRow.eachCell((cell) => {
    //     cell.fill = {
    //       type: "pattern",
    //       pattern: "solid",
    //       fgColor: { argb: "BFBFBF" },
    //     };
    //     cell.font = {
    //       color: { argb: "000000" },
    //       bold: true,
    //       size: 12,
    //       name: "Times New Roman",
    //     };
    //     cell.border = {
    //       top: { style: "thin", color: { argb: "000000" } },
    //       left: { style: "thin", color: { argb: "000000" } },
    //       bottom: { style: "thin", color: { argb: "000000" } },
    //       right: { style: "thin", color: { argb: "000000" } },
    //     };
    //     cell.alignment = {
    //       vertical: "middle",
    //       horizontal: "right",
    //       wrapText: true,
    //     };
    //   });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, type);
  };

  return (
    <IconButton
      variant="text"
      size="small"
      sx={{
        backgroundColor: "#eff2f3",
        color: "#064B4F",
        "&:hover": {
          backgroundColor: "#186569",
          color: "#fff",
        },
      }}
      onClick={(e) => {
        setCredit(data);
        downloadExcel();
      }}
    >
      <DownloadIcon sx={{ fontSize: "14px" }} />
    </IconButton>
    // <button onClick={downloadExcel} className="add-client-representative">
    //   <svg
    //     width="16"
    //     height="16"
    //     viewBox="0 0 16 16"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
    //       fill="white"
    //     />
    //   </svg>
    // </button>
  );
};

export default SupplierCreditReport;
