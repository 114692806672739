import React from "react";
import ExcelJS from "exceljs";
import { useGetPaymentTrackingForProjectReportQuery } from "../../features/SCMApi";

function FinancialReport({ project_id, project }) {
  const downloadExcel = (buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `IE NETWORKS-${project?.project_name}-PA Summary `;
    document.body.appendChild(link);
    link.click();
  };

  const { data: forexNeedData } =
    useGetPaymentTrackingForProjectReportQuery(project_id);
  const mapData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SUMMARY");
    const titleCell = worksheet.getCell("A1");
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCell.font = { size: 12, name: "Times New Roman", bold: true };
    titleCell.value = `SCSM FINANCE  for ${project?.project_name} Summary`;
    titleCell.alignment = { horizontal: "center" };
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };

    worksheet.mergeCells("A1:M1");
    // Make API call to get data

    // Create header row
    const headerRow = worksheet.addRow([
      "No.",
      "Item Description",
      "Supplier Name",
      "Total Cost Payable",
      "Payment advance",
      "Total Cost (USD) paid",
      "Payment Mode",
      "Bank",
      "Date",
      "Currency",
      "Action Plan",
      "PFO",
    ]);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    let No = 0;

    let PFO;
    project.users?.map((items) =>
      items?.pivot?.role_id == "0e324e94-6f2c-415c-9a46-a359a96fea7f"
        ? (PFO = items?.name)
        : ""
    );
    console.log(PFO, "PFO");
    forexNeedData?.data?.forEach((items, index) => {
      const row = worksheet.addRow([
        (No += 1),
        items?.order_name,
        items?.distributor_name,
        Number(items?.total_amount).toLocaleString(),
        `${items.advance_percentage}%`,
        Number(items?.paid_amount).toLocaleString(),
        items?.payment_mode,
        items?.bank_name == null ? "NA" : items?.bank_name,
        items?.receipt_date == null ? "NA" : items?.receipt_date,
        "USD",
        "",
        PFO,
      ]);
      row.font = { size: 12, name: "Times New Roman" };
      row.alignment = { vertical: "middle", horizontal: "center" };

      row.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });
    worksheet.columns.forEach((column, index) => {
      column.width = 20;
      column.height = 20;
      worksheet.getRow(index).height = 50;
    });
    // const lastRow = worksheet.lastRow;
    // const titleRow = worksheet.addRow();
    // console.log(titleRow, "LastRow");

    // titleRow.getCell(2).value = "Summary";
    // titleRow.getCell(2).font = { bold: true };
    // titleRow.getCell(2).alignment = { horizontal: "center" };
    worksheet.columns[0].width = 5;
    worksheet.columns[1].width = 30;
    worksheet.getRow(1).height = 30;
    workbook.xlsx.writeBuffer().then((buffer) => {
      downloadExcel(buffer);
    });
  };

  return (
    <div>
      <button onClick={mapData} className="report-download">
        Financial Report
      </button>
    </div>
  );
}

export default FinancialReport;
