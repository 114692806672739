import React from "react";
import Tree from "react-d3-tree";
import orgChartJson from "../common/org-chart.json";
import { useCenteredTree } from "../common/helpers";
import "./mapping.style.css";
import { useGetSupplierMappingQuery } from "../../features/SCMApi";

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

// Here we're using `renderCustomNodeElement` render a component that uses
// both SVG and HTML tags side-by-side.
// This is made possible by `foreignObject`, which wraps the HTML tags to
// allow for them to be injected into the SVG namespace.
const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}) => (
  <g>
    <circle r={15}></circle>
    <foreignObject {...foreignObjectProps}>
      <div
        style={{
          border: "1px solid black",
          backgroundColor: "#dedede",
        }}
      >
        <p style={{ textAlign: "center" }}>{nodeDatum.name}</p>
      </div>
    </foreignObject>
  </g>
);

export default function Mapping(props) {
  const { data: maps } = useGetSupplierMappingQuery(props?.id);
  const MapArray = [maps];
  let mainMap = maps && maps;
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 10 };
  console.log(maps, "maps");
  return (
    <div style={{ width: "65em", height: "40em" }} ref={containerRef}>
      {maps && maps ? (
        <Tree
          data={mainMap}
          translate={translate}
          nodeSize={nodeSize}
          orientation="horizontal"
        />
      ) : (
        ""
      )}
    </div>
  );
}
