import React, { useState } from "react";
import Settingsidebar from "../../../pages/Settings/SettingIndex";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CreatePermission from "../SettingModal/CreatePermission";
import CheckIcon from "@mui/icons-material/Check";
import {
  useGetPermissionsQuery,
  useGetProjectRolesQuery,
  useGetRolesQuery,
} from "../../../features/SCMApi";
import { Pagination, Tooltip } from "@mui/material";
import axios from "axios";
import RoleDetailModal from "../SettingModal/RoleDetailModal";
import usePagination from "../../Pagination/Pagination";
import { API_BASE_URL } from "../../../api/endPoint";
import Role from "./Role";
export default function ProjectPermission() {
  const [createPermission, setCreatePermission] = useState(false);
  function CreatepermissionHandler() {
    setCreatePermission(true);
  }
  const [Messages, setMessages] = useState(null);
  const [role_id, setRole] = useState(null);
  const [roleDetail, setRoleDetail] = useState();
  const [ViewRoleModal, setViewRoleModal] = useState(false);
  const [permissions, setPermission] = useState([]);
  const { data: roles } = useGetProjectRolesQuery();
  const { data: permission } = useGetPermissionsQuery();
  let [page, setPage] = useState(1);
  const PER_PAGE = 8;
  const count = Math.ceil(roles?.data?.length / PER_PAGE);
  const _DATA = usePagination(roles?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA?.jump(p);
  };
  console.log(roles, "roles?.data");
  let permissionform = {
    role_id,
    permissions,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(`${API_BASE_URL}/assign-permission`, permissionform, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response, "axiosssssssss");
        window.location.reload(true);
        setMessages("Permission assigned successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //   });
  };
  const handleChangepermission = (e, i) => {
    const value = e.target.value;
    setPermission((prev) =>
      permissions.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };
  function handleViewRoleModal() {
    setViewRoleModal(true);
  }

  return (
    <>
      <form className="" onSubmit={HandleSubmit}>
        <div className="d-flex justify-content-center mt-2">
          <select
            name=""
            id=""
            className="setting-select-option"
            required
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="" disabled selected hidden>
              Select Roles
            </option>
            {roles?.data?.map((items) => (
              <option value={items?.id}>{items?.role_name}</option>
            ))}
          </select>
        </div>
        {permission?.data?.map((permission) => (
          <Accordion className="px-4 mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="h5">
                {permission?.resource_name}
              </Accordion.Header>
              <Accordion.Body className="ms-4">
                {permission?.permissions?.length !== 0 ? (
                  permission?.permissions?.map((items, index) => (
                    <label className="d-flex gap-2">
                      <input
                        name={items.permission_name}
                        type="checkbox"
                        value={items?.id}
                        onClick={handleChangepermission}
                      />
                      <span className="h5-pri">{items?.permission_name}</span>
                    </label>
                  ))
                ) : (
                  <p className="text-center prg">
                    There is no Permissions for{" "}
                    <strong>{permission?.resource_name}</strong> yet
                  </p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
        <div className="d-flex justify-content-center mt-3">
          <button className="create-project-btn" type="submit">
            Assign Role
          </button>
        </div>
      </form>

      {createPermission ? (
        <CreatePermission
          modal={setCreatePermission}
          setMessages={setMessages}
        />
      ) : (
        <></>
      )}
      {ViewRoleModal ? (
        <RoleDetailModal
          ToggleModal={setViewRoleModal}
          setMessages={setMessages}
          roledetail={roleDetail}
        />
      ) : (
        <></>
      )}
    </>
  );
}
