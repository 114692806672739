import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import {
  useCreateMissedItemMutation,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { Tooltip } from "@mui/material";

export default function CreateMissedItem(props) {
  const [createMissed] = useCreateMissedItemMutation();
  const { data: loggedUser } = useGetUserInfoQuery();
  const { ProjectId } = useParams();
  const { batchId } = useParams();
  function IDGenerator() {
    let idGenerator = "abcdefghijklmnopqrstuvwxyz0123456789";
    let idGeneratorLength = idGenerator.length;
    let generatedId = "";
    for (let s = 0; s <= 10; s++) {
      generatedId += idGenerator.charAt(
        Math.floor(Math.random() * idGeneratorLength)
      );
    }
    return generatedId;
  }

  let itemsMissed = [];
  props?.items?.map((item, index) =>
    itemsMissed.push({
      ...item,
      // id: IDGenerator(),
    })
  );
  console.log(itemsMissed, "this is missed item props passed data");

  // itemsMissed = [
  //   ...itemsMissed?.filter(
  //     (item) =>
  //       item?.remaining_quantity==0 &&
  //       item?.delivery_group == false &&
  //       item?.missed_item == false &&
  //       item?.rma == false
  //   ),
  // ];

   itemsMissed = [
    ...itemsMissed?.filter(
      (item) =>
        item?.remaining_quantity !==0 &&
        // item?.delivery_group == true &&
        item?.missed_item == false &&
        item?.rma == false
    ),
  ];
  

  // function getSelectedMissedItems(className) {
  //   const checkbox = document.getElementsByClassName(className);
  //   const checked = [];
  //   Object.keys(checkbox).map((box) =>
  //     checkbox[box].checked == true ? checked.push(checkbox[box].id) : null
  //   );
  //   let missedItemsList = [
  //     items?.filter((items) => checked.includes(items?.id)),
  //   ];
  //   let missedItems = missedItemsList[0]?.map((item) => {
  //     return {
  //       delivery_group: item?.delivery_group,
  //       hs_code: item?.hs_code,
  //       id: item?.id,
  //       item_description: item?.item_description,
  //       item_part_number: item?.item_part_number,
  //       missed_item: true,
  //       po_group: item?.po_group,
  //       qty: item?.qty,
  //       rma: item?.rma,
  //       total_price: item?.total_price,
  //       unit_price: item?.unit_price,
  //       uom: item?.uom,
  //     };
  //   });

  //   console.log(
  //     missedItemsList[0],
  //     "1",
  //     missedItems,
  //     items,
  //     "items to send for missed items of data 101"
  //   );

  //   return missedItems;

  //   // console.log(checkedItem, "items to send for missed items of data 3");
  // }
  const [missedItems, setMissedItems] = useState([]);
  let allMissedItems = [];
  itemsMissed?.map((items) => allMissedItems?.push(items?.id));
  const handleChangeBatch = (e, items) => {
    const { checked } = e.target;

    if (items == "all") {
      // Select All option is checked

      if (checked) {
        // Add all permissions to the permissions array
        setMissedItems(allMissedItems);
      } else {
        // Uncheck Select All and remove all permissions from the array
        setMissedItems([]);
      }
    } else {
      if (checked) {
        // Add the permission to the permissions array
        setMissedItems((prevMissedItem) => [...prevMissedItem, items?.id]);
      } else {
        // Remove the permission from the permissions array
        setMissedItems((prevMissedItem) =>
          prevMissedItem.filter((id) => id !== items?.id)
        );
      }
    }
  };
  // const items = SelectedOrderList.map((item) => batchItems.includes(item.id));
  const items = itemsMissed?.map((item) => {
    return {
      delivery_group: item?.delivery_group,
      hs_code: item?.hs_code,
      id: item?.id,
      item_description: item?.item_description,
      item_part_number: item?.item_part_number,
      missed_item: missedItems.includes(item.id) ? true : false,
      po_group: item?.po_group,
      qty: item?.qty,
      rma: item?.rma,
      total_price: item?.total_price,
      unit_price: item?.unit_price,
      uom: item?.uom,
    };
  });
  console.log(items, "allMissedItems");
  function HandleCancel() {
    props.ToggleModal(false);
    console.log("closepeeee");
  }
  function handleSubmit(e, className) {
    e.preventDefault();

    const createMissedForm = {
      project_id: ProjectId,
      batch_id: batchId,
      items: items,
      created_by: loggedUser?.id,
    };
    console.log(
      // getSelectedMissedItems(className),
      "this is the data for missed item"
    );
    // axios({
    //   method: "post",
    //   url: `${API_BASE_URL}/scm/missed-item/missed-items`,
    //   data: createMissedForm,
    //   headers: { "Content-type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success
    //     console.log(
    //       response,
    //       createMissedForm,
    //       "response from backend api for missed item res"
    //     );
    //     HandleCancel(e);
    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log(
    //       response,
    //       createMissedForm,
    //       "response from backend api for missed item err"
    //     );
    //   });

    // console.log(createMissedForm, "createMissedForm");

    createMissed(createMissedForm)
      .unwrap()
      .then((response) => {
        console.log(
          response,
          createMissedForm,
          "response from backend api for missed item"
        );
        HandleCancel();
      })
      .then((error) => {
        console.log(error);
      });
    console.log(createMissedForm, "createMissedForm");
  }
  console.log(itemsMissed, "missed items");
  return (
    <div>
      {itemsMissed?.length != 0 ? (
        <label className="d-flex justify-content-start mb-2 ms-5 gap-2">
          <input
            type="checkbox"
            onChange={(e) => handleChangeBatch(e, "all")}
          />
          <span className="prg-pri">Select All</span>
        </label>
      ) : (
        ""
      )}
      <form
        className="form-container "
        onSubmit={(e) => handleSubmit(e, "create-missed-items")}
      >
        <div className="form-card-container">
          <div className="missed-card-information">
            <div className="card-center">
              <div className="ms-5">
                <div className="supplier-input-labels">
                  {itemsMissed?.map((item) => (
                    <div className="text-center">
                      <label className="d-flex gap-2 align-items-center justify-content-start ">
                        <input
                          type="checkbox"
                          checked={missedItems?.includes(item?.id)}
                          onChange={(e) => handleChangeBatch(e, item)}
                        />
                        <Tooltip title={item?.item_description}>
                          <div className="prg text-start">
                            {item?.item_description?.length >= 150
                              ? item?.item_description?.slice(0, 150) + "..."
                              : item?.item_description}
                          </div>
                        </Tooltip>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center gap-3 mt-2">
          <button
            type="cancel"
            className="form-cancel"
            onClick={(e) => HandleCancel(e)}
          >
            {" "}
            Cancel
          </button>
          <button type="submit" className="form-save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
