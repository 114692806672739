import axios from "axios";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../api/endPoint";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetToolRequestedQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
} from "../../../../features/SCMApi";
import NavBar from "../../../../Layout/NavBar";
import StockIndex from "../../../../pages/Stocks/StockIndex";
import RequestToolItemDescription from "./RequestToolItemDescription";

export default function RequestoTolDetail(props) {
  const params = useParams();
  const { data: ToolDetail } = useGetToolRequestedQuery(params?.id);
  console.log(ToolDetail, "ToolDetailssssss");

  return (
    <StockIndex>
      <RequestToolItemDescription
        setFragment={props?.setFragment}
        data={ToolDetail?.data}
      />
    </StockIndex>
  );
}
