import React, { useState } from "react";
import axios from "axios";
import {
  useCreateOrderMutation,
  useCreateOrderPriceComparisonMutation,
  useGetBoqtypesQuery,
  useGetFinalPriceByProjectQuery,
  useGetManufacturersQuery,
  useGetMillstonesQuery,
  useGetProductsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetSuppliersOrdersQuery,
  useGetSuppliersQuery,
} from "../../../features/SCMApi";
import MultiSelect from "../../common/MultiSelect";
import { API_BASE_URL } from "../../../api/endPoint";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useParams, useLocation } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import FileSaver from "file-saver";
import PiTemplate from "../../../assets/Template/IE-Sample PI.xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NoRecord from "../../common/NoRecord";
import PriceComparisonAccordion from "./PriceComparisonAccordion";
import SourcingIndex from "../../../pages/Sourcing/SourcingIndex";
import PCApproval from "./PCApproval";
import SuccessModal from "../../common/successModal";

export default function CreatePriceComparisonOrder(props) {
  const params = useParams();
  const location = useLocation();
  const [count, setCount] = useState([0]);
  const [order_type, setOrderType] = useState();
  const [service_type, setServiceType] = useState();
  const [supplier_id, setSupplier] = useState([]);
  const [bill_to, setbill] = useState();
  const [project_id, setProject] = useState();
  const [solution_id, setSolution] = useState();
  const [milestone_id, setMilestone] = useState();
  const [description, setDescription] = useState();
  // const [pi, setPi] = useState();
  const solutionsList = [];
  const suppliersList = [];
  const { data: projects } = useGetProjectsQuery();
  const { data: boqType } = useGetBoqtypesQuery();
  const [Manufacturer, setManufacture] = useState();
  const performa_invoice = [];
  const manufacturer_id = [];
  const [open, setOpen] = React.useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const { data: projectid } = useGetProjectQuery(params.id);
  const { data: priceComparison } = useGetFinalPriceByProjectQuery(params.id);
  let finalPriceData = "";
  priceComparison?.data?.map((items) => {
    if (items?.id == params?.f_id) {
      finalPriceData = items;
    }
  });
  console.log(priceComparison, "111111");
  console.log(finalPriceData, "111112");
  let bypass = projectid?.data?.product_boq?.length == 0 ? true : false;
  const [is_offshore, setOffshore] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    data: Solutions,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetProductsQuery();
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  const { data: Manufacturers } = useGetManufacturersQuery();
  const [uploading, setUploading] = useState(false);
  Solutions?.data?.map((items) =>
    solutionsList.push({
      value: items?.id,
      label: items?.abbrivation,
    })
  );
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );
  const comparedSuppliers = Array?.from(
    new Set(finalPriceData?.final_list_price?.map((item) => item.supplier))
  )?.map((id) =>
    finalPriceData?.final_list_price?.find((item) => item.supplier === id)
  );
  console.log(comparedSuppliers, "comparedSuppliers");
  const supplierData = finalPriceData?.final_list_price?.filter(
    (item) => item.supplier === supplier_id
  );

  const [createOrder, errorsorder] = useCreateOrderPriceComparisonMutation();
  const { data: AAAAA } = useCreateOrderPriceComparisonMutation();

  console.log(AAAAA, "@@@###$$$");
  console.log(finalPriceData, "supplierData");
  console.log(supplierData, "supplier Data");
  function handleChange(e) {
    // setBoq(e?.target?.files?.[0]);
  }
  const param = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isStored, setIsStored] = useState(null);
  const is_budget = order_type == "Service" ? 1 : 0;

  const form = {
    id: param?.f_id,
    supplier_id,
    pi: supplierData,
    bill_to,
    order_type: order_type,
    service_type: service_type,
    project_id: param.id,
    solution_id: solution_id,
    milestone_id: milestone_id,
    description: description,
    final_list_price: supplierData,
    is_offshore,
    bypass_pa: bypass,
    is_budget,
  };
  console.log(form, "Hhahahah");
  function handleSubmit(e) {
    setLoading(true);
    setUploading(true);
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/scm/sourcing/pc-create-order/${params?.f_id}`,
        form,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setOpen(true);
        setLoading(false);
        setUploading(false);
        console.log(res, "response for create order in compared price");
      })
      .catch((err) => {
        setLoading(false);
        setUploading(false);
        console.log(err, "response for create order in compared price error");
      });
  }

  if (isSuccess) console.log(Solutions?.data, "ola");
  const { data: milestone } = useGetMillstonesQuery();

  console.log(projectid?.data?.milestone_amount, "project milestone");
  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  // console.log(milestone, "milestone");
  const HandleCancel = () => {
    props.modal(false);
  };
  console.log(bypass, "bypass");
  const [checked, setChecked] = useState(true);
  const CheckBoxHandel = () => {
    setChecked(true);
  };
  const CheckBoxHandelonShore = () => {
    setOffshore(!is_offshore);
  };
  const handleDownload = () => {
    FileSaver.saveAs(PiTemplate, "PI Template.xlsx");
    console.log("download boq");
  };
  const [loading, setLoading] = useState(false);

  // Use a Set and map to filter out objects with duplicate ids
  console.log(finalPriceData?.id, "finalPriceData?.id");
  return (
    <SourcingIndex
      name={"Final Price"}
      title={"Price Comparison"}
      navlink={`/sourcing/pricecomparison/${params?.id}`}
    >
      <div id="" className="" onClick={HandleCancel}>
        <div className="" onClick={(e) => e.stopPropagation()}>
          {projectid?.data?.product_boq?.length == 0 ? (
            <div className="lc-warning bg-danger mb-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  fill-opacity="0.01"
                ></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                  fill="white"
                ></path>
              </svg>
              <div>This project doesn't have a BoQ</div>
            </div>
          ) : (
            ""
          )}

          {uploading ? (
            <div className="inputs-group " style={{ width: "55%" }}>
              <div className="input-label">Uploading</div>
              <div class="loader"></div>
            </div>
          ) : (
            <></>
          )}

          <PCApproval id={finalPriceData?.id && finalPriceData?.id} />
          <div className="row align-items-baseline">
            <div
              className={
                finalPriceData?.is_approved == 1 ? "col-md-6" : "col-md-12"
              }
            >
              {supplier_id != "" ? (
                <PriceComparisonAccordion
                  name={"Selected Items"}
                  datas={supplierData && supplierData}
                />
              ) : (
                ""
              )}
              <PriceComparisonAccordion
                name={"Final Price"}
                compared_data={
                  finalPriceData?.compared_data && finalPriceData?.compared_data
                }
                datas={
                  finalPriceData?.final_list_price &&
                  finalPriceData?.final_list_price
                }
              />
            </div>
            {finalPriceData?.is_approved == 1 ? (
              <div className="col-md-6 bg-white">
                <div className="h5 p-2">Create Order</div>
                <div className="divider"></div>
                <form
                  className="create-order-form mt-4 "
                  enctype="multipart/form-data"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="order-supplier">
                    <div className="d-flex  justify-content-center gap-2 px-2">
                      <input
                        type="checkbox"
                        className="stock-checkmark"
                        onChange={CheckBoxHandelonShore}
                      />
                      <span className="h6">Off Shore</span>
                    </div>
                    {/* {projectid?.data?.product_boq?.length == 0 ? (
                      <div   className="d-flex justify-content-center mb-3">
                        <label className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="checkmark"
                            checked={checked}
                            onChange={CheckBoxHandel}
                          />
                          <span className="h5">Bypass PA</span>
                        </label>
                      </div>
                      
                    ) : (
                      ""
                    )} */}

                    <div className="input-groups">
                      <div className="input-label">Supplier</div>
                      <select
                        name=""
                        id=""
                        className="select-option-create-order "
                        onChange={(e) => setSupplier(e.target.value)}
                      >
                        {" "}
                        <option selected="true" disabled="disabled">
                          Choose Supplier
                        </option>
                        {comparedSuppliers?.map((items) => (
                          <option value={items.supplier}>
                            {items?.SupplierName}
                          </option>
                        ))}
                      </select>
                      <div className="input-label">Bill to</div>
                      <select
                        name=""
                        id=""
                        className="select-option-create-order "
                        onChange={(e) => setbill(e.target.value)}
                      >
                        {" "}
                        <option selected="true" disabled="disabled">
                          Choose Bill To
                        </option>
                        {suppliersList?.map((items) => (
                          <option value={items.value}>{items?.label}</option>
                        ))}
                      </select>
                      <div className="input-label">Order Type</div>
                      <select
                        name=""
                        id=""
                        className="select-option-create-order "
                        onChange={(e) => setOrderType(e.target.value)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose Order Type
                        </option>

                        <option value="Training">Training</option>
                        <option value="Service">Service</option>
                        <option value="Product">Product</option>
                      </select>
                      {order_type == "Service" ? (
                        <>
                          <div className="input-label">Service Type</div>
                          <select
                            name=""
                            id=""
                            className="select-option-create-order "
                            onChange={(e) => setServiceType(e.target.value)}
                          >
                            <option selected="true" disabled="disabled">
                              Choose Service Type
                            </option>
                            <option value="Material">Material</option>
                            <option value="Training">Training</option>
                          </select>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="input-label">Solutions</div>
                      <MultiSelect setters={setSolution} list={solutionsList} />
                      <div className="input-label">Millstone</div>

                      <select
                        name=""
                        id=""
                        className="select-option-create-order "
                        onChange={(e) => setMilestone(e.target.value)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose Millstone
                        </option>
                        {milestonepush?.map((items) => (
                          <option value={items.id}>{items?.name}</option>
                        ))}
                      </select>
                      <div className="input-label">Description</div>
                      <textarea
                        type="text"
                        className="sourcing-text-input-textarea"
                        rows={4}
                        cols={50}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  {projectid?.data?.product_boq?.length == 0 ? (
                    <div class="form-buttons mb-3">
                      <button
                        type="cancel"
                        class="form-cancel"
                        onClick={HandleCancel}
                      >
                        Cancel
                      </button>
                      <button type="submit" class="form-save">
                        {loading ? "Loading....." : "Save With out PA"}
                      </button>
                    </div>
                  ) : (
                    <div class="form-buttons ">
                      <button
                        type="cancel"
                        class="form-cancel"
                        onClick={HandleCancel}
                      >
                        {" "}
                        Cancel
                      </button>
                      <button type="submit" class="form-save">
                        {loading ? "Loading....." : "Save"}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <SuccessModal open={open} isOrder={true} />
    </SourcingIndex>
  );
}
