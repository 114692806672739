import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreateStatusForexMutation,
  useGetStatusForexQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
import { date } from "../../dateFormator";
import { Form } from "react-bootstrap";

export default function RemarkForex({ id }) {
  const [statuses, setStatus] = useState(null);
  const params = useParams();
  const { data: getStatus } = useGetStatusForexQuery(
    params?.id ? params?.id : id
  );
  console.log(getStatus, "status != null");
  let count = 0;
  const [createStatus] = useCreateStatusForexMutation();
  let form = {
    forex_id: params?.id ? params?.id : id,
    statuses,
  };
  function handleSubmit(e) {
    console.log("testing", Form)
    e.preventDefault();
    createStatus(form)
      .unwrap()
      .then((response) => {
        setStatus(null);
        console.log(response, "Response for created  from backend");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error, "Response for created  from backend err");
      });

    // axios
    //   .post(`${API_BASE_URL}/scm/payment/forex-status`, form)
    //   .then((response) => {
    //     console.log(response?.data, "response from back end");
    //   })
    //   .catch((error) => {
    //     console.log(error, "error from backend");
    //   });
  }
  return (
    <div className="forex-remark">
      <div className="text-center h5-pri p-2">Delinquent Status</div>
      <div className="remark-container px-3 py-2">
        <div className="d-grid justify-content-between gap-1">
          <div className="prg text-start">Status</div>
          <textarea
            type="text"
            style={{ width: "100%" }}
            className="sourcing-text-input-textarea"
            rows={4}
            cols={50}
            onChange={(e) => setStatus(e.target.value)}
          ></textarea>
          {statuses != null && statuses != "" ? (
            <button
              type="submit"
              class="form-save"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="">
          <span className="prg text-start">Histories</span>
          <div className="forex-status-history">
            {getStatus?.data?.length != 0 ? (
              <>
                {getStatus?.data.map((items, index) => (
                  <div className="d-grid gap-2">
                    <div className="d-flex gap-1">
                      <div className="prg-pri">{(count += 1)}.</div>
                      <div className="gird gap-1 mb-2">
                        <div className="prg">{items?.statuses}</div>
                        <div className="module-label fst-italic fw-bold">
                          {date(items?.created_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoRecord />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
