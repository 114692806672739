import React, { useState } from "react";
import {
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetProductsQuery,
  useGetSupplierOriginsQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { useParams } from "react-router-dom";
import ExistingGroups from "./ExistingGroups";
import { Tab, Tabs } from "react-bootstrap";
import CreateGroup from "./CreateGroup";
export default function GroupItemsModal(props) {
  const [show, setShow] = useState(false);
  const [showgroup, setGroup] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    data: origin,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetSupplierOriginsQuery();
  const {
    data: manufacturers,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetManufacturersQuery();
  const { data: solutions } = useGetProductsQuery();
  const { data: currencies, isErrorcurrency } = useGetCurrenciesQuery();
  console.log(solutions, "solutions");
  const manufaturerList = [];
  manufacturers?.data?.map((items) =>
    manufaturerList.push({
      value: items?.id,
      label: items?.manufacturer_name,
    })
  );
  const update_id = useParams();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    axios
      .put(
        `${API_BASE_URL}/scm/supplier/bankdetails/${props.value.id}`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response, "axiosssssssss");
        console.log(bank_currency, "bank currency");
        props.modal(false);
        window.location.reload(true);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
        console.log(manufacturer, "manuerr");
        console.log(supplier, "supperrr");
        console.log(freightforwarder, "freree");
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //   });
  };

  // bank detail
  const [bank_name, setBank_name] = useState(props?.value?.bank_name);
  const [account_number, setAccount] = useState(props?.value?.account_number);
  const [swift_code, setSwift] = useState(props?.value?.swift_code);
  const [iban, setIban] = useState(props?.value?.iban);
  const [bank_country, setBankcountry] = useState(props?.value?.bank_country);
  const [createName, setCreateName] = useState("Create Group");
  const [styleexisting, setStyleExisting] = useState("");
  const [bank_currency, setBankcurrency] = useState(
    props?.value?.bank_currency
  );
  const manufacturer = props?.value?.manufacturer_id;
  const supplier = props?.value?.supplier_id;
  const freightforwarder = props?.value?.frieght_forwarder_id;
  // contact person

  let params = {
    manufacturer_id: manufacturer == null ? null : update_id.id,
    supplier_id: supplier == null ? null : update_id.id,
    frieght_forwarder_id: freightforwarder == null ? null : update_id.id,
    bank_name,
    swift_code,
    iban,
    bank_country,
    bank_currency,
    account_number,
  };
  function HandleCancel() {
    props.modal(false);
    console.log("closepeeee");
  }
  const HandleName = () => {
    setCreateName("Group Items");
    setGroup(true);
    console.log("jhbsfjhfvdsjh");
  };
  const HandleNameCreate = () => {
    setCreateName("Create Group");
    setGroup(false);
  };

  console.log(props.value, "bank info value");

  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>{createName}</div>
            <div type="cancel" onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <Tabs
            defaultActiveKey="supplier"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex justify-content-center gap-4"
          >
            <Tab eventKey="supplier" title="Create Group">
              <CreateGroup
                poid={props?.po_id}
                modal={HandleCancel}
                setMessages={props?.setMessages}
              />
            </Tab>
            <Tab eventKey="manufacturer" title="Existing Group">
              <ExistingGroups poid={props.po_id} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
