import React, { useState } from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useGetBatchDetailQuery,
  useGetCostBuildUpDetailFinanceQuery,
  useGetCostBuildUpDetailQuery,
  useUpdateCostBuildUpStatusMutation,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { FiDownload } from "react-icons/fi";
import Declaration from "./Declaration";
import CostIeSide from "./CostIeSide";
import AddCostBuild from "./AddCostBuild";
import DoneIcon from "@mui/icons-material/Done";
import AddDeclaration from "./AddDeclaration";
import { DOC_BASE_URL } from "../../../api/endPoint";
export default function CostBuildUpDetail() {
  const params = useParams();
  const { data: detail } = useGetCostBuildUpDetailQuery(params?.id);
  const { data: detailFin } = useGetCostBuildUpDetailFinanceQuery(params?.id);
  const { data: batch } = useGetBatchDetailQuery(params?.id);
  console.log(detail?.tax_document, "detaildetail");
  console.log(detail, "batchbatch");
  console.log(detailFin, "detailFin");
  let cost = detail?.tax_document;
  console.log(batch?.data, "batch");
  console.log(cost, "####");

  const [addCost, setAddCost] = useState(false);
  const [addDeclaration, setAddDeclaration] = useState(false);
  const [total_per_invoice, setTotalPerInvoice] = useState(0);
  const [total_declaration, setTotalDeclaration] = useState(0);
  const HandleCost = () => {
    setAddCost(true);
  };
  let form = {
    id: detailFin?.costBuildUp?.costBuildUp_id,
  };
  const [updateStatus] = useUpdateCostBuildUpStatusMutation();
  const HandleCostStatus = (e) => {
    e.preventDefault();
    updateStatus(form)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK supplier");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error, "erorrrrr");
      });
  };
  return (
    <ForexSideBar title={"Cost BuildUp"}>
      <div className="cost-card">
        <div className="cost-container">
          <div className="d-flex gap-3 align-items-center">
            <span>Custom Clearance</span>
            {detailFin?.costBuildUp?.Status == 0 ||
            detailFin?.costBuildUp?.Status == undefined ? (
              <>
                <IconButton
                  // size="small"
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#FFF",
                    color: "#186569",
                    "&:hover": {
                      backgroundColor: "#186569",
                      color: "#fff",
                    },
                  }}
                  onClick={() => setAddDeclaration(true)}
                >
                  {detail?.data?.cost?.length == 0 ? (
                    <AddIcon style={{ fontSize: 25 }} />
                  ) : (
                    <ModeEditOutlinedIcon style={{ fontSize: 25 }} />
                  )}
                </IconButton>
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${batch?.data[0]?.tax_docs[0]?.final_declaration}`}
                  >
                    <FiDownload size={24} className="text-white" />
                  </a>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex gap-3 align-items-center">
            <div className="">Finance Side</div>
            {detailFin?.costBuildUp?.Status == 0 ||
            detailFin?.costBuildUp?.Status == undefined ? (
              <>
                <IconButton
                  // size="small"
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#FFF",
                    color: "#186569",
                    "&:hover": {
                      backgroundColor: "#186569",
                      color: "#fff",
                    },
                  }}
                  onClick={HandleCost}
                >
                  {detail?.data?.cost?.length == 0 ? (
                    <AddIcon style={{ fontSize: 25 }} />
                  ) : (
                    <ModeEditOutlinedIcon style={{ fontSize: 25 }} />
                  )}
                </IconButton>

                <IconButton
                  // size="small"
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#FFF",
                    color: "#186569",
                    "&:hover": {
                      backgroundColor: "#186569",
                      color: "#fff",
                    },
                  }}
                  onClick={(e) => HandleCostStatus(e)}
                >
                  <DoneIcon style={{ fontSize: 25 }} />
                </IconButton>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="cost-data-container">
          <Declaration cost={cost} setTotalDeclaration={setTotalDeclaration} />
          <div className="line"></div>
          <CostIeSide
            cost={detailFin?.categoryTotals}
            projectId={detailFin?.project_id}
            costBuildUp={detailFin?.costBuildUp}
            setTotalPerInvoice={setTotalPerInvoice}
          />
          <div className="d-flex justify-content-center gap-2 align-items-center">
            <div className="origin-label">Variance </div>
            <div className="cost-value">
              {total_declaration - total_per_invoice
                ? Number(
                    total_declaration - total_per_invoice
                  )?.toLocaleString()
                : 0}
            </div>
          </div>
        </div>
      </div>
      {/* <AddCostBuild
        cost={detail?.data?.cost}
        data={cost}
        ToggleModal={setAddCost}
      /> */}
      {/* <AddDeclaration
        ToggleModal={setAddDeclaration}
        previousData={batch?.data[0]}
      /> */}
      {addCost ? (
        <AddCostBuild
          cost={detail?.data?.cost}
          data={detailFin?.categoryTotals}
          projectId={detailFin?.project_id}
          costBuildUp={detailFin?.costBuildUp}
          ToggleModal={setAddCost}
        />
      ) : addDeclaration ? (
        <AddDeclaration ToggleModal={setAddDeclaration} previousData={cost} />
      ) : (
        ""
      )}
    </ForexSideBar>
  );
}
