import React, { useState, useEffect, useRef } from "react";
import StockIndex from "../Stocks/StockIndex";
import {
  useGetGrnRequestMutation,
  useLCitemTrackngMutation,
} from "../../features/SCMApi";
import DataTable from "./DataTable";
import GRNSidebar from "./GRNSidebar";
// import { CgSearch } from "react-icons/cg";
// import SendToInventoryModal from '../../components/Logistics/DeliveredItem/DeliveredItemModals/SendToInventoryModal';
import AutoClosePopup from "./SuccessMessage/AutoClosePopup ";
import LCTracking from "./LCTracking";
import LCItemTrackingDetail from "./LCItemTrackingDetail";
import SearchAndTabHeader from "./SearchAndTabHeader";
const GRNReportGenerate = ({ items }) => {
  const [getGrnRequest] = useGetGrnRequestMutation();
  const [LCitemTrackng] = useLCitemTrackngMutation();
  const [requestedGrn, setRequestedGrn] = useState(null);
  const [showComponent, setShowComponent] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [prfType, setPrfType] = useState("sourcing");
  const [originalItems, setOriginalItems] = useState([]);
  const [lcItem, setLcItem] = useState([]);
  const [message, setMessage] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [showLCItemDetail, setShowLCItemDetail] = useState(false);
  const [LCItemDetailId, setLCItemDetailId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = () => {
      const response = getGrnRequest()
        .unwrap() // Invoke the generated function to fetch data
        .then((response) => {
          const typeValue = prfType === "sourcing" ? 1 : 0;
          const filterdData = response?.data?.filter(
            (item) => item?.is_onshore === typeValue
          );
          setRequestedGrn(filterdData);
        })
        .catch((error) => {
          setRequestedGrn(null);
          console.log(error, "response from epha");
        });
    };
    fetchData(); // Call the async function within useEffect
  }, [prfType, selectedItem]);

  useEffect(() => {
    const fetchData = () => {
      const response = LCitemTrackng()
        .unwrap() // Invoke the generated function to fetch data
        .then((response) => {
          console.log(response, "response data LcItme tracking");
          setLcItem(response?.data);
        })
        .catch((error) => {
          setLcItem(null);
          console.log(error, "response data LcItme tracking");
        });
    };
    fetchData(); // Call the async function within useEffect
  }, []);
  console.log(prfType, "prftype ahmed");
  const filteredData = requestedGrn?.filter((item) => {
    return (
      item.PRF_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.purchased_by.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.department_or_project
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.requested_by.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <>
      <AutoClosePopup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        type={type}
        message={message}
      />
      <StockIndex>
        <SearchAndTabHeader
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setPrfType={setPrfType}
          prfType={prfType}
          setShowLCItemDetail={setShowLCItemDetail}
        />
        <DataTable
          open={prfType == "sourcing" || prfType == "logistics"}
          setShowComponent={setShowComponent}
          data={filteredData ?? null}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOriginalItems={setOriginalItems}
        />
        <GRNSidebar
          open={showComponent}
          setMessage={setMessage}
          setIsOpen={setIsOpen}
          setType={setType}
          onclose={() => {
            setShowComponent(false);
            setSelectedItem({});
          }}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          originalItems={originalItems?.items}
        />
        <LCTracking
          lcItem={lcItem}
          setShowLCItemDetail={setShowLCItemDetail}
          showLCItemDetail={showLCItemDetail}
          setLCItemDetailId={setLCItemDetailId}
          prfType={prfType}
        />
        {/* <LCItemTrackingDetail
                           open={prfType =='LCByClient' && showLCItemDetail}
                           groupId={LCItemDetailId}
                           /> */}
        {showLCItemDetail && (
          <LCItemTrackingDetail
            open={prfType == "LCByClient" && showLCItemDetail}
            groupId={LCItemDetailId}
          />
        )}
      </StockIndex>
    </>
  );
};

export default GRNReportGenerate;
