import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "96%",
  [theme.breakpoints.between("xs", "sm", "md")]: {
    height: "100%",
  },
  color: theme.palette.text.secondary,
  marginTop: "20px",
  border: "1px solid #AEB8C9",
  overflow: "hidden",
  borderRadius: "6px",
}));
const DeliveredItemDetailOrderList = (props) => {
  const itemsList = props?.data?.items != undefined ? props?.data?.items : [];

  console.log(itemsList, "itemsList");

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8.5}
        xl={8.5}
        xxl={8.5}
        marginBottom={{
          xs: "0px",
          sm: "0px",
          md: "0px",
          lg: "0px",
          xl: "0px",
          xxl: "0px",
        }}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        <Item elevation={0}>
          <div
            className="rmaBatchDetailHeading"
            style={{ paddingBottom: "17px" }}
          >
            {props?.data && props?.data?.batch_name}
          </div>
          <div style={{ border: "1px solid #ececec", margin: "20px" }}>
            <div className="rmaManufactureName">
              {`Delivered items`}
              <hr className="rmaManufacturerUnderline" />
            </div>
            <div className="rmaBatchInfo">
              <div>
                <div className="rmaItemDescription">
                  <ul className="rmaItemDescriptionList">
                    {itemsList.map((item) => (
                      <li>{item?.item_description}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Item>
      </Grid>
    </>
  );
};

export default DeliveredItemDetailOrderList;
