export function totalDates(endDate) {
  const dateStr = endDate;
  const end_date = new Date(dateStr);
  const currentDate = new Date();
  const diffTime = end_date.getTime() - currentDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let countDown = diffDays;
  return countDown;
}

export function date(dates) {
  const dateStr = dates;
  const date = new Date(dateStr);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 24-hour format
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  console.log(formattedDate, "formattedDate"); // Output: "Saturday, March 25, 2023 14:30:00"

  return formattedDate;
}
