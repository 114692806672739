import React, { useState } from "react";
import {
  useAnswerFaqMutation,
  useGetFaqQuestionsQuery,
} from "../../features/SCMApi";
import FileChooser from "../common/FileChooser";

export default function AnswerFaqQuestions(props) {
  const [answerFaq] = useAnswerFaqMutation();
  const { data: faqQuestions } = useGetFaqQuestionsQuery();
  console.log(faqQuestions, "faqQuestions");
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  function handleCancel(e) {}
  function handleSubmit(e) {
    e.preventDefault();
    const faqVideosForm = { question, answer };
    answerFaq(faqVideosForm)
      .then((response) => {
        console.log(response, "RTK");
        // setUploading(false);
        props?.ToggleModal(false);
      })
      .then((error) => {
        // setUploading(false);
        console.log(error, "erorr from backend");
      });
  }
  return (
    <form className="form-container" onSubmit={(e) => handleSubmit(e)}>
      <div className="inputs-group " style={{ width: "55%" }}>
        <div className="input-label">Question</div>
        <select
          required
          type="text"
          name=""
          id=""
          className="file-chooser "
          onChange={(e) => setQuestion(e.target.value)}
        >
          <option selected="false" disabled="disabled">
            Select Question
          </option>
          {faqQuestions?.data?.map((question) => (
            <option value={question?.question}>{question?.question}</option>
          ))}
        </select>
      </div>

      <div className="inputs-group " style={{ width: "55%" }}>
        <div className="input-label">Answer</div>
        <textarea
          rows={5}
          notRequired={false}
          //   state={video}
          //   setters={setVideo}
          onChange={(e) => setAnswer(e.target.value)}
          className="file-chooser"
          id={"videoSelector"}
          style={{ margin: "0px", height: "fit-content" }}
        />
      </div>

      <div className="form-buttons mt-4">
        <button
          type="cancel"
          className="form-cancel"
          onClick={(e) => handleCancel(e)}
        >
          {" "}
          Cancel
        </button>
        <button type="submit" className="form-save">
          Save
        </button>{" "}
      </div>
    </form>
  );
}
