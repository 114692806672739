import React from "react";
import { useState } from "react";
import { useRequestForGrnMutation } from "../../features/SCMApi";
import { useRequestPrfMutation } from "../../features/SCMApi";
export default function RequestGrnModal(props) {
  console.log(props?.data, "this is all data for tools and inventories");
  const [requestForGrn]=useRequestForGrnMutation();
  const [requestPRF] = useRequestPrfMutation();
  let request = {
    po_id: props?.data?.id,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    console.log("request clicked");
    // console.log(prfRequest,"prf request")
    requestPRF(request).unwrap().then((response) => {
        console.log(response, "RTK supplier");
        props.setMessages("PRF Requested successfully");
        props.modal(false);
      })
      .catch((error) => {
        console.log(error, "request error");
      });
  };
  const requestGrn=(requestPrfId)=>{
    console.log(requestPrfId,"requested prf id ");
    requestForGrn(requestPrfId).unwrap()
    .then((response)=>{
            props?.setType('success');
            props?.setMessage("successfully requested");
            props?.setIsOpen(true);
            console.log("successfully requested");
      })
      .catch((error)=>{
              props?.setType('danger');
              props?.setMessage(error?.data?.message);
              props?.setIsOpen(true);
              console.log(error,"error from backend");
      });
       props?.modal(false);
  }
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to request</div>
       <div>
        {props?.data?.map((prfItem)=>(
            <div className="d-flex justify-content-between gap-8 ">
                           <button className="rounded py-2 px-4  mb-4" onClick={()=>requestGrn(prfItem?.id)} > GRN request</button >
                            <span className="fs-5"><strong>{prfItem?.reason_for}</strong></span>
            </div>
           
                        

                 ))}
            </div>
            
        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            Cancel
          </div>
          {!props?.requestGrn && (<button
            type={"submit"}
            onClick={(e) => HandleSubmit(e)}
            className={"form-save "}
          >
            Request
          </button>)}
        </div>
      </div>
    </div>
  );
}
