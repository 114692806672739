import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useGetBanksQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useUpdatePaymentCollectionMutation,
} from "../../../features/SCMApi";

export default function PaymentCollectionModal(props) {
  const [name, setName] = useState(props?.data?.name);
  const [actual_collection_date, setDate] = useState(
    props?.data?.actual_collection_date
  );
  const [planed_collection_date, setPlannedCollectionDate] = useState(
    props?.data?.planed_collection_date
  );

  const [milestone, setMilestone] = useState(props?.data?.milestone);
  const [planned_amount, setPlannedAmount] = useState(
    props?.data?.planned_amount
  );
  const [project, setProject] = useState(props?.data?.project_id);
  const [actual_amount, setActualAmount] = useState(null);
  const [todays_progress, setTodaysProgress] = useState(
    props?.data?.todays_progress
  );
  const [next_action, setNextAction] = useState(props?.data?.next_action);
  const [is_collected_by_date, setIsCollected] = useState(
    props?.data?.is_collected_by_date == 0 ? false : true
  );
  const [bank_id, setBank] = useState();
  const params = useParams();
  const pro_id = props?.all != true ? params?.id : project;
  const { data: projectid } = useGetProjectQuery(pro_id);
  const { data: projects } = useGetProjectsQuery();
  const CheckBoxHandelonShore = () => {
    setIsCollected(!is_collected_by_date);
  };
  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    id: props?.data?.id,
    name,
    project_id: props?.all != true ? params?.id : project,
    actual_collection_date,
    planed_collection_date,
    milestone,
    planned_amount,
    actual_amount,
    todays_progress,
    next_action,
    bank_id,
    is_collected_by_date: is_collected_by_date == true ? 1 : 0,
  };

  const [UpdatePaymentCollection] = useUpdatePaymentCollectionMutation();
  function handleSubmit(e) {
    e.preventDefault();
    UpdatePaymentCollection(form)
      .unwrap()
      .then((response) => {
        props.modal(false);

        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  let final_amount = 0;
  const { data: paymentCollection } = useGetPaymentCollectionsQuery(params?.id);
  const { data: banks } = useGetBanksQuery();
  paymentCollection?.data?.map((items) =>
    items?.milestone == milestone ? (final_amount = items?.actual_amount) : ""
  );
  console.log(form, "formjnhdn");
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Update Payment Collection</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                <div className="supplier-input-label">Name</div>
                <input
                  value={name}
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                {props?.all == true ? (
                  <>
                    <div className="input-label">Project</div>
                    <select
                      value={project}
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setProject(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Project
                      </option>
                      {projects?.data?.map((items) => (
                        <option value={items.id}>{items?.project_name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}
                <div className="supplier-input-label">
                  Planned Collection Date
                </div>
                <input
                  value={planed_collection_date}
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setPlannedCollectionDate(e.target.value)}
                />
                <div className="supplier-input-label">
                  Actual Collection Date
                </div>
                <input
                  value={actual_collection_date}
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setDate(e.target.value)}
                />

                {project != null ? (
                  <>
                    <div className="input-label">Milestone</div>
                    <select
                      value={milestone}
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setMilestone(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Millstone
                      </option>
                      {milestonepush?.map((items) => (
                        <option value={items.id}>{items?.name}</option>
                      ))}
                    </select>
                  </>
                ) : props?.all != true ? (
                  <>
                    <div className="input-label">Millstone</div>
                    <select
                      value={milestone}
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setMilestone(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Millstone
                      </option>
                      {milestonepush?.map((items) => (
                        <option value={items.id}>{items?.name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}

                <div className="supplier-input-label">Actual Amount</div>
                <input
                  value={actual_amount}
                  type="number"
                  className={`finance-input-field `}
                  onChange={(e) => setActualAmount(e.target.value)}
                />
                {actual_amount != null && actual_amount != "" ? (
                  <>
                    <div className="input-label">Bank</div>
                    <select
                      value={bank_id}
                      required
                      name=""
                      id=""
                      className="finance-input-field"
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Bank
                      </option>
                      {banks?.data?.map((items) => (
                        <option value={items.id}>{items?.bank_name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}
                <div className="supplier-input-label">Today's Progress</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setTodaysProgress(e.target.value)}
                  className="finance-input-field m-0"
                >
                  {todays_progress}
                </textarea>
                <div className="supplier-input-label">Next Action</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setNextAction(e.target.value)}
                  className="finance-input-field m-0"
                >
                  {next_action}
                </textarea>
              </div>
            </div>

            {Number(planned_amount) < Number(actual_amount) ? (
              <label className="prg-error text-danger d-flex">
                Please enter valid Actual Amount
              </label>
            ) : (
              ""
            )}
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                disabled={
                  Number(planned_amount) < Number(actual_amount) ? true : false
                }
                type="submit"
                className={
                  Number(planned_amount) < Number(actual_amount)
                    ? "form-save bg-secondary text-white"
                    : "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
