import React from "react";
import saveAs from "file-saver";
import { Download } from "@mui/icons-material";
import documentPath from "./../../assets/Template/IE-PRF.docx";
import Docxtemplater from "docxtemplater";
import PizZipUtils from "pizzip/utils/index.js";
import PizZip from "pizzip";
import { API_BASE_URL, DOC_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";

const GeneratePRF = ({ data }) => {
  //   const downloadExcel = (e) => {
  //     loadFile(documentPath, function (error, content) {
  //       if (error) {
  //         throw error;
  //       }
  //       var zip = new PizZip(content);

  //       var doc = new Docxtemplater(zip, {
  //         dataType: "xlsx",
  //       });
  //       doc.setData({
  //         // ...PoDoc?.data?.details[0],
  //         // items,
  //         // ...ship,
  //         // total_price: total_price.toLocaleString(),
  //         // numbertoword,
  //         // date: today,
  //         // SO: SO,
  //         // PFO,
  //         // PM,
  //         // image: "https://docxtemplater.com/puffin.png",
  //       });

  //       try {
  //         // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
  //         doc.render();
  //       } catch (error) {
  //         // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
  //         function replaceErrors(key, value) {
  //           if (value instanceof Error) {
  //             return Object.getOwnPropertyNames(value).reduce(function (
  //               error,
  //               key
  //             ) {
  //               error[key] = value[key];
  //               return error;
  //             },
  //             {});
  //           }
  //           return value;
  //         }
  //         console.log(JSON.stringify({ error: error }, replaceErrors));

  //         if (error.properties && error.properties.errors instanceof Array) {
  //           const errorMessages = error.properties.errors
  //             .map(function (error) {
  //               return error.properties.explanation;
  //             })
  //             .join("\n");
  //           console.log("errorMessages", errorMessages);
  //           // errorMessages is a humanly readable message looking like this :
  //           // 'The tag beginning with "foobar" is unopened'
  //         }
  //         throw error;
  //       }
  //       var out = doc.getZip().generate({
  //         type: "blob",
  //         mimeType:
  //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //       }); //Output the document using Data-URI

  //       saveAs(out, ` generatedPO.xlsx`);
  //     });
  //   };
  let date = new Date().toLocaleDateString("en-GB");
  let items = data?.items;
  console.log(data, "Surafel");
  let total_cost = 0;
  items?.map((item) => (total_cost += item?.pi_total_cost));
  let withholding =
    data?.order?.order_type == "Product" && total_cost >= 10000
      ? total_cost * 0.02
      : data?.order?.order_type == "Service" && total_cost >= 3000
        ? total_cost * 0.02
        : 0;
  let vat = total_cost * 0.15;
  let cash_bank = total_cost + vat - withholding;

  console.log(cash_bank, "withholding");
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const downloadExcel = (e) => {
    loadFile(documentPath, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);

      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        payment_mode: data?.payment_mode?.payment_mode,
        reason_for: data?.reason_for,
        payable_to:
          data?.order?.supplier_id == null
            ? "Unknown"
            : data?.order?.supplier?.distributor_name,
        budget_number: data?.budget_number?.toLocaleString(),
        project: data?.project?.project_name,
        items,
        total_cost: total_cost?.toLocaleString(),
        withholding: withholding?.toLocaleString(),
        req_by: data?.requested_by?.name,
        req_pos:
          data?.requested_by?.role?.role_name == undefined
            ? "-"
            : data?.requested_by?.role?.role_name,
        pre_by: data?.prepared_by?.name,
        pre_pos:
          data?.prepared_by?.role?.role_name == undefined
            ? "-"
            : data?.prepared_by?.role?.role_name,
        app_by: data?.approved_by?.name,
        app_pos:
          data?.approved_by?.role?.role_name == undefined
            ? "-"
            : data?.approved_by?.role?.role_name,
        paid_by: data?.paid_by?.name,
        paid_pos:
          data?.paid_by?.role?.role_name == undefined
            ? "-"
            : data?.paid_by?.role?.role_name,
        date,
        vat: vat?.toLocaleString(),
        cash_bank: cash_bank?.toLocaleString(),
      });

      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            }, {});
          }
          return value;
        }
        console.log(JSON.stringify({ error: error }, replaceErrors));

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI

      saveAs(out, `${data?.project?.project_name} PRF.docx`);
    });
  };
  return (
    <div className="d-flex justify-content-center mb-2">
      <a
        type="link"
        href={`${IMG_BASE_URL}/prfview/${data?.order_id}`}
        className="form-save d-flex justify-content-center align-items-center mt-3"
      >
        <Download />
        <div>Download PRF</div>
      </a>
    </div>
  );
};

export default GeneratePRF;
