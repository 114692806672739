import React from "react";
import { useState } from "react";
import {
  useAddPaymentModeMutation,
  useAddPaymentTermMutation,
  useEditPaymentModeMutation,
} from "../../../features/SCMApi";
import "./modal.css";

export default function UpdatepaymentMode(props) {
  const [createPaymentMode, res] = useEditPaymentModeMutation();
  const [payment_mode, setPaymentMode] = useState(props?.value?.payment_mode);
  let payments = {
    id: props?.value?.id,
    payment_mode,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    createPaymentMode(payments)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        props.setMessages("Payment Term Updated successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }

  console.log(props?.value, "hhhhhhhhhhhhhhhhhhhhhh");

  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <h3>Edit Payment Mode</h3>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="pi-inputs">
              <section className="pi-inputs-modal">
                <label>Payment Mode</label>
                <input
                  type="text"
                  defaultValue={payment_mode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                />
              </section>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
