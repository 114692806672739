import React, { useState } from "react";
import attachmentIcon from "../../assets/SVG/attach.svg";
import FileChooser from "../common/FileChooser";

export default function ManufacturerForm(props) {
  // console.log(props.manufacturer);
  const [pi, setPi] = useState({});
  const [manufacturer_id, setMAnufacturer] = useState();
  let obj = { manufacturer_id: manufacturer_id, pi: pi };
  console.log(props?.count, "count");
  props.ManufacturerSetter[props?.count] = manufacturer_id;
  props.PiSetter[props?.count] = obj;
  function handleChange(e) {
    setPi(e?.target?.files?.[0]);
    console.log(pi, "pi");
    console.log(typeof pi, "type");
  }
  console.log(props?.type, "dfghyut");
  return (
    <div className="manufacturer-form">
      <div className="input-groups">
        <div className="input-label">
          {props?.type == "Product"
            ? "Manufacturer"
            : props?.type == "Service"
            ? "Service"
            : "Training"}
        </div>
        <select
          id=""
          className="select-option-create-order "
          onChange={(e) => setMAnufacturer(e.target.value)}
        >
          <option selected="true" disabled="disabled">
            {props?.type == "Product"
              ? " Choose Manufacturer"
              : props?.type == "Service"
              ? " Choose Service provider"
              : "Choose Training provider"}
          </option>
          {props.manufacturer?.map((items) => (
            <option value={items.id}>{items.manufacturer_name}</option>
          ))}
        </select>
        <div className="input-label">PI Import</div>
        <div
          className="file-chooser cl-9 px-0"
          style={{ backgroundImage: `url(${attachmentIcon})` }}
        >
          <input
            id="chooser"
            required
            type={"file"}
            class="file-input"
            multiple
            onChange={(e) => {
              console.log(e.target.files[0], "filesssss");
              handleChange(e);
            }}
          />
          <label
            htmlFor="chooser"
            style={{
              position: "relative",
              top: "-28px",
              left: "5px",
              width: "10rem",
              // zIndex: "-1",
            }}
          >
            {pi
              ? pi?.name?.length > 16
                ? pi?.name?.slice(0, 15) + "..."
                : pi?.name
              : "Choose File"}
          </label>
        </div>
      </div>
    </div>
  );
}
