import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddToolType from "./SettingModal/AddToolType";
import EditToolType from "./SettingModal/EditToolType";
import {
  useDeleteToolTypeMutation,
  useGetToolTypeQuery,
} from "../../features/SCMApi";
export default function ToolType() {
  const [addToolType, setToolType] = useState(false);
  const [editToolType, setEditToolType] = useState(false);
  const HandleToolType = () => {
    setToolType(true);
  };
  const HandleEditToolType = () => {
    setEditToolType(true);
  };
  const { data: tooltype } = useGetToolTypeQuery();
  console.log(tooltype, "tooltype");
  const [updateStatus, setStatus] = useState();
  const [DeleteStore, src] = useDeleteToolTypeMutation();

  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  const [updateToolType, setupdateToolType] = useState();

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Tool Type</h3>
      </section>
      {tooltype?.data?.map((items) => (
        <section className="pi-body">
          <p>{items?.tool_type}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                HandleEditToolType(true);
                setupdateToolType(items);
              }}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={(e) => {
                HandleDelete(e, items.id);
              }}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={HandleToolType} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addToolType ? <AddToolType modal={setToolType} /> : ""}
      {editToolType ? (
        <EditToolType modal={setEditToolType} data={updateToolType} />
      ) : (
        ""
      )}
    </div>
  );
}
