import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import BatchAndOrderSelector from "../Payment/ForexApproval/Select";
import BatchAndOrderNav from "./BatchAndOrderNav";
import { useGetProjectBatchQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
export const BatchDocument = () => {
  const params = useParams();
  const { data: projectBatch } = useGetProjectBatchQuery(params.id);
  const projectBach = projectBatch?.data || [];
  const [selectedBatch, setSelectedBatch] = React.useState(null);
  let batchname = "";

  projectBatch?.data?.map((items) => {
    if (selectedBatch === items.id) {
      batchname = items?.batch_name;
    }
  });
  console.log(batchname, "batchname");

  return projectBatch?.data?.length !== 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
          minHeight: 128,
          marginTop: "2%",
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          border: "1px #ECECEC solid",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ margin: "10px 30px" }}>
            <FolderIcon style={{ fill: "#186569", fontSize: "200%" }} />
            <span
              style={{
                color: "#353945",
                fontWeight: "500",
                fontSize: "16px",
                marginLeft: "10px",
                // marginTop: "10px",
              }}
            >
              Batch Document - {batchname}
            </span>
          </div>
          <select
            name=""
            id=""
            className="batch-select-option"
            required
            onChange={(e) => setSelectedBatch(e.target.value)}
          >
            <option value="" disabled selected hidden>
              Select Batch
            </option>
            {projectBatch?.data?.map((items) => (
              <option value={items.id}>{items.batch_name}</option>
            ))}
          </select>
        </div>
        {selectedBatch !== null ? (
          <BatchAndOrderNav
            name={"batch"}
            batch_name={batchname}
            selectedbatch={selectedBatch}
          />
        ) : (
          <div className="text-center h5-pri">Please Select a Batch</div>
        )}
      </Paper>
    </Box>
  ) : (
    ""
  );
};
