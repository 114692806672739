import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL, DOC_BASE_URL } from "../../api/endPoint";
import FileChooser from "../common/FileChooser";
import SmallFileChooser from "../common/SmallFileChooser";
import { Tooltip } from "@mui/material";

export default function CustomClearance(props) {
  const hasSecurityClearance =
    props?.previousData?.security_clearance?.length > 0 ? true : false;
  const hasTaxDocuments =
    props?.previousData?.tax_docs?.length > 0 ? true : false;

  const hasInsaApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.insa_approval != ""
  );
  const hasBroadcastApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.broadcast_approval != ""
  );
  const hasEcaApproval = props?.previousData?.security_clearance?.find(
    (item) => item?.eca_approval != ""
  );
  const hasOriginalTax = props?.previousData?.tax_docs?.find(
    (item) => item?.original_tax != ""
  );
  const hasAdditionalTax = props?.previousData?.tax_docs?.find(
    (item) => item?.additional_tax != ""
  );
  const hasFinalDeclaration = props?.previousData?.tax_docs?.find(
    (item) => item?.final_declaration != ""
  );
  const hasPenalty = props?.previousData?.tax_docs?.find(
    (item) => item?.penalty != ""
  );
  const hasWarehouseFee = props?.previousData?.tax_docs?.find(
    (item) => item?.warehouse_fee != ""
  );

  console.log(
    // hasOriginalTax,
    // hasAdditionalTax,
    // hasFinalDeclaration,
    // hasPenalty,
    // hasWarehouseFee,
    props?.previousData?.tax_docs[0]?.import_customs_tax,
    "batch detail data for custom clearance"
  );
  console.log(props?.previousData, "props?.previousData");
  const [original_tax, setOriginalTax] = useState(
    hasOriginalTax !== undefined ? { name: "File Exist" } : ""
  );
  const [additional_tax, setAdditionalTax] = useState(
    hasAdditionalTax !== undefined ? { name: "File Exist" } : ""
  );
  const [additional_tax_amount, setAdditionalTaxAmount] = useState(
    props?.previousData?.tax_docs[0]?.additional_tax_amount
  );
  const [final_declaration, setFinalDeclaration] = useState(
    hasFinalDeclaration !== undefined ? { name: "File Exist" } : ""
  );
  const [broadcast_approval, setBroadcastApproval] = useState(
    hasBroadcastApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [penalty, setPenalty] = useState(
    hasPenalty !== undefined ? { name: "File Exist" } : ""
  );

  const [Commercial_Invoice, setCommercial] = useState();
  const { batchId } = useParams();
  const [insa_approval, setINSAApproval] = useState(
    hasInsaApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [eca_approval, setECAApproval] = useState(
    hasEcaApproval !== undefined ? { name: "File Exist" } : ""
  );
  const [sure_tax, setSureTax] = useState(
    props?.previousData?.tax_docs[0]?.sure_tax
  );
  const [warehouse_fee, setWarehouseFee] = useState(
    hasWarehouseFee !== undefined ? { name: "File Exist" } : ""
  );
  const [import_customs_tax, setImportCustomsTax] = useState(
    props?.previousData?.tax_docs[0]?.import_customs_tax
  );
  const [excise_tax, setExciseTax] = useState(
    props?.previousData?.tax_docs[0]?.excise_tax
  );
  const [vat, setVat] = useState(props?.previousData?.tax_docs[0]?.vat);
  const [penalty_amount, setPenaltyAmount] = useState(
    props?.previousData?.tax_docs[0]?.penalty_amount
  );
  const [warehouse_fee_amount, setWarehouseFeeAmount] = useState(
    props?.previousData?.tax_docs[0]?.warehouse_fee_amount
  );
  const [withholding_tax, setWithholdingTax] = useState(
    props?.previousData?.tax_docs[0]?.withholding_tax
  );
  const [scanning_fee, setScanningFee] = useState(
    props?.previousData?.tax_docs[0]?.scanning_fee
  );
  const [custom_tax, setCustomTax] = useState(
    props?.previousData?.tax_docs[0]?.custom_tax
  );
  const [Custom_No, setCustom_No] = useState(
    props?.previousData?.tax_docs[0]?.Custom_No
  );
  const [EX_Rate_per_Declaretion, setEX_Rate_per_Declaretion] = useState(
    props?.previousData?.tax_docs[0]?.EX_Rate_per_Declaretion
  );
  const [IPI_USD_per_Declaretion, setIPI_USD_per_Declaretion] = useState(
    props?.previousData?.tax_docs[0]?.IPI_USD_per_Declaretion
  );
  const [Freight, setFreight] = useState(
    props?.previousData?.tax_docs[0]?.Freight
  );
  const [marin_insurance, setMarin_insurance] = useState(
    props?.previousData?.tax_docs[0]?.marin_insurance
  );
  const [Insurance, setInsurance] = useState(
    props?.previousData?.tax_docs[0]?.Insurance
  );
  const [other, setOther] = useState(props?.previousData?.tax_docs[0]?.other);
  const [customs_duty, setCustoms_duty] = useState(
    props?.previousData?.tax_docs[0]?.customs_duty
  );
  const [freight_sa, setFreight_sa] = useState(
    props?.previousData?.tax_docs[0]?.freight_sa
  );

  console.log(
    props?.previousData?.tax_docs?.length,

    "response from backend api for document prev data"
  );
  // props?.previousData?.batch_id
  function HandleSubmit(e) {
    e.preventDefault();
    const form = {
      batch_id: batchId,
      additional_tax_amount,
      insa_approval:
        hasInsaApproval != undefined
          ? hasInsaApproval?.insa_approval
          : insa_approval,
      broadcast_approval,
      eca_approval,
      original_tax,
      additional_tax,
      final_declaration,
      sure_tax,
      penalty,
      warehouse_fee,
      import_customs_tax,
      excise_tax,
      vat,
      penalty_amount,
      warehouse_fee_amount,
      withholding_tax,
      scanning_fee,
      custom_tax,
      Custom_No,
      EX_Rate_per_Declaretion,
      IPI_USD_per_Declaretion,
      Freight,
      marin_insurance,
      Insurance,
      other,
      customs_duty,
      freight_sa,
      security_clearance_id: props?.previousData?.security_clearance[0]?.id,
      tax_document_id: props?.previousData?.tax_docs[0]?.id,
    };

    console.log(
      props?.previousData?.security_clearance?.length == 0 &&
        props?.previousData?.tax_docs?.length == 0,
      "this is update document for all data"
    );
    props?.previousData?.security_clearance?.length == 0 ||
    props?.previousData?.tax_docs?.length == 0
      ? axios
          .post(
            `${API_BASE_URL}/scm/logistics/custom-clearance-documents`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            props?.ToggleModal(false);
            console.log(
              response,
              form,
              "response from backend api for document res"
            );
          })
          .catch(function (error) {
            console.log(
              error,
              form,
              "response from backend api for document err"
            );
          })
      : axios
          .post(
            `${API_BASE_URL}/scm/logistics/custom-clearance-documents/${form?.batch_id}`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            props?.ToggleModal(false);
            props?.onCustomsUpdated();
            console.log(
              response,
              form,
              "response from backend api for document res"
            );
          })
          .catch(function (error) {
            console.log(
              error,
              form,
              "response from backend api for document err"
            );
          });
  }
  console?.log(props, "propsjnkjdnkjnbdkj");
  return (
    <div className="mini-modal" onClick={(e) => props?.ToggleModal(false)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          Customs Clearance
          <div
            className="close-modal-button"
            onClick={(e) => props?.ToggleModal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="form-card-container mb-4"
          onSubmit={(e) => HandleSubmit(e)}
        >
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Security Clearance</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          INSA Approval
                        </div>
                        <FileChooser
                          notRequired={true}
                          state={insa_approval}
                          setter={setINSAApproval}
                          id={"INSAApproval"}
                        />
                      </div>
                      {props?.previousData?.security_clearance != undefined &&
                      props?.previousData?.security_clearance[0]
                        ?.insa_approval ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasInsaApproval?.insa_approval}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          Broadcast Approval
                        </div>
                        <FileChooser
                          notRequired={true}
                          state={broadcast_approval}
                          id={"BroadcastApproval"}
                          setters={setBroadcastApproval}
                        />
                      </div>
                      {props?.previousData?.security_clearance != undefined &&
                      props?.previousData?.security_clearance[0]
                        ?.broadcast_approval ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasBroadcastApproval?.broadcast_approval}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">ECA Approval</div>
                        <FileChooser
                          notRequired={true}
                          state={eca_approval}
                          setters={setECAApproval}
                          id={"ECAApproval"}
                        />
                      </div>
                      {props?.previousData?.security_clearance != undefined &&
                      props?.previousData?.security_clearance[0]
                        ?.eca_approval ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasEcaApproval?.eca_approval}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Tax</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">Original Tax</div>
                        <FileChooser
                          notRequired={true}
                          state={original_tax}
                          setter={setOriginalTax}
                          id={"OriginalTax"}
                        />
                      </div>
                      {hasTaxDocuments === true &&
                      hasOriginalTax !== undefined ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasOriginalTax?.original_tax}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          Additional Tax
                        </div>
                        <FileChooser
                          notRequired={true}
                          state={additional_tax}
                          id={"AdditionalTax"}
                          setters={setAdditionalTax}
                        />
                      </div>
                      {hasTaxDocuments === true &&
                      hasAdditionalTax !== undefined ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasAdditionalTax?.additional_tax}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          Final Declaration
                        </div>
                        <FileChooser
                          notRequired={true}
                          state={final_declaration}
                          setters={setFinalDeclaration}
                          id={"FinalDeclaration"}
                        />
                      </div>
                      {hasTaxDocuments === true &&
                      hasFinalDeclaration !== undefined ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasFinalDeclaration?.final_declaration}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">Customs Number</div>
                      <input
                        defaultValue={Custom_No}
                        type="text"
                        className="text-input"
                        onChange={(e) => setCustom_No(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Payment</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">Penalty</div>
                        <div className="small-input-group">
                          <input
                            defaultValue={penalty_amount}
                            className="small-text-field"
                            type="number"
                            onChange={(e) => setPenaltyAmount(e.target.value)}
                          />
                          <SmallFileChooser
                            notRequired={true}
                            state={penalty}
                            id={"Penalty"}
                            setters={setPenalty}
                          />
                        </div>
                      </div>
                      {hasTaxDocuments === true && hasPenalty !== undefined ? (
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${hasPenalty?.penalty}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-2 align-items-baseline">
                        <div className="supplier-input-label">
                          Warehouse Fee
                        </div>
                        <div className="small-input-group">
                          <input
                            defaultValue={warehouse_fee_amount}
                            className="small-text-field"
                            type="number"
                            onChange={(e) =>
                              setWarehouseFeeAmount(e.target.value)
                            }
                          />
                          <SmallFileChooser
                            notRequired={true}
                            state={warehouse_fee}
                            id={"WarehouseFee"}
                            setters={setWarehouseFee}
                          />
                        </div>
                        {hasTaxDocuments === true &&
                        hasWarehouseFee !== undefined ? (
                          <Tooltip title="Download">
                            <a
                              href={`${DOC_BASE_URL}scm/document/download?file_path=${hasWarehouseFee?.warehouse_fee}`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#186569"
                                class="bi bi-download"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </a>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="cancel"
              className="form-cancel"
              style={{ width: "100px" }}
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              type="submit"
              className="form-save"
              style={{ width: "100px" }}
              onClick={(e) => HandleSubmit(e)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
