import { Pagination } from "@mui/material";
import * as React from "react";
import usePagination from "../../Pagination/Pagination";
import { styled } from "@mui/material/styles";
import "../forex.style.css";
import { useGetBankBalanceQuery } from "../../../features/SCMApi";
import BalancePageMobileComponent from "./BalancePageMobileComponent";

const TotalDisplay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#FFFFFF",
  backgroundColor: "#186569",
  width: "250px",
  height: "50px",
  borderRadius: "5px",
  marginLeft: "auto",
  marginRight: "40px",
  marginTop: "5px",
  marginBottom: "5px",
}));

const BalancePage = () => {
  const { data: bankBalance } = useGetBankBalanceQuery();
  let counter = 0;
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(bankBalance?.data?.length / PER_PAGE);
  const _DATA = usePagination(bankBalance?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  //Grand total calculator logic
  const grandTotals = [];
  for (const total in bankBalance?.data) {
    grandTotals.push(Number(bankBalance?.data[total]?.amount));
  }

  let grandTotal =
    Math.round(
      (grandTotals
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  console.log(grandTotals, grandTotal, "mulualem is testing");

  return (
    <>
      <div className="mt-4 balanceContainer">
        <TotalDisplay>
          {grandTotal?.toLocaleString()}
          <span className="projectTotal">Grand Total</span>
        </TotalDisplay>
        <table className="table table-borderless balanceTable">
          <thead className="balancetableHeader">
            <tr>
              <th>NO</th>
              <th>BANK NAME</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {_DATA.currentData()?.map((bank) => (
              <tr className="balancetableBodyRow">
                <td id="rollNo">{(counter += 1)}</td>
                <td id="bankName">{bank?.bank_balance?.bank_name}</td>
                <td id="amount">{Number(bank?.amount)?.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div className="balance-page-mobile-view">
        <TotalDisplay sx={{ width: "364px", marginBottom: "15px" }}>
          {grandTotal?.toLocaleString()}
          <span className="projectTotal">Grand Total</span>
        </TotalDisplay>
        {_DATA.currentData()?.map((bank) => (
          <div className="mt-3 forex-bank-mobile">
            <BalancePageMobileComponent data={bank} />
          </div>
        ))}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default BalancePage;
