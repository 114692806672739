import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
  useHasBoqQuery,
} from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
import AddClientRepresentative from "./AddClientRepresentative";
import EditIcon from "@mui/icons-material/Edit";
import ChangeClientModal from "./ChangeClientModal";

export default function ClientRepresentative(props) {
  console.log(props, "props");
  const clientRepresentative = props?.clientRepresentative;

  const [addClientRepresentativeModal, setModal] = useState(false);
  const [changeClientModal, setChangeClientModal] = useState(false);
  console.log(props.clientRepresentative, "cl");
  const handleEdit = (items) => {
    props?.setters(items);
    props?.ModalToggle(true);
  };
  const { projectId } = useParams();

  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectId);
  const { data: userinfo } = useGetUserInfoQuery();

  const { data: projectmembers } = useGetProjectQuery(projectId);
  console.log(props?.client, "passing");
  return (
    <>
      {changeClientModal ? (
        <ChangeClientModal
          ToggleModal={setChangeClientModal}
          previousData={props?.client}
        />
      ) : null}
      <div className="representative-card">
        <div className="card-title client-info-header">
          Client Representative{" "}
          <div className="pending-batch ml-1" style={{ marginRight: "2px" }}>
            {props?.client?.client_name}
            {props.checkPermission(
              "update_client_representative",
              props?.projectmembers,
              props?.userinfo,
              props?.roleRoles
            ) ? (
              <button
                className="sync-btn"
                onClick={(e) => {
                  e.preventDefault();
                  // setProps(props);
                  setChangeClientModal(true);
                }}
              >
                <EditIcon />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="divider"></div>
        {clientRepresentative?.length > 0 ? (
          <div className="representative-container">
            {clientRepresentative?.map((items) => (
              <div className="responsive-card ">
                <div className="representative-address">
                  <div>{items.full_name}</div>
                  <div>{items.email}</div>
                  <div
                    style={{
                      display: " flex",
                      gap: "6px",
                      flexDirection: "column",
                    }}
                  >
                    {/* {console.log(
                    items?.c_r_phone_number,
                    "items?.c_r_phone_number"
                  )} */}
                    {items?.c_r_phone_number?.map((c_r_phone) => (
                      <div>{c_r_phone?.phone_number}</div>
                    ))}
                  </div>
                </div>
                {props?.checkPermission(
                  "update_client_representative",
                  projectmembers,
                  userinfo,
                  roleRoles
                ) ? (
                  <button
                    className="edit-representative"
                    onClick={(e) => handleEdit(items)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.75 14.25H2.66667L11.8958 5.02084L10.9792 4.10417L1.75 13.3333V14.25ZM14.5417 4.125L11.875 1.45834L12.75 0.583336C12.9861 0.347225 13.2813 0.232642 13.6354 0.239586C13.9896 0.24653 14.2847 0.368058 14.5208 0.604169L15.4167 1.5C15.6528 1.73611 15.7708 2.02778 15.7708 2.375C15.7708 2.72222 15.6528 3.01389 15.4167 3.25L14.5417 4.125ZM1.125 15.5C0.944444 15.5 0.795139 15.441 0.677083 15.3229C0.559028 15.2049 0.5 15.0556 0.5 14.875V13.0833C0.5 13 0.513889 12.9236 0.541667 12.8542C0.569444 12.7847 0.618055 12.7153 0.6875 12.6458L11 2.33334L13.6667 5L3.35417 15.3125C3.28472 15.3819 3.21528 15.4306 3.14583 15.4583C3.07639 15.4861 3 15.5 2.91667 15.5H1.125ZM11.4375 4.5625L10.9792 4.10417L11.8958 5.02084L11.4375 4.5625Z"
                        fill="#353849"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        ) : (
          <NoRecord />
        )}
        {/* ToDoo replace add_client_representative permission to change_client_representative */}
        {props?.checkPermission(
          "add_client_representative",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          props?.client?.id != null ? (
            <button
              className="add-client-representative"
              onClick={(e) => {
                setModal(true);
                window.scrollTo(1, 0);
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : (
            ""
          )
        ) : (
          <></>
        )}
      </div>
      {addClientRepresentativeModal ? (
        <AddClientRepresentative
          modal={addClientRepresentativeModal}
          ToggleModal={setModal}
          clientId={props.clientId}
        />
      ) : (
        <></>
      )}
    </>
  );
}
