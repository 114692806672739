import React, { useState } from "react";
import "../forex.style.css";
import { IconButton, Tooltip } from "@mui/material";
import { useGetAllGuaranteeBalanceQuery } from "../../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import CreateGuaranteeBalance from "./CreateGuaranteeBalance";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditGuaranteeBalance from "./EditGuaranteeBalance";
export default function GuaranteeBalance(props) {
  const { data: CreateGuaranteeBalances } = useGetAllGuaranteeBalanceQuery();
  console.log(CreateGuaranteeBalance, "CreateGuaranteeBalance");
  const [guarantee, setGuarantee] = useState(false);
  const [editGuarantee, setEditGuarantee] = useState(false);
  const [data, setData] = useState(null);
  function handleGuaranteeModal() {
    setGuarantee(true);
  }
  function handleEditGuaranteeModal(e, items) {
    setEditGuarantee(true);
    setData(items);
  }
  console.log(CreateGuaranteeBalances, "CreateGuaranteeBalances");
  return (
    <>
      <div className="create-project-btn m-3" onClick={handleGuaranteeModal}>
        <AddIcon />
        Create GB
      </div>
      <div className="supplier-menu-container">
        {CreateGuaranteeBalances?.data?.map((items) => (
          <div className="guarantee-card">
            <div className="">
              <div className="forex-detail-card-sub">
                <div className="forex-card-left-grid">
                  <div className="batch-container">
                    <Tooltip title={items?.bank?.bank_name}>
                      <div className="logistics-batch-name">
                        {items?.bank?.bank_name.length > 20
                          ? items?.bank?.bank_name.slice(0, 20) + "..."
                          : items?.bank?.bank_name}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="guarantee-balance-container">
                <div className="origin-group">
                  <Tooltip title={items?.balance}>
                    <div className="origin-value">
                      {items?.balance?.length > 20
                        ? items?.balance.slice(0, 20) + "..."
                        : Number(items?.balance).toLocaleString()}
                    </div>
                  </Tooltip>
                  <div className="origin-label">Balance</div>
                </div>
                <div className="logistics-batch-item forexProjectBankName">
                  <div className="origin-group">
                    <Tooltip title={items?.current_balance}>
                      <div className="origin-value">
                        {items?.current_balance?.length > 20
                          ? items?.current_balance.slice(0, 20) + "..."
                          : Number(items?.current_balance).toLocaleString()}
                      </div>
                    </Tooltip>
                    <div className="origin-label">Current Balance</div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <IconButton
                  sx={{
                    color: "#fff",
                    backgroundColor: "#064b4f",
                    "&:hover": {
                      backgroundColor: "#186569",
                    },
                  }}
                  onClick={(e) => handleEditGuaranteeModal(e, items)}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  sx={{
                    color: "#fff",
                    backgroundColor: "#dc3545",
                    "&:hover": {
                      backgroundColor: "#dc3545",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      {guarantee ? <CreateGuaranteeBalance modal={setGuarantee} /> : ""}
      {editGuarantee ? (
        <EditGuaranteeBalance data={data} modal={setEditGuarantee} />
      ) : (
        ""
      )}
    </>
  );
}
