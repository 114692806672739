import React, { useState } from "react";
import InitiateForexButton from "../InitiateForexButton";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import IconButtons from "../IconButtons";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PiSummaryTemplate from "../../../../assets/Template/PI Template.docx";
import {
  useClearForexMutation,
  useGetForexSummarizedPiQuery,
  useGetUserInfoQuery,
} from "../../../../features/SCMApi";

import { useParams } from "react-router-dom";
import CheckPermission from "../../../common/checkPermission/checkPermission";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, IconButton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import NBEDialog from "../NBEDialog";
import RemarkForex from "../RemarkForex";
import ExpirationRemark from "../../ExpirationRemark";
import { API_BASE_URL } from "../../../../api/endPoint";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../../../pages/Payment/Payable/aging.css";

const Item = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "73%",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    // margin: "auto",
    height: "73%",
    margin: "auto",
  },
  color: theme.palette.text.secondary,
  marginTop: "20px",
  border: "1px solid #AEB8C9",
  overflow: "hidden",
  borderRadius: "6px",
}));

const ForexOrderItemListContainer = (props) => {
  const forexId = useParams();
  const { data: summarizedPi } = useGetForexSummarizedPiQuery(forexId?.id);
  const { data: userinfo } = useGetUserInfoQuery();
  const [activeTab, setActiveTab] = useState("Tab1");

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const items =
    summarizedPi !== undefined || null ? summarizedPi?.data[0]?.items : [];
  const listItems = [];
  items?.map((item, index) =>
    listItems.push({ ...item, table_index: index + 1 })
  );
  const itemTotal = items?.map((item) => item?.total_cost);
  let itemsTotal =
    Math.round(
      (itemTotal &&
        itemTotal
          .filter((e) => typeof e == "number")
          .reduce((prev, acc) => prev + acc, 0) + Number.EPSILON) * 100
    ) / 100;

  // const ieData = {
  //   openingTo: "IE Network Solutions ",
  //   poBox: "122521",
  //   address: "Haya Hulet, Festival 22 Building, 7th Floor, Addis Ababa",
  //   contactPerson: "Fikirte Kibru",
  //   contactPersonEmail: "fikirte.k@ienetworks.co",
  // };
  console.log("summarizedPi", summarizedPi);

  const ClientData =
    summarizedPi?.data[0]?.forex_project?.forex_resource == 1
      ? {
          client_name:
            summarizedPi?.data[0]?.forex_project?.client?.client_name,
          client_address: summarizedPi?.data[0]?.forex_project?.client?.address,
          client_pobox:
            summarizedPi?.data[0]?.forex_project?.client?.postal_code,
          client_representative_name:
            summarizedPi?.data[0]?.forex_project?.client
              ?.client_representative[0]?.full_name,
          client_representative_email:
            summarizedPi?.data[0]?.forex_project?.client
              ?.client_representative[0]?.email,
        }
      : summarizedPi?.data[0]?.forex_project?.forex_resource == 0
        ? {
            client_name: "IE Network Solutions ",
            client_address:
              "Haya Hulet, Festival 22 Building, 7th Floor, Addis Ababa",
            client_pobox: "122521",
            client_representative_name: "Fikirte Kibru",
            client_representative_email: "fikirte.k@ienetworks.co",
          }
        : {};
  let summarizedPiData = {};
  summarizedPi !== undefined || summarizedPi !== null ? (
    (summarizedPiData = {
      distributer_name:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to
          ?.distributor_name,
      distributer_pobox:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.postal_code,
      distributer_address:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.address,
      reference: summarizedPi?.data[0]?.forex_term[0]?.reference_number,
      opening_date: summarizedPi?.data[0]?.forex_term[0]?.opening_date,
      ...ClientData,
      items: listItems,
      payment_mode:
        summarizedPi?.data[0]?.forex_term[0]?.forex_payment_mode?.payment_mode,
      payment_schedule: "NA",
      price_validity: summarizedPi?.data[0]?.forex_term[0]?.validity_date,
      shipment_mode:
        summarizedPi?.data[0]?.forex_term[0]?.forex_shipment_mode
          ?.shipment_mode,
      country_of_origin:
        summarizedPi?.data[0]?.forex_term[0]?.forex_origin?.name,
      port_of_loading:
        summarizedPi?.data[0]?.forex_term[0]?.forex_air_loading !== null
          ? summarizedPi?.data[0]?.forex_term[0]?.forex_air_loading?.port_name
          : summarizedPi?.data[0]?.forex_term[0]?.forex_sea_loading !== null
            ? summarizedPi?.data[0]?.forex_term[0]?.forex_sea_loading?.port_name
            : "",
      trans_shipment: summarizedPi?.data[0]?.forex_term[0]?.trans_shipment,
      partial_shipment: summarizedPi?.data[0]?.forex_term[0]?.partial_shipment,
      port_of_discharging:
        summarizedPi?.data[0]?.forex_term[0]?.forex_discharge_port?.port_name,
      incoterm_name:
        summarizedPi?.data[0]?.forex_term[0]?.incoterms?.incoterm_name,
      supplier_account_name:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.supplier_bank[0]
          ?.account_name,
      supplier_iban_number:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.supplier_bank[0]
          ?.iban,
      supplier_swift_code:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.supplier_bank[0]
          ?.swift_code,
      supplier_account_number:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.supplier_bank[0]
          ?.account_number,
      supplier_bank_name:
        summarizedPi?.data[0]?.forex_term[0]?.forex_opening_to?.supplier_bank[0]
          ?.bank_name,
      items_total: itemsTotal?.toLocaleString(),
      // estimated_price:
      //   summarizedPi?.data[0]?.forex_term[0]?.estimated_price !== null
      //     ? (summarizedPi?.data[0]?.forex_term[0]?.estimated_price)?.toLocaleString()
      //     : 0,

      estimated_price: 0,
      grand_total: Number(itemsTotal + 0)?.toLocaleString(),
    })
  ) : (
    <></>
  );

  console.log(summarizedPiData, "summarized pi data download");

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const generateDocument = () => {
    loadFile(PiSummaryTemplate, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData(summarizedPiData);
      try {
        // render the document (replace all occurrences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all sub errors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            }, {});
          }
          return value;
        }
        console.log(JSON.stringify({ error: error }, replaceErrors));
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "Pi Summary Invoice.docx");
    });
  };
  const [forexApproved, setForexApproved] = useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const approvalStatus =
    props?.data?.map((forex) => forex?.isApproved) == 0
      ? "Pending"
      : "Approved";
  const [clear, setClear] = useState(false);
  function handleIconButtonClear() {
    setClear(true);
  }
  const [Clear] = useClearForexMutation();
  function HandleClear(e, id) {
    let form = {
      id,
    };
    e.preventDefault();
    Clear(form)
      .unwrap()
      .then((response) => {
        setClear(false);
        console.log(response, "Response for created order from backend");
      })
      .catch((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  const [delinquent, setDelinquent] = useState(true);

  const pi_id = props?.data && props?.data[0]?.pi_id;

  // =====================> DELETE SECTION <===================

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleConfirmation = () => {
    setShowConfirmationModal(true);
  };
  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
  };
  const deleteForexID = forexId?.id;
  const dataForForexDelete = {
    project_ID: props?.data && props.data[0]?.forex_project?.id,
    order_ID: props?.data
      ? props.data.reduce((order_ID, forex) => {
          forex?.items?.forEach((item) => {
            order_ID.push(item?.order_id);
          });
          return order_ID;
        }, [])
      : [],
    bank_ID: props?.data && props.data[0]?.forex_bank?.id,
    pi_id,
  };

  const handleDelete = () => {
    console.log("dataForForexDelete", dataForForexDelete);
    axios
      .delete(
        `${API_BASE_URL}/scm/payment/batch-forex/${deleteForexID}`,
        dataForForexDelete
      )
      .then((response) => {
        console.log("Deleted successfully", response);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error updating data", error);
      });
  };
  console.log("props?.items", pi_id, props);

  // =====================> GENERATE ID FOR EACH ORDER ITEMS <===================
  const [OrderIDConfirmation, setOrderIDConfirmation] = useState(false);
  const handleOrderIDConfirmation = () => {
    setOrderIDConfirmation(true);
  };
  const handleOrderIDCloseConfirmation = () => {
    setOrderIDConfirmation(false);
  };
  let hasUnidentifiedItems = false;

  if (props && props.data) {
    hasUnidentifiedItems =
      props.data[0]?.items?.length > 0 &&
      props.data[0].items.some((item) => !item.id);
  } else {
    console.log("props or props.data is undefined");
  }

  const handleGenerateIds = () => {
    // if (props && props.data) {
    // const updatedOrders = props?.data[0]?.items?.map((item) => ({
    //   ...item,
    //   id: item?.id || uuidv4(),
    // }));
    // const pi = updatedOrders;
    // console.log("pi", pi);
    axios
      .post(`${API_BASE_URL}/scm/sourcing/pi_item/${pi_id}`)
      .then((response) => {
        console.log(response?.data, "Checking response for update order");
        setOrderIDConfirmation(false);
      })
      .catch((error) => {
        console.log(error?.message, "error message for updating order");
      });
    // } else {
    //   console.log("props or props.data is undefined");
    // }
  };
  console.log("props?.data}", props?.data);
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={7.5}
        xl={7.5}
        marginBottom={{
          xs: "0px",
          sm: "0px",
          md: "0px",
          lg: "0px",
          xl: "0px",
        }}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
        sx={{ height: "73%" }}
      >
        <Item elevation={0}>
          <div
            className={
              props?.data?.map((forex) => forex?.isApproved) == 0
                ? "forexDetailDetailHeading"
                : props?.data?.map((forex) => forex?.NBE_cleared) == 0
                  ? "forexDetailDetailHeading bg-danger text-white m-0"
                  : "forexDetailDetailHeading bg-success text-white m-0"
            }
          >
            {props?.data?.map((forex) => forex?.forex_name)}
            {forexApproved ? (
              CheckPermission("close_forex", userinfo) ? (
                <div className="forexPendingStatus">
                  <span className="forexPendingText">Approved</span>
                </div>
              ) : (
                ""
              )
            ) : CheckPermission("close_forex", userinfo) ? (
              props?.data?.map((forex) => forex?.isApproved) == 0 ? (
                <div className="forexPendingStatus">
                  <span className="forexPendingText">{approvalStatus}</span>
                  {props?.data?.map((forex) => forex?.isApproved) == 0 ? (
                    <IconButtons
                      successMessage={props?.successMessage}
                      message={props?.message}
                      forexId={forexId?.id}
                      name="foreaxApprovalButton"
                      approvalStatus={setForexApproved}
                      error={setErrorMessage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="forexPendingStatus">
                  <span className="forexPendingText text-white">
                    {props?.data?.map((forex) => forex?.NBE_cleared) == 0
                      ? "Not Cleared"
                      : "Cleared"}
                  </span>
                  {props?.data?.map((forex) => forex?.NBE_cleared) == 0 ? (
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        backgroundColor: "#064b4f",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={(e) => handleIconButtonClear(e)}
                    >
                      <CachedIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              )
            ) : (
              ""
            )}
          </div>
          {/* {props?.data[0]?.isApproved == 0 ? (
            <div className="text-danger px-4">
              <small>
                Notice: The items listed below have not been approved for forex
              </small>
            </div>
          ) : (
            ""
          )} */}
          {errormessage !== null && errormessage !== undefined ? (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className="text-center">
                    <WarningIcon
                      sx={{
                        color: "red",
                        width: 100,
                        height: 100,
                      }}
                    />
                  </div>

                  <DialogContentText id="alert-dialog-description">
                    {errormessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            ""
          )}
          <>
            <div className="forexOrderList">
              <div style={{ border: "1px solid #ececec", margin: "20px" }}>
                {/* <div className="forexOrderName">
                  Orders List
                  <hr className="forexOrderUnderline" />
                </div> */}
                <div className="d-flex align-items-center gap-2 p-3">
                  <button
                    className={
                      activeTab == "Tab1"
                        ? "ms-0 mt-0 form-save"
                        : "ms-0 mt-0 form-cancel"
                    }
                    onClick={() => openTab("Tab1")}
                  >
                    <div className=" p-1"> Orders List</div>
                  </button>
                  <button
                    className={
                      activeTab == "Tab2"
                        ? "ms-0 mt-0 form-save"
                        : "ms-0 mt-0 form-cancel"
                    }
                    onClick={() => openTab("Tab2")}
                  >
                    <div className=" p-1"> Attached Summerized PI</div>
                  </button>
                </div>

                <hr className="forexOrderUnderline" />

                {/* {hasUnidentifiedItems && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="form-save m-2"
                      onClick={(e) => handleOrderIDConfirmation(e)}
                    >
                      Generate IDs
                    </button>
                  </div>
                )} */}
                {activeTab === "Tab1" ? (
                  <>
                    {/* {OrderIDConfirmation && (
                      <div className="modal">
                        <div className="modal-dialog modal-dialog-centered">
                          <div
                            className="modal-content"
                            style={{ minHeight: "0" }}
                          >
                            <div className="modal-header">
                              <h6 className="modal-title prg">
                                Confirm Generate IDs
                              </h6>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleOrderIDCloseConfirmation}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <p>
                                Are you sure you want to Generate IDs for order
                                items?
                              </p>
                            </div>
                            <div className="d-flex flex-row form-buttons modal-footer">
                              <button
                                className="form-save"
                                onClick={handleGenerateIds}
                              >
                                Yes
                              </button>
                              <button
                                className="form-cancel"
                                onClick={handleOrderIDCloseConfirmation}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    <div className="forexOrderInfo">
                      <div className="forexOrderItemDescription">
                        <ul className="forexOrderItemDescriptionList">
                          {props?.data?.map((forex) =>
                            forex?.items?.map((item) => (
                              <li
                                className="border p-2 my-2 rounded"
                                key={item.id}
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="">
                                    <strong>Part Number: </strong>
                                    {item?.item_part_number}
                                  </div>
                                  <div className="">
                                    <strong>Amount: </strong>
                                    {item?.total_cost}
                                  </div>
                                </div>
                                <div className="">
                                  <strong>Item Description: </strong>
                                  {item?.item_description}
                                </div>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="summarizedPiButton summarized-pi-mobile">
                      {CheckPermission("summerize_pi", userinfo) ? (
                        <InitiateForexButton
                          name={"pi"}
                          title={"PI"}
                          downloadSummarizedPi={generateDocument}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="forexOrderInfo">
                    <div className="forexOrderItemDescription">
                      <ul className="forexOrderItemDescriptionList">
                        {props?.data?.map((forex) =>
                          forex?.forex_summarized_pi[0]?.items?.map((item) => (
                            <li
                              className="border p-2 my-2 rounded"
                              key={item.id}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="">
                                  <strong>Part Number: </strong>
                                  {item?.item_part_number}
                                </div>
                                <div className="">
                                  <strong>Amount: </strong>
                                  {item?.total_price}
                                </div>
                              </div>
                              <div className="">
                                <strong>Item Description: </strong>
                                {item?.item_description}
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {/* {CheckPermission("edit_forex", userinfo) ? (
                  <IconButtons name="editForex" />
                ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="summarizedPiButton summarized-pi-desktop">
              {CheckPermission("summerize_pi", userinfo) ? (
                <InitiateForexButton
                  name={"pi"}
                  title={"PI"}
                  downloadSummarizedPi={generateDocument}
                />
              ) : (
                <></>
              )}

              {/* {CheckPermission("edit_forex", userinfo) ? (
                <IconButtons name="editForex" />
              ) : (
                <></>
              )} */}
            </div>
          </>
        </Item>
        <div className="d-flex justify-content-center mt-3">
          <button
            type="submit"
            className="supplier-remove-btn"
            onClick={(e) => handleConfirmation(e)}
          >
            Delete Forex
          </button>
        </div>
        {showConfirmationModal && (
          // <div className="modal">
          //   <div className="modal-dialog modal-dialog-centered ">
          //     <div className="modal-content " style={{ minHeight: "0" }}>
          //       <div className="modal-header">
          //         <p>Are you sure you want to delete Forex?</p>
          //         <button
          //           type="button"
          //           className="btn-close"
          //           data-bs-dismiss="modal"
          //           aria-label="Close"
          //           onClick={handleCloseConfirmation}
          //         ></button>
          //       </div>
          //       <div className="d-flex flex-row form-buttons modal-body">
          //         <button className="form-save" onClick={handleDelete}>
          //           Yes
          //         </button>
          //         <button
          //           className="form-cancel"
          //           onClick={handleCloseConfirmation}
          //         >
          //           No
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <div className="modal">
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content " style={{ minHeight: "0" }}>
                <div className="modal-header">
                  {/* <h6>Delete Forex </h6> */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseConfirmation}
                  ></button>
                </div>
                <div class="modal-body">
                  <h6 className=" prg d-flex justify-content-center">
                    Add Order item Id
                  </h6>
                </div>
                {/* <div className="d-flex flex-row form-buttons modal-footer">
                  <button
                    className="form-cancel"
                    onClick={handleOrderIDCloseConfirmation}
                  >
                    No
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex gap-5 align-items-center mt-2 px-5 py-3 bg-white rounded">
          <div className="d-flex justify-content-end ">
            <div className="d-flex align-items-center gap-2 me-3">
              <button
                value={true}
                onClick={(e) => setDelinquent(true)}
                className={
                  delinquent == true
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Delinquent Status</label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <button
                value={false}
                onClick={(e) => setDelinquent(false)}
                className={
                  delinquent == false
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Expiration Status</label>
            </div>
          </div>
        </div>
        {delinquent ? <RemarkForex /> : <ExpirationRemark />}
      </Grid>

      {clear ? (
        <NBEDialog
          setDialog={setClear}
          dialog={props?.data?.map((forex) => forex?.forex_name)}
          action={(e) =>
            HandleClear(
              e,
              props?.data?.map((forex) => forex?.id)
            )
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ForexOrderItemListContainer;
