import React from "react";
import { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import Inventories from "./Inventories";
import InventoryDetail from "./InventoryDetail";
export default function Index(props) {
  const [fragment, setFragment] = useState("index");
  const [data, setData] = useState();
  console.log(props?.Message, "hhaprops");
  return (
    <StockIndex>
      {fragment === "index" ? (
        <Inventories
          setMessages={props?.setMessage}
          setFragment={setFragment}
          setData={setData}
        />
      ) : fragment === "detail" ? (
        <InventoryDetail setFragment={setFragment} data={data} />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
