import React, { useState } from "react";

import { IMG_BASE_URL } from "../../../api/endPoint";
import { MdApproval } from "react-icons/md";
import ConfirStore from "../ConfirmStore";
import {
  useGetRequestItemsQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import StockIndex from "../../../pages/Stocks/StockIndex";

function StoreTransferTool() {
  const { data: getRequestItems } = useGetRequestItemsQuery();

  const { data: userinfo } = useGetUserInfoQuery();
  let requetedData = getRequestItems?.data ? getRequestItems?.data : {};

  const filterdStore = requetedData.tools || [];
  const dataArray = Object.values(filterdStore)?.filter(
    (storeData) => storeData?.store?.store_keeper_id == userinfo?.id
  );
  console.log(getRequestItems, "requetedDatatoolsssss");
  console.log(requetedData, "requetedDatassssssss");
  console.log(filterdStore, "filterdStorefillll");
  console.log(dataArray, "dataArrayssssssssAsset");
  // console.log(data, "dataArrayssssssssLenght");

  return (
    <StockIndex>
      <div className="inventory-header-container"></div>
      {Object.values(dataArray).map((items, index) => (
        <div key={index}>
          <div className="accordion-item">
            <h2
              className="accordion-header"
              id={`flush-heading-${index ? index : 0}`}
            >
              <div className="d-flex align-items-center gap-3"></div>
              <button
                className="accordion-button collapsed "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse-${index}`}
                aria-expanded="false"
                aria-controls={`flush-collapse-${index}`}
              >
                <div class="d-flex align-items-center gap-2">
                  <div class="mt-2">
                    Transfer From{" "}
                    {items?.tools?.[0]?.previous_store?.store_name}
                  </div>
                  <div class="mt-2"> to {items?.store_name}</div>
                </div>
              </button>
            </h2>
            <div
              id={`flush-collapse-${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`flush-heading-${index}`}
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <div className="d-flex justify-content-between gap-5">
                  <>
                    <table
                      className="table table-striped table-bordered "
                      style={{
                        width: "90%",
                        // minWidth: "1500px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead className="stock-table-header">
                        <tr>
                          <th style={{ width: "18%" }}></th>
                          <th style={{ width: "14%" }}>Item Name</th>
                          <th style={{ width: "10%" }}>Quantity</th>
                          <th style={{ width: "12%" }}>Model</th>
                          <th style={{ width: "12%" }}>Serial No</th>
                          <th style={{ width: "12%" }}>Requester</th>
                          <th style={{ width: "12%" }}>Requested Date</th>

                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody className="stock-table-header">
                        {items?.tools?.map((item, i) => (
                          <tr key={i}>
                            <td style={{ width: "18%" }}>
                              <img
                                className="stock-img"
                                src={`${IMG_BASE_URL}${item?.image}`}
                                alt=""
                              />
                            </td>
                            <td style={{ width: "14%" }}>{item?.item_name}</td>
                            <td style={{ width: "10%" }}>{item?.amount}</td>
                            <td style={{ width: "12%" }}>{item?.model}</td>
                            <td style={{ width: "12%" }}>
                              {item?.serial_number}
                            </td>
                            <td style={{ width: "12%" }}>
                              {" "}
                              {item?.previous_store?.user?.name}
                            </td>
                            <td style={{ width: "12%" }}>{item?.amount}</td>
                            <td style={{ width: "10%" }}>
                              {/* <ConfirStore item={item} way={"single"} /> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                  <>
                    <ConfirStore
                      item={items?.tools}
                      way={"all"}
                      store={"tools"}
                      from={items?.tools?.[0]?.previous_store?.store_name}
                      to={items?.store_name}
                      userId={userinfo?.id}
                    />{" "}
                  </>
                </div>
              </div>
              <></>
            </div>
          </div>
        </div>
      ))}
    </StockIndex>
  );
}

export default StoreTransferTool;
