import { Style } from "@mui/icons-material";
import { style } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
  useRemoveMemberMutation,
} from "../../features/SCMApi";
import ConfirmDelete from "../common/ConfirmDelete";
import NoRecord from "../common/NoRecord";
import MemberSlider from "./MemberSlider";

export default function AssignedTeams(props) {
  let avatar = [];
  const [deleteDialog, setDialog] = useState(false);
  const [isConfirmed, setConfirmation] = useState(false);
  if (deleteDialog == true)
    document.querySelector(".body-custom").classList.add("disable-scroll");
  else if (deleteDialog == false)
    document.querySelector(".body-custom").classList.remove("disable-scroll");

  const [removeMember] = useRemoveMemberMutation();
  const [selectedMember, setMember] = useState();
  const [selectedProject, setProject] = useState();
  const [SelectedPersonName, setName] = useState();
  const HandleEdit = (e) => {
    setDialog(true);
  };
  console.log(props, "props");
  const actionRemove = () => {
    const form = { project: selectedProject, member: selectedMember };
    removeMember(form)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        setDialog(false);
        // window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
    console.log(form, "form");
  };
  console.log(props.project_members, "props.project_members");
  console.log(SelectedPersonName, "SelectedPersonName");
  const { projectId } = useParams();
  // let memberSlides = [];
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectId);
  const { data: userinfo } = useGetUserInfoQuery();

  const { data: projectmembers } = useGetProjectQuery(projectId);
  const membersList = [];
  props.project_members?.map((members) => membersList.push(members));

  console.log(membersList, "membersList");

  let index = 3;
  function handlePreviews() {
    let memberSlides = [];
    console.log(` ${index} index`);

    console.log("Previews");
    if (index == 3) memberSlides = [...membersList.slice(0, index)];
    else {
      memberSlides = [...membersList.slice(index - 6, index - 3)];
      index = index - 3;
      console.log(true);
    }

    return memberSlides;
  }
  function handleNext() {
    let memberSlides = [];
    console.log(`${index}   index`);
    if (index >= membersList?.length) {
      memberSlides = membersList.slice(index - 3, index);
    } else {
      memberSlides = membersList.slice(index, index + 3);
      index = index + 3;
    }

    console.log(memberSlides, "memberSlides");
    return memberSlides;
  }

  return (
    <>
      {deleteDialog ? (
        <ConfirmDelete
          action={actionRemove}
          setters={setConfirmation}
          setDialog={setDialog}
          dialog={`Remove ${SelectedPersonName} from the project ?`}
        />
      ) : (
        <></>
      )}
      <div
        className="assigned-team-container"
        style={{ marginRight: "0px", minHeight: "fit-content" }}
      >
        <div className="card-title" style={{ height: "43px" }}>
          Assigned Teams
        </div>
        <div className="divider"></div>

        {props.project_members?.length > 0 ? (
          <MemberSlider
            checkPermission={props?.checkPermission}
            projectmembers={projectmembers}
            userinfo={userinfo}
            roleRoles={roleRoles}
            HandleEdit={HandleEdit}
            setMember={setMember}
            setProject={setProject}
            setName={setName}
            // currentSlide={currentSlide}
            project_members={props.project_members}
            handlePreviews={handlePreviews}
            handleNext={handleNext}
          />
        ) : (
          <NoRecord />
        )}

        {props?.checkPermission(
          "add_member_to_project",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          <button
            className="assign-personal-btn mb-3"
            onClick={(e) => {
              window.scrollTo(0, 0);
              props.ModalToggle(true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                fill="white"
              />
            </svg>
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
