import React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import { Box } from "@mui/material";
import MissedItemETD from "./missedItemHeadingComponent/MissedItemETD";
import MissedItemProfile from "./missedItemHeadingComponent/MissedItemProfile";
import MissedItemOrigin from "./missedItemHeadingComponent/MissedItemOrigin";
import MissedItemStatus from "./missedItemHeadingComponent/MissedItemStatus";
import SuccessMessage from "../../Payment/SuccessMessage";

export default function MissedItemDetailsHeading(props) {
  console.log(props?.data, "missed item heading data");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return (
    <>
      <Box marginTop={"20px"}>
        <Grid container spacing={{ xs: 2, md: 2 }}>
          {successMessage === true ? (
            <>
              <SuccessMessage
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "98.5%",
                  marginLeft: "17px",
                  backgroundColor: "#52ffab96",
                  mb: "-10px",
                }}
                Message={message}
              />
            </>
          ) : (
            <></>
          )}
          <MissedItemETD
            data={props?.data}
            setSuccessMessage={setSuccessMessage}
            setMessage={setMessage}
          />
          <MissedItemProfile data={props?.data} />
          <MissedItemOrigin data={props?.data} />
          <MissedItemStatus
            data={props?.data}
            setSuccessMessage={setSuccessMessage}
            setMessage={setMessage}
          />
        </Grid>
      </Box>
    </>
  );
}
