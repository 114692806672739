import * as React from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Checkboxes(props) {
  function cheking() {
    const checkbox = document.getElementsByClassName("dataName");
    const checkedItem = [];
    Object.keys(checkbox)?.map((element) =>
      checkbox[element].checked === true
        ? checkedItem.push(checkbox[element].id)
        : null
    );
    console.log("checkedItem", checkedItem, checkbox);

    const items = props?.allOrderItemList
      ?.filter((filtered) => filtered?.forex_initiated === false)
      ?.filter((item) => checkedItem?.includes(item?.item_part_number));
    props?.setOrderList(items);
    items?.length > 0 ? props?.isChecked(false) : props?.isChecked(true);
  }
  console.log(props?.data, "props?.data");
  return (
    <>
      {props?.data
        ?.filter((filtered) => filtered?.forex_initiated === false)
        ?.map((dataName) => (
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              key={dataName}
              sx={{
                color: "#aeb8c9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                  marginTop: "1px",
                  marginLeft: "-20px",
                },
              }}
              control={
                <div className="check-box-field">
                  <input
                    className={"dataName"}
                    onChange={cheking}
                    type="checkbox"
                    name=""
                    id={dataName?.item_part_number}
                  />
                </div>
              }
              label={dataName?.item_description}
            />
          </Box>
        ))}
    </>
  );
}
