import React from "react";
import AgingTopBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { Tab, Tabs } from "react-bootstrap";
import RecievableAging from "./ReceivableAging/RecievableAging";
import RecievableUpcomig from "./RecivableUpcoming/RecivableUpcoming";

function RecievableIndex() {
  return (
    <div>
      <AgingTopBar title={"Receivable"}>
        <Tabs
          defaultActiveKey="Upcoming"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
        >
          <Tab eventKey="Upcoming" title="Upcoming">
            <RecievableUpcomig />
          </Tab>
          <Tab eventKey="Aging" title="Aging">
            <RecievableAging />
          </Tab>
        </Tabs>
      </AgingTopBar>
    </div>
  );
}

export default RecievableIndex;
