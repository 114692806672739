import React, { useEffect, useState } from "react";
import {
  useAssignPersonnelMutation,
  useGetProjectInputParamsQuery,
  useGetUsersQuery,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import ProjectMemberSelector from "../common/ProjectMemberSelector";

export default function AssignPersonnelModal(props) {
  // const { projectId } = useParams();
  // const { data: inputPrams } = useGetProjectMembersQuery(projectId);

  const { data: inputPrams } = useGetProjectInputParamsQuery();
  const { data: users } = useGetUsersQuery();
  let userList = [];
  let sourcingOfficers = [];

  let projectFinanceOfficers = [];
  let logisticsOfficers = [];
  let technicalLeaders = [];
  let fieldEngineers = [];
  const project_members = [];

  const [userRole, setUserRole] = useState();

  const [assignPersonnel, response] = useAssignPersonnelMutation();
  const [selectedSourcingOfficers, setSourcingOfficer] = useState([]);
  const [selectedProjectFinanceOfficers, setProjectFinanceOfficer] = useState(
    []
  );
  console.log(inputPrams, "inputPrams");

  const [selectedLogisticsOfficers, setLogisticsOfficer] = useState([]);
  const [selectedTechnicalLeaders, setTechnicalLeader] = useState([]);
  const [selectedFieldEngineers, setFieldEngineer] = useState([]);
  let project_member_id = [
    ...selectedSourcingOfficers,
    ...selectedProjectFinanceOfficers,
    ...selectedLogisticsOfficers,
    ...selectedTechnicalLeaders,
    ...selectedFieldEngineers,
  ];
  inputPrams?.data?.sourcingOfficers?.map((sourcingOfficer) =>
    sourcingOfficers.push({
      value: sourcingOfficer?.id,
      label: sourcingOfficer?.name,
    })
  );

  inputPrams?.data?.projectFinanceOfficers?.map((projectFinanceOfficer) =>
    projectFinanceOfficers.push({
      value: projectFinanceOfficer?.id,
      label: projectFinanceOfficer?.name,
    })
  );

  inputPrams?.data?.technicalLeaders?.map((technicalLeader) =>
    technicalLeaders.push({
      value: technicalLeader?.id,
      label: technicalLeader?.name,
    })
  );

  inputPrams?.data?.logisticsOfficers?.map((logisticsOfficer) =>
    logisticsOfficers.push({
      value: logisticsOfficer?.id,
      label: logisticsOfficer?.name,
    })
  );

  inputPrams?.data?.fieldEngineers?.map((fieldEngineer) =>
    fieldEngineers.push({
      value: fieldEngineer?.id,
      label: fieldEngineer?.name,
    })
  );
  const { projectId } = useParams();

  function handleSubmit(e) {
    e.preventDefault();
    const form = { members: project_members, project: projectId };
    console.log(form, "form");
    assignPersonnel(form)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        props?.ModalToggle(false);
        // window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });

    // axios
    //   .post(`${API_BASE_URL}/scm/project/projects/add-members`, form, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //     console.log(form, "inputs");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  const fetchingData = () => {
    axios
      .get(`${API_BASE_URL}/view/project-roles`)
      .then((res) => {
        // console.log(res?.data, "res");
        setUserRole(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchingData();
  }, []);
  const oldData = [];
  props?.project_members?.map((member) =>
    oldData.push({ user_id: member?.id, role_id: member?.role_id })
  );
  console.log(oldData, "oldData");
  console.log(props?.project_members, "old project_members");
  function handleMembers(data) {
    // console.log(data, "data");
    // let isNew = true;
    if (project_members.length > 0) {
      for (let i = 0; i < project_members.length; i++) {
        if (project_members[i].role_id == data.role_id) {
          if (data?.user_id.length == 0) {
            console.log("delete");
          } else {
            if (project_members[i]?.role_id == data?.role_id) {
              project_members[i] = data;
              console.log("updated");
            }
          }
        }
      }
      const condition = project_members?.filter(
        (items) => items?.role_id == data?.role_id
      );
      console.log(condition, "condition");
      condition.length == 0 ? project_members.push(data) : <></>;
    } else project_members.push(data);

    // setProjectMembers(...project_members);

    // project_members.length>0?.map((member) => console.log(member, "member"));
    console.log(project_members, "project_members");
    console.log(project_members.length, "project_members");
  }

  users?.data?.map((logisticsOfficer) =>
    userList.push({
      value: logisticsOfficer?.id,
      label: logisticsOfficer?.name,
    })
  );
  return (
    <div>
      <div>
        {" "}
        <div
          id="myModal"
          className="mini-modal"
          onClick={(e) => {
            e.preventDefault();
            props?.ModalToggle(false);
          }}
        >
          <div
            className="mini-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="create-project-modal-title">
              Assign Personnel
              <div
                className="close-modal-button"
                onClick={(e) => {
                  e.preventDefault();
                  props?.ModalToggle(false);
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                    fill="#353945"
                  />
                </svg>
              </div>
            </div>
            <form className="row justify-content-center gap-2">
              {userRole?.data?.map((items) => (
                // console.log(items, "items")
                <div className="row align-items-center  gap-1 justify-content-center ">
                  <div className="input-label col-3 px-0">
                    {items?.role_name}
                  </div>

                  <ProjectMemberSelector
                    role={items?.id}
                    list={userList}
                    setters={handleMembers}
                    label={items?.role_name}
                  />
                </div>
              ))}

              <div className="row mt-4 align-items-center justify-content-center gap-3">
                <button
                  type="cancel"
                  className="col-6 form-cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    props?.ModalToggle(false);
                  }}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type={"submit"}
                  className="col-6 form-save"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
