import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePagination from "../../Pagination/Pagination";
import { IconButton, Pagination } from "@mui/material";
import { useGetProjectsQuery } from "../../../features/SCMApi";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import EditGuarantee from "./EditGurantee";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ClearGuarantee from "./ClearGuarantee";
import { totalDates } from "../../dateFormator";
export default function PerformanceGuarantee({ data, cleared }) {
  let [page, setPage] = React.useState(1);
  const { data: Projects } = useGetProjectsQuery();
  const PER_PAGE = 5;
  const count = Math.ceil(data?.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(data, cleared, "datadata");
  let numbers = 0;
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  let rows = {
    fontSize: "12px",
    padding: `10px`,
  };
  let danger_rows = {
    fontSize: "12px",
    padding: `10px`,
    backgroundColor: "#000",
  };

  // function totalDates(endDate) {
  //   const dateStr = endDate;
  //   const end_date = new Date(dateStr);
  //   const currentDate = new Date();
  //   const diffTime = end_date.getTime() - currentDate.getTime();
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   let countDown = diffDays;
  //   return countDown;
  // }
  const [row, setRow] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const [clear, setClear] = React.useState(false);
  function HandleEdit(e, item) {
    setEdit(true);
    setRow(item);
  }
  function HandleClear(e, item) {
    setRow(item);
    setClear(item);
  }
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell sx={rows}></TableCell>
            <TableCell sx={rows}>CLIENT</TableCell>
            <TableCell sx={rows}>REF NUM BANK</TableCell>
            <TableCell sx={rows}>REF IE BANK</TableCell>
            <TableCell sx={rows}>AMOUNT</TableCell>
            <TableCell sx={rows}>BALANCE</TableCell>
            <TableCell sx={rows}>DESCRIPTION</TableCell>
            <TableCell sx={rows}>START DATE</TableCell>
            <TableCell sx={rows}>END DATE</TableCell>
            <TableCell sx={rows}>TOTAL DATES</TableCell>
            <TableCell sx={rows}>DATE LEFT</TableCell>
            <TableCell sx={rows}>RATE</TableCell>
            <TableCell sx={rows}>PERIOD</TableCell>
            <TableCell sx={rows}>COST</TableCell>
            {cleared == true ? "" : <TableCell sx={rows}>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {_DATA?.currentData()?.map((row, index) => (
            <TableRow
              key={index}
              className={`${
                totalDates(row.end_date) <= 15 && totalDates(row.end_date) > 0
                  ? "bg-warning"
                  : totalDates(row.end_date) < 0
                  ? "bg-danger"
                  : ""
              }`}
            >
              <TableCell sx={rows} align="">
                {(numbers += 1)}
              </TableCell>
              <TableCell sx={rows} align="">
                {row?.project?.project_name}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.bank_ref_num == null ? "Unknown" : row.bank_ref_num}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.ref_num == null ? "Unknown" : row.ref_num}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.amount?.toLocaleString()}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.guarantee_balance?.current_balance?.toLocaleString()}
              </TableCell>
              <TableCell sx={rows} align="">
                {row?.description == null ? "-" : row?.description}
              </TableCell>
              <TableCell sx={rows} align="">
                {date(row.start_date)}
              </TableCell>
              <TableCell sx={rows} align="">
                {date(row.end_date)}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.total_dates}
              </TableCell>
              <TableCell
                sx={totalDates(rows.end_date) <= 2 ? danger_rows : rows}
                align=""
              >
                {totalDates(row.end_date)}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.rate == null ? "-" : row.rate}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.period == null ? "-" : row.period}
              </TableCell>
              <TableCell sx={rows} align="">
                {row.cost?.toLocaleString()}
              </TableCell>
              {cleared == true ? (
                ""
              ) : (
                <TableCell sx={rows} align="">
                  <Tooltip title="Edit Row">
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        backgroundColor: "#fff",
                        color: "#064B4F",
                        marginRight: "5px",
                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={(e) => HandleEdit(e, row)}
                    >
                      <ModeEditOutlineOutlinedIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear Guarantee">
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        backgroundColor: "#fff",
                        color: "#064B4F",
                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={(e) => HandleClear(e, row)}
                    >
                      <ClearAllIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )}
      {edit ? <EditGuarantee modal={setEdit} all={true} data={row} /> : ""}
      {clear ? <ClearGuarantee modal={setClear} all={true} data={row} /> : ""}
    </TableContainer>
  );
}
