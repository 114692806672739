import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import { useGetProjectsQuery } from "../../../features/SCMApi";

export default function Summary({ data }) {
  // let [page, setPage] = React.useState(1);
  // const PER_PAGE = 5;
  // const count = Math.ceil(data?.length / PER_PAGE);
  // const _DATA = usePagination(data, PER_PAGE);
  // const handleChange = (e, p) => {
  //   setPage(p);
  //   _DATA.jump(p);
  // };
  // console.log(_DATA, "rows");
  let numbers = 0;
  let rows = {
    fontSize: "12px",
    padding: `10px`,
  };
  let datas = [];
  let summary = [
    {
      name: "",
      limit: "",
      amount: 0,
      balance: 0,
    },
  ];
  function amount(numbers) {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i];
    }
    return sum;
  }
  let finalSummary = Object.keys(data == undefined ? [] : data).map(
    (key, index) =>
      (summary[index] = {
        name: `Summary of ${key}`,
        balance: data[key][0]?.balance,
        amount: amount(data[key]?.map((items) => items?.amount)),
        cost: amount(data[key]?.map((items) => items?.cost)),
      })
  );
  console.log(data, "summary Data");

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell sx={rows}></TableCell>
            <TableCell sx={rows}>Description</TableCell>
            <TableCell sx={rows}>GUARANTEE LIMIT</TableCell>
            <TableCell sx={rows}>USED AMOUNT</TableCell>
            <TableCell sx={rows}>COST</TableCell>
            <TableCell sx={rows}>BALANCE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {finalSummary?.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={rows} align="">
                {(numbers += 1)}
              </TableCell>
              <TableCell sx={rows} align="">
                {row?.name}
              </TableCell>
              <TableCell sx={rows} align="">
                {row?.name == "Summary of Performance Guarantee"
                  ? Number(150000000)?.toLocaleString()
                  : row?.name == "Summary of Advance Guarantee"
                  ? Number(130000000)?.toLocaleString()
                  : ""}
              </TableCell>
              <TableCell sx={rows} align="">
                {Number(row?.amount)?.toLocaleString()}
              </TableCell>
              <TableCell sx={rows} align="">
                {Number(row?.cost)?.toLocaleString()}
              </TableCell>
              <TableCell sx={rows} align="">
                {row?.name == "Summary of Performance Guarantee"
                  ? Number(150000000 - row?.amount)?.toLocaleString()
                  : row?.name == "Summary of Advance Guarantee"
                  ? Number(130000000 - row?.amount)?.toLocaleString()
                  : ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableCell sx={rows} align=""></TableCell>
        <TableCell sx={rows} align="">
          TOTAL
        </TableCell>
        <TableCell sx={rows} align="">
          {Number(finalSummary[0]?.balance)?.toLocaleString()}
        </TableCell>
        <TableCell sx={rows} align="">
          {Number(
            amount(finalSummary?.map((items) => items?.amount))
          )?.toLocaleString()}
        </TableCell>
        <TableCell sx={rows} align="">
          {Number(
            amount(finalSummary?.map((items) => items?.cost))
          )?.toLocaleString()}
        </TableCell>
        <TableCell sx={rows} align="">
          {Number(
            finalSummary[0]?.balance -
              amount(finalSummary?.map((items) => items?.amount))
          )?.toLocaleString()}
        </TableCell>
      </Table>
      {/* {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )} */}
    </TableContainer>
  );
}
