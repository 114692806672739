export default function CheckProjectPermission(
  Permission,
  projectmembers,
  userinfo,
  roleRoles
) {
  console.log(userinfo?.permissions, "userinfo?.role?.role_name");
  if (userinfo?.permissions[0]?.slug === "all") {
    const userId = userinfo?.id;
    console.log(userinfo, "response");
    console.log(Permission, "Permission");

    console.log(userId, "userId");
    let isAuthorized = true;
    let permissions = userinfo?.permissions;
    const condition = permissions?.filter(
      (item) => item?.slug === Permission || item?.slug === "all"
    );
    condition?.length ? (isAuthorized = true) : (isAuthorized = false);
    console.log(userinfo, "bool");
    console.log(condition, "condition");
    console.log(permissions, "permissions");
    return isAuthorized;
  } else {
    const userId = userinfo?.id;
    console.log(userinfo, "response");
    console.log(Permission, "Permission");
    let isAuthorized = false;
    let usersRole = [];
    let setOfUserRole = [];
    let members =
      projectmembers?.data?.users === undefined
        ? []
        : projectmembers?.data?.users;
    console.log(projectmembers?.data?.users, "ola");
    const isMember = [...members?.filter((member) => member?.id === userId)];

    console.log(isMember, members, userId, roleRoles, "isMember");
    if (isMember?.length > 0) {
      console.log(isMember, "isMember");
      isMember?.map((useRoles) => usersRole.push(useRoles?.pivot?.role_id));
      setOfUserRole = roleRoles?.data?.roles?.filter((role) =>
        usersRole?.includes(role?.id)
      );
    }
    console.log(setOfUserRole, "setOfUserRole");
    let permissions = [];
    let bool = setOfUserRole?.filter((items) =>
      permissions.push(...items?.permissions)
    );

    const condition = permissions?.filter((item) => item?.slug === Permission); // permissions = [...permissions];
    condition.length ? (isAuthorized = true) : (isAuthorized = false);
    console.log(condition, permissions, "condition");
    console.log(condition, "isAuthorized");
    return isAuthorized;
  }
}
