import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useGetMappingsQuery } from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
export default function SuppliermappingIndex(props) {
  const {
    data: mappings,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetMappingsQuery();
  const product = mappings?.data?.suppliers;

  let productList;
  for (let prod in product) productList = product[prod].product;

  console.log(mappings?.data, "sourcing mapping");
  // const [search, setSearch] = useState("");

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  //   console.log(search, "search");
  // };
  return (
    <div className="">
      {mappings?.data?.suppliers?.length !== 0 ? (
        <>
          {mappings?.data?.suppliers
            .filter((items) => {
              if (props.search == "") {
                return items;
              } else if (
                items.distributor_name?.toLowerCase().includes(props.search)
              ) {
                return items;
              }
            })
            .map((items) =>
              items?.product.length != 0 ? (
                <div className="mapping-card-container primary pb-5 mb-3">
                  <div>
                    <div className="mapping-card-header mt-4 ms-5">
                      {items.distributor_name}
                    </div>
                    <div className="row products_padding">
                      {items?.product?.map((product) => (
                        <div class="col-sm-3 col-md-2 me-3">
                          <div class="card mapping ">
                            <Tooltip title={product?.manufacturer_name}>
                              <div>
                                <p class="h5 text-center my-2">
                                  {product?.manufacturer_name?.length >= 22
                                    ? product?.manufacturer_name.slice(0, 10) +
                                      "..."
                                    : product?.manufacturer_name}
                                </p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
