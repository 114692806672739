import React, { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import OnUseInventory from "./OnUseInventory";
import OnUseTools from "./OnUseTools";
import OnUseAssets from "./OnUseAssets";

export default function OnUse() {
  const [Items, setItems] = useState("Tool");
  function onChangeValue(event) {
    setItems(event.target.value);
  }
  return (
    <StockIndex>
      <div
        className="tool-header-container d-flex gap-3 justify-content-center "
        onChange={onChangeValue}
      >
        <input
          checked={Items === "Tool"}
          id="default-radio-1"
          type="radio"
          value="Tool"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-1" className="prg">
          Tools
        </label>

        <input
          checked={Items === "Inventory"}
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-2" className="prg">
          Items
        </label>
        <input
          checked={Items === "Assets"}
          id="default-radio-2"
          type="radio"
          value="Assets"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-2" className="prg">
          Assets
        </label>
      </div>
      {Items === "Tool" ? (
        <OnUseTools />
      ) : Items === "Inventory" ? (
        <OnUseInventory />
      ) : (
        <OnUseAssets />
      )}
    </StockIndex>
  );
}
