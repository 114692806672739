import axios from "axios";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import {
  useEditProjectMutation,
  useGetCurrenciesQuery,
} from "../../features/SCMApi";

export default function EditProjectModal(props) {
  const { data: currency } = useGetCurrenciesQuery();
  const [editProject] = useEditProjectMutation();
  const { projectId } = useParams();
  const [currency_id, setCurrency] = useState(null);
  const [project_name, setProjectName] = useState(null);
  const [budget, setBudget] = useState(null);
  const [contract_sign_date, setContractSignDate] = useState(null);
  let [forex_resource, setForexResource] = useState(null);
  const [milestone_amount, setMilestoneAmount] = useState(null);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [contract_payment, setContractPayment] = useState(null);
  const [forex_contract_payment, setForexContractPayment] = useState(null);
  const [forex_contract_payment_currency, setForexContractPaymentCurrency] =
    useState(null);
  console.log(props?.previewsData, "contract_payment");
  function handleCancel(e) {
    e.preventDefault();
    props?.ToggleModal(false);
  }
  const editProjectForm = {
    id: projectId,
    project_name: project_name
      ? project_name
      : props?.previewsData?.project_name,
    budget: budget ? budget : props?.previewsData?.budget,
    currency_id: currency_id ? currency_id : props?.previewsData?.currency?.id,
    contract_sign_date: contract_sign_date
      ? contract_sign_date
      : props?.previewsData?.contract_sign_date,
    forex_resource:
      forex_resource != null ? forex_resource : props?.previewsData?.forex,
    milestone_amount: milestone_amount
      ? milestone_amount
      : props?.previewsData?.milestones,
    end_date: end_date ? end_date : props?.previewsData?.data?.end_date,
    contract_payment: contract_payment
      ? contract_payment
      : props?.previewsData?.data?.contract_payment,
    contract_payment: contract_payment
      ? contract_payment
      : props?.previewsData?.data?.contract_payment,
    forex_contract_payment: forex_contract_payment
      ? forex_contract_payment
      : props?.previewsData?.data?.forex_contract_payment,
    forex_contract_payment_currency: forex_contract_payment_currency
      ? forex_contract_payment_currency
      : props?.previewsData?.data?.forex_contract_payment_currency,
  };
  console.log(editProjectForm, "editProjectForm");
  function handleSubmit(e) {
    e.preventDefault();

    // editProject(editProjectForm)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "RTK");
    //     props?.setMessage("Project created successfully");
    //     props?.setSuccessToggle(true);
    //     handleCancel(e);
    //     window?.location?.reload(true);
    //   })
    //   .then((error) => {
    //     console.log(error, "erorr from backend");
    //   });
    axios
      .post(
        `${API_BASE_URL}/scm/project/projects-update/${editProjectForm?.id}`,
        editProjectForm,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        console.log(editProjectForm, "inputs");
        window?.location?.reload(true);
      })
      .catch(function (error) {
        console.log(error, "kef malet");
      });
  }
  console.log(props?.previewsData, "props?.previewsData?.end_date");
  return (
    <div className="mini-modal" onClick={(e) => handleCancel(e)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>Edit Project</div>
          <div className="close-modal-button" onClick={(e) => handleCancel(e)}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="form-container-edit-project"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="inputs-group ">
            <div className="input-label"> Project Name</div>
            <input
              className="file-chooser "
              type="text"
              disabled={true}
              defaultValue={props?.previewsData?.project_name}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>
          <div className="inputs-group ">
            <div className="input-label">Project Budget</div>
            <div
              style={{
                width: "200px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <input
                className="small-text-field text-dark "
                type="number"
                defaultValue={props?.previewsData?.budget}
                onChange={(e) => setBudget(e.target.value)}
              />
              {console.log(props?.previewsData, "olalala")}
              <select
                name=""
                id=""
                className="small-text-field text-dark "
                required
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option selected="false" disabled="disabled">
                  {
                    currency?.data?.filter(
                      (cur) => cur?.id == props?.previewsData?.current_currency
                    )[0]?.code
                  }
                </option>
                {currency?.data?.map((curr) => (
                  <option value={curr?.id}>{curr?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="inputs-group ">
            <div className="input-label">Contract Payment in ETB</div>
            <input
              className="file-chooser "
              type="Number"
              defaultValue={props?.previewsData?.data?.contract_payment}
              onChange={(e) => setContractPayment(e.target.value)}
            />
          </div>
          <div className="inputs-group ">
            <div className="input-label">Forex Payment</div>
            <div
              style={{
                width: "200px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <input
                className="small-text-field text-dark "
                type="number"
                defaultValue={props?.previewsData?.data?.forex_contract_payment}
                onChange={(e) => setForexContractPayment(e.target.value)}
              />
              {console.log(props?.previewsData, "olalala")}
              <select
                name=""
                id=""
                className="small-text-field text-dark "
                required
                onChange={(e) =>
                  setForexContractPaymentCurrency(e.target.value)
                }
              >
                <option selected="false" disabled="disabled">
                  {
                    currency?.data?.filter(
                      (cur) =>
                        cur?.id ==
                        props?.previewsData?.data
                          ?.forex_contract_payment_currency
                    )[0]?.code
                  }
                </option>
                {currency?.data?.map((curr) => (
                  <option value={curr?.id}>{curr?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="inputs-group ">
            <div className="input-label">contract signed date</div>
            <input
              className="file-chooser "
              type="date"
              defaultValue={props?.previewsData?.contract_sign_date}
              onChange={(e) => setContractSignDate(e.target.value)}
            />
          </div>

          <div className="inputs-group ">
            <div className="input-label">Project end date</div>
            <input
              className="file-chooser "
              type="date"
              defaultValue={props?.previewsData?.data?.end_date}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="inputs-group ">
            <div className="input-label">Milestones</div>
            <input
              className="file-chooser "
              type="text"
              defaultValue={props?.previewsData?.milestones}
              onChange={(e) => setMilestoneAmount(e.target.value)}
            />
          </div>
          <div className="inputs-group">
            <div className="input-label">Forex Resource</div>
            <select
              className="file-chooser "
              name=""
              id=""
              onChange={(e) => setForexResource(JSON.parse(e.target.value))}
            >
              <option selected="false" disabled="disabled">
                {props?.previewsData?.forex == 0
                  ? "IE Network solutions"
                  : "By Client"}
              </option>
              <option value={true}>Client</option>
              <option value={false}>IE Network solutions</option>
            </select>
          </div>
          <div className="form-buttons mt-4">
            <button
              type="cancel"
              className="form-cancel"
              onClick={handleCancel}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="form-save">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
