import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAddPaymentTermMutation,
  useAddPurchasedRequestMutation,
  useAddToolTypeMutation,
  useGetItemCategorysQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "../../../components/Settings/SettingModal/modal.css";
import "../../../pages/Stocks/stock.style.css";
import axios from "axios";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
export default function EditPurchaseRequest(props) {
  const [purchasedRequest, res] = useAddPurchasedRequestMutation();
  const [item_name, setItemName] = useState(props?.data?.item_name);
  const [description, setDescription] = useState(props?.data?.description);
  const [project_id, setproject] = useState(props?.data?.project_id);
  const [category_id, setCategory] = useState(props?.data?.category_id);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const { data: response } = useGetProjectsQuery();
  const [image, setImage] = useState({ preview: "", data: "" });
  const handleChange = function loadFile(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e?.target?.files[0],
    };
    setImage(img);
  };
  let purchased = {
    item_name,
    description,
    project_id,
    category_id,
    requested_by: userinfo?.id,
    purchase_request_image: image?.data,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // purchasedRequest(purchased)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    //     props.modal(false);
    //     props.setMessages("Payment Term created successfully");
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //   });
    axios
      .put(
        `${API_BASE_URL}/scm/stock/purchase-request/update/${props?.data?.id}`,
        purchased,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        props.modal(false);
        console.log(purchased, "purchased");
      })
      .catch(function (error) {
        console.log(error, "errorrrrrrrrrrrrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }
  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Edit Purchased Request </h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div id="request--icon">
              <input
                name="Select File"
                type="file"
                onChange={handleChange}
                accept="image/*"
              ></input>
              {image?.preview !== "" ? (
                <img
                  className="add-purchase-image"
                  src={image?.preview}
                  alt="image description"
                />
              ) : (
                <img
                  className="add-purchase-image"
                  src={`${IMG_BASE_URL}${props?.data?.image}`}
                  alt="image description"
                />
              )}
            </div>
            <div className="d-grid gap-3 mt-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Item Name</div>
                <input
                  value={item_name}
                  onChange={(e) => setItemName(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Project</div>
                <select
                  value={project_id}
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setproject(e.target.value)}
                >
                  {" "}
                  <option selected="true" disabled="disabled">
                    Choose Project
                  </option>
                  {response?.data?.map((items) => (
                    <option value={items.id}>{items?.project_name}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Category</div>
                <select
                  value={category_id}
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {" "}
                  <option selected="true" disabled="disabled">
                    Choose Category
                  </option>
                  {ItemCategory?.data?.map((items) => (
                    <option value={items.id}>{items?.category}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
