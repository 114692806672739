import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const CostBuildUp = ({ data, type, is_tool, is_return }) => {
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = "Cost Build";
  let title3 = "Report";
  let count = 0;
  console.log(data, "Datasssss");
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    // const headersCost = data?.map(
    //   (items) => `${items?.p} Planned Cost `
    // );
    // const headersRevenue = result?.map(
    //   (items) => `${items?.start_date} Planned Revenue `
    // );

    // const item_names = result?.map((items,index) =>
    // result[index]?.events[0]?.variance_project[0].project_name
    // );
    // const headersActualCost = result?.map((items) =>
    //   items?.events
    //     .map((e, index) => Number(e?.actual_cost))
    //     .reduce((a, b) => {
    //       return a + b;
    //     }
    //     )
    //     );
    // const headersPlannedCost = result?.map((items) =>
    //   items?.events
    //     .map((e, index) => Number(e?.planned_cost))
    //     .reduce((a, b) => {
    //       return a + b;
    //     }
    //     )
    // );

    // let PlannedTotalCost = 0;
    // headersPlannedCost.map((item)=> (

    //   PlannedTotalCost+=item

    //  )
    //   )

    //   let ActualTotalCost = 0;
    //   headersActualCost.map((item) => (

    //        ActualTotalCost+=item

    //   ));

    // console.log(PlannedTotalCost, "headersPlannedCost");
    // Add title cell above the table headers
    worksheet.mergeCells("A1:AE1");
    worksheet.mergeCells("A2:AE2");
    worksheet.mergeCells("A3:AE3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Times New Roman",
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell2.font = {
      name: "Times New Roman",
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "SN",
      "Project List",
      "Item Description",
      "C no",
      "Ex Rate Per Declaration",
      "Ex Rate Per Invoice",
      "Item price in USD per Declaration",
      "Item price in USD per Invoice",
      "Freight",
      "Insurance",
      "Other",
      "Total FOB Item Price in ETB as per Declaration",
      "Total FOB Item Price in ETB as per Invoice",
      "Custom Duty",
      "Vat",
      "Sure Tax",
      "Withholding Tax",
      "Excises Tax",
      "Freight Sea/Air",
      "Main Insurance",
      "Clearing/Storage and warehouse service",
      "Transitor Service",
      "InLand Transport",
      "Loading Unloading Expense",
      "Bank Charge",
      "Total Inland Cost",
      "Total as per Declaration",
      "Total as per Invoice",
      "Variance",
      "Remark",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    let cols = 2;
    console.log(headerRow, "headerRow");
    worksheet.getColumn(1).width = 10;
    headerRow?._cells?.map(
      (items, index) => (worksheet.getColumn(cols++).width = 40)
    );

    // Set the header row to bold
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(4);

    // Populate the table with data from the map
    data?.map((items) => {
      const dataRow = worksheet?.addRow([
        (count += 1),
        items?.project_id == null ? "-" : items?.project?.project_name,
        items?.model == null ? "-" : items?.model,
        items?.tax_document?.Custom_No == null
          ? "-"
          : items?.tax_document?.Custom_No,
        items?.tax_document?.EX_Rate_per_Declaretion == null
          ? "-"
          : items?.tax_document?.EX_Rate_per_Declaretion,
        items?.EX_Rate_per_Invoice != null ? items.EX_Rate_per_Invoice : "-",
        items?.tax_document?.IPI_USD_per_Declaretion != null
          ? items?.tax_document?.IPI_USD_per_Declaretion
          : "-",
        items?.IPI_USD_Per_Invoice != null ? items?.IPI_USD_Per_Invoice : "-",
        items?.tax_document?.Freight != null
          ? items?.tax_document?.Freight
          : "-",
        items?.tax_document?.Insurance != null
          ? items?.tax_document?.Insurance
          : "-",
        items?.tax_document?.other != null ? items?.tax_document?.other : "-",
        items?.tax_document?.EX_Rate_per_Declaretion *
          items?.tax_document?.IPI_USD_per_Declaretion +
          items?.tax_document?.Freight +
          items?.tax_document?.Insurance +
          items?.tax_document?.other,
        items?.EX_Rate_per_Invoice * items?.IPI_USD_Per_Invoice,
        items?.tax_document?.customs_duty != null
          ? items?.tax_document?.customs_duty
          : "-",
        items?.tax_document?.vat != null ? items?.tax_document?.vat : "-",
        items?.tax_document?.sure_tax != null
          ? items?.tax_document?.sure_tax
          : "-",
        items?.tax_document?.withholding_tax != null
          ? items?.tax_document?.withholding_tax
          : "-",
        items?.tax_document?.excise_tax != null
          ? items?.tax_document?.excise_tax
          : "-",
        items?.tax_document?.freight_sa != null
          ? items?.tax_document?.freight_sa
          : "-",
        items?.tax_document?.marin_insurance != null
          ? items?.tax_document?.marin_insurance
          : "-",
        items?.Clearing_and_warehouse_Service != null
          ? items?.Clearing_and_warehouse_Service
          : "-",
        items?.Transitor_Service != null ? items?.Transitor_Service : "-",
        items?.Inland_Transport != null ? items?.Inland_Transport : "-",
        items?.Loading_unloading_Expense != null
          ? items?.Loading_unloading_Expense
          : "-",
        items?.Bank_Charge != null ? items?.Bank_Charge : "-",
        Number(items?.Bank_Charge) +
          Number(items?.Loading_unloading_Expense) +
          Number(items?.Inland_Transport) +
          Number(items?.Clearing_and_warehouse_Service) +
          Number(
            items?.marin_insurance == undefined ? 0 : items?.marin_insurance
          ) +
          Number(items?.freight_sa == undefined ? 0 : items?.freight_sa) +
          Number(items?.excise_tax == undefined ? 0 : items?.excise_tax) +
          Number(
            items?.withholding_tax == undefined ? 0 : items?.withholding_tax
          ) +
          Number(items?.sure_tax == undefined ? 0 : items?.sure_tax) +
          Number(items?.vat == undefined ? 0 : items?.vat) +
          Number(items?.customs_duty == undefined ? 0 : items?.customs_duty),
        items?.tax_document?.EX_Rate_per_Declaretion *
          items?.tax_document?.IPI_USD_per_Declaretion +
          items?.tax_document?.Freight +
          items?.tax_document?.Insurance +
          items?.tax_document?.other +
          (Number(items?.Bank_Charge) +
            Number(items?.Loading_unloading_Expense) +
            Number(items?.Inland_Transport) +
            Number(items?.Clearing_and_warehouse_Service) +
            Number(
              items?.marin_insurance == undefined ? 0 : items?.marin_insurance
            ) +
            Number(items?.freight_sa == undefined ? 0 : items?.freight_sa) +
            Number(items?.excise_tax == undefined ? 0 : items?.excise_tax) +
            Number(
              items?.withholding_tax == undefined ? 0 : items?.withholding_tax
            ) +
            Number(items?.sure_tax == undefined ? 0 : items?.sure_tax) +
            Number(items?.vat == undefined ? 0 : items?.vat) +
            Number(
              items?.customs_duty == undefined ? 0 : items?.customs_duty
            )) -
          items?.tax_document?.vat +
          items?.tax_document?.withholding_tax,

        Number(items?.EX_Rate_per_Invoice) *
          Number(items?.IPI_USD_Per_Invoice) -
          (Number(items?.tax_document?.vat) +
            Number(items?.tax_document?.withholding_tax)),

        Number(items?.Bank_Charge) +
          Number(items?.Loading_unloading_Expense) +
          Number(items?.Inland_Transport) +
          Number(items?.Clearing_and_warehouse_Service) +
          Number(
            items?.marin_insurance == undefined ? 0 : items?.marin_insurance
          ) +
          Number(items?.freight_sa == undefined ? 0 : items?.freight_sa) +
          Number(items?.excise_tax == undefined ? 0 : items?.excise_tax) +
          Number(
            items?.withholding_tax == undefined ? 0 : items?.withholding_tax
          ) +
          Number(items?.sure_tax == undefined ? 0 : items?.sure_tax) +
          Number(items?.vat == undefined ? 0 : items?.vat) +
          Number(items?.customs_duty == undefined ? 0 : items?.customs_duty) -
          items?.tax_document?.vat +
          items?.tax_document?.withholding_tax -
          (Number(items?.EX_Rate_per_Invoice) *
            Number(items?.IPI_USD_Per_Invoice) -
            (Number(items?.tax_document?.vat) +
              Number(items?.tax_document?.withholding_tax))),
        items?.Status == 0 ? items?.Remark : "Done",
      ]);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    // const FooterRow = worksheet.addRow([
    //   " ",
    //   "Total",
    //   PlannedTotalCost,
    //   " ",
    //   " ",
    //   ActualTotalCost,
    //   " ",
    //   " ",
    //   " ",
    //   " ",
    //   ]);
    //   FooterRow.eachCell((cell) => {
    //     cell.fill = {
    //       type: "pattern",
    //       pattern: "solid",
    //       fgColor: { argb: "BFBFBF" },
    //     };
    //     cell.font = {
    //       color: { argb: "000000" },
    //       bold: true,
    //       size: 12,
    //       name: "Times New Roman",
    //     };
    //     cell.border = {
    //       top: { style: "thin", color: { argb: "000000" } },
    //       left: { style: "thin", color: { argb: "000000" } },
    //       bottom: { style: "thin", color: { argb: "000000" } },
    //       right: { style: "thin", color: { argb: "000000" } },
    //     };
    //     cell.alignment = {
    //       vertical: "middle",
    //       horizontal: "right",
    //       wrapText: true,
    //     };
    //   });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, type);
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default CostBuildUp;
