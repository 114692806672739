import { Pagination, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../api/endPoint";
import { useGetEtasQuery, useGetProjectsQuery } from "../../features/SCMApi";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";
export default function EtaFollowup(props) {
  const params = useParams();
  const { data: eta } = useGetEtasQuery(params.id);
  const { data: response } = useGetProjectsQuery();
  console.log(eta?.data, "eta index");
  // console.log(response, "response index");
  const Projectdata = [];
  console.log("props?.order_type", props?.order_type);
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  let [page, setPage] = useState(1);
  // const filterData = eta?.data.filter(
  //   (item) => item.order_type == props?.order_type
  // );
  const filterData = eta?.data.filter(
    (item) =>
      item.order_type === props?.order_type &&
      (!props?.serviceType ||
        item.service_type === props?.serviceType ||
        item.service_type === "")
  );
  console.log("filterDatas : ", props?.serviceType, filterData);
  const PER_PAGE = 9;
  const count = Math.ceil(filterData?.length / PER_PAGE);
  const _DATA = usePagination(filterData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <>
      {filterData?.length !== 0 ? (
        <div className="order-card-container mt-3">
          {_DATA
            .currentData()
            ?.filter((items) => {
              if (props?.search === "") {
                return items;
              } else if (
                items.order_name?.toLowerCase().includes(props?.search)
              ) {
                return items;
              }
            })
            ?.map((items) => (
              <NavLink
                to={`/sourcing/eta/etaDetail/${items.id}`}
                state={{
                  names: items?.order_name,
                  title: items?.project?.project_name,
                  navlink: `/sourcing/eta/${params.id}`,
                }}
                className="order-card"
              >
                <div className="order-information-container">
                  <Tooltip title={items?.order_name}>
                    <div className="order-name">
                      {items?.order_name?.length >= 30
                        ? items?.order_name?.slice(0, 18) + "..."
                        : items?.order_name}
                    </div>
                  </Tooltip>

                  {/* {Projectdata[0]?.roles?.map((items) =>
                    items?.role_name === "Project Manager" ? (
                      <div className="sourcing-officer-info">
                        <div className="pa-participant-img">
                          {items?.users?.avatar !== "" ? (
                            <img
                              src={`${IMG_BASE_URL}${items?.users?.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = items?.name?.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div className="sourcing-officer">
                          <div className="sourcing-officer-name">
                            {items?.name}
                          </div>
                          <div className="sourcing-officer-designation">
                            Sourcing Officer
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )} */}
                </div>
                <div className="order-info">
                  <div className="order-project">
                    <div>
                      <div className="project-name">
                        {items?.project?.project_name}
                      </div>
                      <div className="project-label">Project</div>
                    </div>

                    <div>
                      <div className="project-name">{items?.order_type}</div>
                      <div className="project-label">Order Type</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="order-supplier">
                  {items?.supplier?.distributor_name}
                </div>
              </NavLink>
            ))}
        </div>
      ) : (
        <NoRecord />
      )}
      <div className="mt-3">
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        ) : (
          ""
        )}
      </div>{" "}
    </>
  );
}
