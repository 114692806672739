import React from "react";
import OrderCard from "../../components/Sourcing/OrderCard";
import "./orders.style.css";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "./SourcingIndex";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import Payment from "../../assets/SVG/order.png";
import Service from "../../assets/SVG/customer-service.png";
import Traning from "../../assets/SVG/counseling.png";
export default function Orders() {
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  const params = useParams();
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  console.log(Projectdata[0]?.project_name, params, "pastage after push");
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [message, setMessage] = useState(null);
  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="Orders"
      handleSearchChange={handleSearchChange}
      placeholder={"search Order..."}
      Message={message}
    >
      {/* <OrderCard search={search} setMessage={setMessage} /> */}
      <div className="supplier-menu-container">
        <NavLink to={"products"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Payment}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Product
          </span>
        </NavLink>
        <NavLink to={"service"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Service}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Service
          </span>
        </NavLink>
        <NavLink to={"training"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Traning}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Training
          </span>
        </NavLink>
      </div>
    </SourcingIndex>
  );
}
