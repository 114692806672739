import React from "react";
import ToolHistory from "./ToolHistory";
import ToolItemDescription from "./ToolItemDescription";

export default function ToolDetail(props) {
  console.log(props?.data, "project Detail");
  return (
    <div>
      <ToolItemDescription
        isproject={props.isproject}
        setFragment={props?.setFragment}
        data={props?.data}
      />
      <ToolHistory data={props?.data} />
    </div>
  );
}
