import React from "react";

export default function Declaration({ cost, setTotalDeclaration }) {
  console.log(cost, "cost on Declaration");

  const TotalFobETB =
    Number(
      cost?.EX_Rate_per_Declaretion == undefined
        ? 0
        : cost?.EX_Rate_per_Declaretion
    ) *
      Number(
        cost?.IPI_USD_per_Declaretion == undefined
          ? 0
          : cost?.IPI_USD_per_Declaretion
      ) +
    Number(cost?.Freight == undefined ? 0 : cost?.Freight) +
    Number(cost?.insurance == undefined ? 0 : cost?.insurance) +
    Number(cost?.other == undefined ? 0 : cost?.other);
  const TotalinLandCost =
    Number(cost?.bank_charge == undefined ? 0 : cost?.bank_charge) +
    Number(
      cost?.loading_unloading_expense == undefined
        ? 0
        : cost?.loading_unloading_expense
    ) +
    Number(cost?.inland_transport == undefined ? 0 : cost?.inland_transport) +
    Number(
      cost?.clearing_and_warehouse_service == undefined
        ? 0
        : cost?.clearing_and_warehouse_service
    ) +
    Number(cost?.marin_insurance == undefined ? 0 : cost?.marin_insurance) +
    Number(cost?.freight_sa == undefined ? 0 : cost?.freight_sa) +
    Number(cost?.excise_tax == undefined ? 0 : cost?.excise_tax) +
    Number(cost?.withholding_tax == undefined ? 0 : cost?.withholding_tax) +
    Number(cost?.sure_tax == undefined ? 0 : cost?.sure_tax) +
    Number(cost?.vat == undefined ? 0 : cost?.vat) +
    Number(cost?.customs_duty == undefined ? 0 : cost?.customs_duty);
  const TotalDeclaration =
    TotalFobETB == undefined
      ? 0
      : TotalFobETB + TotalinLandCost == undefined
        ? 0
        : TotalFobETB +
          TotalinLandCost -
          (Number(
            cost?.withholding_tax == undefined ? 0 : cost?.withholding_tax
          ) +
            Number(cost?.vat == undefined ? 0 : cost?.vat));
  setTotalDeclaration(TotalDeclaration);

  console.log(TotalDeclaration, "costttttt");

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">EX Rate as per Declaretion</div>
        <div className="cost-value">
          {cost?.EX_Rate_per_Declaretion
            ? Number(cost?.EX_Rate_per_Declaretion)?.toLocaleString()
            : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Item price In USD as per Declaretion</div>
        <div className="cost-value">
          {cost?.IPI_USD_per_Declaretion
            ? Number(cost?.IPI_USD_per_Declaretion)?.toLocaleString()
            : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Freight</div>
        <div className="cost-value">
          {cost?.Freight ? Number(cost?.Freight)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Insurance</div>
        <div className="cost-value">
          {cost?.Insurance ? Number(cost?.Insurance)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Other</div>
        <div className="cost-value">
          {cost?.other ? Number(cost?.other)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">
          Total FOB Item price In ETB as per Declaretion
        </div>
        <div className="cost-value">
          {Number(TotalFobETB)?.toLocaleString()}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Customs duty</div>
        <div className="cost-value">
          {cost?.customs_duty
            ? Number(cost?.customs_duty)?.toLocaleString()
            : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Vat</div>
        <div className="cost-value">
          {cost?.vat ? Number(cost?.vat)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Sure Tax </div>
        <div className="cost-value">
          {cost?.sure_tax ? Number(cost?.sure_tax)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Withold </div>
        <div className="cost-value">
          {cost?.withholding_tax
            ? Number(cost?.withholding_tax)?.toLocaleString()
            : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Excise Tax </div>
        <div className="cost-value">
          {cost?.excise_tax ? Number(cost?.excise_tax)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Freight - Sea/Air</div>
        <div className="cost-value">
          {cost?.freight_sa ? Number(cost?.freight_sa)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Marin Insurance</div>
        <div className="cost-value">
          {cost?.marin_insurance ? cost?.marin_insurance : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Total as per Declaretion</div>
        <div className="cost-value">
          {TotalDeclaration ? Number(TotalDeclaration)?.toLocaleString() : 0}
        </div>
      </div>

      <div></div>
    </div>
  );
}
