import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import { useAddInventoryTypeSettingMutation } from "../../../features/SCMApi";
import "../../../components/Settings/SettingModal/modal.css";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import InventoryForm from "./inventoryForm";
import ToolForm from "./ToolForm";
import EditTools from "./EditTools";

export default function RegisterTool(props) {
  function HandleCancel() {
    props.modal(false);
    console.log("lalalal");
  }

  return (
    <div className="pi-modal" onClick={() => HandleCancel()}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{props?.data == null ? "Register Tool" : "Edit Tools"}</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        {props?.data == null ? (
          <div className="pi-modal-content">
            <ToolForm isFixed={props?.isFixed} modal={props?.modal} />
          </div>
        ) : (
          <div className="pi-modal-content">
            <EditTools data={props?.data} modal={props?.modal} />
          </div>
        )}
      </div>
    </div>
  );
}
