import React from "react";
import ExcelJS from "exceljs";
import {} from // useGetBatchesQuery,
// useGetProjectItemQuery,
"../../features/SCMApi";

function App({ project_id, project, data }) {
  const downloadExcel = (buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `IE NETWORKS-${
      project_id == "" ? "All" : project?.project_name
    }-SCTS with PA `;
    document.body.appendChild(link);
    link.click();
  };
  const groupedProjects = data?.reduce((acc, project) => {
    if (!acc[project?.project_id]) {
      acc[project?.project_id] = [];
    }
    acc[project?.project_id].push(project);
    return acc;
  }, {});
  let projectExport = groupedProjects == undefined ? [] : groupedProjects;
  const projectNames = Object?.keys(projectExport).map((projectId) => {
    return projectExport[projectId];
  });
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  console.log(groupedProjects, data, projectNames, "datassss");
  // const { data: orderDetail } = useGetProjectItemQuery(project_id);
  // const { data: batches } = useGetBatchesQuery(project_id);
  const mapData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SUMMARY");
    const worksheetproject = projectNames?.map((items, index) =>
      workbook.addWorksheet(items[0]?.project_po?.project_name)
    );
    const titleCell = worksheet.getCell("A1");
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCell.font = { size: 12, name: "Times New Roman", bold: true };
    titleCell.value = `IE NETWORKS-${
      project_id == "" ? "All" : project?.project_name
    }-PA Summary`;
    titleCell.alignment = { horizontal: "center" };
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    worksheet.mergeCells("A1:W1");
    const titleCellProject = projectNames?.map((items, index) =>
      worksheetproject[index]?.getCell("A1")
    );
    console.log(titleCellProject, "titleCellProject");

    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].style.alignment = {
          vertical: "middle",
          horizontal: "center",
        })
    );

    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].style.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        })
    );
    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].style.font = {
          size: 12,
          name: "Times New Roman",
          bold: true,
        })
    );
    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].value =
          `IE NETWORKS-${items[0]?.project_po?.project_name}-PA Summary`)
    );
    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].alignment = { horizontal: "center" })
    );
    projectNames?.map(
      (items, index) =>
        (titleCellProject[index].fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00B0F0" },
        })
    );

    projectNames?.map((items, index) =>
      worksheetproject[index]?.mergeCells("A1:V1")
    );
    const headerRow = worksheet?.addRow([
      "No.",
      project_id == "" ? "Project" : null,
      "Item Description",
      "Order",
      "Boq Total Cost (USD)",
      "Final Total Cost (USD)",
      "Profit/Loss (USD)",
      "Supplier Name",
      "LC Open To",
      "Payment Mode",
      "Advanced Payment",
      "Payment Status",
      "Supplier Bank Details",
      "Port of Loading",
      "Mode of Shipment",
      "ETA",
      "ETD",
      "Email Subject",
      "Supplier Contact person Detail",
      "Shipment Batch",
      "Handover LO",
      "Handover Date",
      "Current Status",
    ]);

    const headerRowProject = projectNames?.map((items, index) =>
      worksheetproject[index]?.addRow([
        "No.",
        "Item Description",
        "Order",
        "Boq Total Cost (USD)",
        "Final Total Cost (USD)",
        "Profit/Loss (USD)",
        "Supplier Name",
        "LC Open To",
        "Payment Mode",
        "Advanced Payment",
        "Payment Status",
        "Supplier Bank Details",
        "Port of Loading",
        "Mode of Shipment",
        "ETA",
        "ETD",
        "Email Subject",
        "Supplier Contact person Detail",
        "Shipment Batch",
        "Handover LO",
        "Handover Date",
        "Current Status",
      ])
    );

    headerRow?.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    projectNames?.map((items, index) =>
      headerRowProject[index]?.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 12,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      })
    );

    let No = 0;
    const boqTotalCost = [];
    const manufacturers = [];
    const boqTotalCostproject = [];

    const newchange = [];
    const numberss = [1, 2, 3, 4, 5];

    data && data.map((items) => manufacturers?.push(items?.po));
    console.log(projectNames, "manufacturersprojects");

    for (let i = 0; i < manufacturers?.length; i++) {
      const boqTotal =
        manufacturers &&
        manufacturers[i]
          ?.map((item) => item?.boq_total_cost)
          ?.reduce((acc, curr) => acc + curr, 0);

      const finalCost =
        manufacturers &&
        manufacturers[i]
          ?.map((item) => item?.pi_total_cost)
          ?.reduce((acc, curr) => acc + curr, 0);
      boqTotalCost?.push({
        id: i,
        boqTotal,
        finalCost,
      });
    }
    let perProject = [];
    let num1 = 0;
    let num2 = 0;
    projectNames.map((items, index) => {
      for (let i = 0; i < projectNames[index]?.length; i++) {
        perProject?.push({
          id: index,
          boqtotalcost: boqTotalCost[num1++]?.boqTotal,
          totalcost: boqTotalCost[num2++]?.finalCost,
        });
      }
    });
    function groupAndSum(arr) {
      const groupedById = arr.reduce((acc, obj) => {
        const id = obj.id;
        if (!acc[id]) {
          acc[id] = {
            id: id,
            boqtotalcost: 0,
            totalcost: 0,
          };
        }
        acc[id].boqtotalcost += obj.boqtotalcost;
        acc[id].totalcost += obj.totalcost;

        return acc;
      }, {});

      return Object.values(groupedById);
    }
    const result = groupAndSum(perProject);
    console.log(result[0], "result");

    console.log(projectNames, "newArray");

    const fetchedData = data && data;
    console.log(fetchedData, "fetchedDatafetchedData");
    fetchedData &&
      fetchedData?.map((items, index) => {
        const row = worksheet?.addRow([
          (No += 1),
          project_id == "" ? items?.project_po?.project_name : null,
          items?.order?.description == undefined
            ? "-"
            : items?.order?.description,
          items?.order?.order_name,
          boqTotalCost[index]?.boqTotal?.toLocaleString(),
          boqTotalCost[index]?.finalCost?.toLocaleString(),
          (
            boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost
          )?.toLocaleString(),
          items?.order?.supplier?.distributor_name,
          (items?.lc_open_to &&
            items?.lc_open_to[0][0]?.forex_opening_to?.distributor_name ==
              undefined) ||
          null
            ? "-"
            : items?.lc_open_to &&
              items?.lc_open_to[0][0]?.forex_opening_to?.distributor_name,
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.payment_term?.payment_term,
          items?.payment_data && items?.payment_data?.length == 0
            ? "-"
            : items?.payment_data && items?.payment_data[0]?.advance_paid == 0
              ? `${
                  items?.payment_data &&
                  items?.payment_data[0]?.advance_percentage == null
                    ? 0
                    : items?.payment_data[0]?.advance_percentage
                }%`
              : "Paid",
          //payment Status

          items?.total_payment == null
            ? `0 %`
            : items?.payed_amount == null
              ? "Unpaid"
              : Number((items?.payed_amount / items.total_payment) * 100) > 99
                ? "Paid"
                : `${Number(
                    (items?.payed_amount / items.total_payment) * 100
                  ).toLocaleString()} %`,
          //payemt status end

          items?.order?.supplier?.supplier_bank?.length == 0
            ? "NA"
            : items?.order?.supplier?.supplier_bank?.map(
                (Bankitems) =>
                  `${
                    Bankitems?.bank_name == null
                      ? "Unknown"
                      : Bankitems?.bank_name
                  }(${
                    Bankitems?.country?.name == null
                      ? "-"
                      : Bankitems?.country?.name
                  })
                         AC.No (${Bankitems?.bank_currency}) : ${
                           Bankitems?.account_number
                         }
                      Swift code: ${Bankitems?.swift_code}
                      IBAN No (${Bankitems?.bank_currency}): ${Bankitems?.iban}`
              ),
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.loading_port == null
              ? `Any port in ${items?.payment_information_po?.origin?.name}`
              : `Any port in ${items?.payment_information_po?.loading_port?.port_name}`,
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.shipment_mode?.shipment_mode,
          items?.order?.eta == null ? "Pending" : items?.order?.eta,
          items?.order?.etd == null ? "Pending" : items?.order?.etd,
          items?.order?.email_subject == null
            ? "-"
            : items?.order?.email_subject,
          `${
            items?.order?.supplier?.supplier_representatives[0]?.full_name ==
            undefined
              ? "-"
              : items?.order?.supplier?.supplier_representatives[0]?.full_name
          } 
          ${
            items?.order?.supplier?.supplier_representatives[0]?.email == null
              ? "-"
              : items?.order?.supplier?.supplier_representatives[0]?.email
          }`,
          `Batch#${items?.order?.batch == null ? "-" : items?.order?.batch}`,
          items?.project_po?.users
            ?.map((pro) =>
              pro.pivot?.role_id == "6b829425-8842-42c4-b68c-cc001a22985f"
                ? pro?.name
                : undefined
            )
            ?.filter(function (element) {
              return element !== undefined;
            })
            .map((items) => items),

          items?.order?.handover_date == null
            ? "-"
            : items?.order?.handover_date,

          `${
            (items?.order?.handover_date,
            items?.order?.status_id == null
              ? "-"
              : items?.order?.status?.status_name)
          }
            ${date(
              items?.order?.updated_at == null
                ? items?.order?.created_at
                : items?.order?.updated_at
            )}
            `,
        ]);

        console.log(fetchedData, "for all projects without selecting project");
        row.font = { size: 12, name: "Times New Roman" };

        row.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };

        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        });
        row.eachCell((cell) => {
          if (cell.address.includes("G")) {
            if (
              boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost <
              0
            ) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" },
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00B050" },
              };
            }
          }
        });
        row.eachCell((cell) => {
          if (cell.address.includes("H")) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "BFBFBF" },
            };
          }
        });
      });
    let numbers = 0;
    let numbersfinal = 0;
    let numbersproboq = 0;
    let numbersprototal = 0;

    const rowproject =
      projectNames &&
      projectNames?.map((items, index) =>
        projectNames[index]?.map((pitems, pindex) =>
          worksheetproject[index]?.addRow([
            (pindex += 1),
            pitems?.order?.description == undefined
              ? "-"
              : pitems?.order?.description,
            pitems?.po_number,
            boqTotalCost[numbers++]?.boqTotal?.toLocaleString(),
            boqTotalCost[numbersfinal++]?.finalCost?.toLocaleString(),
            (
              boqTotalCost[numbersproboq++]?.boqTotal -
              boqTotalCost[numbersprototal++]?.finalCost
            )?.toLocaleString(),
            pitems?.order?.supplier?.distributor_name,
            pitems?.lc_open_to &&
              pitems?.lc_open_to[0][0]?.forex_opening_to?.distributor_name,
            pitems?.payment_information_id == null
              ? "NA"
              : pitems?.payment_information_po?.payment_term?.payment_term,
            pitems?.payment_data && pitems?.payment_data?.length == 0
              ? "-"
              : pitems?.payment_data &&
                  pitems?.payment_data[0]?.advance_paid == 0
                ? `${
                    pitems?.payment_data &&
                    pitems?.payment_data[0]?.advance_percentage == null
                      ? 0
                      : pitems?.payment_data[0]?.advance_percentage
                  }%`
                : "Paid",
            //payment Status

            pitems?.total_payment == null
              ? `0 %`
              : pitems?.payed_amount == null
                ? "Unpaid"
                : Number((pitems?.payed_amount / pitems.total_payment) * 100) >
                    99
                  ? "Paid"
                  : `${Number(
                      (pitems?.payed_amount / pitems.total_payment) * 100
                    ).toLocaleString()} %`,
            //payemt status end
            pitems?.order?.supplier?.supplier_bank?.length == 0
              ? "NA"
              : pitems?.order?.supplier?.supplier_bank?.map(
                  (Bankitems) =>
                    `${
                      Bankitems?.bank_name == null
                        ? "Unknown"
                        : Bankitems?.bank_name
                    }(${
                      Bankitems?.country?.name == null
                        ? "-"
                        : Bankitems?.country?.name
                    })
                           AC.No (${Bankitems?.bank_currency}) : ${
                             Bankitems?.account_number
                           }
                        Swift code: ${Bankitems?.swift_code}
                        IBAN No (${Bankitems?.bank_currency}): ${
                          Bankitems?.iban
                        }`
                ),
            pitems?.payment_information_id == null
              ? "NA"
              : pitems?.payment_information_po?.loading_port == null
                ? `Any port in ${
                    pitems?.payment_information_po?.origin == null
                      ? "Unknown"
                      : pitems?.payment_information_po?.origin?.name
                  }`
                : `Any port in ${pitems?.payment_information_po?.loading_port?.port_name}`,
            pitems?.payment_information_id == null
              ? "NA"
              : pitems?.payment_information_po?.shipment_mode?.shipment_mode,
            pitems?.order?.eta == null ? "Pending" : pitems?.order?.eta,
            pitems?.order?.etd == null ? "-" : pitems?.order?.etd,
            pitems?.order?.email_subject == null
              ? "-"
              : pitems?.order?.email_subject,
            `${
              pitems?.order?.supplier?.supplier_representatives[0]?.full_name ==
              undefined
                ? "-"
                : pitems?.order?.supplier?.supplier_representatives[0]
                    ?.full_name
            } 
                      ${
                        pitems?.order?.supplier?.supplier_representatives[0]
                          ?.email == null
                          ? "-"
                          : pitems?.order?.supplier?.supplier_representatives[0]
                              ?.email
                      }`,
            `Batch#${
              pitems?.order?.batch == null ? "-" : pitems?.order?.batch
            }`,
            pitems?.project_po?.users
              ?.map((pro) =>
                pro.pivot?.role_id == "6b829425-8842-42c4-b68c-cc001a22985f"
                  ? pro?.name
                  : undefined
              )
              ?.filter(function (element) {
                return element !== undefined;
              })
              .map((items) => items),
            pitems?.order?.handover_date == null
              ? "-"
              : pitems?.order?.handover_date,
            `${
              pitems?.order?.status_id == null
                ? "-"
                : pitems?.order?.status?.status_name
            }
              ${date(
                pitems?.order?.updated_at == null
                  ? pitems?.order?.created_at
                  : pitems?.order?.updated_at
              )}
              `,
          ])
        )
      );
    console.log(rowproject, "rowproject");
    projectNames?.map((items, index) =>
      projectNames[index]?.map(
        (pitems, pindex) =>
          (rowproject[index][pindex].font = {
            size: 12,
            name: "Times New Roman",
          })
      )
    );
    projectNames?.map((items, index) =>
      projectNames[index]?.map(
        (pitems, pindex) =>
          (rowproject[index][pindex].alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          })
      )
    );

    projectNames?.map((items, index) =>
      projectNames[index]?.map((pitems, pindex) =>
        rowproject[index][pindex].eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        })
      )
    );

    let colorboq = 0;
    let colortotal = 0;

    projectNames?.map((items, index) =>
      projectNames[index]?.map((pitems, pindex) =>
        rowproject[index][pindex]?.eachCell((cell) => {
          if (cell.address.includes("F")) {
            if (
              boqTotalCost[colorboq++]?.boqTotal -
                boqTotalCost[colortotal++]?.finalCost <
              0
            ) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" },
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00B050" },
              };
            }
          }
        })
      )
    );

    projectNames?.map((items, index) =>
      projectNames[index]?.map((pitems, pindex) =>
        rowproject[index][pindex]?.eachCell((cell) => {
          if (cell.address.includes("G")) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "BFBFBF" },
            };
          }
        })
      )
    );

    // worksheet.getColumn("L").eachCell({ includeEmpty: true }, (cell) => {
    //   cell.style = { ...cell.style, wrapText: true };
    // });
    worksheet.getColumn("B").hidden = project_id == "" ? false : true;
    // worksheet.getColumn("P").hidden = true;
    worksheet.columns.forEach((column, index) => {
      column.width = 20;
      column.height = 20;
      worksheet.getRow(index).height = 50;
    });
    worksheet.columns[0].width = 10;
    worksheet.columns[1].width = 30;
    worksheet.getRow(1).height = 30;

    projectNames?.map((items, indexs) =>
      projectNames[indexs]?.map(
        (pitems, pindex) =>
          worksheetproject &&
          worksheetproject[indexs]?.columns?.forEach((column, index) => {
            column.width = 20;
            column.height = 20;
            worksheetproject[indexs].getRow(indexs).height = 50;
          })
      )
    );
    // projectNames?.map((items, index) =>
    //   projectNames[index]?.map(
    //     (pitems, pindex) => (worksheetproject.columns[0].width = 5),
    //     (worksheetproject.columns[1].width = 30),
    //     (worksheetproject.getRow(1).height = 30)
    //   )
    // );

    let FooterBoqtotalSummary = 0;
    let FootertotalcostSummary = 0;
    fetchedData &&
      fetchedData?.map(
        (items, index) =>
          (FooterBoqtotalSummary =
            FooterBoqtotalSummary + boqTotalCost[index]?.boqTotal)
      );
    fetchedData &&
      fetchedData?.map(
        (items, index) =>
          (FootertotalcostSummary =
            FootertotalcostSummary + boqTotalCost[index]?.finalCost)
      );
    const FooterRowSummary = worksheet.addRow([
      "Total ",
      "",
      "",
      "",
      FooterBoqtotalSummary?.toLocaleString(),
      FootertotalcostSummary?.toLocaleString(),
      (FooterBoqtotalSummary - FootertotalcostSummary)?.toLocaleString(),
      " ",
      "",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
    ]);
    FooterRowSummary.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
    });

    let FooterBoqtotal = 0;
    let Footertotalcost = 0;
    let footerboq = 0;
    let footertotal = 0;

    projectNames?.map((items, index) =>
      projectNames[index]?.map(
        (pitems, pindex) =>
          (FooterBoqtotal = boqTotalCost[index][pindex++]?.boqTotal)
      )
    );
    projectNames?.map((items, index) =>
      projectNames[index]?.map(
        (pitems, pindex) =>
          (Footertotalcost =
            Footertotalcost +
            boqTotalCost[footertotal++]?.finalCost?.toLocaleString())
      )
    );

    // (
    //   boqTotalCost[numbersproboq++]?.boqTotal -
    //   boqTotalCost[numbersprototal++]?.finalCost
    // )?.toLocaleString(),
    console.log(boqTotalCost, "FooterBoqtotal");
    const FooterRow =
      projectNames &&
      projectNames?.map((items, index) =>
        worksheetproject[index].addRow([
          "Total ",
          "",
          "",
          result[index]?.boqtotalcost?.toLocaleString(),
          result[index]?.totalcost?.toLocaleString(),
          (
            result[index]?.boqtotalcost - result[index]?.totalcost
          )?.toLocaleString(),
          "",
          " ",
          "",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
        ])
      );
    projectNames &&
      projectNames?.map((items, index) =>
        FooterRow[index].eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "BFBFBF" },
          };
          cell.font = {
            color: { argb: "000000" },
            bold: true,
            size: 12,
            name: "Times New Roman",
          };
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "right",
            wrapText: true,
          };
        })
      );

    workbook.xlsx.writeBuffer().then((buffer) => {
      downloadExcel(buffer);
    });
  };

  return (
    <div>
      <button onClick={mapData} className="report-download">
        Download Report
      </button>
    </div>
  );
}

export default App;
