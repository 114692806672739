import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "../../Settings/Settings.css";
import "../forex.style.css";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import Select from "../ForexApproval/Select";
import DataTable from "./PaymentTracking";
import {
  useGetPaymentTrackingForVendorQuery,
  useGetForexPaymentTrackingVendorQuery,
} from "../../../features/SCMApi";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () =>
      // console.log("totally custom!")
      ""
  );

  return (
    <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
      {children}
    </p>
  );
}

export default function PaymentTrackingVendor(props) {
  const { data: VendorList } = useGetForexPaymentTrackingVendorQuery();
  let data;
  const [selectedProject, setSelectedProject] = React.useState(null);
  const vendorId =
    selectedProject === "All" || null
      ? "0"
      : VendorList?.data !== []
      ? VendorList?.data
          ?.filter((vendor) => vendor?.distributor_name === selectedProject)
          ?.map((vendor) => vendor?.id)
          .flat()
          .toString()
      : "0";

  selectedProject === "All"
    ? (data = VendorList?.data !== undefined ? VendorList?.data : [])
    : selectedProject === null
    ? (data = VendorList?.data !== undefined ? VendorList?.data : [])
    : (data =
        VendorList?.data !== undefined
          ? VendorList?.data?.filter(
              (vendor) => vendor.distributor_name === selectedProject
            )
          : []);

  const { data: PaymentTrackingVendor } =
    useGetPaymentTrackingForVendorQuery(vendorId);

  console.log(PaymentTrackingVendor?.data, "payment tracking data for vendors");

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(data?.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className="card paymentTrackingContainer">
      <div className="card-body">
        <div className="paymentTrackingSelectProject">
          <Select
            componentName={"paymentTracking"}
            name={
              VendorList?.data !== []
                ? VendorList?.data?.map((vendor) => vendor?.distributor_name)
                : []
            }
            placeHolder={"Select Vendor..."}
            onChange={setSelectedProject}
            style={{ width: 300, fontSize: 11, marginTop: 1.5 }}
          />
        </div>
        {_DATA.currentData().map((vendor) => (
          <Accordion>
            <Card className="mb-2 mt-0 paymentTrackingAccordionCard">
              <div className="card-header paymentTrackingTitle">
                <CustomToggle eventKey="0">
                  <span className="paymentTrackingProjectName">
                    {vendor?.distributor_name}
                  </span>
                </CustomToggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="paymentTrackingUnderline"></div>

                  <DataTable
                    trackingSheet={PaymentTrackingVendor?.data?.filter(
                      (vendorD) => vendorD?.id === vendor?.id
                    )}
                    name={"paymentTrackingVendor"}
                    totalPayment={
                      Math.round(
                        (PaymentTrackingVendor?.data
                          ?.filter((pro) => pro?.id === vendor?.id)
                          ?.map((totalPayment) =>
                            Number(totalPayment?.total_amount)
                          )
                          ?.filter((e) => typeof e == "number")
                          ?.reduce((prev, acc) => prev + acc, 0) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }
                    paidAmount={
                      Math.round(
                        (PaymentTrackingVendor?.data
                          ?.filter((pro) => pro?.id === vendor?.id)
                          ?.map((totalPayment) =>
                            Number(totalPayment?.paid_amount)
                          )
                          ?.filter((e) => typeof e == "number")
                          ?.reduce((prev, acc) => prev + acc, 0) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
