import * as React from "react";
import {
  useGetForexPaymentModeQuery,
  useGetForexShipmentModeQuery,
  useGetForexLoadingOriginQuery,
  useGetForexDischargePortQuery,
  useGetForexAirLoadingPortQuery,
  useGetSeaLoadingPortQuery,
  useGetForexOpeningToQuery,
  useGetForexDetailQuery,
  useGetForexIncotermsQuery,
  useUpdateForexTermConditionMutation,
} from "../../../../features/SCMApi";

import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Select from "../Select";
import DatePicker from "../../ForexPlanning/DatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MUISelect from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";

const EditTermAndConditionsModal = (props) => {
  const forexId = useParams();
  const [editForexTerm] = useUpdateForexTermConditionMutation();
  const { data: forexDetail } = useGetForexDetailQuery(forexId?.id);
  const { data: paymentMode } = useGetForexPaymentModeQuery();
  const { data: openingSupplier } = useGetForexOpeningToQuery();
  const { data: modeOfShipment } = useGetForexShipmentModeQuery();
  const { data: loadingOrigin } = useGetForexLoadingOriginQuery();
  const { data: airportLoading } = useGetForexAirLoadingPortQuery();
  const { data: seaportLoading } = useGetSeaLoadingPortQuery();
  const { data: portOfDischarge } = useGetForexDischargePortQuery();
  const { data: incoterms } = useGetForexIncotermsQuery();

  const forexTermId = forexDetail?.data
    .map((forexData) =>
      forexData?.forex_term?.map((forexTermData) => forexTermData?.id)
    )
    .flat();

  //Previous Data
  const previousPaymentModeData = props?.previousData?.map(
    (value) => value?.forex_payment_mode?.payment_mode
  );
  const previousOpeningToData = props?.previousData?.map(
    (value) => value?.forex_opening_to?.distributor_name
  );

  const previousModeOfShipmentData = props?.previousData?.map(
    (value) => value?.forex_shipment_mode?.shipment_mode
  );
  const previousTransShipmentData = props?.previousData?.map(
    (value) => value?.trans_shipment
  );
  const previousPartialShipmentData = props?.previousData?.map(
    (value) => value?.partial_shipment
  );
  const previousCountryOfOriginData = props?.previousData?.map(
    (value) => value?.forex_origin?.name
  );

  const previousPortOfLoadingData = props?.previousData?.map((value) =>
    value?.forex_sea_loading !== null
      ? value?.forex_sea_loading?.port_name
      : value?.air_loading_id !== null
        ? value?.forex_air_loading?.port_name
        : ["Unspecified"]
  );
  const previousDischargingPort = props?.previousData?.map(
    (value) => value?.forex_discharge_port?.port_name
  );
  const previousFreight = props?.previousData?.map((value) => value?.freight);
  const previousIncoterm = props?.previousData?.map(
    (value) => value?.incoterms?.incoterm_name
  );

  //Previous Value
  const previousPaymentMode = paymentMode?.data?.find(
    (modeOfPayment) =>
      modeOfPayment?.payment_mode === previousPaymentModeData?.toString()
  )?.id;

  const previousOpeningTo = openingSupplier?.data?.find(
    (distributer) =>
      distributer?.distributor_name === previousOpeningToData?.toString()
  )?.id;

  const previousValidityDate = props?.previousData?.map(
    (value) => value?.validity_date
  );

  const previousModeOfShipment = modeOfShipment?.data?.find(
    (shipmentMode) =>
      shipmentMode?.shipment_mode === previousModeOfShipmentData?.toString()
  )?.id;

  const previousCountryOfOrigin = loadingOrigin?.data?.find(
    (origin) => origin?.name === previousCountryOfOriginData?.toString()
  )?.id;

  const previousAirPort = airportLoading?.data?.find(
    (airPort) => airPort?.port_name === previousPortOfLoadingData?.toString()
  )?.id;

  const previousSeaPortData = seaportLoading?.data?.find(
    (seaport) => seaport?.port_name === previousPortOfLoadingData?.toString()
  )?.id;

  const previousDischargingPortData = portOfDischarge?.data?.find(
    (airPort) => airPort?.port_name === previousDischargingPort?.toString()
  )?.id;

  const previousOpeningDateData = props?.previousData?.map(
    (value) => value?.opening_date
  );
  const previousExpirationDateData = props?.previousData?.map(
    (value) => value?.expiration_date
  );
  const previousApprovalDateData = props?.previousData?.map(
    (value) => value?.approval_date
  );
  const [approval_date, setApprovedDate] = React.useState("");
  const [paymentModeValue, setPaymentModeValue] = React.useState(null);
  const [validityDate, setValidityDate] = React.useState(null);
  const [openingTo, setOpeningTo] = React.useState(null);
  const [openingDate, setOpeningDate] = React.useState(null);
  const [expiration_date, setExpirationDate] = React.useState("");
  const [shipmentMode, setShipmentMode] = React.useState(
    props?.previousData
      ?.map((value) => value?.forex_shipment_mode?.shipment_mode)
      .flat()
      .toString()
  );
  const [transShipment, setTransShipment] = React.useState(
    props?.previousData?.map((value) => value?.trans_shipment)?.toString()
  );
  const [partialShipment, setPartialShipment] = React.useState(
    props?.previousData?.map((value) => value?.partial_shipment)?.toString()
  );
  const [countryOfOrigin, setCountryOfOrigin] = React.useState(
    previousCountryOfOriginData?.toString()
  );
  const [portOfLoading, setPortOfLoading] = React.useState(null);
  const [dischargePort, setDischargePort] = React.useState(null);
  const [referenceNumber, setReferenceNumber] = React.useState(null);
  const [freightId, setFreightId] = React.useState(
    props?.previousData?.map((value) => value?.freight)
  );
  const [incoterm, setIncoterm] = React.useState(
    props?.previousData?.map((value) => value?.incoterms?.incoterm_name)
  );

  const origin = loadingOrigin?.data?.find(
    (origin) => origin?.name === countryOfOrigin
  )?.id;
  const previousOpeningDate = props?.previousData?.map(
    (value) => value?.opening_date
  );
  const previousReferenceNumber = props?.previousData?.map(
    (value) => value?.reference_number
  );

  const transShipmentData = ["Allowed", "Not allowed"];
  const partialShipmentData = ["Allowed", "Not allowed"];
  const freight = ["Freight on board", "Freight on destination"];

  const shipmentData =
    shipmentMode === "Air"
      ? countryOfOrigin !== null
        ? airportLoading?.data
            ?.filter(
              (airPort) =>
                airPort?.country?.toLowerCase() ===
                countryOfOrigin?.toLowerCase()
            )
            ?.map((airPort) => airPort?.port_name)
            ?.flat()
        : ["Not yet"]
      : shipmentMode === "Sea"
        ? seaportLoading?.data
            ?.filter((seaPort) => seaPort?.country === countryOfOrigin)
            ?.map((seaPort) => seaPort?.port_name)
            ?.flat()
        : ["Please select shipment mode"];

  const shipmentMapData =
    shipmentData?.length !== 0 ? shipmentData : ["No port available"];

  //MultiSelect
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 226,
        marginTop: -10,
      },
    },
    disableScrollLock: false,
  };

  let params = {
    payment_mode_id:
      paymentModeValue === null
        ? previousPaymentMode?.toString()
        : paymentMode?.data?.find((modeOfPayment) =>
            modeOfPayment?.payment_mode.includes(paymentModeValue)
          )?.id,
    validity_date:
      validityDate !== null ? validityDate : previousValidityDate?.toString(),
    opening_to:
      openingTo === null
        ? previousOpeningTo?.toString()
        : openingSupplier?.data?.find((airPort) =>
            airPort?.distributor_name?.includes(openingTo)
          )?.id,

    shipment_mode_id:
      shipmentMode === null
        ? previousModeOfShipment?.toString()
        : modeOfShipment?.data?.find((modeOfShipment) =>
            modeOfShipment?.shipment_mode.includes(shipmentMode)
          )?.id,
    forex_id: forexId?.id,
    trans_shipment: transShipment,
    partial_shipment: partialShipment,
    loading_origin_id:
      countryOfOrigin === null ? previousCountryOfOrigin?.toString() : origin,
    air_loading_id:
      portOfLoading === null
        ? previousAirPort?.toString()
        : airportLoading?.data?.find(
            (airPort) => airPort?.port_name === portOfLoading
          )?.id,

    sea_loading_id:
      portOfLoading === null
        ? previousSeaPortData?.toString()
        : seaportLoading?.data?.find(
            (seaport) => seaport?.port_name === portOfLoading
          )?.id,

    discharge_port_id:
      dischargePort === null
        ? previousDischargingPortData?.toString()
        : portOfDischarge?.data?.find((airPort) =>
            airPort?.port_name.includes(dischargePort)
          )?.id,

    incoterm_id:
      previousIncoterm === null
        ? previousIncoterm?.toString()
        : incoterms?.data?.find((incot) =>
            incot?.incoterm_name.includes(incoterm)
          )?.id,

    freight: freightId?.toString(),
    opening_date:
      openingDate !== null ? openingDate : previousOpeningDate?.toString(),
    expiration_date: expiration_date
      ? expiration_date
      : previousExpirationDateData[0],
    approval_date: approval_date ? approval_date : previousApprovalDateData[0],
    reference_number:
      referenceNumber !== null
        ? referenceNumber
        : previousReferenceNumber?.toString(),
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    console.log(params, "parammmmmmmms");
    e.preventDefault();
    editForexTerm(params)
      .unwrap()
      .then((response) => {
        HandleCancel();
        props?.successMessage(true);
        props?.message("Term and condition edited successfully!!!");
        console.log(response, "response from database");
      })
      .catch((error) => {
        console.log(error, "error from backend");
      });
  };

  console.log("previousApprovalDateData", previousApprovalDateData);
  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="AddNew">Edit Terms </div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels" style={{ gap: "23px" }}>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Payment Mode
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px", marginLeft: "-25px" }}
                      >
                        Reference No.
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Validity Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        PI Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Approval Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Expiration Date
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "14px" }}
                      >
                        Opening to
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "9px" }}
                      >
                        Mode of Shipment
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Trans Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Partial Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Country of Origin
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "7px" }}
                      >
                        Port of Loading
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Port of Discharge
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Incoterm
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Select
                          name={paymentMode?.data?.map(
                            (modeOfPayment) => modeOfPayment?.payment_mode
                          )}
                          passedValue={previousPaymentModeData?.toString()}
                          onChange={setPaymentModeValue}
                          required={true}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          required
                          defaultValue={previousReferenceNumber}
                          className="payment-term-and-condition-input"
                          placeholder="Enter reference number"
                          onChange={(event) =>
                            setReferenceNumber(event.target.value)
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          required
                          type="number"
                          defaultValue={previousValidityDate}
                          className="payment-term-and-condition-input"
                          placeholder="Enter validity date"
                          onChange={(event) =>
                            setValidityDate(event.target.value)
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          defaultValue={previousOpeningDateData}
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter opening date"
                          required
                          onChange={(e) => setOpeningDate(e.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          defaultValue={previousApprovalDateData}
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter approval date"
                          required
                          onChange={(e) => setApprovedDate(e.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          defaultValue={previousExpirationDateData}
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter opening date"
                          required
                          onChange={(e) => setExpirationDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <Select
                          name={openingSupplier?.data?.map(
                            (supplier) => supplier?.distributor_name
                          )}
                          passedValue={previousOpeningToData}
                          onChange={setOpeningTo}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={modeOfShipment?.data?.map(
                            (shipmentMode) => shipmentMode?.shipment_mode
                          )}
                          passedValue={previousModeOfShipmentData}
                          onChange={setShipmentMode}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={transShipmentData}
                          passedValue={previousTransShipmentData}
                          onChange={setTransShipment}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={partialShipmentData}
                          passedValue={previousPartialShipmentData}
                          onChange={setPartialShipment}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={loadingOrigin?.data?.map(
                            (origin) => origin?.name
                          )}
                          passedValue={previousCountryOfOriginData}
                          onChange={setCountryOfOrigin}
                          required={true}
                        />
                        {/* <FormControl sx={{ m: 1, width: 226 }}>
                          <MUISelect
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={originName}
                            onChange={(e) => handleChange(e)}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            sx={{
                              width: 226,
                              height: 32,
                              backgroundColor: "#ececec",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              color: "#186569",
                            }}
                            required={true}
                          >
                            {loadingOrigin?.data &&
                              loadingOrigin?.data?.map((origin) => (
                                // <Tooltip title={order?.order_name} arrow>
                                <MenuItem
                                  key={origin?.id}
                                  value={origin?.name}
                                  sx={{
                                    color: "#186569",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      originName.indexOf(origin?.name) > -1
                                    }
                                    sx={{
                                      color: "#186569",
                                      height: "25px",
                                      "&.Mui-checked": {
                                        color: "#186569",
                                        height: "25px",
                                      },
                                    }}
                                  />
                                  <ListItemText primary={origin?.name} />
                                </MenuItem>
                                //  </Tooltip>
                              ))}
                          </MUISelect>
                        </FormControl> */}
                      </div>
                      <div>
                        <Select
                          name={shipmentMapData}
                          passedValue={previousPortOfLoadingData}
                          onChange={setPortOfLoading}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={portOfDischarge?.data?.map(
                            (dischargePort) => dischargePort?.port_name
                          )}
                          passedValue={previousDischargingPort}
                          onChange={setDischargePort}
                          required={true}
                        />
                      </div>
                      {/* <div>
                        <Select
                          name={freight}
                          passedValue={previousFreight}
                          onChange={setFreightId}
                          required={true}
                        />
                      </div> */}

                      <div>
                        <Select
                          name={incoterms?.data?.map(
                            (incoterm) => incoterm?.incoterm_name
                          )}
                          passedValue={previousIncoterm}
                          onChange={setIncoterm}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditTermAndConditionsModal;
