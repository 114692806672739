import React from "react";
import { date, totalDates } from "../dateFormator";
import { Tooltip } from "@mui/material";

export default function EndDate({ project }) {
  // const defaultDate = new Date("2023-12-31"); // Default date value\
  // // let endTime = "";
  // // if (project?.end_date != null) {
  // //   endTime = project?.end_date?.getTime();
  // // } else {
  // //   endTime = defaultDate;
  // // }
  // const endTime = project?.end_date?.getTime();

  // const startTime = project?.contract_sign_date?.getTime();

  // //   const endTime = project?.end_date?.getTime() ?? defaultDate;
  // //   const startTime = project?.start_date?.getTime() ?? defaultDate;
  // const diffTime = endTime - startTime;
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // let countDown = diffDays;
  // console.log(endTime, "countDown");

  let startDate = new Date(project.contract_sign_date);
  let endDate = new Date(project.end_date);
  const diffTime = endDate.getTime() - startDate.getTime();

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let countDown = diffDays;
  console.log(countDown, "countDown");
  return (
    <div className="project-date">
      <Tooltip title={date(project.end_date)}>
        <div
          className={
            totalDates(project?.end_date) > 30
              ? "project-start-date text-success"
              : "project-start-date text-danger"
          }
        >
          {project?.isActive == 0
            ? "Closed"
            : project.end_date != null
            ? project.end_date
            : "Not set"}
        </div>
      </Tooltip>

      <div
        className={
          totalDates(project?.end_date) > 30
            ? `project-date-description text-success`
            : `project-date-description text-danger`
        }
      >
        Project End Date
      </div>
    </div>
  );
}
