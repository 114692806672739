import Settingsidebar from "../../pages/Settings/SettingIndex";
import "./Settings.css";
import "./paymentSettings.css";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIncotermsModal from "./SettingModal/AddIncotermsModal";
import { useState } from "react";
import AddPaymentModal from "./SettingModal/AddPaymentModal";
import AddPaymentTerm from "./SettingModal/AddPaymentTerm";
import {
  useAddPaymentTermMutation,
  useDeletePaymentModeMutation,
  useDeletePaymentTermMutation,
  useGetAllGuaranteeTypeQuery,
  useGetpaymentIncotermQuery,
  useGetpaymentModeQuery,
  useGetPaymentsModeQuery,
  useGetPaymentTermsQuery,
} from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
import PaymentMode from "./SettingModal/PaymentMode";
import UpdatepaymentMode from "./SettingModal/updatepaymentMode";
import UpdatePaymentTerm from "./SettingModal/UpdatePaymentTerm";

/* Handling add Boq button */
export default function Payment() {
  const [showPaymentMode, setshowPaymentMode] = useState(false);
  const [showPaymentTerm, setshowPaymentTerm] = useState(false);
  const [showupdatePaymentMode, setshowUpdatePaymentMode] = useState(false);
  const [showUpdatePaymentTerm, setshowUpdatePaymentTerm] = useState(false);
  const { data: payment } = useGetPaymentTermsQuery();
  const { data: paymentmode } = useGetPaymentsModeQuery();
  const { data: guarantee } = useGetAllGuaranteeTypeQuery();
  const [editmode, setEditmode] = useState();
  const [editterm, setEditTerm] = useState();
  const [Message, setMessages] = useState(null);
  function createPaymentHandler() {
    setshowPaymentTerm(true);
  }
  function upadatePaymentTermHandler(e, items) {
    setshowUpdatePaymentTerm(true);
    setEditTerm(items);
  }
  function upadatePaymentModeHandler(e, items) {
    setshowUpdatePaymentMode(true);
    setEditmode(items);
  }
  function createPaymentModeHandler() {
    setshowPaymentMode(true);
  }
  console.log(showPaymentTerm, "showPaymentTerm");

  const [modeid, setModeid] = useState();
  const [termid, setTermid] = useState();
  const [deletedmode, setDeletedmode] = useDeletePaymentModeMutation();
  const [deletedterm, setDeletedterm] = useDeletePaymentTermMutation();

  const HandleDeleteModes = (e, items) => {
    let modedeleted = { id: items };
    e.preventDefault();
    deletedmode(modedeleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("Payment Mode Deleted successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  const HandleDeleteTerm = (e, items) => {
    let termdeleted = { id: items };
    e.preventDefault();
    deletedterm(termdeleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("Payment Term Deleted successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  return (
    <Settingsidebar name={"Payment"} Messages={Message}>
      {/* {showPaymentTerm ? (
        <AddPaymentTerm onClose={() => setshowPaymentTerm(false)} />
      ) : (
        <></>
      )} */}
      <div className="page">
        <div className="pbody-container">
          <div className="pcard payment-mode">
            <section className="pi-header">
              <h3>Payment Mode</h3>
            </section>
            {paymentmode?.data?.length !== 0 ? (
              paymentmode?.data?.map((items) => (
                <section className="pi-body">
                  <p>{items.payment_mode}</p>
                  <div>
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: "#52FFAC",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#52FFAC",
                          border: "1px solid #52FFAC",
                          borderRadius: 25,
                        },
                      }}
                      onClick={(e) => upadatePaymentModeHandler(e, items)}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={(e) => HandleDeleteModes(e, items.id)}
                      sx={{
                        color: "#FF5252",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          border: "1px solid #FF5252",
                          borderRadius: 25,
                        },
                      }}
                    />
                  </div>
                </section>
              ))
            ) : (
              <NoRecord />
            )}

            <section className="pi-footer">
              <div
                onClick={createPaymentModeHandler}
                className="pi-footer-icon"
              >
                <AddIcon />
              </div>
            </section>
          </div>
          <div className="pcard payment-term">
            <section className="pi-header">
              <h3>Payment Term</h3>
            </section>
            {payment?.data?.length !== 0 ? (
              payment?.data?.map((items) => (
                <section className="pi-body">
                  <div>
                    <p>
                      {items?.advance_percentage === null
                        ? "Unknown"
                        : items?.advance_percentage}
                    </p>
                  </div>
                  <div>
                    <p>{items?.payment_term}</p>
                  </div>
                  <div>
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: "#52FFAC",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#52FFAC",
                          border: "1px solid #52FFAC",
                          borderRadius: 25,
                        },
                      }}
                      onClick={(e) => upadatePaymentTermHandler(e, items)}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={(e) => HandleDeleteTerm(e, items.id)}
                      sx={{
                        color: "#FF5252",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          border: "1px solid #FF5252",
                          borderRadius: 25,
                        },
                      }}
                    />
                  </div>
                </section>
              ))
            ) : (
              <NoRecord />
            )}
            <section className="pi-footer">
              <div onClick={createPaymentHandler} className="pi-footer-icon">
                <AddIcon />
              </div>
            </section>
          </div>
          <div className="pcard payment-term">
            <section className="pi-header">
              <h3>Guarantee Type</h3>
            </section>
            {guarantee?.data?.length != 0 ? (
              guarantee?.data?.map((items) => (
                <section className="pi-body">
                  <div>
                    <p>
                      {items?.guarantee_type_name === null
                        ? "Unknown"
                        : items?.guarantee_type_name}
                    </p>
                  </div>

                  {/* <div>
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: "#52FFAC",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#52FFAC",
                          border: "1px solid #52FFAC",
                          borderRadius: 25,
                        },
                      }}
                      onClick={(e) => upadatePaymentTermHandler(e, items)}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={(e) => HandleDeleteTerm(e, items.id)}
                      sx={{
                        color: "#FF5252",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          border: "1px solid #FF5252",
                          borderRadius: 25,
                        },
                      }}
                    />
                  </div> */}
                </section>
              ))
            ) : (
              <NoRecord />
            )}
            {/* <section className="pi-footer">
              <div onClick={createPaymentHandler} className="pi-footer-icon">
                <AddIcon />
              </div>
            </section> */}
          </div>
        </div>
      </div>

      {showPaymentTerm ? (
        <AddPaymentTerm modal={setshowPaymentTerm} setMessages={setMessages} />
      ) : (
        <></>
      )}

      {showPaymentMode ? (
        <PaymentMode modal={setshowPaymentMode} setMessages={setMessages} />
      ) : (
        <></>
      )}
      <>
        {showupdatePaymentMode ? (
          <UpdatepaymentMode
            modal={setshowUpdatePaymentMode}
            setMessages={setMessages}
            value={editmode}
          />
        ) : (
          <></>
        )}
      </>
      <>
        {showUpdatePaymentTerm ? (
          <UpdatePaymentTerm
            modal={setshowUpdatePaymentTerm}
            setMessages={setMessages}
            value={editterm}
          />
        ) : (
          <></>
        )}
      </>
    </Settingsidebar>
  );
}
