import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import {
  useViewPaymentTrackingHistoryQuery,
  useViewSupplierPaymentHistoryQuery,
} from "../../../../features/SCMApi";
import "../../forex.style.css";
import NoRecord from "../../../common/NoRecord";

export default function SupplierPaymentTrackingDetail(props) {
  const poId = props?.previousData?.id;
  const { data: paymentHistory } = useViewSupplierPaymentHistoryQuery(poId);

  function HandleCancel() {
    props.modal(false);
  }
  const total_amount = props?.previousData?.total_amount;
  console.log(props, "supplier tracking");
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="d-flex align-items-center justify-content-between border border-bottom p-1">
            <div className="h4-pri">Supplier Payment History</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          {paymentHistory?.data?.length !== 0 ? (
            <form className="" onSubmit={""}>
              <div className="d-flex justify-content-between align-items-baseline text-start px-4">
                <div className="h5-pri">{props?.previousData?.order_name}</div>
                <hr></hr>
                <div className="h5-pri">
                  {props?.previousData?.distributor_name}
                </div>
              </div>
              <div className="d-flex justify-content-between gap-5 px-5 my-5">
                <div className="h5-pri">Total Payment</div>
                <hr></hr>
                <div className="h5-pri">
                  {props?.previousData?.currency_symbol == null
                    ? ""
                    : props?.previousData?.currency_symbol}
                  {Number(total_amount)?.toLocaleString()}
                </div>
              </div>
              <div className="my-3  d-grid justify-content px-5">
                {paymentHistory?.data !== undefined || null
                  ? paymentHistory?.data?.map((paymentHistory) => (
                      <>
                        <div className="d-flex justify-content-between align-items-baseline gap-5 mb-3 ">
                          <div className="paymentDetailInputLabel">
                            <div>{date(paymentHistory?.created_at)}</div>
                          </div>
                          <div className="origin-value">
                            {`${
                              props?.previousData?.currency_symbol == null
                                ? ""
                                : props?.previousData?.currency_symbol
                            } ${Number(
                              paymentHistory?.paid_amount
                            )?.toLocaleString()}`}
                            <div className="origin-label">
                              {paymentHistory?.payment_mode}
                            </div>
                          </div>
                        </div>
                        <div className="border border-bottom"></div>
                      </>
                    ))
                  : ""}
              </div>
            </form>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </>
  );
}
