import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddEmailHandoverMutation,
  useAddEtadateMutation,
  useAddEtaDateMutation,
  useAddEtdBatchMutation,
  usEditEmailHandoverMutation,
  useEditEmailHandoverMutation,
  useEditEtadateMutation,
  useeEditEtadateMutation,
  useFinalEtaMutation,
  useGetEtaDetailQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import CheckPermission from "../common/checkPermission/checkPermission";

export default function EtdBatch({ setMessages, data }) {
  const params = useParams();
  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  console.log(etaDetail, "eta detail");
  const location = useLocation();
  const [show, setShow] = useState();
  const [showedit, setShowEdit] = useState();
  let preetd = data && data?.etd;
  let prenum = data && data?.number_of_batch;
  let prebatch = data && data?.batch;
  const [etd, setETD] = useState(data?.etd);
  console.log(etd, "etd");
  const [number_of_batch, setNumberofBatch] = useState(
    data?.number_of_batch == null ? 1 : data?.number_of_batch
  );
  const [batch, setBatch] = useState(data?.batch);

  let emailhandover = {
    id: params?.id,
    etd,
    number_of_batch,
    batch,
  };
  let batchpush = [];
  for (let i = 1; i <= number_of_batch; i++) {
    batchpush.push({
      id: i,
      name: `Batch ${i}`,
    });
  }
  let total_line_price = 0;
  const [AddEmailHandoverDate, res] = useAddEtdBatchMutation();
  const HandleEditSubmit = async (e) => {
    e.preventDefault();
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("Update Successfully");
        setShowEdit(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  const HandleAddSubmit = async (e) => {
    e.preventDefault();
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("Added Successfully");
        setShow(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };

  const projectid = etaDetail?.data[0]?.project_id;
  console.log(emailhandover, "emailhandover");
  let count = 0;
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: roles } = useGetProjectRolesQuery();
  let avatar = [];
  return (
    <div className="eta-card p-1 mt-2">
      <div className="">
        {etaDetail?.data?.map((items) => (
          <>
            <div className="lc-value mt-3">
              ETD and Batch (Number of batch {items?.number_of_batch}){" "}
            </div>
            <div className="d-grid  gap-1 mt-2">
              {!show && !showedit && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">ETD</span>
                    <div className="lc-label">
                      {items?.etd == null ? "Unknown" : items?.etd}
                    </div>
                  </div>
                  <div className="d-grid">
                    <span className="prg">Batch</span>
                    <div className="lc-label">
                      {items?.batch == null ? "Unknown" : items?.batch}
                    </div>
                  </div>
                </div>
              )}
              {show && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">ETD</span>
                    <input
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) => setETD(e.target.value)}
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Number of Batch</span>
                    <input
                      style={{ width: "294px" }}
                      type="number"
                      value={number_of_batch}
                      className="supplier-text-input"
                      placeholder="Please enter the number of batch fot this order"
                      onChange={(e) => setNumberofBatch(e.target.value)}
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Batch</span>
                    <select
                      name=""
                      id=""
                      className="select-option-create-order "
                      style={{ width: "294px" }}
                      onChange={(e) => setBatch(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Batch
                      </option>
                      {batchpush?.map((items) => (
                        <option value={items.id}>{items?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {showedit && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">ETD</span>
                    <input
                      defaultValue={etd != undefined ? etd : preetd}
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) =>
                        setETD(etd != undefined ? e.target.value : preetd)
                      }
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Number of Batch</span>
                    <input
                      defaultValue={
                        number_of_batch != undefined ? number_of_batch : prenum
                      }
                      style={{ width: "294px" }}
                      type="number"
                      className="supplier-text-input"
                      placeholder="Please enter the number of batch fot this order"
                      onChange={(e) =>
                        setNumberofBatch(
                          number_of_batch != undefined ? e.target.value : prenum
                        )
                      }
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Batch </span>
                    <select
                      value={batch != undefined ? batch : prebatch}
                      name=""
                      id=""
                      className="select-option-create-order "
                      style={{ width: "294px" }}
                      onChange={(e) =>
                        setBatch(batch != undefined ? e.target.value : prebatch)
                      }
                    >
                      <option selected="true" disabled="disabled">
                        Choose Batch
                      </option>
                      {batchpush?.map((items) => (
                        <option value={items.id}>{items?.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={HandleEditSubmit}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={() => setShowEdit((prev) => !prev)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              )}

              {items?.batch != null ? (
                <div className="d-flex justify-content-center">
                  {CheckPermission("update_logistics_etd", userinfo)
                    ? !showedit && (
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            width: "30px",
                            height: "30px",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                        >
                          <ModeEditOutlinedIcon
                            onClick={() => setShowEdit((prev) => !prev)}
                          />
                        </IconButton>
                      )
                    : ""}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  {CheckProjectPermission(
                    "update_logistics_etd",
                    projectmembers,
                    userinfo,
                    roleRoles
                  )
                    ? !show && (
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            width: "30px",
                            height: "30px",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                              width: "30px",
                              height: "30px",
                            },
                          }}
                          onClick={() => setShow((prev) => !prev)}
                        >
                          <AddIcon />
                        </IconButton>
                      )
                    : ""}

                  {show && (
                    <>
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#186569",

                          "&:hover": {
                            backgroundColor: "#186569",
                            color: "#fff",
                          },
                        }}
                        onClick={HandleAddSubmit}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#186569",

                          "&:hover": {
                            backgroundColor: "#186569",
                            color: "#fff",
                          },
                        }}
                        onClick={() => setShow((prev) => !prev)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
