import { Alert } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import attachmentIcon from "../../assets/SVG/attach.svg";
import {
  useAddBoqMutation,
  useGetBoqtypesQuery,
  useGetProductsQuery,
  useGetProjectsQuery,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import MultipleSelect from "./../project/MultipleSelect";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import Spinner from "../common/Spinner";
import { useParams } from "react-router-dom";
export default function AddBoq(props) {
  const { projectId } = useParams();
  const [addBoq, reasponse] = useAddBoqMutation();
  const { data: Projects } = useGetProjectsQuery();
  const { data: BoqTypes } = useGetBoqtypesQuery();
  const { data: Solution } = useGetProductsQuery();
  const [solutions, setSolution] = useState([]);
  const [project_id, setProject] = useState(projectId);
  const [boq_type_id, setBoqType] = useState();
  const [boq, setBoq] = useState();
  const [errormessage, setErrorMessage] = useState(null);
  const solutionsList = [];
  const boqType = [
    { value: "Product", label: "Product" },
    { value: "Service", label: "Service" },
    { value: "Training", label: "Training" },
  ];
  Solution?.data?.map((items) =>
    solutionsList.push({
      value: items?.abbrivation,
      label: items?.abbrivation,
    })
  );
  let formData = {
    boq,
    boq_type_id,
    solutions,
    project_id,
  };

  function handleSubmit(e) {
    e.preventDefault();
    console.log(formData, "formData");
    setUploading(true);

    const data = new FormData();
    data.append("boq", boq);
    // solutions.forEach((item) => {
    //   data.append("solutions[]", item);
    // });
    data.append("project_id", project_id);
    // data.append("boq_type_id[]", boq_type_id);
    data.append("boq", boq);
    data.append("solutions", solutions);
    data.append("project_id", project_id);
    boq_type_id.forEach((item) => {
      data.append("boq_type[]", item);
    });

    for (var pair of data.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    // addBoq(data)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "RTK");
    //   })
    //   .then((error) => {
    //     console.log(error);
    //   });
    axios
      .post(`${API_BASE_URL}/scm/product/boq`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then(function (response) {
        console.log(response, "response boq");
        console.log(boq, solutions, project_id, boq_type_id, "inputs");
        props?.ToggleModal(false);
        props?.setMessage("BoQ added successfully");
        props?.setSuccessMessage(true);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error?.response?.data?.message);
        handleClickOpen();
        setUploading(false);
      });
  }
  function handleChange(e) {
    setBoq(e?.target?.files?.[0]);
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [uploading, setUploading] = useState(false);

  return (
    <div>
      <div
        id="myModal"
        className="medium-modal"
        onClick={(e) => {
          e.preventDefault();
          props?.ToggleModal(false);
        }}
      >
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            Add BoQ
            <div
              className="close-modal-button"
              onClick={(e) => props?.ToggleModal(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                  fill="#353945"
                />
              </svg>
            </div>
          </div>
          <div className="lc-warning ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="24"
                height="24"
                fill="white"
                fill-opacity="0.01"
              ></rect>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                fill="white"
              ></path>
            </svg>
            <div>
              Please make sure the BoQ file you are about to import follows the
              proper format
            </div>
          </div>
          {errormessage !== null ? (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className="text-center">
                    <WarningIcon
                      sx={{
                        color: "red",
                        width: 100,
                        height: 100,
                      }}
                    />
                  </div>

                  <DialogContentText id="alert-dialog-description">
                    {errormessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            // <Alert severity="error"></Alert>
            ""
          )}

          <form className="add-boq-container" onSubmit={(e) => handleSubmit(e)}>
            <div className="option-group"></div>
            <div className="add-boq-form">
              <div className="add-boq-form-container">
                {/* <div className="file-chooser-group">
                 
                </div> */}
                <div className="file-chooser-group">
                  <div className="input-label">Select Boq Type</div>
                  {/* <select
                    required
                    name=""
                    id=""
                    className="select-option"
                    onChange={(e) => setBoqType(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Boq Types
                    </option>
                    {BoqTypes?.data?.map((items) => (
                      <option value={items?.id}>{items?.type_name}</option>
                    ))}
                  </select> */}
                  <MultiSelect
                    list={boqType}
                    setters={setBoqType}
                    width="80%"
                  />
                </div>
                <div className="file-chooser-group">
                  <div className="input-label">Select Solution</div>

                  <MultiSelect
                    required
                    list={solutionsList}
                    setters={setSolution}
                    // label={"Sub Solution"}
                    width="80%"
                  />
                </div>
                <div className="file-chooser-group">
                  <div className="input-label">BoQ</div>
                  <div
                    className="file-chooser"
                    style={{ backgroundImage: `url(${attachmentIcon})` }}
                  >
                    <input
                      required
                      type="file"
                      className="file-input"
                      id="boqfile"
                      name="boq"
                      onChange={(e) => {
                        console.log(e.target.files[0], "filesssss");
                        handleChange(e);
                      }}
                    />

                    <label
                      htmlFor="boqfile"
                      style={{
                        position: "relative",
                        top: "-22px",
                        left: "5px",
                        width: "5rem",
                        // zIndex: "-1",
                      }}
                    >
                      {boq ? boq?.name.slice(0, 5) + "...." : " File"}
                    </label>
                  </div>
                </div>
                {uploading ? (
                  <div className="file-chooser-group">
                    <div className="input-label">File</div>
                    <Spinner />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div class="form-buttons">
              <button
                type="cancel"
                class="form-cancel"
                onClick={(e) => props.ToggleModal(false)}
              >
                {" "}
                Cancel
              </button>
              <button type="submit" class="form-save">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
