import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../../api/endPoint";

export default function ConfirmPaymentRequest(props) {
  let form = { id: props?.data?.id };
  const [confirmDate, setConfirmDate] = useState(null);
  const [alerts, setAlerts] = useState(null);

  function handleSubmit(e) {
    if (confirmDate === null) {
      setAlerts("Please insert expected date");
    } else {
      e.preventDefault();
      axios
        .post(`${API_BASE_URL}/scm/paymentRequest/${props?.data?.id}`, {
          expected_date: confirmDate,
        })
        .then((response) => {
          console.log("Response Data", response);
          window.location.reload(true);
          // if (response.data) {
          //   alert(response.data.msg);
          // }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // alert(props?.data?.id);

    // console.log(`Payment request D: ${props?.data}`);
  }
  // console.log("####confirmDate :", confirmDate);
  // console.log("####alerts", alerts);
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info test-center">
          Insert payment expected date for {props?.data?.name} task
        </div>
        <div style={{ color: "red", fontWeight: "bold" }}>{alerts}</div>
        <div>
          <form>
            <input
              type="date"
              required
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) => setConfirmDate(e.target.value)}
              className="supplier-text-input"
            />
          </form>
        </div>
        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
