import React, { useEffect, useState } from "react";
import ForexNeedMobileComponent from "./ForexNeedMobileComponent";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import SuccessMessage from "../SuccessMessage";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ReceivableHistory from "./ReceivableHistory";
import axios from "axios";

import {
  useGetUserInfoQuery,
  useGetReceivableHistoryQuery,
  useGetCreditNotesQuery,
  useGetSuppliersQuery,
  useGetForexNeedsQuery,
} from "../../../features/SCMApi";
import ForexNeedHistory from "./ForexNeedHistory";
import { API_BASE_URL } from "../../../api/endPoint";
const HistoryDisplay = (props) => {
  const [successMessage, setSuccessMessage] = React.useState(false);
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const data = [];
  for (let forexNeed in props?.data?.data?.forex_need) {
    data.push({
      ...props?.data?.data?.forex_need[forexNeed],
      financeOfficer: props?.data?.data?.finance_officer
        ?.map((fo) => fo?.name)
        ?.toString(),
      sourcingOfficer: props?.data?.data?.sourcing_officer
        ?.map((so) => so?.name)
        ?.toString(),
      bank:
        props?.data?.data?.forexe_bank.find(
          (item) =>
            item?.id === props?.data?.data?.forex_need[forexNeed]?.forex_need_id
        )?.forex_need_bank !== null
          ? props?.data?.data?.forexe_bank.find(
              (item) =>
                item?.id ===
                props?.data?.data?.forex_need[forexNeed]?.forex_need_id
            )?.forex_need_bank?.bank_name
          : null,
    });
  }

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(data.length && data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const { data: receivableHistory } = useGetReceivableHistoryQuery();
  const { data: forexNeedHistory } = useGetForexNeedsQuery();

  const [forexNeedHistoryController, setForexNeedHistoryController] =
    React.useState(true);
  const [receivableHistoryController, setReceivableHistoryController] =
    React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const [forexReceivableHistoryData, setForexReceivableHistoryData] =
    React.useState([]);
  React.useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/payment/forex-receivable_history`)
      .then((response) => {
        console.log("checking", response?.data);
        setForexReceivableHistoryData(response?.data);
      })
      .catch((error) => {
        console.log("error getting forex receivable", error.message);
      });
  }, []);
  console.log(
    receivableHistory,
    forexReceivableHistoryData,
    "receivableHistory"
  );
  return (
    <>
      <div className="gap-analysis-web-view">
        {/* <ForexNeed data={props?.data} /> */}

        <div className="card paymentTrackingContainer">
          <div className="card-body">
            <div className="paymentTrackingSelectProject">
              <FormControl sx={{ marginTop: "-10px", backgroundColor: "#fff" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue="frightForwarder"
                >
                  <FormControlLabel
                    sx={{
                      color: "#AEB8C9",
                      ".MuiFormControlLabel-label": checked1 === true && {
                        color: "#186569",
                      },
                    }}
                    value="frightForwarder"
                    control={
                      <Radio
                        onChange={(e) => {
                          setChecked1(true);
                          setChecked2(false);
                          setForexNeedHistoryController(true);
                        }}
                        sx={{
                          color: "#AEB8C9",
                          "&.Mui-checked": {
                            color: "#186569",
                          },
                        }}
                      />
                    }
                    label={
                      <div className="shipment-arra-radio-button-lable">
                        Forex Need History
                      </div>
                    }
                  />

                  <FormControlLabel
                    sx={{
                      color: "#AEB8C9",
                      ".MuiFormControlLabel-label": checked2 === true && {
                        color: "#186569",
                      },
                    }}
                    value="byVendor"
                    control={
                      <Radio
                        onClick={(e) => setForexNeedHistoryController(false)}
                        onChange={(e) => {
                          setChecked1(false);
                          setChecked2(true);
                          setReceivableHistoryController(true);
                        }}
                        sx={{
                          color: "#AEB8C9",
                          "&.Mui-checked": {
                            color: "#186569",
                          },
                        }}
                      />
                    }
                    label={
                      <div className="shipment-arra-radio-button-lable">
                        Receivable History
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="form-card-container">
              {forexNeedHistoryController === true ? (
                <ForexNeedHistory data={forexNeedHistory?.data} />
              ) : // <div>Forex need history is under development</div>
              receivableHistoryController === true ? (
                <ReceivableHistory data={forexReceivableHistoryData?.data} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="gap-analysis-mobile-view">
        {successMessage === true ? (
          <SuccessMessage
            style={{
              marginTop: "10px",
              marginBottom: "-20px",
              backgroundColor: "#52ffab96",
              marginRight: "10px",
              width: "362px",
            }}
            className={"success_messages_text_style_mobile_View"}
            Message={"Forex bank updated successfully!!!"}
          />
        ) : (
          <></>
        )}
        {_DATA?.currentData()?.map((bankGap) => (
          <ForexNeedMobileComponent
            forexNeedData={bankGap}
            setSuccessMessage={setSuccessMessage}
          />
        ))}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default HistoryDisplay;
