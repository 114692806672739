// import React, { useEffect, useState } from "react";
import "../Recievable.css";
import ExcelJS from "exceljs";
import { LuDownloadCloud } from "react-icons/lu";
// import { API_BASE_URL } from "../../../../api/endPoint";
// import axios from "axios";

// ... (other imports remain unchanged)

function RecivableUpcomingDownload({ productData }) {
  // const [productData, setProductData] = useState([]);
  const currentDate = new Date().toLocaleDateString();

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = () => {
  //   axios
  //     .get(`${API_BASE_URL}/scm/incomingRevcievables`)
  //     .then((response) => {
  //       console.log("API Response:", response.data.data);
  //       setProductData(response.data.data);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching agingHistoryRecievable data:", err);
  //     });
  // };

  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  const addDataToSheet = (sheet, header, rowData, isSummarySheet = false) => {
    let count = 0;

    rowData.map((row) => {
      count++;
      sheet.addRow([
        count,
        row.project,
        row.currency,
        row.amount,
        row.paymentRequestDate,
        row.plannedCollectionDate,
        row.expectedPaymentDate,
      ]);
    });
    const totalAmount = rowData.reduce((total, row) => total + row.amount, 0);
    sheet.addRow(["", "Total Amount", "", totalAmount]);
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("AgingReceivables");

    const projectHeader = [
      "NO",
      "Project Name",
      "Currency",
      "Ammount",
      "paymentRequestDate",
      "plannedCollectionDate",
      "actualCollectionDate",
    ];

    // Add headers
    sheet.addRow([""]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow(["IE NETWORK SOLUTIONS PL"]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow(["COLLECTED ACCOUNT RECEIVABLES"]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow([`AT THE DATE ${currentDate}`]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    // Add project header
    sheet.addRow([""]);
    sheet.addRow(projectHeader).fill = {
      type: "pattern",
      pattern: "solid",
      color: { argb: "ffffff" },
      fgColor: { argb: "0099ff" },
    };
    sheet.addRow([""]);

    // Group data by currency
    const groupedByCurrency = {};

    productData.forEach((row) => {
      if (!groupedByCurrency[row.currency]) {
        groupedByCurrency[row.currency] = [];
      }
      groupedByCurrency[row.currency].push(row);
    });

    // Add project data for each currency
    Object.keys(groupedByCurrency).forEach((currency) => {
      sheet.addRow(["", ` ${currency}`]).fill = {
        type: "pattern",
        pattern: "solid",
        color: { argb: "ffffff" },
        fgColor: { argb: "0099ff" },
      };

      addDataToSheet(sheet, projectHeader, groupedByCurrency[currency]);
    });

    // Save and download the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Upcoming Receivable ${currentDate}.xlsx`;
      link.click();
    });
  };

  return (
    <div className="exportIcon">
      <button className="buttonExport" onClick={handleDownload}>
        <LuDownloadCloud />
        Export upcoming
      </button>
    </div>
  );
}

export default RecivableUpcomingDownload;
