import React, { useState } from "react";
import { useCreateRevenueMutation } from "../../../features/SCMApi";

export default function Revenue(props) {
  const [revenue] = useCreateRevenueMutation();
  let form = { id: props?.data?.id };
  function handleSubmit(e) {
    e.preventDefault();
    revenue(form)
      .unwrap()
      .then((response) => {
        console.log(response, "Response for Revenue order from backend");
        props?.modal(false);
      })
      .catch((error) => {
        console.log(error, "Response for Revenue from backend err");
      });
  }
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info test-center">
          Are you sure to make revenue {props?.data?.name} task
        </div>

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
