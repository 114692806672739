export default function CheckPermission(Permission, userinfo) {
  const userId = userinfo?.id;
  console.log(userId, "userId");
  let isAuthorized = true;
  let permissions = userinfo?.permissions;
  const condition = permissions?.filter(
    (item) => item?.slug === Permission || item?.slug === "all"
  );
  condition?.length ? (isAuthorized = true) : (isAuthorized = false);
  console.log(isAuthorized, Permission, userinfo, "isAuthorized");
  return isAuthorized;
}
