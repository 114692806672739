import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAssetsAcceptsBatchMutation,
  useBatchRequestInventoryMutation,
  useBatchRequestToolsMutation,
  useGetInventoryQuery,
  useGetItemCategorysQuery,
  useGetProjectsQuery,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useInventoryAcceptsBatchMutation,
  useItemsReturnBatchMutation,
  useToolsAcceptsBatchMutation,
  useToolsReturnMutation,
} from "../../../features/SCMApi";
import DeleteIcon from "@mui/icons-material/Delete";
import { IMG_BASE_URL } from "../../../api/endPoint";
import ErrorMessage from "../../Payment/ErrorMessage";
import { useParams } from "react-router-dom";

export default function BatchAccept(props) {
  function HandleCancel() {
    props.modal(false);
    console.log("lalalal");
  }
  const [confirmRequestItems, setConfirmRequestItems] = useState(props?.data);
  const [loading, setLoading] = useState(false);
  const { data: projects } = useGetProjectsQuery();
  const [project, setProject] = useState(null);
  const [formData, setFormData] = useState(props?.data);
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...formData];
    tools[i] = { ...items, remark: value, is_returned: true };
    setFormData(tools);
  }

  const removeForm = (id) => {
    const filteredArray = formData.filter((obj) => obj.id !== id);
    setFormData(filteredArray);
  };
  console.log(confirmRequestItems, "confirmRequestItems");
  const { data: userinfo } = useGetUserInfoQuery();
  const params = useParams();
  let batchAccepts = {
    id: params?.id,
    accepted_items: JSON.stringify(formData),
    accepted_by: userinfo?.id,
  };
  const [ToolsAccept, res] = useToolsAcceptsBatchMutation();
  const [AssetsAccept] = useAssetsAcceptsBatchMutation();
  const [ToolsItems] = useInventoryAcceptsBatchMutation();
  const [ItemsReturn] = useItemsReturnBatchMutation();
  const [errordisplay, setErrorDisplay] = useState(false);
  const [error, setError] = useState();
  console.log(params?.id, "surafel formData");

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    props?.is_items == true
      ? ToolsAccept(batchAccepts)
          .unwrap()
          .then((response) => {
            console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
            console.log(error, "erorrrrr");
          })
      : ToolsItems(batchAccepts)
          .unwrap()
          .then((response) => {
            console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
            console.log(error, "erorrrrr");
          });
  };
  const HandleSubmitAssets = async (e) => {
    setLoading(true);
    e.preventDefault();
    AssetsAccept(batchAccepts)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        setLoading(true);
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);

        setLoading(false);
        console.log(error, "erorrrrr");
      });
  };

  let count = 0;
  console.log(formData, "formData formData itess");
  setTimeout(() => {
    setErrorDisplay(false);
  }, 10000);

  return (
    <div id="mymodal" className="modal" onClick={() => HandleCancel()}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <h5>Batch Accept </h5>
          <Close onClick={HandleCancel} />
        </div>

        {errordisplay ? <ErrorMessage Message={error} /> : ""}

        <table style={{ width: "100%" }}>
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "3%" }}
              >
                No
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Image
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Item Name
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Requested Amount
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Remark
              </th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((items, index) => (
              <tr className="stock-table-row">
                <td className="text-center">{(count += 1)}</td>

                <td className="">
                  <img
                    className="stock-img"
                    src={`${IMG_BASE_URL}${items?.image}`}
                    alt=""
                  />
                </td>
                <td className="text-start">{items?.item_name}</td>
                <td className="text-center">
                  {formData[index]?.returned_amount > items?.requested_amount ||
                  formData[index]?.returned_amount <= 0 ? (
                    <span className="prg-error text-danger">
                      Please enter correct value
                    </span>
                  ) : (
                    items?.returned_amount
                  )}
                </td>
                <td className="text-center">
                  <textarea
                    type="text"
                    style={{ height: 55 }}
                    className="sourcing-text-input-textarea"
                    rows={4}
                    cols={20}
                    onChange={(e) => handleBatchRequest(e, items, index)}
                  ></textarea>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pi-modal-footer d-flex justify-content-center">
          <button onClick={HandleCancel}>Cancel</button>
          <button
            disabled={formData?.length >= 1 ? false : true}
            type="submit"
            className={
              formData?.length >= 1
                ? "form-save "
                : loading
                ? "form-save bg-secondary text-white"
                : "form-save"
            }
            class={loading ? "form-save bg-secondary text-white" : "form-save"}
            onClick={(e) =>
              !props?.isAssets ? HandleSubmit(e) : HandleSubmitAssets(e)
            }
          >
            {loading ? "Loading..." : "Return"}
          </button>
        </div>
      </div>
    </div>
  );
}
