import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddToolType from "./SettingModal/AddToolType";
import EditToolType from "./SettingModal/EditToolType";
import {
  useDeleteAssetCatagoriesMutation,
  useGetAssetCatagoriesQuery,
} from "../../features/SCMApi";
import AddUnitofMeasurement from "./SettingModal/AddUnitofMesurenment";
import AddAssetCategory from "./SettingModal/AddAssetCategory";
import EditUnitofMeasurenment from "./SettingModal/EditUnitofMeasurenment";
import EditAssetCatagories from "./SettingModal/EditAssetCategory";

export default function AssetCatagories() {
  const [addAssetCatagories, setAddAssetCatagories] = useState(false);
  const [editAssetCatagories, setEditAssetCatagories] = useState(false);
  const HandleAssetCategory = () => {
    setAddAssetCatagories(true);
  };
  const HandleEditAssetCategory = () => {
    setEditAssetCatagories(true);
  };
  const { data: asset_catagories } = useGetAssetCatagoriesQuery();

  console.log(asset_catagories, "asset_catagories");
  const [updateStatus, setStatus] = useState();
  const [DeleteStore, src] = useDeleteAssetCatagoriesMutation();

  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  const [updateAssetCatagories, setUpdateAssetCatagories] = useState();

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Asset Category</h3>
      </section>
      {asset_catagories?.data?.map((items) => (
        <section className="pi-body">
          <p>{items?.name}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                HandleEditAssetCategory(true);
                setUpdateAssetCatagories(items);
              }}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={(e) => {
                HandleDelete(e, items.id);
              }}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={HandleAssetCategory} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addAssetCatagories ? (
        <AddAssetCategory modal={setAddAssetCatagories} />
      ) : (
        ""
      )}
      {editAssetCatagories ? (
        <EditAssetCatagories
          modal={setEditAssetCatagories}
          data={updateAssetCatagories}
        />
      ) : (
        ""
      )}
    </div>
  );
}
