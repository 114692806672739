import React from "react";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useState } from "react";
import PaymentCollectionModal from "./PaymentCollectionModal";
import {
  useGetAllPaymentCollectionsQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetVarianceAnalysisAllQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { useParams } from "react-router-dom";
import UpdatePaymentCollectionModal from "./UpdatePaymentCollections";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../../Pagination/Pagination";
import VarianceAnalysisReport from "./DownloadReports/VarianceAnalysesDownload";
import VarianceAnalysisModal from "./VarianceAnalysisModal";
import VarianceAnalysisUpdateModal from "./VarianceAnalysisUpdate";
export default function AllVarianceAnalyses() {
  const params = useParams();
  const { data: varianceAnalyses } = useGetVarianceAnalysisAllQuery();

  const { data: projects } = useGetProjectsQuery();

  let count = 0;
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();

  function HandleAdd() {
    setAdd(true);
  }
  console.log(varianceAnalyses, "new dattaata");
  const [milestone, setMilestone] = useState("");
  const [status, setStatus] = useState("");
  const [project, setProject] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const filteredData = (project, status) => {
    return varianceAnalyses?.data
      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.variance_project[0]?.id == project) {
          return items;
        }
      })
      ?.filter((items, index) => {
        if (status === "") {
          return items;
        } else if (
          status == "Favorable" &&
          items?.planned_cost - items?.actual_cost >= 0
        ) {
          return items;
        } else if (
          status == "Unfavorable" &&
          items?.planned_cost - items?.actual_cost < 0
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        const itemDate = new Date(items.start_date);
        if (endDate === "") {
          return items;
        } else if (
          itemDate >= new Date(startDate) &&
          itemDate <= new Date(endDate)
        ) {
          return items;
        }
      })
      .map((items) => items);
  };
  const filteredDatas = filteredData(project, status)?.filter((item) => {
    const itemDate = new Date(item.start_date);
    console.log(itemDate, "");
    return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
  });
  let PFO = "";
  let PM = "";

  project?.data?.users?.map((items) =>
    items?.role?.role_name == "Project Finance Officer"
      ? (PFO = items?.name)
      : ""
  );
  project?.data?.users?.map((items) =>
    items?.role?.role_name == "Project Manager" ? (PM = items?.name) : ""
  );
  const [filter, setFilter] = useState(false);
  function HandleFilter() {
    setFilter(!filter);
    setMilestone("");
    setStatus("");
    setProject("");
  }
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const p_count = Math.ceil(filteredData(project, status)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(project, status), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(filteredDatas, "filteredDatas");
  const { data: showproject } = useGetProjectQuery(project);
  let milestoneamount = showproject?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  return (
    <>
      <div className="forex-page">
        <div className="payment-collection-container">
          <div className="d-flex gap-1 p-4 align-items-center">
            <button onClick={HandleAdd} className="add-client-representative">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                  fill="white"
                />
              </svg>
            </button>

            <VarianceAnalysisReport
              start_date={startDate}
              end_date={endDate}
              data={filteredData(milestone, project)}
            />

            {/* <div className="d-grid">
              <div className="h5-pri">{project?.data?.project_name}</div>
              <div className="d-flex gap-4">
                <div className="d-grid">
                  <div className="project-label">Project Manager</div>
                  <div className="lc-value">
                    {PM == "" ? "Not assigned" : PM}
                  </div>
                </div>
                <div className="d-grid">
                  <div className="project-label">Project Finance Officer</div>
                  <div className="lc-value">
                    {PFO == "" ? "Not assigned" : PFO}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {varianceAnalyses?.data?.length > 1 ? (
            <div className="d-flex gap-4 ms-5 align-items-start">
              {filter ? (
                <Tooltip title="Filter On">
                  <FilterAltIcon
                    onClick={HandleFilter}
                    style={{ fontSize: "32px", color: "#064b4f" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Filter Off">
                  <FilterAltOffIcon
                    onClick={HandleFilter}
                    style={{ fontSize: "32px", color: "#064b4f" }}
                  />
                </Tooltip>
              )}
              {filter ? (
                <>
                  <select
                    style={{ marginTop: "11px" }}
                    className="report-filter-selector"
                    onChange={(e) => setProject(e.target.value)}
                  >
                    <option value="" selected>
                      Project
                    </option>
                    {projects?.data?.map((items) => (
                      <option value={items?.id}>{items?.project_name}</option>
                    ))}
                  </select>
                  <div className="d-grid">
                    <span className="project-label">Start Date</span>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="report-filter-selector"
                    />
                  </div>
                  <div className="d-grid">
                    <span className="project-label">End Date</span>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="report-filter-selector"
                    />
                  </div>

                  <select
                    style={{ marginTop: "11px" }}
                    className="report-filter-selector"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Status</option>
                    <option value="Favorable">Favorable</option>
                    <option value="Unfavorable"> Unfavorable</option>
                  </select>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="p-2">
            {_DATA?.currentData()?.length != 0 ? (
              <div className="p-3">
                <table style={{ width: "100%" }}>
                  <thead className="stock-table-header">
                    <tr>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "3%" }}
                      >
                        No
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "15%" }}
                      >
                        Project
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "10%" }}
                      >
                        Task
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        Start Date
                      </th>

                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        End Date
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        Planned Cost
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        Actual Cost
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        Variance
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      >
                        Status
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "5%" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA?.currentData()?.map((items, orderindex) => (
                      <tr className="stock-table-row">
                        <td className="text-start">{(count += 1)}</td>
                        <td className="text-start">{items?.project_name}</td>
                        <td className="text-start">{items?.task_name}</td>
                        <td className="text-start">{items?.start_date}</td>
                        <td className="text-start">{items?.end_date}</td>
                        <td className="text-start">
                          {Number(items?.planned_cost)?.toLocaleString()}
                        </td>
                        <td className="text-start">
                          {Number(items?.actual_cost)?.toLocaleString()}
                        </td>
                        <td className="text-start">
                          {(
                            items?.planned_cost - items?.actual_cost
                          )?.toLocaleString()}
                        </td>
                        <td className="text-start">
                          {items?.planned_cost - items?.actual_cost > 0
                            ? "Favorable"
                            : "Unfavorable"}
                        </td>
                        <td className="text-center">
                          <ModeEditOutlinedIcon
                            onClick={(e) => (setEdit(true), setData(items))}
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecord />
            )}
            {p_count > 1 ? (
              <Pagination
                count={p_count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                className="p-2"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {add ? <VarianceAnalysisModal all={true} modal={setAdd} /> : ""}
      {edit ? (
        <VarianceAnalysisUpdateModal all={true} modal={setEdit} data={data} />
      ) : (
        ""
      )}
    </>
  );
}
