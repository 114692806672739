import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import { useApproveForexMutation } from "../../../../features/SCMApi";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeletePaymentTrackingModal({
  show = false,
  handleDeleteModal,
  forexId,
  successMessage,
  message,
  width,
  approved,
  errorMessage,
}) {
  const [approveForex] = useApproveForexMutation();
  const params = {
    id: forexId,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/scm/payment/forex-Approved/${forexId}`)
      .then((response) => {
        successMessage(true);
        message("Forex Approved successfully!!!");
        handleDeleteModal(false);
        errorMessage(null);
        approved(true);
        console.log(response?.data, "response from back end");
      })
      .catch((error) => {
        handleDeleteModal(false);
        errorMessage(error?.response?.data?.message);
        console.log(error?.response?.data?.message, "error from backend");
      });
    // approveForex(params)
    //   .unwrap()
    //   .then((response) => {
    //     successMessage(true);
    //     handleDeleteModal(false);
    //     errorMessage(null);
    //     // approved(true);
    //     message("Forex Approved successfully!!!");
    //     console.log(response?.data, "response from back end");
    //   })
    //   .then((error) => {
    //     handleDeleteModal(false);
    //     errorMessage(error?.response?.data?.message);
    //     console.log(error?.response?.data?.message, "error from backend");
    //   });
  };

  return (
    <BootstrapDialog
      onClose={handleDeleteModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleDeleteModal}
        sx={{ width: width ? width : 400, color: "#575757" }}
      ></BootstrapDialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#FF5252",
                  marginBottom: "4%",
                }}
              >
                Are you sure ?
              </p>
              <br />
              <br />
            </Grid>
          </Grid>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "inherent",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                borderRadius: "25px",
                width: "104.5x",
                backgroundColor: "#064b4f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
                marginTop: "-50px",
                textTransform: "none",
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              type="reset"
              sx={{
                backgroundColor: "#fff",
                color: "#575757",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor: "#ececec",
                  color: "#575757",
                },
                width: "84.5px",
                marginTop: "-50px",
                textTransform: "none",
              }}
              onClick={handleDeleteModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </BootstrapDialog>
  );
}
