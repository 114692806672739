import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import CreateProject from "./CreateProject";
import "./projectContainer.style.css";
import {
  useGetProjectRoleQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import Projectindex from "../../pages/Projects";
import AddIcon from "@mui/icons-material/Add";
import { IMG_BASE_URL } from "../../api/endPoint";
import CheckPermission from "../common/checkPermission/checkPermission";
// import EditProjectModal from "./EditProjectModal";
import NoRecord from "../common/NoRecord";
import usePagination from "./../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";
import SuccessMessage from "../Payment/SuccessMessage";
import ReactPaginate from "react-paginate";
import EndDate from "./EndDate";
import ErrorModal from "../common/ErrorModal";

let avatar = [];
function ProjectContainer() {
  const { data: userinfo } = useGetUserInfoQuery();
  // const permission = userinfo?.permissions;
  console.log(userinfo, "userinfo");
  const [createProjectModal, setCreateProject] = useState(false);
  const { data: projects } = useGetProjectsQuery();
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const PER_PAGE = 9;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    console.log(search, "search");
  };
  const count = Math.ceil(
    projects?.data?.filter((items) => {
      if (search === "") {
        return items;
      } else if (
        items.project_name.toLowerCase().includes(search.toLowerCase())
      ) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };
  function createProjectHandler() {
    setCreateProject(true);
    console.log(createProjectModal);
  }
  const { data: userRole } = useGetProjectRoleQuery();
  const [open, setOpen] = useState(true);
  // const listOfPermissions = permission?.map(
  //   (permission) => permission?.permission_name
  // );
  const roles = userRole?.data;
  console.log(projects?.data, "project");
  console.log(roles, "roles");
  console.log(userRole, "userRole");
  function checkRole(roleId) {
    let response = "";
    console.log(roleId, "roleId");
    for (let i = 0; i < roles?.length; i++) {
      roles[i]?.id === roleId ? (response = roles[i]?.role_name) : <></>;
    }
    return response;
  }

  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  // const diffTime = endDate.getTime() - startDate.getTime();
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // let countDown = diffDays;
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  console.log();
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
  return (
    <Projectindex
      handleSearchChange={handleSearchChange}
      placeholder={"search Project..."}
    >
      <div className="project-index-page mt-3">
        {CheckPermission("create_project", userinfo) ? (
          <div className="create-project-btn" onClick={createProjectHandler}>
            <AddIcon />
            Create Project
          </div>
        ) : (
          ""
        )}
        {successMessage ? (
          <SuccessMessage
            style={{
              marginTop: "10px",
              backgroundColor: "#52ffab96",
            }}
            Message={message}
          />
        ) : (
          <></>
        )}
        {projects?.data?.length !== 0 ? (
          <>
            <div className="project-card-container">
              {projects?.data
                ?.filter((items) => {
                  if (search === "") {
                    return items;
                  } else if (
                    items.project_name
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase())
                  ) {
                    return items;
                  }
                })
                .slice(pageVisted, pageVisted + PER_PAGE)
                .map((items) => (
                  <NavLink
                    to={items.id}
                    state={{ names: items?.project_name }}
                    className="project-card"
                  >
                    <div className="project-card-content">
                      <div className="d-flex gap-1">
                        <div className="project-title">
                          {items?.project_name?.length > 24
                            ? ` ${items.project_name?.slice(0, 24)}...`
                            : items?.project_name}{" "}
                        </div>
                      </div>

                      <div className="project-detail">
                        <div className="project-participant">
                          <div className="participant-name">
                            {items?.users?.map((members) =>
                              checkRole(members?.pivot?.role_id) ===
                              "Project Manager" ? (
                                <div>PM-{members.name}</div>
                              ) : checkRole(members?.pivot?.role_id) ===
                                "Technical Leader" ? (
                                <div>TL-{members.name}</div>
                              ) : (
                                <></>
                              )
                            )}
                          </div>
                        </div>
                        <div className="product-divider"></div>
                        {items?.is_office == 1 ? (
                          <div className="project-start-date text-success">
                            Office Project
                          </div>
                        ) : (
                          <EndDate project={items} />
                        )}
                      </div>

                      <div className="project-card-divider"></div>
                      <div className="participant-img">
                        {items.users
                          ?.slice(
                            0,
                            items?.users?.length >= 4 ? 4 : items?.users?.length
                          )
                          ?.map((items) => (
                            <div className="participant-avatar">
                              {items?.avatar ? (
                                <img
                                  src={`${IMG_BASE_URL}${items?.avatar}`}
                                  alt=""
                                />
                              ) : (
                                <div>
                                  {" "}
                                  <div style={{ display: "none" }}>
                                    {(avatar = items?.name.split(" "))}
                                  </div>
                                  <>{`${avatar[0]?.charAt(0)}`} </>
                                </div>
                              )}
                            </div>
                          ))}
                        {items?.users?.length > 4 ? (
                          <div className="more-avatars">
                            +{items?.users?.length - 4} More
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </NavLink>
                ))}
            </div>
          </>
        ) : (
          <NoRecord />
        )}
        <div className="faq-pagination-container mt-3">
          {count > 1 ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={count}
              onPageChange={handleChange}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ErrorModal
        errormessage={"Project name already taken"}
        open={isErrorModalOpen}
        handleClose={closeErrorModal}
      />
      {createProjectModal ? (
        <CreateProject
          modal={setCreateProject}
          setMessage={setMessage}
          setSuccessMessage={setSuccessMessage}
          setErrorModalOpen={setErrorModalOpen}
        />
      ) : (
        <></>
      )}
    </Projectindex>
  );
}

export default ProjectContainer;
