import React, { useEffect, useState } from 'react'
import StockIndex from '../Stocks/StockIndex'
import { useLCItemTrackingDetailMutation } from '../../features/SCMApi';
import AutoClosePopup from './SuccessMessage/AutoClosePopup ';
import { FaLess } from 'react-icons/fa';
import SearchAndTabHeader from './SearchAndTabHeader';
import axios from 'axios';
import {  DOC_BASE_URL } from '../../api/endPoint';
function LCItemTrackingDetail(props) {
const [itemDetail,setItemDetail]=useState([]);
const [ LCItemTrackingDetail ] =useLCItemTrackingDetailMutation();
const [isOpen,setIsOpen]=useState(false);
const [message,setMessage]=useState('');
const [type,setType]=useState("success");

console.log(itemDetail,"props?.groupId");

useEffect(()=>{
        const FetchLCitemDetail=()=>{
        LCItemTrackingDetail(props?.groupId).unwrap()
        .then((response)=>{
        console.log(response?.data,"**************************");
        setItemDetail(response?.data);
        setIsOpen(true);
        setType('success');
        setMessage("success");
        })
        .catch((error)=>{
        setItemDetail([]);
        setIsOpen(true);
        setType('danger');
        setMessage("something error");
        console.log(error,"*****************");
        });
        }
        FetchLCitemDetail();
},[]);

const fileDownloadHandler=(link)=>{
axios({
method: "post",
url: `${DOC_BASE_URL}scm/document/download?file_path=${link}`,
headers: { "Content-type": "application/json" },
})
.then((response)=>{
const blob = new Blob([response.data], { type: response.headers["content-type"] });

// Create a link element to trigger the download
const downloadLink = document.createElement("a");
const url = window.URL.createObjectURL(blob);
downloadLink.href = url;
downloadLink.download = `${link}`; // Set the desired filename
document.body.appendChild(downloadLink);

// Trigger the download
downloadLink.click();

// Clean up
document.body.removeChild(downloadLink);
window.URL.revokeObjectURL(url);
})
.catch((error)=>{
console.log(error);
});
}
  
return (
<>
{props?.open && (
<>
<AutoClosePopup message={message} type={type} onClose={()=>setIsOpen(false)} isOpen={isOpen}/>
<div className="container-fluid">
<div className="row gap-4">
<div className="col-md-6 left-content rounded" style={{backgroundColor:'white',minHeight:'800px'}}>
{itemDetail[0]?.item?.map((item)=>(
    <>
        <div className="card fs-5 mt-5" style={{font:'bold'}}>
            <div className="d-felx card-body  justify-content-between" style={{display:'flex'}}>
                <p className="card-text fs-6">{item?.item_description}</p>
                <h5 className="card-title">Qty : {item?.qty}</h5>
            </div>
        </div>
    </>
))}
</div>
<div className="col-md-5 right-content" style={{backgroundColor:'white',minHeight:'800px'}}> 
    <>
        <div className="card fs-5 mt-5" style={{font:'bold'}}>
            <div className="row card-body  justify-content-between gap-4">
                <div>  
                    <div className='card-title'>Delivery Note</div>
                    <p className="card-text fs-6 ">{itemDetail[0]?.delivery_note ?  itemDetail[0]?.delivery_note :"NOT Attached"}</p>
                    {itemDetail[0]?.delivery_note && <a href={`${DOC_BASE_URL}scm/document/download?file_path=${itemDetail[0]?.delivery_note}`}>
                    <button className='px-3 py-2' style={{ color: 'white', backgroundColor: '#046475',marginTop:'40px', borderRadius: '8px' }} >Download</button>
                    </a>}
                </div>
            </div>
        </div>
        <div className="card fs-5 mt-5" style={{font:'bold',color:'#000000'}}>
            <div className="row card-body  justify-content-between gap-4">
                <div>
                    <div className='card-title'>Summarized Pi</div>
                    <p className="card-text fs-6 ">{itemDetail[0]?.summerized_pi ?  itemDetail[0]?.summerized_pi : "   NOT Attached  "}</p>
                    {itemDetail[0]?.summerized_pi  && <a href={`${DOC_BASE_URL}scm/document/download?file_path=${itemDetail[0]?.summerized_pi}`}>
                    <button className='px-3 py-2' style={{ color: 'white', backgroundColor: '#046475',marginTop:'40px', borderRadius: '8px' }} >Download</button>
                    </a>}
                    </div>
            </div>
        </div>
    </>
</div>
</div>
</div>
</>
)}
</>
)
}

export default LCItemTrackingDetail
