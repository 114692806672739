import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddEtadateMutation,
  useAddEtaDateMutation,
  useEditEtadateMutation,
  useeEditEtadateMutation,
  useFinalEtaMutation,
  useGetEtaDetailQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import CheckPermission from "../common/checkPermission/checkPermission";
import EmailSubjectHandoverDate from "./EmailSubjectHandoverDate";
import EtdBatch from "./EtdBatch";
export default function EtaFollowupDetail(props) {
  const params = useParams();
  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  console.log(etaDetail, "eta detail");
  const location = useLocation();
  const [show, setShow] = useState();
  const [showedit, setShowEdit] = useState();
  const [eta, setEta] = useState();
  let etadate = {
    id: params?.id,
    eta,
  };
  let Etafinal = {
    id: params?.id,
  };
  let total_line_price = 0;
  const [Messages, setMessages] = useState();
  const [AddEtaDate, res] = useAddEtadateMutation();
  const [UpdateEtaDate, ress] = useEditEtadateMutation();
  const [FinalEtaDate, resss] = useFinalEtaMutation();
  const HandleEditSubmit = async (e) => {
    e.preventDefault();
    AddEtaDate(etadate)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("ETA Date Added Successfully");
        setShow(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  const HandleFinalubmit = async (e) => {
    e.preventDefault();
    FinalEtaDate(Etafinal)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("ETA Final Added Successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  const HandleUpdateSubmit = async (e) => {
    e.preventDefault();
    UpdateEtaDate(etadate)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        setMessages("ETA Date Updated Successfully");
        setShowEdit(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  let etaDetails = etaDetail?.data?.map((items) =>
    items?.pis?.map((poitems) => poitems?.pi)
  );
  let etaFlat = etaDetails?.flat(1);
  const projectid = etaDetail?.data[0]?.project_id;
  console.log(projectid, "etaDetails etaFlat");
  let count = 0;
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: roles } = useGetProjectRolesQuery();
  let avatar = [];
  return (
    <SourcingIndex
      name={etaDetail?.data[0]?.order_name}
      title={"ETA Followup"}
      navlink={`/sourcing/eta/${etaDetail?.data[0]?.project_id}`}
      Message={Messages}
    >
      <div className="row mt-3">
        <div className="supplier-user-container">
          {etaDetail?.data?.map((items) => (
            <>
              {console.log(items.project.project_members, "projectz items")}
              <div className="eta-card">
                <div className="Lc-card">
                  <Tooltip title={items?.supplier?.distributor_name}>
                    <div className="h4-pri mt-2">
                      {items?.supplier?.distributor_name?.length >= 20
                        ? items?.supplier?.distributor_name?.slice(0, 18) +
                          "..."
                        : items?.supplier?.distributor_name}
                    </div>
                  </Tooltip>
                  <div className="lc-label mb-2">Supplier</div>
                </div>
              </div>
            </>
          ))}
          <div className="user-container-card overflow-auto">
            <div className="Lc-card">
              {projectmembers?.data?.users.map((items) =>
                roles?.data?.map((role) =>
                  role.id === items?.pivot?.role_id ? (
                    role?.role_name === "Sourcing Officer" ? (
                      <div className="d-flex align-items-center gap-3">
                        <div className="participantManufacturer-img">
                          {items.avatar !== null ? (
                            <img
                              src={`${IMG_BASE_URL}${items.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = items?.name?.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="lc-value">{items.name}</div>
                          <div className="lc-label">{role?.role_name}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          </div>
          <div className="user-container-card overflow-auto">
            <div className="Lc-card">
              {projectmembers?.data?.users.map((items) =>
                roles?.data?.map((role) =>
                  role.id === items?.pivot?.role_id ? (
                    role?.role_name === "Logistics Officer" ? (
                      <div className="d-flex align-items-center gap-3">
                        <div className="participantManufacturer-img">
                          {items.avatar !== null ? (
                            <img
                              src={`${IMG_BASE_URL}${items.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = items?.name?.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="lc-value">{items.name}</div>
                          <div className="lc-label">{role?.role_name}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          </div>
        </div>
        <div className=" po-summary-table col-md-8 ">
          {etaFlat?.length !== 0 ? (
            <>
              <div className=" summarymanufacturer-header poStage-row">
                <div className="no">No</div>
                <div>Part Number</div>
                <div className="no">Item Description</div>
                <div>qty</div>
                <div>Unit Price</div>
                <div>Total price</div>
              </div>
              {etaDetail?.data?.map((items) =>
                items?.pis?.map((poitems) =>
                  poitems?.pi?.map((items) => (
                    <div className="poStage-row mb-2">
                      <div className="label-container">
                        <div className="pa-label">NO</div>
                        <div className="">
                          <div>{(count += 1)}</div>
                        </div>
                      </div>
                      <div className="label-container">
                        <div className="pa-label">Part Number</div>
                        <div>{items?.item_part_number}</div>
                      </div>
                      <div className="label-container">
                        <div className="pa-label">Item Description</div>
                        <Tooltip
                          title={
                            items?.item_description == undefined
                              ? "N/A"
                              : items?.item_description
                          }
                        >
                          <div>
                            {items?.item_description?.slice(0, 30) + "..."}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="label-container">
                        <div className="pa-label">qty</div>
                        <div>{items?.qty}</div>
                      </div>
                      <div className="label-container">
                        <div className="pa-label">Unit Price</div>
                        <div>
                          {items?.unit_cost === 0
                            ? items?.unit_cost?.toLocaleString()
                            : items?.unit_cost?.toLocaleString()}
                        </div>
                      </div>
                      <div className="label-container">
                        <div className="pa-label">Total price</div>
                        <div>
                          {" "}
                          {
                            ((total_line_price += items?.total_cost),
                            items?.total_cost === 0
                              ? items?.total_cost?.toLocaleString()
                              : items?.total_cost?.toLocaleString())
                          }
                        </div>
                      </div>
                    </div>
                  ))
                )
              )}
              <div className="d-flex justify-content-end gap-3">
                <div className="payment-text-bold mt-2 mb-0">Sub Total</div>
                <div
                  className={`payment-text-bold mt-2 mb-0 ${
                    total_line_price < 0 ? "text-danger" : "text-success"
                  }`}
                >
                  {total_line_price === 0
                    ? total_line_price?.toLocaleString()
                    : total_line_price?.toLocaleString()}
                </div>
              </div>

              <div className="divider"></div>
            </>
          ) : (
            <NoRecord />
          )}
        </div>
        <div className="col-md-4">
          <div className="eta-card p-1">
            <div className="Lc-card">
              {etaDetail?.data?.map((items) => (
                <>
                  <div className="lc-value mt-3">ETA Follow Up</div>
                  <div className="d-flex align-items-center gap-5 mt-2">
                    {!show && !showedit && (
                      <div className="lc-label">
                        {items?.eta == null ? "Not Available" : items?.eta}
                      </div>
                    )}
                    {show && (
                      <input
                        type="date"
                        className="supplier-text-input"
                        onChange={(e) => setEta(e.target.value)}
                      />
                    )}

                    {showedit && (
                      <>
                        <input
                          type="date"
                          className="supplier-text-input"
                          defaultValue={items?.eta}
                          onChange={(e) => setEta(e.target.value)}
                        />
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                            marginLeft: "-30px",
                            marginBottom: "3px",
                          }}
                        >
                          <ModeEditOutlinedIcon onClick={HandleUpdateSubmit} />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                            marginLeft: "-30px",
                            marginBottom: "3px",
                          }}
                          onClick={() => setShowEdit((prev) => !prev)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    )}

                    {items?.eta !== null ? (
                      <>
                        {" "}
                        {CheckPermission("add_eta_followup", userinfo)
                          ? !showedit &&
                            (items?.finalEta == 0 ? (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },
                                  marginLeft: "-30px",
                                  marginBottom: "3px",
                                }}
                              >
                                <ModeEditOutlinedIcon
                                  onClick={() => setShowEdit((prev) => !prev)}
                                />
                              </IconButton>
                            ) : (
                              ""
                            ))
                          : ""}
                      </>
                    ) : (
                      <>
                        {CheckProjectPermission(
                          "add_eta_followup",
                          projectmembers,
                          userinfo,
                          roleRoles
                        )
                          ? !show && (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },
                                  marginLeft: "-30px",
                                  marginBottom: "3px",
                                }}
                                onClick={() => setShow((prev) => !prev)}
                              >
                                <AddIcon />
                              </IconButton>
                            )
                          : ""}

                        {show && (
                          <>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },
                                marginLeft: "-30px",
                                marginBottom: "3px",
                              }}
                              onClick={HandleEditSubmit}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },
                                marginLeft: "-30px",
                                marginBottom: "3px",
                              }}
                              onClick={() => setShow((prev) => !prev)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {items?.eta != null && items?.finalEta == 0 ? (
                    CheckProjectPermission(
                      "make_eta_final",
                      projectmembers,
                      userinfo,
                      roleRoles
                    ) ? (
                      <button
                        className="eta-btn px-5 mb-3"
                        onClick={HandleFinalubmit}
                      >
                        Final
                      </button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
          <EmailSubjectHandoverDate setMessages={setMessages} />
          <EtdBatch data={etaDetail?.data[0]} setMessages={setMessages} />
        </div>
      </div>
    </SourcingIndex>
  );
}
