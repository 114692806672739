import React from "react";
import { useState } from "react";
import { useAddPaymentTermMutation } from "../../../features/SCMApi";
import "./modal.css";

export default function AddPaymentTerm(props) {
  const [createPaymentTerm, res] = useAddPaymentTermMutation();
  const [advance_percentage, setAdvancePayment] = useState(null);
  const [payment_term, setPaymentTerm] = useState(null);
  let payments = {
    advance_percentage,
    payment_term,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    createPaymentTerm(payments)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        props.setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }

  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <h3>Add Payment Term</h3>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="pi-inputs">
              <section className="pi-inputs-modal">
                <label>Advance%</label>
                <input
                  type="text"
                  onChange={(e) => setAdvancePayment(e.target.value)}
                />
              </section>
              <section className="pi-inputs-modal">
                <label>Description</label>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setPaymentTerm(e.target.value)}
                ></textarea>
              </section>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
