import React, { useState } from "react";
import Summary from "../../components/Sourcing/Summary";
import "./paStagemanufacturer.style.css";
import NavBar from "../../Layout/NavBar";
import PaStageManufacturer from "../../components/Sourcing/PaStageManufacturer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  useGetManufacturersQuery,
  useGetOrderItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetUserInfoQuery,
  useGetApproverQuery,
} from "../../features/SCMApi";
import { useLocation, useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../api/endPoint";
import SourcingIndex from "./SourcingIndex";
import { Tooltip } from "@mui/material";

//Approval icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ApprovalHistory from "../../components/common/ApprovalHistory";
import BcStageData from "../../components/Sourcing/BcStageData";

export default function BcStageDetail() {
  const params = useParams();
  const { data: orderDetail } = useGetOrderItemQuery(params.id);
  let supplier_name =
    orderDetail?.data?.orders?.supplier?.distributor_name == null
      ? ""
      : orderDetail?.data?.orders?.supplier.distributor_name;
  const { data: manufacturer } = useGetManufacturersQuery();
  let final;
  if (orderDetail?.data?.pa !== undefined)
    final = Object.entries(orderDetail?.data?.pa);
  let manPush = [];
  final?.map((index) =>
    manufacturer?.data
      .filter((manus) => manus?.id === index[0])
      .map((item) => manPush.push(item))
  );
  const [manufactuer_id, setManufacturer] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const HandleManufacturer = (e) => {
    setManufacturer(e.target.value);
  };
  const HandleAll = () => {
    setManufacturer();
  };
  console.log(orderDetail?.data?.orders?.project_id, "projectname");
  let avatar = [];
  const [Messages, setMessages] = useState();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    orderDetail?.data?.orders?.project_id
  );
  const { data: roles } = useGetProjectRolesQuery();
  console.log(orderDetail?.data?.orders?.pa_status, "nnnn roles");

  const paData = orderDetail && Object.values(orderDetail?.data?.pa);
  const paId = paData && paData.flat(1)[0]?.id;

  const { data: getApprover } = useGetApproverQuery({
    module_name: "PA",
    id: paId,
  });
  console.log(getApprover, "getApprover");
  let feedBack =
    getApprover &&
    getApprover?.data?.history?.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history?.find((user) => user?.feedback)?.feedback
      : false;

  let feedBackUser =
    getApprover &&
    getApprover?.data?.history?.find((user) => user?.feedback)?.name
      ? getApprover?.data?.history?.find((user) => user?.feedback)?.name
      : false;
  let roleName =
    getApprover &&
    getApprover?.data?.current?.find((paData) => paData?.level)?.role_name;

  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current?.find((paData) => paData?.pa_approval == 1)
      ?.pa_approval == 1
      ? true
      : getApprover?.data?.current?.find((paData) => paData?.pa_approval == 0)
            ?.pa_approval == 0
        ? false
        : getApprover?.data?.current?.find((paData) => paData?.pa_approval == 2)
              ?.pa_approval == 2
          ? null
          : "";

  let level = getApprover?.data?.current.find((paData) => paData?.level)?.level;

  const approvalStatus = {
    level,
    finalApproved: isPaApproved == 1 ? true : isPaApproved == 0 ? false : null,
    feedBack,
    feedBackUser,
  };
  console.log(getApprover, "this is approval status for test");
  console.log(isPaApproved, "this is approval isPaApproved");
  console.log(
    approvalStatus?.finalApproved,
    "this is approval for test tttsss"
  );
  const [approvalHistory, setApprovalHistory] = useState(false);
  function HandleHistory() {
    setApprovalHistory(true);
  }
  console.log(orderDetail, "orderDetailorderDetail");
  return (
    <SourcingIndex
      title={"BC Stage"}
      navlink={`/sourcing/BCStage/${orderDetail?.data?.orders?.project_id}`}
      names={orderDetail?.data?.orders?.order_name}
      Message={Messages}
    >
      {manPush?.length > 1 ? (
        <div className="pa-stage-tab my-3">
          <button
            className="tab-btn tab-item sourcing-active-tab"
            onClick={HandleAll}
          >
            All
          </button>
          <div className="options sourcing" onClick={toggling}>
            <div className=" d-flex align-items-center">
              Manufacturers <ArrowDropDownIcon />
            </div>
            {isOpen && (
              <div className="option-container">
                {manPush?.map((items) => (
                  <button
                    className="option "
                    value={items.id}
                    onClick={(e) => HandleManufacturer(e)}
                  >
                    {items.manufacturer_name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="smManufacturer-card-container">
        <div className="smManufacturer-card " onClick={HandleHistory}>
          <div className="Lc-card">
            <div className="lc-value">
              {console.log(getApprover?.data?.current, "@@@@")}
              {approvalStatus?.finalApproved === false &&
              getApprover &&
              getApprover?.data?.current?.length !== 0 ? (
                <div
                  style={{
                    color: "#fcce37",
                    textAlign: "center",
                  }}
                >
                  Level {approvalStatus?.level} (
                  {roleName?.length > 21
                    ? roleName?.slice(0, 20) + "..."
                    : roleName}
                  ) approval pending
                </div>
              ) : approvalStatus?.finalApproved === true ? (
                <span
                  style={{
                    color: "#064B4F",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Approved
                  <CheckCircleOutlineIcon />
                </span>
              ) : approvalStatus?.finalApproved === null ? (
                <Tooltip
                  title={`${approvalStatus?.feedBackUser} reject PA with comment ${approvalStatus?.feedBack}`}
                >
                  <span
                    style={{
                      color: "#FF5252",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    PA rejected
                  </span>
                </Tooltip>
              ) : (
                "Waiting to send for approver"
              )}
            </div>
            <div className="lc-label">Status</div>
          </div>
        </div>
        <div className="smManufacturer-card ">
          <div className="Lc-card">
            <Tooltip title={supplier_name}>
              {orderDetail?.data?.orders?.supplier_id != null ? (
                <div className="lc-value">
                  {supplier_name == null
                    ? "-"
                    : supplier_name?.length >= 20
                      ? supplier_name?.slice(0, 18) + "..."
                      : supplier_name}
                </div>
              ) : (
                <div className="lc-value">
                  {orderDetail?.data?.orders?.bill_to == null
                    ? "-"
                    : orderDetail?.data?.orders?.bill_to?.distributor_name
                          ?.length >= 20
                      ? orderDetail?.data?.orders?.bill_to?.distributor_name?.slice(
                          0,
                          18
                        ) + "..."
                      : orderDetail?.data?.orders?.bill_to?.distributor_name}
                </div>
              )}
            </Tooltip>

            <div className="lc-label">
              {orderDetail?.data?.orders?.supplier_id != null
                ? "Supplier"
                : "Bill to"}
            </div>
          </div>
        </div>
        <div className="smManufacturer-card overflow-auto">
          <div className="Lc-card mt-5">
            {projectmembers?.data?.users.map((items) =>
              roles?.data?.map((role) =>
                role.id === items?.pivot?.role_id ? (
                  role?.role_name === "Sourcing Officer" ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="participantManufacturer-img">
                        {items.avatar !== null ? (
                          <img src={`${IMG_BASE_URL}${items.avatar}`} alt="" />
                        ) : (
                          <div className="default-avatar">
                            <div style={{ display: "none" }}>
                              {(avatar = items?.name?.split(" "))}
                            </div>
                            {avatar[0]?.charAt(0)}
                            {avatar[1]?.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="lc-value">{items.name}</div>
                        <div className="lc-label">{role?.role_name}</div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
        <div className="smManufacturer-card overflow-auto">
          <div className="Lc-card">
            {projectmembers?.data?.users.map((items) =>
              roles?.data?.map((role) =>
                role.id === items?.pivot?.role_id ? (
                  role?.role_name === "Logistics Officer" ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="participantManufacturer-img">
                        {items.avatar !== null ? (
                          <img src={`${IMG_BASE_URL}${items.avatar}`} alt="" />
                        ) : (
                          <div className="default-avatar">
                            <div style={{ display: "none" }}>
                              {(avatar = items?.name?.split(" "))}
                            </div>
                            {avatar[0]?.charAt(0)}
                            {avatar[1]?.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="lc-value">{items.name}</div>
                        <div className="lc-label">{role?.role_name}</div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
      </div>
      <BcStageData manufacturer_id={manufactuer_id} setMessages={setMessages} />
      {approvalHistory ? (
        <ApprovalHistory
          open={approvalHistory}
          data={getApprover?.data?.history}
          handleClose={() => setApprovalHistory(false)}
        />
      ) : (
        ""
      )}
    </SourcingIndex>
  );
}
