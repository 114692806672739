import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import {
  useGetOriginQuery,
  useGetSupplierCategoriesQuery,
  useGetSupplierOriginsQuery,
  useUpdateSupplierAddressMutation,
  useupdatesupplieraddressMutation,
} from "../../features/SCMApi";
import DoneIcon from "@mui/icons-material/Done";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { useEffect } from "react";

export default function SupplierEditAddress(props) {
  const [showBank, setShowBank] = useState(false);
  const params = useParams();
  const [product_id, setProductid] = useState();
  const [distributor_name, setManufacturer_name] = useState(
    props?.value?.data?.distributor_name
  );
  const [origin_id, setManufacturer_origin] = useState(
    props?.value?.data?.origin_id
  );
  const [website, setManufacturer_website] = useState(
    props?.value?.data?.website
  );
  const [address, setManufacturer_address] = useState(
    props?.value?.data?.address
  );
  const [postal_code, setPobox] = useState(props?.value?.data?.postal_code);
  const [city, setManufacturer_city] = useState(props?.value?.data?.city);
  const citys = props?.value?.data?.city;
  const [isSupplier, setisSupplier] = useState(true);
  const [errorMessage, seterrorMessage] = useState(null);
  const [EditsupplierAddress, response] = useUpdateSupplierAddressMutation();
  const [department, setDepartment] = useState(props?.value?.data?.department);

  const [is_offshore, setOffshore] = useState(false);
  useEffect(() => {
    setOffshore(props?.value?.data.is_offshore == 0 ? false : true);
    setDepartment(props?.value?.data?.department);
  }, [props?.value?.data.is_offshore, props?.value?.data?.department]);
  const [category_id, setCategory_id] = useState(
    props?.value?.data?.categories?.map((items) => ({
      label: items?.name,
      value: items?.id,
    }))
  );

  const { data: supplierCategoryTypes } = useGetSupplierCategoriesQuery();

  const SupplierCategorytypeList = [];
  supplierCategoryTypes?.data?.map((items) =>
    SupplierCategorytypeList.push({
      value: items?.id,
      label: items?.name,
    })
  );

  let category_ids = [];
  if (category_id != null) {
    for (let id in category_id) {
      category_ids.push(category_id[id]?.value);
    }
  }

  let addressmanufacturer = {
    id: params.id,
    distributor_name,
    origin_id,
    city,
    website,
    address,
    postal_code,
    isSupplier,
    is_offshore: is_offshore === false ? 0 : 1,
    department,
    category_ids,
  };
  console.log(addressmanufacturer, "addressmanufacturer");
  const { data: origin } = useGetSupplierOriginsQuery();
  const HandleEditSubmit = async (e) => {
    e.preventDefault();
    EditsupplierAddress(addressmanufacturer)
      .unwrap()
      .then((response) => {
        props.setMessages("Supplier Address Updated successfully");
        props.showaddress(false);
      })
      .catch((error) => {
        console.log(error, "erorrrrr");
      });
  };
  const HandleCloseAddress = async () => {
    props.showaddress(false);
  };

  const CheckBoxHandelonShore = () => {
    setOffshore(!is_offshore);
  };
  console.log(props?.value?.data, " props?.value?.data");
  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCloseAddress}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>Edit Information</div>
            <div onClick={HandleCloseAddress}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="form-container" onSubmit={HandleEditSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Address Detail</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div
                          style={{ marginTop: "20px" }}
                          className="d-flex justify-content-center gap-2 px-2"
                        >
                          <input
                            checked={is_offshore}
                            type="checkbox"
                            className="stock-checkmark"
                            onChange={CheckBoxHandelonShore}
                          />
                          <span className="h6">Off Shore</span>
                        </div>

                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Supplier Name
                          </div>
                          <input
                            typ="text"
                            id=""
                            required
                            defaultValue={distributor_name}
                            className="supplier-text-input"
                            onChange={(e) =>
                              setManufacturer_name(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Department</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            <option value="" disabled selected hidden>
                              Select Department
                            </option>
                            <option value="Sourcing">Sourcing</option>
                            <option value="Finance">Finance</option>
                            <option value="Logistics">Logistics</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Origin</div>
                          <select
                            name=""
                            id=""
                            required
                            value={origin_id}
                            className="supplier-select-option"
                            onChange={(e) =>
                              setManufacturer_origin(e.target.value)
                            }
                          >
                            <option value="" disabled selected hidden>
                              Select Origin
                            </option>
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Supplier category
                          </div>

                          <div style={{ width: "199px" }}>
                            <Select
                              options={SupplierCategorytypeList}
                              isSearchable={true}
                              isMulti
                              required
                              value={category_id}
                              onChange={(e) => {
                                setCategory_id(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">City</div>
                          <input
                            typ="text"
                            id=""
                            required
                            value={city}
                            className="supplier-text-input"
                            onChange={(e) =>
                              setManufacturer_city(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Website</div>
                          <input
                            required
                            type="text"
                            value={website}
                            className="supplier-text-input"
                            onChange={(e) =>
                              setManufacturer_website(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Address</div>
                          <input
                            required
                            type="text"
                            value={address}
                            className="supplier-text-input"
                            onChange={(e) =>
                              setManufacturer_address(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">P.O Box</div>
                          <input
                            required
                            type="text"
                            value={postal_code}
                            className="supplier-text-input"
                            onChange={(e) => setPobox(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons">
                <button
                  type="cancel"
                  className="form-cancel"
                  onClick={HandleCloseAddress}
                >
                  {" "}
                  Cancel
                </button>
                <button type="submit" className="form-save">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
