import React from "react";

export default function ModuleSelector({ module, setModule }) {
  console.log(module, "module");
  return (
    <div className="d-flex gap-3 align-items-baseline mt-2">
      <div className="d-flex align-items-center gap-2">
        <button
          value="sourcing"
          onClick={(e) => setModule(e.target.value)}
          className={
            module == "sourcing"
              ? "report-module-select-active"
              : "report-module-select"
          }
        ></button>
        <label className="module-label">Sourcing</label>
      </div>
      <div className="d-flex align-items-center gap-2">
        <button
          value="logistics"
          onClick={(e) => setModule(e.target.value)}
          className={
            module == "logistics"
              ? "report-module-select-active"
              : "report-module-select"
          }
        ></button>
        <label className="module-label">Logistics</label>
      </div>
      <div className="d-flex align-items-center gap-2">
        <button
          value="finance"
          onClick={(e) => setModule(e.target.value)}
          className={
            module == "finance"
              ? "report-module-select-active"
              : "report-module-select"
          }
        ></button>
        <label className="module-label">Finance</label>
      </div>
    </div>
  );
}
