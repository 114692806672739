import React from "react";

export default function DeliveredItemBatchCards(props) {
  console.log(props, "this is props for delivered items");
  return (
    <>
      <>
        <div className="batch-detail-card missed-item-card">
          <div className="batch-detail-card-sub">
            <div className="batch-card-left-grid">
              <div className="batch-container">
                <div className="missed-item-logistics-batch-name">
                  {props?.data?.batch_name}
                </div>
              </div>
              <div className="missed-item-origin-group">
                <div className="origin-value">
                  {props?.data?.batch_origin?.name}
                </div>
                <div className="origin-label">Origin</div>
              </div>
            </div>

            <div className="logistics-batch-items-container">
              <div className="missed-items-batch-item">
                <div
                  className="logistics-batch-value"
                  style={{ marginLeft: "15px" }}
                >
                  {props?.data?.items?.length}
                </div>
                <div className="logistics-batch-label">Delivered</div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
