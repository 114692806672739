import * as React from "react";
import { NavLink } from "react-router-dom";
import InitiateForexButton from "../../../../components/Payment/ForexApproval/InitiateForexButton";
import ForexCard from "../../../../components/Payment/ForexApproval/ForexCard";
import "../../forexPages.style.css";
import usePagination from "../../../../components/Pagination/Pagination";
import { Pagination, Tooltip } from "@mui/material";
import {
  useGetForexQuery,
  useGetForexProjectIeInitiatedQuery,
  useGetUserInfoQuery,
  useGetProjectsQuery,
} from "../../../../features/SCMApi";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { date, totalDates } from "../../../../components/dateFormator";
import ForexSideBar from "../../../../components/Payment/ForexApproval/PaymentSideBar";
import DelinquentReport from "../../../../components/Payment/ForexPlanning/ForexReport/DelinquentReport";
import DeliquentItems from "./DeliquentItems";
import NoRecord from "../../../../components/common/NoRecord";

export default function Delinquent() {
  const { data: forexApprovalIe } = useGetForexQuery();
  const { data: projectList } = useGetForexProjectIeInitiatedQuery();
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projects } = useGetProjectsQuery();
  const [filter, setFilter] = React.useState(false);
  let initiatedForexLists = [];
  forexApprovalIe?.data?.filter((forex) =>
    forex?.forex_project?.forex_resource == 0 && forex?.isApproved == 1
      ? initiatedForexLists.push(forex)
      : ""
  );
  const [project, setProject] = React.useState("");
  const [clear, setClear] = React.useState(0);
  const filteredData = (clear, project) => {
    return initiatedForexLists
      ?.filter((items) => {
        if (clear === "") {
          return items;
        } else if (items?.NBE_cleared == clear) {
          return items;
        }
      })
      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.project_id == project) {
          return items;
        }
      })

      .map((items) => items);
  };
  function HandleFilter() {
    setFilter(!filter);
    setClear("");
    setProject("");
  }
  console.log(initiatedForexLists, filteredData, "forexApprovalIe");
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(filteredData(clear, project)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(clear, project), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const itemsTotal = [];
  initiatedForexLists?.map((itemsdata, index) =>
    itemsTotal.push(initiatedForexLists[index].items)
  );
  console.log(clear, "dfgvhvhg");
  const [data, setData] = React.useState(null);
  const [showPi, setShowPi] = React.useState(false);
  function handleDeliquent(items) {
    setData(items);
    setShowPi(true);
  }
  let counts = 0;
  return (
    <ForexSideBar>
      <div className="payment-collection-container">
        <div className="h5-pri text-center py-2">Delinquent Tracking Sheet</div>

        <div className="d-flex gap-5 align-items-center mt-2 px-5">
          <div className="d-flex justify-content-end mt-3">
            {filteredData(clear, project)?.length != 0 ? (
              <DelinquentReport data={filteredData(clear, project)} />
            ) : (
              ""
            )}
            <div className="d-flex align-items-center gap-2 me-3">
              <button
                value={0}
                onClick={(e) => setClear(0)}
                className={
                  clear == 0
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Not Cleared</label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <button
                value={1}
                onClick={(e) => setClear(1)}
                className={
                  clear == 1
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Cleared</label>
            </div>
            <select
              style={{ width: "18rem" }}
              className="report-filter-selector border ms-3"
              onChange={(e) => setProject(e.target.value)}
            >
              <option value="" selected>
                Project
              </option>
              {projects?.data?.map((items) => (
                <option value={items?.id}>{items?.project_name}</option>
              ))}
            </select>
          </div>
        </div>
        {filteredData(clear, project)?.length != 0 ? (
          <>
            <div className="p-2 finance-table-container">
              <table
                className="table table-striped table-bordered "
                style={{
                  width: "auto",
                  minWidth: "1400px",
                  borderCollapse: "collapse",
                }}
              >
                <thead className="stock-table-header table-dark ">
                  <tr>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "3%" }}
                    >
                      No
                    </th>

                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "15%" }}
                    >
                      Project
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "10%" }}
                    >
                      Merged Project
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "10%" }}
                    >
                      Items
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "10%" }}
                    >
                      Supplier
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "10%" }}
                    >
                      Type of Payment
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "10%" }}
                    >
                      Amount
                    </th>
                    <th
                      className="stock-table-header-text text-start text-white"
                      style={{ width: "15%" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {_DATA?.currentData()?.map((items, index) => (
                    <tr
                      className={`stock-table-row ${
                        totalDates(items?.planed_collection_date) <= 0 &&
                        items?.is_collected == 0
                          ? "bg-danger text-white"
                          : items?.is_collected == 1
                            ? "bg-success text-white"
                            : ""
                      }`}
                      onClick={() => handleDeliquent(items)}
                    >
                      <td className="text-start">{(counts += 1)}</td>
                      <td className="text-start">
                        {items?.forex_project?.project_name}
                      </td>
                      <td className="text-start">
                        {items?.merged_projects ? items?.merged_projects : "-"}
                      </td>
                      <td className="text-start">
                        {items?.items_group_name
                          ? items?.items_group_name
                          : "-"}
                      </td>
                      <td className="text-start">
                        {items?.forex_term[0]?.forex_opening_to
                          ?.distributor_name == undefined
                          ? "-"
                          : items?.forex_term[0]?.forex_opening_to
                              ?.distributor_name}
                      </td>
                      <td className="text-start">
                        {items?.forex_term
                          ? items?.forex_term[0]?.forex_payment_mode
                              ?.payment_mode
                          : "-"}
                      </td>
                      <td className="text-start">
                        {items?.is_actual == 1
                          ? Number(items?.amount)?.toLocaleString()
                          : Number(
                              items?.items
                                ?.map((row) =>
                                  row?.total_cost ? row?.total_cost : 0
                                )
                                .reduce((acc, val) => acc + val, 0)
                            )?.toLocaleString()}
                      </td>
                      <td className="text-start">
                        {items?.NBE_cleared == 1
                          ? "Cleared"
                          : items?.forex_status
                            ? items?.forex_status[
                                items?.forex_status?.length - 1
                              ]?.statuses
                            : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                className="p-2"
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <NoRecord />
        )}
      </div>
      {showPi ? <DeliquentItems data={data} modal={setShowPi} /> : ""}
    </ForexSideBar>
  );
}
