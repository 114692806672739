import React, { useState } from "react";
import axios from "axios";
import {
  useCreateOrderMutation,
  useGetBoqtypesQuery,
  useGetManufacturersQuery,
  useGetMillstonesQuery,
  useGetProductsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetSuppliersOrdersQuery,
  useGetSuppliersQuery,
  useUpdateOrderMutation,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import ManufacturerForm from "./ManufacturerForm";
import { API_BASE_URL } from "../../api/endPoint";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import attachmentIcon from "../../assets/SVG/attach.svg";

export default function EditPi(props) {
  const [count, setCount] = useState([0]);
  const [boq_type_id, setBoqType] = useState();
  const [supplier_id, setSupplier] = useState([]);
  const [project_id, setProject] = useState();
  const [solution_id, setSolution] = useState();
  const [milestone_id, setMilestone] = useState();
  const [description, setDescription] = useState();
  // const [pi, setPi] = useState();
  const solutionsList = [];
  const suppliersList = [];
  const { data: projects } = useGetProjectsQuery();
  const { data: boqType } = useGetBoqtypesQuery();
  const [Manufacturer, setManufacture] = useState();
  const performa_invoice = [];
  const manufacturer_id = [];
  const [open, setOpen] = React.useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const {
    data: Solutions,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetProductsQuery();
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  const { data: Manufacturers } = useGetManufacturersQuery();
  const [uploading, setUploading] = useState(false);

  Solutions?.data?.map((items) =>
    solutionsList.push({
      value: items?.id,
      label: items?.abbrivation,
    })
  );
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );
  const [updateOrder, errorsorder] = useUpdateOrderMutation();
  console.log(props?.Manufacturer_id, "Manufacturer");
  const param = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isStored, setIsStored] = useState(null);
  const [pi, setPi] = useState();

  function handleSubmit(e) {
    setUploading(true);
    e.preventDefault();
    const form2 = new FormData();
    form2.append("id", params?.id);
    form2.append("manufacturer_id", props?.Manufacturer_id);
    form2.append("pi", pi);

    // updateOrder(form2)
    //   .unwrap()
    //   .then((response) => {
    //     props?.setMessage("Order Created Successfully");
    //     props.modal(false);
    //     setUploading(null);
    //     console.log(response, "Response for created order from backend");
    //   })
    //   .then((error) => {
    //     console.log(error, "error for created order from backend");
    //   });

    axios({
      method: "post",
      url: `${API_BASE_URL}/scm/sourcing/orders-updatePi/${params?.id}`,
      data: form2,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        props.modal(false);
        setUploading(null);
        window.location.reload(true);
        console.log(response, "kira response");
      })
      .catch(function (response) {
        console.log(response);
      });

    console.log(form2, "parameters for create");

    if (errorsorder?.status === "uninitialized") {
      setIsStored(true);
    } else if (errorsorder?.status === "rejected") {
      setErrorMessage(errorsorder?.error?.data?.message);
      handleClickOpen();
      setUploading(false);
      setIsStored(null);
    }

    console.log(form2, "psssssssssssssssssssi");
  }

  if (isSuccess) console.log(Solutions?.data, "ola");
  const params = useParams();
  console.log(pi, "psssssssssssssssssssi");

  // console.log(milestone, "milestone");
  const HandleCancel = () => {
    props.modal(false);
  };

  function handleChange(e) {
    setPi(e?.target?.files?.[0]);
  }
  const { data: projectid } = useGetProjectQuery(props?.project_id);
  console.log(projectid, "props?.manufacturer_id");

  return (
    <div>
      {" "}
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Edit PI</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <div className="lc-warning ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="24"
                height="24"
                fill="white"
                fill-opacity="0.01"
              ></rect>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                fill="white"
              ></path>
            </svg>
            <div>
              Please make sure the PI file you are about to import follows the
              proper format
            </div>
            {error?.status} {JSON?.stringify(error?.data)}
          </div>
          {projectid?.data?.product_boq?.length == 0 ? (
            <div className="lc-warning bg-danger ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  fill-opacity="0.01"
                ></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                  fill="white"
                ></path>
              </svg>
              <div>This project doesn't have a BoQ</div>
            </div>
          ) : (
            ""
          )}
          {uploading ? (
            <div className="inputs-group " style={{ width: "55%" }}>
              <div className="input-label">Uploading</div>
              <div class="loader"></div>
            </div>
          ) : (
            <></>
          )}
          {errormessage !== null ? (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className="text-center">
                    <WarningIcon
                      sx={{
                        color: "red",
                        width: 100,
                        height: 100,
                      }}
                    />
                  </div>

                  <DialogContentText id="alert-dialog-description">
                    {errormessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            // <Alert severity="error"></Alert>
            ""
          )}
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier"></div>

            <div className="file-chooser-group">
              <div className="input-label">PI Import</div>
              <div
                className="file-chooser"
                style={{ backgroundImage: `url(${attachmentIcon})` }}
              >
                <input
                  required
                  type="file"
                  className="file-input"
                  id="boqfile"
                  name="boq"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <label
                  htmlFor="boqfile"
                  style={{
                    position: "relative",
                    top: "-22px",
                    left: "5px",
                    width: "5rem",
                    // zIndex: "-1",
                  }}
                >
                  {pi ? pi?.name.slice(0, 5) + "...." : " File"}
                </label>
              </div>
            </div>
            {isStored === true ? (
              <>
                <p className="text-danger h5">you have an error</p>
                <button type="submit" class="create-project-btn">
                  For More Information
                </button>
              </>
            ) : (
              <></>
            )}

            <div class="form-buttons">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                {" "}
                Cancel
              </button>
              <button type="submit" class="form-save">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
