import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RemarkForex from "../../../../components/Payment/ForexApproval/RemarkForex";
import ExpirationRemark from "../../../../components/Payment/ExpirationRemark";

export default function DeliquentItems(props) {
  function HandleCancel() {
    props?.modal(false);
  }
  console.log(props?.data, "sdfghjk");
  console.log(props?.isExpiration, "isExpiration");
  const [item, setItems] = useState(true);
  return (
    <div>
      {" "}
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title m-3">
            <div className="d-flex gap-5">
              <div>PI's ({props?.data?.forex_name})</div>
              {item ? (
                <button
                  onClick={(e) => setItems(false)}
                  style={{
                    width: "10rem",
                    border: "none",
                    fontSize: "14px",
                    color: "#198754",
                  }}
                >
                  History
                </button>
              ) : (
                <button
                  onClick={(e) => setItems(true)}
                  style={{
                    width: "10rem",
                    border: "none",
                    fontSize: "14px",
                    color: "#198754",
                  }}
                >
                  Items
                </button>
              )}
            </div>

            <CloseIcon onClick={HandleCancel} />
          </div>
          {item ? (
            <>
              <div className="h5 m-3">
                Total Price:{" "}
                <span className="h5-pri">
                  {props?.data?.is_actual == 1
                    ? Number(props?.data?.amount)?.toLocaleString()
                    : Number(
                        props?.data?.items
                          ?.map((row) =>
                            row?.total_cost ? row?.total_cost : 0
                          )
                          .reduce((acc, val) => acc + val, 0)
                      )?.toLocaleString()}
                </span>
              </div>
              <div className="">
                <ul class="list-group">
                  {props?.data?.items?.map((item) => (
                    <li class="list-group-item d-flex justify-content-between align-items-center task-order-name">
                      {item?.item_description ? item?.item_description : "-"}
                      <span class="badge bg-success rounded-pill">
                        {Number(item?.total_cost)?.toLocaleString()}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : !props?.isExpiration ? (
            <div className="border-0 px-4">
              <RemarkForex id={props?.data?.id} />
            </div>
          ) : (
            <div className="border-0 px-4">
              <ExpirationRemark data={props?.data} id={props?.data?.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
