import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddItemsType from "./SettingModal/AddItemsType";
import EditItemsType from "./SettingModal/EditItemsType";
import {
  useDeleteInventoryTypeMutation,
  useGetItemTypeQuery,
} from "../../features/SCMApi";
export default function ItemsType() {
  const [additemstype, setAddItemsType] = useState(false);
  const [edititemstype, setEditItemsType] = useState(false);
  const HandleItemType = () => {
    setAddItemsType(true);
  };
  const HandleEditItemType = () => {
    setEditItemsType(true);
  };
  const { data: itemstype } = useGetItemTypeQuery();
  console.log(itemstype, "itemstype");
  const [updateStatus, setStatus] = useState();
  const [DeleteStore, src] = useDeleteInventoryTypeMutation();

  function HandleDelete(e, id) {
    let deleted = {
      id: id,
    };
    DeleteStore(deleted)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        // setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  }
  const [itemsType, setItemsType] = useState();
  return (
    <div className="pcard shipment-mode">
      <section className="pi-header">
        <h3>Item Type</h3>
      </section>
      {itemstype?.data?.map((items) => (
        <section className="pi-body">
          <p>{items?.inventory_type}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                HandleEditItemType(true);
                setItemsType(items);
              }}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={(e) => {
                HandleDelete(e, items.id);
              }}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={HandleItemType} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {additemstype ? <AddItemsType modal={setAddItemsType} /> : ""}
      {edititemstype ? (
        <EditItemsType modal={setEditItemsType} data={itemsType} />
      ) : (
        ""
      )}
    </div>
  );
}
