import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./tabstyle.css";
import PoDragFeature from "../../Settings/features/PoDragFeature";

export default function PoPriceTab({ ranges, type, setMessage }) {
  console.log(ranges, "ranges");
  return (
    <>
      <Tabs
        defaultActiveKey={0}
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center gap-4"
      >
        {ranges?.map((items, index) => (
          <Tab
            eventKey={index}
            title={
              Number(items?.min_value)?.toLocaleString() +
              ">" +
              Number(items?.max_value)?.toLocaleString()
            }
          >
            <PoDragFeature
              id={items?.id}
              type={type}
              setMessage={setMessage}
              range_stages={items?.approval_stage}
              index={index}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
