import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreateForexExpirationRemarkMutation,
  useCreateStatusForexAchievedMutation,
  useCreateStatusForexMutation,
  useGetForexExpirationRemarkQuery,
  useGetStatusForexQuery,
} from "../../features/SCMApi";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import { date } from "../dateFormator";
import ConfirmDelete from "../common/ConfirmDelete";

export default function ExpirationRemark({ id, data }) {
  const [status, setStatus] = useState(null);
  const [remark, setRemark] = useState(null);
  const params = useParams();
  const { data: getStatus } = useGetForexExpirationRemarkQuery(
    params?.id ? params?.id : id
  );
  console.log(getStatus, "forex expiration");
  let count = 0;
  const [createStatus] = useCreateForexExpirationRemarkMutation();
  const [updateAchieved] = useCreateStatusForexAchievedMutation();
  let form = {
    forex_id: params?.id ? params?.id : id,
    status,
    remark,
  };
  const [statusHistory, setStatusHistory] = useState(true);
  const [EXP_achieved, setEXP_achieved] = useState(true);
  useEffect(() => {
    setEXP_achieved(data?.EXP_achieved == 0 ? false : true);
  }, [data?.EXP_achieved]);
  const [dialog, setDialog] = useState(false);
  console.log(form, "form");
  function handleSubmit(e) {
    e.preventDefault();
    createStatus(form)
      .unwrap()
      .then((response) => {
        setStatus(null);
        console.log(response, "Response for created  from backend");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error, "Response for created  from backend err");
      });
  }
  console.log(EXP_achieved, data?.EXP_achieved, " Clicked2");

  function handleSubmitAchieved(e) {
    e?.preventDefault();
    console.log(EXP_achieved, " Clicked1");

    let achievedForm = {
      id: params?.id ? params?.id : id,
      EXP_achieved: !EXP_achieved,
    };
    updateAchieved(achievedForm)
      .unwrap()
      .then((response) => {
        setStatus(null);
        console.log(response, "Response for created  from backend");
        setDialog(false);

        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error, "Response for created  from backend err");
      });
  }

  return (
    <div className="forex-remark">
      <div className="d-flex gap-2 justify-content-center py-3">
        <div className="text-center h5-pri p-2">Forex Expiration Status</div>
        <button
          onClick={(e) => setDialog(true)}
          style={{
            width: "10rem",
            border: "none",
            fontSize: "14px",
            color: "#198754",
          }}
        >
          {data?.EXP_achieved == 0
            ? "Mark as Achieved"
            : "mark as not Achieved"}
        </button>
      </div>

      <div className="remark-container px-3 py-2">
        <div className="d-grid justify-content-between gap-1">
          <div className="prg text-start">Status</div>
          <textarea
            type="text"
            style={{ width: "100%" }}
            className="sourcing-text-input-textarea"
            rows={4}
            cols={50}
            onChange={(e) => setStatus(e.target.value)}
          ></textarea>
          <div className="prg text-start">Remark</div>
          <textarea
            type="text"
            style={{ width: "100%" }}
            className="sourcing-text-input-textarea"
            rows={4}
            cols={50}
            onChange={(e) => setRemark(e.target.value)}
          ></textarea>
          {status != null && status != "" ? (
            <button
              type="submit"
              class="form-save"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="">
          <span className="prg text-start me-3">
            {statusHistory ? "Status History" : "Remark"}
          </span>
          {statusHistory ? (
            <button
              onClick={(e) => setStatusHistory(false)}
              style={{
                width: "6rem",
                border: "none",
                fontSize: "14px",
                color: "#198754",
              }}
              className="fw-bold"
            >
              Remark
            </button>
          ) : (
            <button
              onClick={(e) => setStatusHistory(true)}
              style={{
                width: "6rem",
                border: "none",
                fontSize: "14px",
                color: "#198754",
              }}
              className="fw-bold"
            >
              Status
            </button>
          )}
          {statusHistory ? (
            <div className="forex-status-history">
              {getStatus?.data?.length != 0 ? (
                <>
                  {getStatus?.data.map((items, index) => (
                    <div className="d-grid gap-2">
                      <div className="d-flex gap-1">
                        <div className="prg-pri">{(count += 1)}.</div>
                        <div className="gird gap-1 mb-2">
                          <div className="prg">{items?.status}</div>
                          <div className="module-label fst-italic fw-bold">
                            {date(items?.created_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoRecord />
              )}
            </div>
          ) : (
            <div className="forex-status-history">
              {getStatus?.data?.length != 0 ? (
                <>
                  {getStatus?.data.map((items, index) => (
                    <div className="d-grid gap-2">
                      <div className="d-flex gap-1">
                        <div className="prg-pri">{(count += 1)}.</div>
                        <div className="gird gap-1 mb-2">
                          <div className="prg">
                            {items?.remark ? items?.remark : "-"}
                          </div>
                          <div className="module-label fst-italic fw-bold">
                            {date(items?.created_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoRecord />
              )}
            </div>
          )}
        </div>
      </div>
      {dialog && (
        <ConfirmDelete
          action={(e) => handleSubmitAchieved(e)}
          setters={setDialog}
          setDialog={setDialog}
          dialog={`Are you to achieve this ${data?.forex_name} `}
          buttonTitle={"Confirm"}
        />
      )}
    </div>
  );
}
