import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import { useParams } from "react-router-dom";

export default function SummarizedPi(props) {
  function HandleCancel() {
    props.modal(false);
  }

  const forexId = useParams();
  const [summarizedPi, setSummarizedPi] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let params = {
    items: summarizedPi,
    forex_id: forexId?.id,
    project_id: props?.projectId,
    summarized_pi_id: props?.summarizedPiId,
  };

  const handleChange = (e) => {
    setSummarizedPi(e?.target?.files[0]);
    setIsFileSelected(true);
  };

  const handleClick = () => {
    isFileSelected === true ? setErrorMessage(false) : setErrorMessage(true);
  };

  const HandleSubmit = async (e) => {
    console.log("firstparams", params);

    e.preventDefault();
    isFileSelected ? (
      props?.previousData[0] === undefined ? (
        axios
          .post(`${API_BASE_URL}/scm/payment/add-summerized-pi`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            mode: "no-cors",
            withCredentials: true,
          })
          .then((response) => {
            console.log(response, "response frome database");
            HandleCancel();
            props?.successMessage(true);
            props?.message("Summarized pi document attached successfully!!!");
            // props?.refresh();
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      ) : (
        axios
          .post(
            `${API_BASE_URL}/scm/payment/update-summarized-pi/${props?.previousData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response, "response frome database");
            HandleCancel();
            props?.successMessage(true);
            props?.message("Summarized pi document attached successfully!!!");
            props.refreshe();
          })
          .then((error) => {
            console.log(error, "error from backend");
          })
      )
    ) : (
      <></>
    );
  };

  console.log(
    props?.previousData[0],
    "Summarized pi document attached successfully"
  );
  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="requestLetterTitle">Summarized PI</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <div>
            <div className="summarized-pi-lc-warning">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  fill-opacity="0.01"
                ></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                  fill="white"
                ></path>
              </svg>
              <div>
                Please make sure the PI file you are about to import follows the
                proper format
              </div>
            </div>
          </div>
          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {props?.previousData[0]?.length !== 0 ? (
                  errorMessage === true ? (
                    <>
                      <p className="forex-attached-file-warning">
                        Please select a file !!!
                      </p>
                    </>
                  ) : props?.previousData[0] !== undefined ? (
                    <>
                      <p className="forex-attached-file-warning">
                        Summarized Pi document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <div className="">
                  <div className="card-center">
                    <div className="card-form" style={{ marginTop: "-50px" }}>
                      <div className="rmaFileInputLabelContainer inputLabelsHolder">
                        <div className="rmaFileInputLabel">Summarized PI</div>
                      </div>
                      <div className="input-fields">
                        <div
                          className="file-chooser rmaMissedItemFile-choosers"
                          style={{
                            backgroundImage: `url(${attachmentIcon})`,
                          }}
                        >
                          <input
                            required={
                              props?.previousData[0] !== undefined
                                ? true
                                : false
                            }
                            type="file"
                            className="file-input"
                            name="boq"
                            onChange={(e) => handleChange(e)}
                          />
                          <div className="rmaFileUploader">
                            {summarizedPi
                              ? summarizedPi?.name.slice(0, 10) + "...."
                              : props?.previousData[0] !== undefined
                                ? "File exist"
                                : " File"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                  style={{ width: "112px", height: "32px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={handleClick}
                >
                  {props?.previousData[0] !== undefined ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
