import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";

export default function PayableHistorySummaryTable() {
  const [payableHistorySummaryData, setPayableHistorySummaryData] = useState(
    []
  );

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/sourcing/supplier/payment-history`)
      .then((response) => {
        setPayableHistorySummaryData(response?.data?.data);

        console.log("PayableHistoryData", response?.data?.data);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  let count = 0;

  return (
    <div className="table responsive">
      <table className="table border" style={{ marginTop: "20px" }}>
        <thead className="table-head">
          <tr>
            <th>No.</th>
            <th>Project Name</th>
            <th>Supplier Name</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Due date</th>
            <th>Actual payment date</th>
            <th>Age of payable</th>
            <th>Status</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {payableHistorySummaryData?.map((payables) => {
            count++;
            return (
              <tr>
                <td>{count}</td>
                <td>{payables.project_name}</td>
                <td>{payables.supplier_name}</td>
                <td>{payables.amount}</td>
                <td>
                  {payables.currency_value === "Other"
                    ? "Unknown"
                    : payables.currency_value}
                </td>
                <td>{payables.Due_date}</td>
                <td>{payables.Paid_date}</td>
                <td>{payables.Aging}</td>
                <td>{payables.Status}</td>
                <td>{payables.Reason}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
