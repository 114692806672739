import Settingsidebar from "../../pages/Settings/SettingIndex";
import "./Settings.css";
import "./paymentSettings.css";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PoDragFeature from "./features/PoDragFeature";
import DocumentDrag from "./features/DocumentDrag";
import PoPriceTab from "./features/PoPriceTab";
import { useState } from "react";
import AddDocumentModal from "./SettingModal/AddDocumentModal";
import AddPoModal from "./SettingModal/AddPoModal";
import AddPaModal from "./SettingModal/AddPaModal";
import { useGetSingleApprovalModuleQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import NoRecord from "./../../components/common/NoRecord";
import NormalApproval from "./features/NormanlApproval";
import ComparisonApproval from "./features/ComparisionApproval";

export default function ApprovalDetail() {
  const [showPa, setShowPa] = useState(false);
  const [showPo, setShowPo] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const params = useParams();
  const { data: modules } = useGetSingleApprovalModuleQuery(params?.id);
  console.log(modules, "module");
  const [message, setMessage] = useState(null);
  return (
    <Settingsidebar
      Messages={message}
      name={"Approval"}
      title={modules?.module_name}
    >
      <AddDocumentModal
        onClose={() => setShowDocument(false)}
        showDocument={showDocument}
      />
      <AddPoModal onClose={() => setShowPo(false)} showPo={showPo} />
      <AddPaModal onClose={() => setShowPa(false)} showPa={showPa} />
      <div className="page">
        <div className="">
          {modules?.normal_approval?.length != 0 ? (
            <div className="pcard pa">
              <section className="pi-header d-flex gap-3 align-items-center">
                <h3>Normal Approval</h3>
                <div onClick={() => setShowPa(true)} className="pi-footer-icon">
                  <AddIcon />
                </div>
              </section>

              <NormalApproval
                normal={
                  modules?.normal_approval == undefined
                    ? []
                    : modules?.normal_approval
                }
                module_data={modules}
                setMessage={setMessage}
              />
            </div>
          ) : (
            ""
          )}
          {modules?.comparision_approval?.length != 0 ? (
            <div className="pcard pa">
              <section className="pi-header d-flex gap-2">
                <h3>Comparison Approval</h3>
                <div onClick={() => setShowPa(true)} className="pi-footer-icon">
                  <AddIcon />
                </div>
              </section>
              <ComparisonApproval
                setMessage={setMessage}
                comparison={modules?.comparision_approval}
                type={"Comparison"}
              />
            </div>
          ) : (
            ""
          )}
          {modules?.range_approval?.length != 0 ? (
            <div className="pcard pa">
              <section className="pi-header d-flex align-items-center gap-2">
                <h3>Range Approval</h3>
              </section>

              <PoPriceTab
                setMessage={setMessage}
                type={"Range"}
                ranges={modules?.range_approval}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Settingsidebar>
  );
}
