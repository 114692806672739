import { Tooltip } from "@mui/material";
import React from "react";
import { API_BASE_URL, DOC_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useGetCiQuery,
  useGetOrderPoDocumentQuery,
  useGetPlQuery,
  useGetPoGroupDocumentQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import documentPath from "./../../assets/Template/POTemplate1.docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { useState } from "react";
import convert from "number-to-words";
import ImageModule from "docxtemplater-image-module";
import NoRecord from "../common/NoRecord";

export default function PoDocument(props) {
  console.log(props?.data?.data[0].pl, "Sample");
  const { data: PoGroupDoc } = useGetPoGroupDocumentQuery(props.SelectedOrder);
  const [groupid, setGroupid] = useState(PoGroupDoc?.data[0]?.id);
  const HandleGroup = (e, value) => {
    setGroupid(value);
  };
  const { data: PoDoc } = useGetOrderPoDocumentQuery(PoGroupDoc?.data[0]?.id);
  const items = PoDoc?.data?.details[0]?.po
    ? JSON.parse(PoDoc?.data?.details[0]?.po)
    : null;
  items?.map((items, index) => (items.table_index = index + 1));
  let total_price = 0;
  let ship = PoDoc?.data?.ship_to;
  console.log(PoGroupDoc?.data[0]?.id, "PoDoc");
  items?.map((item) => (total_price = total_price + item?.pi_total_cost));
  //const converter = require("number-to-words");
  let numbertoword = convert.toWords(total_price);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  const { data: userinfo } = useGetUserInfoQuery();
  let SO;
  let PFO;
  let PM;
  PoDoc?.data?.project?.users?.map((items) =>
    items?.role?.role_name == "Sourcing Officer" ? (SO = items?.name) : ""
  );
  PoDoc?.data?.project?.users?.map((items) =>
    items?.role?.role_name == "Project Finance Officer"
      ? (PFO = items?.name)
      : ""
  );
  PoDoc?.data?.project?.users?.map((items) =>
    items?.role?.role_name == "Project Manager" ? (PM = items?.name) : ""
  );

  let so_signature =
    "https://t4.ftcdn.net/jpg/00/00/42/95/360_F_429547_YJTlwk2Ld5kYDAbtCUwFgzmatgUHEg.jpg";

  console.log(so_signature, "Officers");

  const PoData = {
    ...PoDoc?.data?.details[0],
    items,
    ...ship,
    total_price: total_price.toLocaleString(),
    numbertoword,
    date: today,
    SO: SO,
    PFO,
    PM,
  };
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const generatePLDocument = (e) => {
    loadFile(documentPath, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);

      const imageModule = new ImageModule({
        getImage: (path, tagValue, tagName) => {
          return URL.createObjectURL("https://docxtemplater.com/puffin.png");
        },
        getSize: (img, tagValue, tagName) => {
          return [500, 500];
        },
      });
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        imageModule,
      });
      doc.setData({
        ...PoDoc?.data?.details[0],
        items,
        ...ship,
        total_price: total_price.toLocaleString(),
        numbertoword,
        date: today,
        SO: SO,
        PFO,
        PM,
        image: "https://docxtemplater.com/puffin.png",
      });

      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }
        console.log(JSON.stringify({ error: error }, replaceErrors));

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI

      saveAs(out, `${props.orderName} generatedPO.docx`);
    });
  };
  console.log(groupid, "PoDoc");
  return (
    <>
      <div style={{ margin: "10px 30px" }}>
        <span
          style={{
            color: "#353945",
            fontWeight: "500",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          PO Document
        </span>
      </div>
      {PoGroupDoc?.data?.length !== 0 ? (
        <>
          {" "}
          {PoGroupDoc?.data?.map((items) => (
            <>
              <div
                style={{
                  border: "2px #ECECEC solid",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px auto",
                  padding: "3px",
                  alignSelf: "center",
                  width: "90%",
                }}
              >
                <span
                  style={{
                    marginLeft: "4%",
                    marginTop: "5px",
                    color: "#353945",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {items?.group_name}
                </span>
                <Tooltip title="Download">
                  <a
                    href={`${API_BASE_URL}/scm/sourcing/document-preparation/generatepo/${items?.id}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              </div>
            </>
          ))}
        </>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
