import React from "react";
import NoRecord from "../../common/NoRecord";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap";
import { useState } from "react";
import { useFinalPriceComparisionMutation } from "../../../features/SCMApi";
import CreatePriceComparisonOrder from "./CreatePriceComparisonOrder";
import SuccessModal from "../../common/successModal";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import SourcingIndex from "../../../pages/Sourcing/SourcingIndex";

export default function CompariedDataModal({ setComparisonModal, data, id }) {
  const location = useLocation();
  const params = useParams();
  const [open, setOpen] = useState(false);

  console.log(location, "location");
  console.log(id, "location?.state price");
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );
    return (
      <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
        {children}
      </p>
    );
  }
  let count = 0;
  let comparedData = [];
  Object.keys(location?.state).map((key, index) =>
    location?.state[key]?.map((items, orderindex) =>
      location?.state[key].length >= 2 ? null : comparedData.push(items.id)
    )
  );
  const [selectedItems, setSelectedItems] = useState(comparedData);
  const [loading, setLoading] = useState(false);
  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(id);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const getSelectedItems = () => {
    return Object.keys(location?.state).map((key, index) =>
      location?.state[key]?.filter((item) => selectedItems.includes(item.id))
    );
  };
  let items = getSelectedItems().flat(1);
  console.log(items, "itemsitemsitems");
  const [FinalPriceComparison, res] = useFinalPriceComparisionMutation();
  const [finaldata, setFinalData] = useState();
  const [finalDataModal, setFinalDataModal] = useState(false);
  const [success, setSuccess] = useState(false);
  let form = {
    id: params?.c_id,
    pc: items,
    project_id: params?.id,
  };
  console.log(form, "dataaaaaaaaaaaaa");
  let navigate = useNavigate();
  function HandleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    FinalPriceComparison(form)
      .unwrap()
      .then((response) => {
        // setFinalDataModal(true);
        // navigate(
        //   `/sourcing/pricecomparison/${params?.id}/final/${response?.data?.id}`,
        //   {
        //     state: response?.data?.final_list_price,
        //   }
        // );
        setOpen(true);
        setFinalData(response);
        setLoading(false);
        console.log(
          response,
          "Response for created order from backend for price "
        );
      })
      .catch((error) => {
        console.log(
          error,
          "Response for created order from backend for price err"
        );
      });
  }
  console.log(success, "successvvvvvvv");
  return (
    <SourcingIndex
      name={"Compared Price"}
      title={"Price Comparison"}
      navlink={`/sourcing/pricecomparison/${params?.id}`}
      // Message={Message}
    >
      {" "}
      <div id="" className="">
        <div className="">
          <div className="create-project-modal-title mb-2">
            <div>Compared Price</div>
          </div>
          <div className="price-accordion-container">
            {Object.keys(location?.state).map((key, index) => (
              <Accordion>
                <Card className="mb-2 mt-0 stockAccordionCardAccordionCard">
                  <div
                    className={`card-header incomingTitle ${
                      location?.state[key].length >= 2 ? "bg-danger" : ""
                    }`}
                  >
                    <CustomToggle eventKey="0">
                      <span
                        className={
                          location?.state[key].length >= 2
                            ? "paymentTrackingProjectName text-white"
                            : "paymentTrackingProjectName"
                        }
                      >
                        {key}{" "}
                      </span>
                    </CustomToggle>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div>
                        {location?.state[key].length !== 0 ? (
                          <>
                            <table style={{ width: "100%" }}>
                              <thead className="stock-table-header">
                                <tr>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "3%" }}
                                  ></th>

                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "3%" }}
                                  >
                                    No
                                  </th>

                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Supplier
                                  </th>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Item
                                  </th>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "20%" }}
                                  >
                                    Item Description
                                  </th>

                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    UoM
                                  </th>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Qty
                                  </th>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Unit Price
                                  </th>
                                  <th
                                    className="stock-table-header-text text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Total Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {location?.state[key]?.map(
                                  (items, orderindex) => (
                                    <tr className="stock-table-row">
                                      <td className={`text-center ${items.id}`}>
                                        <input
                                          type="checkbox"
                                          disabled={
                                            location?.state[key].length >= 2
                                              ? false
                                              : true
                                          }
                                          defaultChecked={
                                            location?.state[key].length >= 2
                                              ? selectedItems.includes(items.id)
                                              : false
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              location?.state[key].length >= 2
                                                ? items.id
                                                : selectedItems.includes(
                                                    items.id
                                                  )
                                            )
                                          }
                                        />
                                      </td>

                                      <td className="text-center">
                                        {(count += 1)}
                                      </td>
                                      <td className="text-start">
                                        {items?.SupplierName}
                                      </td>
                                      <td className="text-start">
                                        {items?.item_part_number}
                                      </td>

                                      <td className="text-center">
                                        {items?.item_description}
                                      </td>
                                      <td className="text-center">
                                        {items?.uom}
                                      </td>
                                      <td className="text-center">
                                        {items?.qty}
                                      </td>
                                      <td className="text-center">
                                        {Number(
                                          items?.unit_cost
                                        )?.toLocaleString()}
                                      </td>
                                      <td
                                        className={`text-center  ${
                                          location?.state[key].length
                                        }

                                    ${
                                      location?.state[key].length >= 2
                                        ? "bg-warning"
                                        : ""
                                    }`}
                                      >
                                        {Number(
                                          items?.total_cost
                                        )?.toLocaleString()}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <NoRecord />
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </div>
          <div class="form-buttons ">
            <button
              type="cancel"
              class="form-cancel"
              onClick={(e) => setComparisonModal(false)}
            >
              Cancel
            </button>
            <button
              disabled={loading == true ? true : false}
              onClick={(e) => HandleSubmit(e)}
              class={
                loading ? "form-save bg-secondary text-white" : "form-save"
              }
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </div>
        {finalDataModal ? (
          <CreatePriceComparisonOrder
            id={finaldata?.data?.id}
            data={finaldata?.data?.final_list_price}
            modal={setFinalDataModal}
            setSuccess={setSuccess}
            setOpen={setOpen}
          />
        ) : (
          ""
        )}
      </div>
      <SuccessModal open={open} />
    </SourcingIndex>
  );
}
// import React from "react";
// import { NavLink, useLocation, useParams } from "react-router-dom";

// export default function CompariedDataModal() {
//   const location = useLocation();

//   console.log(location, "location");
//   return <div>CompariedDataModal</div>;
// }
