import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "../../ForexApproval/Select";
import { useUpdatePaymentOnPaymentTrackingMutation } from "../../../../features/SCMApi";
import "../../forex.style.css";
import DatePicker from "../../ForexPlanning/DatePicker";
import AdvancePaymentCheck from "../AdvancePaidCheckBox";

export default function EditPaymentTracking(props) {
  const [updatePayment] = useUpdatePaymentOnPaymentTrackingMutation();

  const [amount, setAmount] = useState(null);
  const [paymentWay, setPaymentWay] = React.useState(null);
  const [paymentDate, setPaymentDate] = React.useState(null);
  const [invoiceDate, setInvoice] = useState(null);
  const wayOfPayment = ["Direct Transfer", "PDC"];
  const [advancePaid, setAdvancePaid] = useState(false);

  const previousData = props.previousData && props.previousData;
  const previousAdvancePercentage = Number(previousData?.advance_percentage);
  const previousAdvancePaid = Number(previousData?.advance_paid);
  const previousTotalAmount = Number(previousData?.total_amount);

  const maximumAmount =
    previousAdvancePercentage == 0 && previousAdvancePaid == 0
      ? previousTotalAmount - previousAdvancePaid
      : previousAdvancePercentage != 0 &&
        advancePaid == true &&
        previousAdvancePaid == 0
      ? previousTotalAmount -
        (previousAdvancePercentage / 100) * previousTotalAmount
      : previousAdvancePercentage != 0 &&
        advancePaid == false &&
        previousAdvancePaid == 0
      ? 1
      : previousAdvancePercentage != 0 && previousAdvancePaid == 1
      ? previousTotalAmount - previousAdvancePaid
      : "";
  console.log(maximumAmount, "maximumAmount");
  const previousInvoiceDate = props?.previousData?.receipt_date;
  let params = {
    payment_mechanism: paymentWay,
    new_payment: Number(amount),
    po_id: props.previousData?.po_id,
    receipt_date:
      invoiceDate !== null
        ? `${invoiceDate?.$y}-${invoiceDate?.$M + 1}-${invoiceDate?.$D}`
        : previousInvoiceDate,
    payment_date: `${paymentDate?.$y}-${paymentDate?.$M + 1}-${
      paymentDate?.$D
    }`,
    advance_paid: advancePaid,
  };

  function HandleCancel() {
    props.modal(false);
  }

  console.log(
    props.previousData,
    maximumAmount,
    params,
    "previous data for edit payment tracking"
  );

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/payment/payment-update`,
    //     params,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response, "response from backend with data success");
    //     HandleCancel();
    //     // props.refreshe();
    //   })
    //   .then((error) => {
    //     console.log(error, "response from backend with data error");
    //   });
    maximumAmount != 1 ? (
      updatePayment(params)
        .unwrap()
        .then((response) => {
          props?.successMessage(true);
          props?.message("Payment updated successfully!!!");
          console.log(response, "response from backend with data success");
          HandleCancel();
        })
        .then((error) => {
          console.log(error, "response from backend with data error");
        })
    ) : (
      <></>
    );
  };
  console.log(maximumAmount, previousAdvancePaid, "maximumAmount");
  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="updatePayment">{props?.isadd==true?"Add Payment":"Edit Payment"}</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="paymentTrackingForm" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-informationPayment">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div className="paymentPlanningInputLabel">
                        Total Amount
                      </div>
                      <div
                        className="paymentPlanningInputLabel"
                        style={{ marginTop: "-10px" }}
                      >
                        Paid Amount
                      </div>

                      <div
                        className="paymentPlanningInputLabel"
                        style={{ marginTop: "-10px" }}
                      >
                        Remaining Amount
                      </div>

                      <div
                        className="paymentPlanningInputLabel"
                        style={{ marginTop: "-10px" }}
                      >
                        Advance Payment
                      </div>
                    </div>

                    <div className="payment-input-labels">
                      <div className="paymentPlanningInputFields">
                        $
                        {(
                          Math.round(
                            (props?.previousData?.total_amount +
                              Number.EPSILON) *
                              10000
                          ) / 10000
                        ).toLocaleString()}
                      </div>
                      <div
                        className="paymentPlanningInputFields"
                        style={{ marginTop: "-10px" }}
                      >
                        $
                        {(
                          Math.round(
                            (props?.previousData?.paid_amount +
                              Number.EPSILON) *
                              10000
                          ) / 10000
                        ).toLocaleString()}
                      </div>
                      <div
                        className="paymentPlanningInputFields"
                        style={{ marginTop: "-10px" }}
                      >
                        $
                        {(
                          Math.round(
                            (props?.previousData?.total_amount -
                              props?.previousData?.paid_amount +
                              Number.EPSILON) *
                              10000
                          ) / 10000
                        ).toLocaleString()}
                      </div>
                      <div
                        className="paymentPlanningInputFields"
                        style={{ marginTop: "-10px" }}
                      >
                        {props?.previousData?.advance_percentage === 0
                          ? "Not Required"
                          : props?.previousData?.advance_percentage !== 0
                          ? `${props?.previousData?.advance_percentage}%`
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <div className="paymentTrackingDivider" />
                  {maximumAmount == 1
                    ? "Please check your advance payment."
                    : ""}
                  {props?.previousData?.advance_percentage !== 0 ? (
                    <>
                      {props.previousData?.advance_percentage != 0 &&
                      props.previousData?.advance_paid == 0 ? (
                        <div className="orderListCheckBox">
                          <AdvancePaymentCheck
                            checkedStatus={props?.previousData?.advance_paid}
                            data={["Advance paid"]}
                            isPaid={setAdvancePaid}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <br></br>
                  )}
                  <>
                    <div className="card-center">
                      <div className="card-form">
                        <div className="paymentPaidInputs">
                          <div
                            style={{ marginTop: "-5px" }}
                            className="rmaFileInputLabel"
                          >
                            New Payment
                          </div>

                          <div
                            className="rmaFileInputLabel"
                            style={{ marginTop: "0px" }}
                          >
                            Way of Payment
                          </div>
                          <div
                            className="rmaFileInputLabel"
                            style={{ marginTop: "10px" }}
                          >
                            Payment Date
                          </div>
                          <div
                            className="rmaFileInputLabel"
                            style={{ marginTop: "10px" }}
                          >
                            Invoice Date
                          </div>
                        </div>
                      </div>
                      <div
                        className="forex-input-fields"
                        style={{ marginLeft: "10px" }}
                      >
                        <div>
                          <input
                            required={advancePaid == true ? false : true}
                            step="any"
                            type="number"
                            className="forex-select-option"
                            min="0"
                            max={maximumAmount == 1 ? "" : maximumAmount}
                            placeholder={
                              maximumAmount == 1
                                ? "Check your advance payment"
                                : "Amount"
                            }
                            onChange={(event) => setAmount(event.target.value)}
                          />
                        </div>
                        <div>
                          <Select
                            name={wayOfPayment}
                            placeHolder={"Select Way of payment..."}
                            onChange={setPaymentWay}
                            required={true}
                          />
                        </div>
                        <div style={{ marginRight: "4px" }}>
                          <DatePicker
                            onChange={setPaymentDate}
                            passedValue={""}
                          />
                        </div>
                        <div style={{ marginRight: "4px" }}>
                          <DatePicker
                            onChange={setInvoice}
                            passedValue={previousInvoiceDate}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
