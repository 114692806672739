import React, { useState } from "react";
import axios from "axios";
import { IMG_BASE_URL, LOGOUT_API } from "../api/endPoint";
import "./NavaBar.style.css";
import SupplierUtilities from "../pages/Supplier/SupplierUtilities";
import DehazeIcon from "@mui/icons-material/Dehaze";
import MobileSidebar from "./MobileSidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  useGetUserInfoQuery,
  useGetNotificationQuery,
} from "../features/SCMApi";
import NotificationCard from "../components/notification/NotificationCard";
export default function NavBar(props) {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { data: userinfo } = useGetUserInfoQuery();

  const UserName =
    userinfo?.name.split(" ") === undefined ? [] : userinfo?.name.split(" ");

  //Logout Function
  const BearerToken = localStorage.getItem("accessToken");
  const logoutUser = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  const [showNotification, setShowNotification] = useState(false);
  const [clickCounter, setClickCounter] = useState(1);
  //Notification feature
  const handleNotification = () => {
    setClickCounter(clickCounter + 1);
    if (clickCounter === 1) {
      setShowNotification(true);
      setClickCounter(clickCounter - 1);
    } else {
      setShowNotification(false);
    }
  };

  const { data: notifications } = useGetNotificationQuery();
  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);

  console.log(
    unreadNotifications,
    "this is the response for next approver unread should be here"
  );
  return (
    <>
      <MobileSidebar open={isOpen} close={ToggleSidebar} />
      <div className="card shadow-sm main-nav-bar">
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-inline ml-auto">
            <div className="mobile-side-bar" onClick={ToggleSidebar}>
              <DehazeIcon />
            </div>
          </div>
          {props.name == undefined && props.handleSearchChange !== undefined ? (
            <SupplierUtilities
              placeholder={props?.placeholder}
              handlesearch={props.handleSearchChange}
              handleChange={props?.handleChange}
            />
          ) : (
            ""
          )}

          <div className="nav-container">
            <Badge color="error" badgeContent={unreadNotifications?.length}>
              <IconButton
                aria-label="notification"
                size="small"
                onClick={handleNotification}
              >
                <NotificationsIcon
                  fontSize="medium"
                  style={{ color: "#186568" }}
                />
              </IconButton>
            </Badge>
            <div className="prg-pri">
              {UserName[0]}.{UserName[1]?.charAt(0)}
            </div>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {userinfo?.avatar !== "" ? (
                  <Avatar
                    sx={{ border: "2px solid #186569", marginLeft: "5px" }}
                    alt={`${userinfo?.name}`}
                    src={`${IMG_BASE_URL}${userinfo?.avatar}`}
                  />
                ) : (
                  <div className="default-avatar">
                    <div
                      style={{
                        display: "none",
                        border: "2px solid #186569",
                        marginLeft: "5px",
                      }}
                    ></div>
                    {UserName[0]?.charAt(0)}
                    {UserName[1]?.charAt(0)}
                  </div>
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <NavLink to={"/Account"}>
                <MenuItem>
                  <AccountCircleIcon sx={{ color: "#575757" }} />
                  <Typography sx={{ marginLeft: 2 }} textAlign="center">
                    Account
                  </Typography>
                </MenuItem>
              </NavLink>
              <NavLink to={"/Faq"}>
                <MenuItem>
                  <QuizRoundedIcon sx={{ color: "#575757" }} />
                  <Typography sx={{ marginLeft: 2 }} textAlign="center">
                    FAQ
                  </Typography>
                </MenuItem>
              </NavLink>
              <MenuItem onClick={logoutUser}>
                <LogoutRoundedIcon sx={{ color: "#575757" }} />
                <Typography sx={{ marginLeft: 2 }} textAlign="center">
                  Log Out
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>
        <NotificationCard show={showNotification} />
      </div>
    </>
  );
}
