import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import {
  useGetReceivableBanksQuery,
  useUpdateForexBankMutation,
} from "../../../../features/SCMApi";
import { useParams } from "react-router-dom";
import Selector from "../Select";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import ErrorMessage from "../../ErrorMessage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditBankModal({
  show = false,
  handleUpdateBankModal,
  successMessage,
  message,
  passedValue,
}) {
  const { data: bankList } = useGetReceivableBanksQuery();
  const forexId = useParams();
  const [updateForexBank] = useUpdateForexBankMutation(forexId?.id);
  const [bank, setBank] = React.useState(passedValue?.toString());

  const params = {
    bank_id: bankList?.data
      ?.filter((bankName) => bankName?.bank_name === bank)
      ?.map((bankName) => bankName?.id)
      ?.toString(),
    id: forexId?.id,
  };
  console.log(
    bankList?.data
      ?.filter((bankName) => bankName?.bank_name === passedValue)
      ?.map((bankName) => bankName?.id)
      .toString(),
    "previous bank"
  );
  const HandleSubmit = async (e) => {
    e.preventDefault();

    // axios
    //   .post(`${API_BASE_URL}/scm/payment/forex-bank-update/${forexId}`)
    //   .then((response) => {
    //     handleUpdateBankModal(false);
    //     successMessage(true);
    //     message("Forex Bank Updated  successfully!!!");
    //     console.log(response, "response from database");
    //   })
    //   .catch((err) => {
    //     handleUpdateBankModal(false);
    //     setErrorMessage(true);
    //     setErrorMessageText("There is something wrong!!!");
    //     console.log(errorMessage, errorMessageText, err, "response from");
    //   });
    updateForexBank(params)
      .unwrap()
      .then((response) => {
        handleUpdateBankModal(false);
        successMessage(true);
        message("Forex Bank Updated  successfully!!!");
        console.log(response, "response from database");
      })
      .catch((error) => {
        setErrorMessage(true);
        setErrorMessageText("There is something wrong!!!");
        console.log(errorMessage, errorMessageText, error, "response from");
      });
  };
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [errorMessageText, setErrorMessageText] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setErrorMessage(false);
    }, 3000);

  return (
    <BootstrapDialog
      onClose={handleUpdateBankModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleUpdateBankModal}
        sx={{ width: 400, color: "#575757" }}
      ></BootstrapDialogTitle>

      <form
        onSubmit={HandleSubmit}
        style={{ height: errorMessage === true ? 90 : 150 }}
      >
        {errorMessage === true ? (
          <>
            <ErrorMessage
              style={{
                width: "93%",
                backgroundColor: "#fd5f5fbe",
                mt: "10px",
                height: "50px",
                marginLeft: "15px",
              }}
              Message={errorMessageText}
            />
          </>
        ) : (
          <>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-15px",
                    }}
                  >
                    <Selector
                      name={
                        bankList?.data &&
                        bankList?.data?.map((bank) => bank?.bank_name)
                      }
                      passedValue={passedValue}
                      onChange={setBank}
                      required={true}
                    />
                  </div>
                </Grid>
              </Grid>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "inherent",
                  marginTop: "30px",
                }}
              >
                <Button
                  variant="contained"
                  type="reset"
                  sx={{
                    backgroundColor: "#fff",
                    color: "#575757",
                    borderRadius: "25px",
                    "&:hover": {
                      backgroundColor: "#ececec",
                      color: "#575757",
                    },
                    width: "112px",
                    textTransform: "none",
                  }}
                  onClick={handleUpdateBankModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    borderRadius: "25px",
                    width: "112px",
                    backgroundColor: "#064b4f",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#186569",
                      color: "#fff",
                    },
                    textTransform: "none",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )}
      </form>
    </BootstrapDialog>
  );
}
