import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AssignedTeams from "../../components/project/AssignedTeams";
import AssignPersonnelModal from "../../components/project/AssignPersonnelModal";
import AttachDocumentModal from "../../components/project/AttachDocumentModal";
import Budget from "../../components/project/Budget";
import ClientRepresentative from "../../components/project/ClientRepresentative";
import EditClientRepresentative from "../../components/project/EditClientRepresentative";
import InvolvedSolution from "../../components/project/InvolvedSolution";
import ProjectCalender from "../../components/project/ProjectCalender";
import Timeline from "../../components/project/Timeline";
import {
  useGetProjectQuery,
  useGetUserInfoQuery,
  useGetProjectRolePermissionQuery,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import "./project.style.css";
import Projectindex from "../../pages/Projects";
import checkProjectPermission from "../../components/common/checkPermission/CheckProjectPermission";
import BoqComponent from "../../components/project/BoqComponent";
import AddBoq from "../../components/products/AddBoq";
import ConfirmDelete from "../../components/common/ConfirmDelete";
import SuccessMessage from "../../components/Payment/SuccessMessage";
import CloseProject from "../../components/project/CloseProject";
import Revenue from "../../components/project/Revenu";
export default function Project() {
  const { projectId } = useParams();
  const [editClientRepresentativeModal, setRepresentativeModal] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectId);
  const [addBoqModalToggle, setAddBoqModal] = useState(false);
  const [updateProject, setUpdateProject] = useState(false);
  const { data: projectmembers } = useGetProjectQuery(projectId);
  const [selectedRepresentative, setRepresentative] = useState();
  const [assignPersonnelModal, setAssignModal] = useState(false);
  // const [project, setProject] = useState("");
  const { data: response, isSuccess } = useGetProjectQuery(projectId);
  const [documentAttachmentModal, setDocumentAttachmentModal] = useState(false);
  console.log(response, "response");
  const { data: userinfo } = useGetUserInfoQuery();
  const permission = userinfo?.permissions;
  console.log(userinfo, "userinfo");
  // function checkPermission(Permission) {
  //   const userId = userinfo?.id;
  //   console.log(userId, "userId");
  //   console.log(Permission, "Permission");
  //   let isAuthorized = false;
  //   let usersRole = [];
  //   let setOfUserRole = [];
  //   console.log(response?.data?.users, "ola");
  //   const isMember = [
  //     ...response?.data?.users?.filter((member) => member?.id == userId),
  //   ];

  //   // console.log(isMember, "isMember");
  //   if (isMember?.length > 0) {
  //     console.log(isMember, "isMember");
  //     isMember?.map((useRoles) => usersRole.push(useRoles?.pivot?.role_id));
  //     setOfUserRole = roleRoles?.data?.roles?.filter((role) =>
  //       usersRole?.includes(role?.id)
  //     );
  //   }
  //   let permissions = [];
  //   let bool = setOfUserRole?.filter((items) =>
  //     permissions.push(...items?.permissions)
  //   );
  //   const condition = permissions.filter(
  //     (item) => item?.slug == Permission || item?.slug == "all"
  //   );
  //   // permissions = [...permissions];
  //   condition.length ? (isAuthorized = true) : (isAuthorized = false);
  //   console.log(bool, "bool");
  //   console.log(condition, "condition");
  //   console.log(permissions, "permissions");
  //   console.log(setOfUserRole, "setOfUserRole");
  //   console.log(usersRole, "usersRole");
  //   return isAuthorized;
  // }
  function HandleAddBoqModal() {
    // setDetailedBoqModal(false);
    setAddBoqModal(true);
    console.log(addBoqModalToggle);
  }
  console.log(response?.data, "response project");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return isSuccess ? (
    <Projectindex title={response?.data?.project_name}>
      {successMessage ? (
        <SuccessMessage
          style={{
            marginTop: "10px",
            backgroundColor: "#52ffab96",
          }}
          Message={message}
        />
      ) : (
        <></>
      )}
      <CloseProject
        setMessage={setMessage}
        setSuccessMessage={setSuccessMessage}
        checkPermission={checkProjectPermission}
      />
      <div className="top-section mt-4">
        <div>
          <ClientRepresentative
            clientId={response?.data.client_id}
            client={response?.data.client}
            ModalToggle={setRepresentativeModal}
            clientRepresentative={response?.data?.client?.client_representative.filter(
              (items) => items?.isDeleted == 0
            )}
            setters={setRepresentative}
            checkPermission={checkProjectPermission}
            projectmembers={projectmembers}
            userinfo={userinfo}
            roleRoles={roleRoles}
          />
          <AssignedTeams
            project_members={response?.data?.users}
            ModalToggle={setAssignModal}
            checkPermission={checkProjectPermission}
          />
          <InvolvedSolution
            involved_departments={response?.data?.involved_departments}
            project={projectId}
            project_name={response?.data?.project_name}
            checkPermission={checkProjectPermission}
          />
        </div>

        <div className="top-left-section">
          <BoqComponent
            ToggleModal={setAddBoqModal}
            setMessage={setMessage}
            setSuccessMessage={setSuccessMessage}
            // setIsError={setIsError}
            projectmembers={projectmembers}
            userinfo={userinfo}
            roleRoles={roleRoles}
          />
          {checkProjectPermission(
            "attach_project_documents",
            projectmembers,
            userinfo,
            roleRoles
          ) ? (
            <div className="attach-document">
              {" "}
              <div
                className="attach-document-active-link"
                onClick={(e) => setDocumentAttachmentModal(true)}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.75467 8.42323L11.9988 3.17916V6.16732H13.6654V0.333984H7.83209V2.00065H10.8203L5.57617 7.24473L6.75467 8.42323Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M12.8345 12.0007V8.66732H11.1678V12.0007H2.00114V2.83398H5.33451V1.16732H2.00114C1.08067 1.16732 0.334473 1.91351 0.334473 2.83398V12.0007C0.334473 12.9212 1.08067 13.6673 2.00114 13.6673H11.1678C12.0883 13.6673 12.8345 12.9212 12.8345 12.0007Z"
                    fill="#ffffff"
                  />
                </svg>
                <div> Attach Documents</div>{" "}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex gap-1">
            <Budget
              data={response?.data}
              budget={response?.data?.budget}
              contract_sign_date={response?.data?.contract_sign_date}
              start_date={response?.data?.start_date}
              forex={response?.data?.forex_resource}
              milestones={response?.data?.milestone_amount}
              project_name={response?.data?.project_name}
              currency={response?.data?.currency}
              current_currency={response?.data?.currency_id}
              projectmembers={projectmembers}
              userinfo={userinfo}
              roleRoles={roleRoles}
              checkPermission={checkProjectPermission}
              ToggleModal={setUpdateProject}
              setMessage={setMessage}
              setSuccessMessage={setSuccessMessage}
            />
            {/* <Revenue
              data={response?.data}
              budget={response?.data?.budget}
              contract_sign_date={response?.data?.contract_sign_date}
              start_date={response?.data?.start_date}
              forex={response?.data?.forex_resource}
              milestones={response?.data?.milestone_amount}
              project_name={response?.data?.project_name}
              currency={response?.data?.currency}
              current_currency={response?.data?.currency_id}
              projectmembers={projectmembers}
              userinfo={userinfo}
              roleRoles={roleRoles}
              checkPermission={checkProjectPermission}
              ToggleModal={setUpdateProject}
              setMessage={setMessage}
              setSuccessMessage={setSuccessMessage}
            /> */}
          </div>

          {/* <Timeline
            contract_sign_date={response?.data?.contract_sign_date}
            start_date={response?.data?.start_date}
          /> */}
          {/* <Lc lc={response?.data?.project_l_c} /> */}
          <ProjectCalender />
        </div>
      </div>

      {editClientRepresentativeModal ? (
        <EditClientRepresentative
          selectedRepresentative={selectedRepresentative}
          ModalToggle={setRepresentativeModal}
          setMessage={setMessage}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <></>
      )}
      {assignPersonnelModal ? (
        <AssignPersonnelModal
          ModalToggle={setAssignModal}
          project_members={response?.data?.users}
        />
      ) : (
        <></>
      )}
      {documentAttachmentModal ? (
        <AttachDocumentModal
          ToggleModal={setDocumentAttachmentModal}
          setMessage
          setSuccessMessage
        />
      ) : null}
      {addBoqModalToggle ? (
        <AddBoq
          ToggleModal={setAddBoqModal}
          setMessage={setMessage}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <></>
      )}
    </Projectindex>
  ) : (
    <>
      <NavBar />
      <div className="page">Loading</div>
    </>
  );
}
