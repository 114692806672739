import React, { useState } from "react";
import Report from "../../pages/Reports";
import DownloadReport from "./DownloadReport";
import FinanceReport from "./FinanceReport";
import LogisticsReport from "./LogisticsReports";
import ModuleSelector from "./ModuleSelector";
import Projectselect from "./Projectselect";
import ReportFilter from "./ReportFilter";
import Session from "./Session";
import SourcingReport from "./SourcingReport";
export default function Index() {
  const [module, setModule] = useState("sourcing");
  const [download, setDownload] = useState(false);
  const [project, setProject] = useState("");
  const [country_id, setCountry] = useState("");
  const [Supplier_id, setSupplier] = useState("");
  const [profit, setProfit] = useState("");
  const [batch_id, setBatch] = useState("");
  const [stage, setStage] = useState("");
  const [project_name, setProjectName] = useState("");
  console.log(project_name, "project_name");
  console.log("stages", stage);
  console.log("module", module);
  return (
    <Report>
      <div className="d-grid gap-3">
        <div className="report-header-container ">
          <div className="d-flex gap-5">
            <Projectselect setProject={setProject} />
            {/* <Session /> */}
            <ModuleSelector module={module} setModule={setModule} />
          </div>
          <ReportFilter
            project_id={project}
            setProfit={setProfit}
            setCountry={setCountry}
            setSupplier={setSupplier}
            setBatch={setBatch}
            setStage={setStage}
            setProjectName={setProjectName}
            module={module}
          />
        </div>
        {module == "sourcing" ? (
          <SourcingReport
            project={project_name?.data}
            country_id={country_id}
            supplier_id={Supplier_id}
            project_id={project}
            profit={profit}
            modal={setDownload}
            stage={stage}
            module={module}
          />
        ) : module == "logistics" ? (
          <LogisticsReport
            project={project_name?.data}
            batch_id={batch_id}
            project_id={project}
            modal={setDownload}
            stage={stage}
            module={module}
          />
        ) : module == "finance" ? (
          <FinanceReport
            project={project_name?.data}
            project_id={project}
            modal={setDownload}
            module={module}
          />
        ) : (
          ""
        )}
      </div>
      {download ? <DownloadReport modal={setDownload} /> : ""}
    </Report>
  );
}
