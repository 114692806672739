import React, { useEffect, useState } from "react";
import "../Recievable.css";
import ExcelJS from "exceljs";
import { LuDownloadCloud } from "react-icons/lu";
import { API_BASE_URL } from "../../../../api/endPoint";
import axios from "axios";

function RecivableDownload({ productData, curentDate }) {
  // let curentDate = new Date().toLocaleDateString();
  const [summeryData, setSummeryData] = useState([]);
  const IE_NETWORK_SOLUTIONS_PL = "        IE NETWORK SOLUTIONS PL";
  const ACCOUNTS_RECEIVABLE_LEDGER = "     ACCOUNTS RECEIVABLE LEDGER";
  const AT_THE_DATE = ` AT THE DATE ${new Date().toLocaleDateString()}`;

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/summery`)
      .then((response) => {
        setSummeryData(response.data.data);
      })
      .catch((err) => {
        console.error("Error fetching summary data:", err);
      });
  }, []); // Empty dependency array to run the effect only once on mount

  const calculateDateDifference = (endDate, curentDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const curentDateTime = new Date(curentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - curentDateTime) / oneDay);
  };

  const addDataToSheet = (
    sheet,
    header,
    rowData,
    isSummarySheet = false,
    range = null,
    currency = null
  ) => {
    if (sheet.rowCount === 0) {
      sheet.addRow([""]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([IE_NETWORK_SOLUTIONS_PL]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([ACCOUNTS_RECEIVABLE_LEDGER]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([AT_THE_DATE]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
    }

    if (!isSummarySheet) {
      if (sheet.rowCount <= 6) {
        const currencyRow1 = sheet.addRow([""]);
        currencyRow1.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
        };
        const currencyRow2 = sheet.addRow([
          "NO",
          "Project Name",
          "Contract Date",
          "Milestone",
          "Amount",
          "ATP/DN Sign Date",
          "Payment Request Date",
          "Payment Expected Date",
          "Report Date",
          "ATP - RD",
          "PR - RD",
          "PE - RD",
          "Reason",
          "Status",
        ]);
        currencyRow2.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "ffffff" },
          fgColor: { argb: "4db8ff" },
        };
        const currencyRow3 = sheet.addRow([""]);
        currencyRow3.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
        };
      }
    }

    if (isSummarySheet && currency) {
      console.log("Currency:", currency);

      const currencyRow = sheet.addRow([`Currency: ${currency}`]);
      currencyRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0099ff" },
      };
    }

    if (range) {
      const rangeRow = sheet.addRow([`Range: ${range}`]);

      if (range === "current and upcoming") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00ff00" },
        };
        rangeRow.font = { color: { argb: "000000" } };
      } else if (range === "1-30") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "0000FF" },
        };
        rangeRow.font = { color: { argb: "ffffff" } };
      } else if (range === "31-60") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
      } else if (range === "61-90") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFA500" },
        };
      } else if (range === "91-120") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      } else if (range === "121-150") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      } else if (range > "150") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      }
      // else if ("Current and upcoming") {
      //   rangeRow.fill = {
      //     type: "pattern",
      //     pattern: "solid",
      //     fgColor: { argb: "00ff00" },
      //   };
      // }
    }
    let count = 0;
    rowData.map((row) => {
      count++;
      sheet.addRow(
        isSummarySheet
          ? [
              row.currency,
              row.project,
              row.currentAndUpcomingAmmount,
              row.DaysOverDue1_30,
              row.DaysOverDue31_60,
              row.DaysOverDue61_90,
              row.DaysOverDue91_120,
              row.DaysOverDue121_150,
              row.DaysOverDue151Above,
              row.Total,
            ]
          : [
              count,
              row.project,
              row.contractDate,
              row.milestone,
              row.amount,
              row.ATPSignedDate,
              row.paymentRequestDate,
              row.expectedDate,
              curentDate,
              calculateDateDifference(curentDate, row.ATPSignedDate),
              calculateDateDifference(curentDate, row.paymentRequestDate),
              row.expectedDate
                ? calculateDateDifference(curentDate, row.expectedDate)
                : "-",
              row.Reason,
              row.status,
            ]
      );
    });

    if (!isSummarySheet && !range) {
      sheet.addRow([]);
    }
  };

  const addSummaryDataToSheet = (sheet) => {
    let currentCurrency = null;
    let counter = 0;

    summeryData.forEach((row) => {
      if (row.Currency !== currentCurrency) {
        sheet.addRow([` ${row.Currency}`]).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4db8ff" },
        };
        currentCurrency = row.Currency;
      }
      counter++;
      sheet.addRow([
        "",
        counter,
        row.project,
        row.currentAndUpcomingAmmount,
        row.DaysOverDue1_30,
        row.DaysOverDue31_60,
        row.DaysOverDue61_90,
        row.DaysOverDue91_120,
        row.DaysOverDue121_150,
        row.DaysOverDue151Above,
        row.Total,
      ]);
    });
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();

    const summarySheet = workbook.addWorksheet("Summary");

    if (summarySheet.rowCount === 0) {
      summarySheet.addRow([""]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([IE_NETWORK_SOLUTIONS_PL]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([ACCOUNTS_RECEIVABLE_LEDGER]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([AT_THE_DATE]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
    }

    const byRow1 = summarySheet.addRow([""]);
    byRow1.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    const byRow2 = summarySheet.addRow([
      "",
      "NO",
      "Project",
      "Current & upcoming",
      "1-30 days overdue",
      "31-60 days overdue",
      "61-90 days overdue",
      "91-120 days overdue",
      "121-150 days overdue",
      "Above 150 days overdue",
      "Total",
    ]);
    byRow2.fill = {
      type: "pattern",
      pattern: "solid",
      color: { argb: "ffffff" },
      fgColor: { argb: "4db8ff" },
    };
    const byRow3 = summarySheet.addRow([""]);
    byRow3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    addSummaryDataToSheet(summarySheet);

    const groupedByCurrency = productData.reduce((acc, item) => {
      const currency = item.currency;
      if (!acc[currency]) {
        acc[currency] = [];
      }
      acc[currency].push(item);
      return acc;
    }, {});

    Object.entries(groupedByCurrency).forEach(([currency, currencyData]) => {
      const sheet = workbook.addWorksheet(`${currency}`);
      const filteredData = productData.filter(
        (item) => item.currency === currency
      );

      const idRanges = {
        "current and upcoming": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) < 1
        ),
        "1-30": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) >= 1 &&
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) <= 30
        ),
        "31-60": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) > 30 &&
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) <= 60
        ),
        "61-90": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) > 60 &&
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) <= 90
        ),
        "91-120": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) > 90 &&
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) <= 120
        ),
        "121-150": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) > 120 &&
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) <= 150
        ),
        ">150": filteredData.filter(
          (item) =>
            calculateDateDifference(
              curentDate,
              item.expectedDate ? item.expectedDate : item.paymentRequestDate
            ) > 150
        ),
      };

      Object.entries(idRanges).forEach(([range, rangeData]) => {
        addDataToSheet(
          sheet,
          [
            "NO",
            "Project Name",
            "Contract Date",
            "Milestone",
            "Amount",
            "ATP/DN Sign Date",
            "Payment Request Date",
            "Report Date",
            "ATP - RD",
            "PR - RD(Aging AR)",
            "Reason",
            "Status",
          ],
          rangeData,
          false,
          range
        );
      });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Aging Receivable  ${new Date().toLocaleDateString()}.xlsx`;
      link.click();
    });
  };

  return (
    <div className="exportIcon">
      <button className="buttonExport" onClick={handleDownload}>
        <LuDownloadCloud />
        Export Aging
      </button>
    </div>
  );
}

export default RecivableDownload;
