import { Pagination, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useGetBatchesQuery } from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import DownloadReport from "./DownloadReport";

export default function LogisticsReport({
  modal,
  project_id,
  batch_id,
  project,
  stage,
  module,
}) {
  function HandleModal() {
    modal(true);
  }
  console.log("project_id", project_id);

  const { data: batches } = useGetBatchesQuery(project_id);

  const filteredData = (batch_id) => {
    return batches?.data
      ?.filter((items) => {
        if (batch_id === "") {
          return items;
        } else if (items?.id == batch_id) {
          return items;
        }
      })
      ?.filter((items) => {
        if (stage === "") {
          return items;
        } else if (items?.status_id == stage) {
          return items;
        }
      })
      .map((items) => {
        return items;
      });
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(filteredData(batch_id)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(batch_id), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log("SorcingLogstic", module);

  return (
    <div className="report-sourcing-container">
      {/* <>STAGE : {stage}</> */}
      <div className="d-flex justify-content-end align-items-center">
        {project_id != null ? (
          <DownloadReport project_id={project_id} project={project} />
        ) : (
          ""
        )}
      </div>
      <div className="d-grid gap-2 mb-4">
        {" "}
        <div className="report-sourcing-table-header">
          <div className="logistics-table-headers">
            <div className="">Batch Name</div>
            <div>ETD</div>
            <div>Mode of Shipment</div>
            <div>Deliver Status</div>
            <div>Batch</div>
            <div>Port of Loading</div>
          </div>
        </div>
        {_DATA?.currentData()?.map((items) =>
          items?.items?.map((batchitems) => (
            <div className="report-sourcing-table-header">
              <div className="logistics-table-headers">
                <Tooltip title={batchitems.item_description}>
                  <div>
                    {batchitems.item_description?.length >= 80
                      ? batchitems?.item_description.slice(0, 80) + "..."
                      : batchitems?.item_description}
                  </div>
                </Tooltip>

                <div>
                  {items?.etd == null ? "Pending" : items?.pa_order?.eta}
                </div>
                <div>
                  {
                    items?.forex?.forex_term[0]?.forex_shipment_mode
                      ?.shipment_mode
                  }
                </div>
                <div>
                  {items?.batch_status?.status == null
                    ? "Pending"
                    : items?.batch_status?.status}
                </div>
                <div>{items?.batch_name}</div>
                <div>Port of Loading</div>
              </div>
            </div>
          ))
        )}
      </div>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
