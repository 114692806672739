import React, { useState } from "react";
import {
  useCreateGuaranteeBalanceMutation,
  useDispatchedToolsMutation,
  useEditGuaranteeBalanceMutation,
  useGetBanksQuery,
} from "../../../features/SCMApi";

export default function EditGuaranteeBalance(props) {
  const { data: Banks } = useGetBanksQuery();
  const [bank_id, setBank] = React.useState(props?.data?.bank_id);
  const [balance, setBalance] = useState(props?.data?.current_balance);
  const [EditGuaranteeBalance] = useEditGuaranteeBalanceMutation();
  let form = { id: props?.data?.id, bank_id, balance };
  function handleSubmit(e) {
    e.preventDefault();
    EditGuaranteeBalance(form)
      .unwrap()
      .then((response) => {
        props?.modal(false);
        console.log(response, "Response for created order from backend");
      })
      .then((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }
  console.log(props, "props guarantee");
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">
          Please select <strong>Bank</strong> to create
        </div>

        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Bank</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setBank(e.target.value)}
            value={bank_id}
          >
            <option selected="true" disabled="disabled">
              Choose Bank
            </option>
            {Banks?.data?.map((items) => (
              <option value={items?.id}>{items?.bank_name}</option>
            ))}
          </select>
        </div>
        {bank_id != null ? (
          <div className="d-flex gap-2 align-items-baseline">
            <div className="supplier-input-label">Balance</div>
            <input
              onChange={(e) => setBalance(e.target.value)}
              type="number"
              className={`stock-text-input`}
              required
              value={balance}
            />
          </div>
        ) : (
          ""
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            Cancel
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            disabled={bank_id == null ? true : false}
            className={
              bank_id == null ? "dialog-cancel-btn border-none" : "form-save"
            }
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
