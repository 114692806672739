import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
  useRemoveSolutionMutation,
} from "../../features/SCMApi";
import ConfirmDelete from "../common/ConfirmDelete";
import SuccessMessage from "../common/SuccessMessage";
import AddSolution from "./AddSolution";
import EditSolution from "./EditSolution";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import NoRecord from "../common/NoRecord";

export default function InvolvedSolution(props) {
  const { projectId } = useParams();
  const [deleteDialog, setDialog] = useState(false);
  const [removeSolution] = useRemoveSolutionMutation();
  const [abbreviation, setAbbreviation] = useState();
  const [confirmation, setConfirmation] = useState(false);
  if (deleteDialog === true)
    document.querySelector(".body-custom").classList.add("disable-scroll");
  else if (deleteDialog === false)
    document.querySelector(".body-custom").classList.remove("disable-scroll");

  const [editSolutionModal, setEditSolutionModal] = useState(false);
  const [addSolutionModal, setAddSolutionModal] = useState(false);

  const [optionModal, setModal] = useState(false);
  const [selectedComponent, setComponent] = useState("");
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(true);
  const [success, setSuccessToggle] = useState(false);
  const [message, setMessage] = useState("");
  function handleClick(e) {
    console.log(e.target.id, "event");
    if (count === 0) {
      setComponent(e.target.id);
      setModal(true);
      setCount(1);
    }
    if (count === 1) {
      if (selectedComponent === e.target.id) {
        setComponent("");
        setCount(0);
      } else {
        setComponent(e.target.id);
        setModal(true);
        setCount(1);
      }
    }
  }
  const actionRemove = () => {
    const form = {
      project_id: projectId,
      project_department_id: selectedComponent,
    };
    console.log(form, "delete project");
    removeSolution(form)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");

        // window.location.reload();
        setDialog(false);
        setSuccessToggle(true);
        setMessage("solution Removed successfully");
      })

      .then((error) => {
        console.log(error);
      });
    console.log(form, "form");
    // axios.post(`${API_BASE_URL}/scm/project/projects/remove-solutions`, form, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
  };
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectId);
  const { data: userinfo } = useGetUserInfoQuery();

  const { data: projectmembers } = useGetProjectQuery(projectId);
  console.log(props.project_name, "project name involved");
  const involved_container = {
    gridTemplateColumns: "28% 28% 28%",
    marginTop: "10px",
    marginBottom: "0px",
  };
  return (
    <>
      {deleteDialog ? (
        <ConfirmDelete
          action={actionRemove}
          setters={setConfirmation}
          setDialog={setDialog}
          dialog={`Remove ${abbreviation} from the project ?`}
        />
      ) : null}
      <div
        className="InvolvedSolution-container"
        style={{ marginRight: "0px" }}
        onClick={(e) => {
          setComponent("");
          setCount(0);
        }}
      >
        <div className="card-title">Involved Solutions</div>
        <div className="divider"> </div>
        {success ? (
          <SuccessMessage
            ToggleModal={setOpen}
            state={open}
            Message={message}
          />
        ) : null}
        {props?.involved_departments?.length > 0 ? (
          <div className="involved-team-container" style={involved_container}>
            {props?.involved_departments?.map((items) => (
              <div style={{ maxHeight: "193px" }}>
                <div className="involved-team">
                  <div className="card-top-section-involved-solution">
                    <div className="involvedTeam-name">
                      {items?.abbrivation}
                    </div>
                    {props?.checkPermission(
                      "edit_involved_solutions",
                      projectmembers,
                      userinfo,
                      roleRoles
                    ) ? (
                      <div
                        className="edit-option"
                        id={`${items?.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(e);
                        }}
                      >
                        <svg
                          id={`${items?.id}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e);
                          }}
                          width="22"
                          height="4"
                          viewBox="0 0 22 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.93325 3.6C1.48881 3.6 1.11103 3.44445 0.799919 3.13334C0.488807 2.82222 0.333252 2.44445 0.333252 2C0.333252 1.55556 0.488807 1.17778 0.799919 0.866667C1.11103 0.555556 1.48881 0.400002 1.93325 0.400002C2.3777 0.400002 2.75547 0.555556 3.06659 0.866667C3.3777 1.17778 3.53325 1.55556 3.53325 2C3.53325 2.44445 3.3777 2.82222 3.06659 3.13334C2.75547 3.44445 2.3777 3.6 1.93325 3.6ZM10.9999 3.6C10.5555 3.6 10.1777 3.44445 9.86658 3.13334C9.55547 2.82222 9.39992 2.44445 9.39992 2C9.39992 1.55556 9.55547 1.17778 9.86658 0.866667C10.1777 0.555556 10.5555 0.400002 10.9999 0.400002C11.4444 0.400002 11.8221 0.555556 12.1333 0.866667C12.4444 1.17778 12.5999 1.55556 12.5999 2C12.5999 2.44445 12.4444 2.82222 12.1333 3.13334C11.8221 3.44445 11.4444 3.6 10.9999 3.6ZM20.0666 3.6C19.6221 3.6 19.2444 3.44445 18.9333 3.13334C18.6221 2.82222 18.4666 2.44445 18.4666 2C18.4666 1.55556 18.6221 1.17778 18.9333 0.866667C19.2444 0.555556 19.6221 0.400002 20.0666 0.400002C20.511 0.400002 20.8888 0.555556 21.1999 0.866667C21.511 1.17778 21.6666 1.55556 21.6666 2C21.6666 2.44445 21.511 2.82222 21.1999 3.13334C20.8888 3.44445 20.511 3.6 20.0666 3.6Z"
                            fill="#186569"
                          />
                        </svg>
                      </div>
                    ) : (
                      <></>
                    )}
                    {console.log(items?.id, "items?.abbrivation")}
                  </div>

                  <div className="inner-items-container">
                    <div className="inner-item">
                      <div className="items-number">221</div>
                      <div className="items-label">Total Items</div>
                    </div>
                    <div className="inner-item">
                      <div className="number-items">154</div>
                      <div className="items-label">Delivered</div>
                    </div>
                  </div>

                  <NavLink
                    to={`DepartmentsDetail/${props.project}/${items?.id}`}
                    state={{ title: props?.project_name, names: "Items" }}
                    className="viewMore-btn"
                  >
                    View More
                  </NavLink>
                </div>
                {optionModal === true ? (
                  selectedComponent === items?.id ? (
                    <div className="option-modal">
                      <div
                        className="option-modal-controller"
                        onClick={(e) => {
                          setAbbreviation(items?.abbrivation);
                          setDialog(true);
                          setCount(0);
                          setModal(false);
                        }}
                      >
                        <svg
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.68333 12C1.41667 12 1.18333 11.9 0.983333 11.7C0.783333 11.5 0.683333 11.2667 0.683333 11V1.5H0.5C0.355556 1.5 0.236111 1.45278 0.141667 1.35833C0.0472223 1.26389 0 1.14444 0 1C0 0.855556 0.0472223 0.736111 0.141667 0.641667C0.236111 0.547222 0.355556 0.5 0.5 0.5H3.13333C3.13333 0.355556 3.18056 0.236111 3.275 0.141667C3.36944 0.0472223 3.48889 0 3.63333 0H7.03333C7.17778 0 7.29722 0.0472223 7.39167 0.141667C7.48611 0.236111 7.53333 0.355556 7.53333 0.5H10.1667C10.3111 0.5 10.4306 0.547222 10.525 0.641667C10.6194 0.736111 10.6667 0.855556 10.6667 1C10.6667 1.14444 10.6194 1.26389 10.525 1.35833C10.4306 1.45278 10.3111 1.5 10.1667 1.5H9.98333V11C9.98333 11.2667 9.88333 11.5 9.68333 11.7C9.48333 11.9 9.25 12 8.98333 12H1.68333ZM3.45 9.06667C3.45 9.21111 3.49722 9.33056 3.59167 9.425C3.68611 9.51945 3.80556 9.56667 3.95 9.56667C4.09444 9.56667 4.21389 9.51945 4.30833 9.425C4.40278 9.33056 4.45 9.21111 4.45 9.06667V3.41667C4.45 3.27222 4.40278 3.15278 4.30833 3.05833C4.21389 2.96389 4.09444 2.91667 3.95 2.91667C3.80556 2.91667 3.68611 2.96389 3.59167 3.05833C3.49722 3.15278 3.45 3.27222 3.45 3.41667V9.06667ZM6.21667 9.06667C6.21667 9.21111 6.26389 9.33056 6.35833 9.425C6.45278 9.51945 6.57222 9.56667 6.71667 9.56667C6.86111 9.56667 6.98056 9.51945 7.075 9.425C7.16944 9.33056 7.21667 9.21111 7.21667 9.06667V3.41667C7.21667 3.27222 7.16944 3.15278 7.075 3.05833C6.98056 2.96389 6.86111 2.91667 6.71667 2.91667C6.57222 2.91667 6.45278 2.96389 6.35833 3.05833C6.26389 3.15278 6.21667 3.27222 6.21667 3.41667V9.06667Z"
                            fill="red"
                          />
                        </svg>
                        Delete
                      </div>
                      <div className="option-divider"></div>
                      <div className="option-modal-controller">
                        <svg
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1213 2.80084L8.19916 0.878696L8.82986 0.247991C9.00005 0.0778011 9.21279 -0.00479107 9.46808 0.000214525C9.72336 0.00522012 9.9361 0.092818 10.1063 0.263008L10.752 0.908729C10.9222 1.07892 11.0073 1.28915 11.0073 1.53943C11.0073 1.78971 10.9222 1.99995 10.752 2.17014L10.1213 2.80084ZM0.450503 11C0.320358 11 0.212738 10.9575 0.127643 10.8724C0.0425476 10.7873 0 10.6796 0 10.5495V9.25805C0 9.19799 0.0100112 9.14293 0.0300335 9.09287C0.0500559 9.04281 0.085095 8.99276 0.135151 8.9427L7.56845 1.5094L9.4906 3.43155L2.0573 10.8648C2.00724 10.9149 1.95719 10.9499 1.90713 10.97C1.85707 10.99 1.80201 11 1.74195 11H0.450503Z"
                            fill="#186569"
                          />
                        </svg>
                        Edit
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        ) : (
          <NoRecord />
        )}
        {props?.checkPermission(
          "add_involved_solutions",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          <button
            className="assign-personal-btn mb-3"
            onClick={(e) => {
              window.scrollTo(1, 0);
              setAddSolutionModal(true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                fill="white"
              />
            </svg>
          </button>
        ) : (
          <></>
        )}
      </div>
      {editSolutionModal ? (
        <EditSolution ModalToggle={setEditSolutionModal} />
      ) : (
        <></>
      )}
      {addSolutionModal ? (
        <AddSolution
          ModalToggle={setAddSolutionModal}
          setMessage={setMessage}
          setSuccessToggle={setSuccessToggle}
        />
      ) : (
        <></>
      )}
    </>
  );
}
