import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAddPaymentTermMutation,
  useEditStoreSettingMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "./modal.css";

export default function EditStore(props) {
  const [updateStore, res] = useEditStoreSettingMutation();
  const [project_id, setProject] = useState(props?.data?.project_id);
  const [store_keeper_id, setStoreKeeper] = useState(
    props?.data?.store_keeper_id
  );
  const [store_name, setStoreName] = useState(props?.data?.store_name);
  const [location, setLocation] = useState(props?.data?.location);
  const [description, setDescription] = useState(props?.data?.description);
  const [is_permanent, setIs_permanent] = useState(
    props?.data?.is_permanent == 1 ? true : false
  );
  const isStorePermanent = () => {
    setIs_permanent(!is_permanent);
  };
  let editstore = {
    id: props.data?.id,
    store_keeper_id,
    project_id,
    store_name,
    description,
    location,
    is_permanent,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    updateStore(editstore)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        props.setMessages("Payment Term created successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }
  const { data: response } = useGetProjectsQuery();
  const { data: users } = useGetUsersQuery();
  console.log(is_permanent, "props?data");
  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Edit Store</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="d-grid gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Name</div>
                <input
                  value={store_name}
                  onChange={(e) => setStoreName(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Location</div>
                <input
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  type="text"
                  className="stock-text-input"
                  required
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Project</div>
                <select
                  value={project_id}
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setProject(e.target.value)}
                >
                  {" "}
                  <option selected="true">Choose Project</option>
                  {response?.data?.map((items) => (
                    <option value={items.id}>{items?.project_name}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Store Keeper</div>
                <select
                  value={store_keeper_id}
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setStoreKeeper(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose user
                  </option>
                  {users?.data?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-row-reverse gap-2 px-2">
                <input
                  checked={is_permanent}
                  type="checkbox"
                  className="stock-checkmark"
                  onChange={isStorePermanent}
                />
                <span className="h6">Permanent store</span>
              </div>
              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
