import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useBatchRequestInventoryMutation,
  useBatchRequestToolsMutation,
  useGetInventoryQuery,
  useGetItemCategorysQuery,
  useGetstoreQuery,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useTransferredAssetsMutation,
  useTransferredItemsMutation,
  useTransferredToolsMutation,
} from "../../../features/SCMApi";
import DeleteIcon from "@mui/icons-material/Delete";
import { IMG_BASE_URL } from "../../../api/endPoint";
import ErrorMessage from "../../Payment/ErrorMessage";

export default function Transferred(props) {
  console.log(props?.data, "!!!!!");
  function HandleCancel() {
    props.modal(false);
    console.log("lalalal");
  }
  const [confirmRequestItems, setConfirmRequestItems] = useState(props?.data);
  const [loading, setLoading] = useState(false);
  const { data: store } = useGetStoreQuery();
  const [store_id, setStore_id] = useState(null);
  const { data: userinfo } = useGetUserInfoQuery();
  const [formData, setFormData] = useState(props?.data);
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...formData];
    tools[i] = { ...items, requested_amount: value };
    setFormData(tools);
  }
  const removeForm = (id) => {
    const filteredArray = formData.filter((obj) => obj.id !== id);
    setFormData(filteredArray);
  };
  console.log(formData, "formDatasss");

  let form = {
    transfered_tools: JSON.stringify(formData),
    transfered_to: store_id,
  };
  const [errordisplay, setErrorDisplay] = useState(false);
  const [error, setError] = useState();
  const [transferTools, res] = useTransferredToolsMutation();
  const [transferItems] = useTransferredItemsMutation();
  const [transferAssets] = useTransferredAssetsMutation();

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    props?.item == "1"
      ? transferItems(form)
          .unwrap()
          .then((response) => {
            console.log(response, "item response");
            props.modal(false);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);
            console.log(error, "erorrrrr");
            setLoading(false);
          })
      : props?.item == "2"
        ? transferTools(form)
            .unwrap()
            .then((response) => {
              console.log(response, "tool response");
              props.modal(false);
            })
            .catch((error) => {
              setError(error?.data?.message);
              setErrorDisplay(true);
              console.log(error, "erorrrrr");
              setLoading(false);
            })
        : transferAssets(form)
            .unwrap()
            .then((response) => {
              console.log(response, "Assets response");
              props.modal(false);
            })
            .catch((error) => {
              setError(error?.data?.message);
              setErrorDisplay(true);
              console.log(error, "erorrrrr");
              setLoading(false);
            });
  };

  let count = 0;
  console.log(formData, "formData formData");

  setTimeout(() => {
    setErrorDisplay(false);
  }, 10000);

  return (
    <div id="mymodal" className="modal" onClick={() => HandleCancel()}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="d-flex justify-content-between px-2 px-2">
          <h5>Transfer Items</h5>
          <Close onClick={HandleCancel} />
        </div>

        {errordisplay ? <ErrorMessage Message={error} /> : ""}
        <div
          className="d-flex gap-2 align-items-baseline"
          style={{ justifyContent: "center", marginRight: "140px" }}
        >
          <div className="supplier-input-label">Store</div>

          <select
            required
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setStore_id(e.target.value)}
          >
            <option selected="true" disabled="disabled">
              Choose Store
            </option>
            {store?.data?.map((items) => (
              <option value={items.id}>{items?.store_name}</option>
            ))}
          </select>
        </div>
        {store_id !== null ? (
          ""
        ) : (
          <small className="prg-error text-danger text-center">
            Please select <strong>Store</strong> to proceed
          </small>
        )}

        <table style={{ width: "100%" }}>
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "3%" }}
              >
                No
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              ></th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "20%" }}
              >
                Item Name
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "20%" }}
              >
                Store
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Amount
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((items, index) => (
              <tr className="stock-table-row">
                <td className="text-center">{(count += 1)}</td>

                <td className="">
                  <img
                    className="stock-img"
                    src={`${IMG_BASE_URL}${items?.image}`}
                    alt=""
                  />
                </td>
                <td className="text-start">{items?.item_name}</td>
                <td className="text-start">{items?.store?.store_name}</td>
                <td className="text-center">
                  {formData[index]?.requested_amount > items?.amount ||
                  formData[index]?.requested_amount <= 0 ? (
                    <span className="prg-error text-danger">
                      Please enter correct value
                    </span>
                  ) : (
                    items?.amount
                  )}
                </td>
                <td className="text-center">
                  {formData?.length > 2 ? (
                    <DeleteIcon
                      onClick={() => removeForm(items?.id)}
                      style={{ color: "red", fontSize: 20 }}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pi-modal-footer d-flex justify-content-center">
          <button onClick={HandleCancel}>Cancel</button>
          <button
            disabled={formData?.length >= 1 && store_id !== null ? false : true}
            type="submit"
            className={
              formData?.length >= 1 || store_id !== null
                ? "form-save"
                : loading
                  ? "form-save bg-secondary text-white"
                  : "form-save"
            }
            class={loading ? "form-save bg-secondary text-white" : "form-save"}
            onClick={(e) => HandleSubmit(e)}
          >
            {loading ? "Loading..." : "Transfer"}
          </button>
        </div>
      </div>
    </div>
  );
}
