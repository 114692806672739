import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  useGetAllPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetVarianceAnalysisAllQuery,
  useGetVarianceAnalysisQuery,
} from "./../../../../features/SCMApi";

const VarianceAnalysisReportDownload = ({ data, start_date, end_date }) => {
  const { data: varianceanalysis } = useGetVarianceAnalysisAllQuery();
  const { data: paymentCollection } = useGetAllPaymentCollectionsQuery();

  const { data: project } = useGetProjectQuery(
    "0179ef4b-3aa7-45ee-858a-1370bb839c48"
  );

  const result = data?.reduce((acc, curr) => {
    {
      console.log(
        curr?.collection_project,
        "curr.variance_project[0].project_id"
      );
    }
    const group = acc?.find((g) => g.project_id === curr.project_id);

    if (group) {
      group.events.push(curr);
    } else {
      acc.push({
        project_id: curr.project_id,
        actual_cost: curr.actual_cost,
        planned_cost: curr.actual_cost,
        collection_project: curr.payment_collection,
        events: [curr],
      });
    }
    return acc;
  }, []);
  let PaymentCollection = [];
  result?.map((vitems) => PaymentCollection.push(vitems?.collection_project));
  let PlannedAmount = PaymentCollection?.map((items, index) =>
    items?.map((pcitems) => pcitems?.planned_amount)
  );
  let ActualAmount = PaymentCollection?.map((items, index) =>
    items?.map((pcitems) => Number(pcitems?.actual_amount))
  );
  let PA = [];
  let PATotal = 0;
  let AA = [];
  let AATotal = 0;

  for (let i = 0; i < PlannedAmount.length; i++) {
    let sum = 0;
    for (let j = 0; j < PlannedAmount[i].length; j++) {
      sum += parseInt(PlannedAmount[i][j]);
    }
    PA.push(sum);
  }
  for (let i = 0; i < ActualAmount.length; i++) {
    let sum = 0;
    for (let j = 0; j < ActualAmount[i].length; j++) {
      sum += parseInt(ActualAmount[i][j]);
    }
    AA.push(sum);
  }
  PA?.map((items) => (PATotal += items));
  AA?.map((items) => (AATotal += items));
  console.log(PATotal, "PlannedAmount");

  function startDate(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  function endDate(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = "Time Phase Budget Analysis";
  let title3 =
    start_date == "" ? "" : `${startDate(start_date)} - ${endDate(end_date)}`;

  let count = 0;
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Variance Analysis");
    const headersCost = result?.map((items) => `${items?.p} Planned Cost `);
    const headersRevenue = result?.map(
      (items) => `${items?.start_date} Planned Revenue `
    );

    const project_names = result?.map(
      (items, index) =>
        result[index]?.events[0]?.variance_project[0].project_name
    );
    const headersActualCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.actual_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );
    const headersPlannedCost = result?.map((items) =>
      items?.events
        .map((e, index) => Number(e?.planned_cost))
        .reduce((a, b) => {
          return a + b;
        })
    );

    let PlannedTotalCost = 0;
    headersPlannedCost.map((item) => (PlannedTotalCost += item));

    let ActualTotalCost = 0;
    headersActualCost.map((item) => (ActualTotalCost += item));
    const plannedRevenue = varianceanalysis?.data?.map((items) =>
      items?.payment_collection?.map((pcitems) =>
        items?.project_id == pcitems?.project_id ? pcitems?.planned_amount : 0
      )
    );

    console.log(plannedRevenue, "headersPlannedCost");
    // Add title cell above the table headers
    worksheet.mergeCells("A1:J1");
    worksheet.mergeCells("A2:J2");
    worksheet.mergeCells("A3:J3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");
    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    titleCell2.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    titleCell.font = {
      name: "Arial",
      bold: true,
      size: 14,
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Project Lists",
      "Planned Cost",
      "Planned Revenue",
      "Actual Revenue",
      "Actual Cost",
      "Revenue Variance",
      "Status",
      "Cost Variance",
      "Status",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Calibri",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 25;
    // Set the header row to bold
    worksheet.getRow(4);
    // Populate the table with data from the map
    result?.map((items, index) => {
      worksheet?.addRow([
        (count += 1),
        project_names[index],
        headersPlannedCost[index]?.toLocaleString(),
        Number(PA[index])?.toLocaleString(),
        Number(AA[index])?.toLocaleString(),
        headersActualCost[index]?.toLocaleString(),
        Number(PA[index] - AA[index])?.toLocaleString(),
        PA[index] - AA[index] == 0 ? "Favorable" : "Unfavorable",
        Number(
          headersPlannedCost[index] - headersActualCost[index]
        )?.toLocaleString(),
        headersPlannedCost[index] - headersActualCost[index] >= 0
          ? "Favorable"
          : "Unfavorable",
      ]);
    });
    const FooterRow = worksheet.addRow([
      " ",
      "Total",
      PlannedTotalCost?.toLocaleString(),
      PATotal?.toLocaleString(),
      AATotal?.toLocaleString(),
      ActualTotalCost?.toLocaleString(),
      (PATotal - AATotal)?.toLocaleString(),
      " ",
      (PlannedTotalCost - ActualTotalCost)?.toLocaleString(),
      " ",
    ]);
    FooterRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Calibri",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
    });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "All project variance.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default VarianceAnalysisReportDownload;
