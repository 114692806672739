import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  useGetAllPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetpaymentCollectionAllQuery,
  useGetpaymentCollectionQuery,
} from "./../../../../features/SCMApi";
import { Tooltip } from "@mui/material";

const DelinquentReport = ({ data }) => {
  let count = 0;
  let countCleared = 0;
  let countNotCleared = 0;
  let clearedData = [];
  let notClearedData = [];
  data?.map((items) => {
    if (items?.NBE_cleared == 1) clearedData.push(items);
  });
  data?.map((items) => {
    if (items?.NBE_cleared == 0) notClearedData?.push(items);
  });
  console.log(clearedData, notClearedData, "notClearedDatanotClearedData");
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("All");
    const titleCell = worksheet.getCell("A1");
    const cleared = workbook.addWorksheet("Cleared");
    const titleCellCleared = cleared.getCell("A1");
    const notCleared = workbook.addWorksheet("Not Cleared");
    const titleCellNotCleared = notCleared.getCell("A1");
    // for all delinquent title
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCell.font = { size: 12, name: "Times New Roman", bold: true };
    titleCell.value = `IE NETWORKS Delinquent Tracking`;
    titleCell.alignment = { horizontal: "center" };
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    worksheet.mergeCells("A1:G1");
    // for cleared delinquent
    titleCellCleared.alignment = { vertical: "middle", horizontal: "center" };
    titleCellCleared.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCellCleared.font = { size: 12, name: "Times New Roman", bold: true };
    titleCellCleared.value = `IE NETWORKS Cleared Delinquent Tracking`;
    titleCellCleared.alignment = { horizontal: "center" };
    titleCellCleared.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    cleared.mergeCells("A1:F1");
    //end of title for cleared
    //  for not cleared delinquent
    titleCellNotCleared.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleCellNotCleared.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCellNotCleared.font = {
      size: 12,
      name: "Times New Roman",
      bold: true,
    };
    titleCellNotCleared.value = `IE NETWORKS Not Cleared Delinquent Tracking`;
    titleCellNotCleared.alignment = { horizontal: "center" };
    titleCellNotCleared.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };
    notCleared.mergeCells("A1:F1");

    //end on not clear

    const itemsTotal = [];
    data?.map((itemsdata, index) => itemsTotal.push(data[index].items));

    let total = [];
    data?.map((itemsdata, index) =>
      total.push(
        itemsTotal[index]
          ?.map((row) =>
            row?.total_cost == undefined ? row?.pi_total_cost : row?.total_cost
          )
          .reduce((acc, val) => acc + val, 0)
      )
    );
    // cleared
    const itemsClearedTotal = [];
    clearedData?.map((itemsdata, index) =>
      itemsClearedTotal.push(clearedData[index].items)
    );
    let totalCleared = [];
    clearedData?.map((itemsdata, index) =>
      totalCleared.push(
        itemsClearedTotal[index]
          ?.map((row) =>
            row?.total_cost == undefined ? row?.pi_total_cost : row?.total_cost
          )
          .reduce((acc, val) => acc + val, 0)
      )
    );
    //not cleared
    const itemsNotClearedTotal = [];
    notClearedData?.map((itemsdata, index) =>
      itemsNotClearedTotal.push(notClearedData[index].items)
    );
    let totalNotCleared = [];
    notClearedData?.map((itemsdata, index) =>
      totalNotCleared.push(
        itemsNotClearedTotal[index]
          ?.map((row) =>
            row?.total_cost == undefined ? row?.pi_total_cost : row?.total_cost
          )
          .reduce((acc, val) => acc + val, 0)
      )
    );
    function sumArray(numbers) {
      let sum = 0;
      for (let i = 0; i < numbers.length; i++) {
        sum += numbers[i];
      }
      return sum;
    }

    console.log(itemsNotClearedTotal, "itemsClearedTotal");

    // Create the table headers for all
    const headerRow = worksheet.addRow([
      "No",
      "Project",
      "item",
      "Supplier",
      "Status",
      "Type of Payment",
      "Amount",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });
    //end of all header row
    //cleared row
    const headerRowCleared = cleared.addRow([
      "No",
      "Project",
      "item",
      "Supplier",
      "Type of Payment",
      "Amount",
    ]);
    headerRowCleared.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });
    //end of cleared
    // for not clear
    const headerRowNotCleared = notCleared.addRow([
      "No",
      "Project",
      "item",
      "Supplier",
      "Type of Payment",
      "Amount",
    ]);
    headerRowNotCleared.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });
    //end of not clear
    // Set column widths
    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 40;
    cleared.getColumn(1).width = 5;
    cleared.getColumn(2).width = 40;
    cleared.getColumn(3).width = 20;
    cleared.getColumn(4).width = 20;
    cleared.getColumn(5).width = 20;
    cleared.getColumn(6).width = 40;
    notCleared.getColumn(1).width = 5;
    notCleared.getColumn(2).width = 40;
    notCleared.getColumn(3).width = 20;
    notCleared.getColumn(4).width = 20;
    notCleared.getColumn(5).width = 20;
    notCleared.getColumn(6).width = 40;

    // Set the header row to bold
    worksheet.getRow(1).font = { bold: true };
    cleared.getRow(1).font = { bold: true };
    notCleared.getRow(1).font = { bold: true };

    // Populate the table with data from the map

    data?.map((items, index) => {
      const DataRow = worksheet?.addRow([
        (count += 1),
        items?.forex_project?.project_name,
        "items",
        items?.forex_term[0]?.forex_opening_to?.distributor_name == undefined
          ? "-"
          : items?.forex_term[0]?.forex_opening_to?.distributor_name,
        items?.NBE_cleared == 1 ? "Cleared" : "Not Cleared",
        items?.forex_term[0]?.forex_payment_mode?.payment_mode,
        Number(
          itemsTotal[index]
            ?.map((row) =>
              row?.total_cost == undefined
                ? row?.pi_total_cost
                : row?.total_cost
            )
            .reduce((acc, val) => acc + val, 0)
        )?.toLocaleString(),
      ]);
      DataRow.eachCell((cell) => {
        if (cell.address.includes("H")) {
          if (items?.is_collected_by_date == 1) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00B050" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF" },
            };
          }
        }
      });
      DataRow.eachCell((cell) => {
        if (cell.address.includes("E")) {
          if (items?.NBE_cleared == 0) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF0000" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00B050" },
            };
          }
        }
      });
      DataRow.eachCell((cell) => {
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
        };
      });
    });
    // data clear
    clearedData?.map((items, index) => {
      const DataRow = cleared?.addRow([
        (countCleared += 1),
        items?.forex_project?.project_name,
        "items",
        items?.forex_term[0]?.forex_opening_to?.distributor_name == undefined
          ? "-"
          : items?.forex_term[0]?.forex_opening_to?.distributor_name,
        items?.forex_term[0]?.forex_payment_mode?.payment_mode,
        Number(
          itemsClearedTotal[index]
            ?.map((row) =>
              row?.total_cost == undefined
                ? row?.pi_total_cost
                : row?.total_cost
            )
            .reduce((acc, val) => acc + val, 0)
        )?.toLocaleString(),
      ]);
      DataRow.eachCell((cell) => {
        if (cell.address.includes("H")) {
          if (items?.is_collected_by_date == 1) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00B050" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF" },
            };
          }
        }
      });
      DataRow.eachCell((cell) => {
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
        };
      });
    });
    // end data clear
    // data not clear
    notClearedData?.map((items, index) => {
      const DataRow = notCleared?.addRow([
        (countNotCleared += 1),
        items?.forex_project?.project_name,
        "items",
        items?.forex_term[0]?.forex_opening_to?.distributor_name == undefined
          ? "-"
          : items?.forex_term[0]?.forex_opening_to?.distributor_name,
        items?.forex_term[0]?.forex_payment_mode?.payment_mode,
        Number(
          itemsNotClearedTotal[index]
            ?.map((row) =>
              row?.total_cost == undefined
                ? row?.pi_total_cost
                : row?.total_cost
            )
            .reduce((acc, val) => acc + val, 0)
        )?.toLocaleString(),
      ]);
      DataRow.eachCell((cell) => {
        if (cell.address.includes("H")) {
          if (items?.is_collected_by_date == 1) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00B050" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF" },
            };
          }
        }
      });
      DataRow.eachCell((cell) => {
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
        };
      });
    });

    const FooterRow = worksheet.addRow([
      " ",
      "Total",
      "",
      "",
      "",
      " ",
      Number(sumArray(total)).toLocaleString(),
    ]);
    const FooterRowCleared = cleared.addRow([
      " ",
      "Total",
      "",
      "",
      " ",
      Number(sumArray(totalCleared)).toLocaleString(),
    ]);
    const FooterRowNotCleared = notCleared.addRow([
      " ",
      "Total",
      "",
      "",
      " ",
      Number(sumArray(totalNotCleared)).toLocaleString(),
    ]);
    FooterRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });
    FooterRowCleared.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });
    FooterRowNotCleared.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Delinquent Tracking Report.xlsx");
  };

  return (
    <Tooltip title="Delinquent Report">
      <button onClick={downloadExcel} className="add-client-representative">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
            fill="white"
          />
        </svg>
      </button>
    </Tooltip>
  );
};

export default DelinquentReport;
