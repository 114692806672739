import React, { useEffect } from "react";
import {
  useAddSupplierProductMutation,
  useDeletesupplierBankMutation,
  useDeleteSupplierProductMutation,
  useDeletesupplierRepersentativeMutation,
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetSupplierManufacterQuery,
  useGetSupplierQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Supplier from "../../pages/Supplier";
import RepresentativeModal from "./SupplierModal/RepresentativeModal";
import BankinformationModal from "./SupplierModal/BankInformationModal";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import Select from "react-select";
import SupplierRepresentativeEditModal from "./SupplierModal/SupplierRepresentativeEditModal";
import SupplierBankDetailEditModal from "./SupplierModal/SupplierBankDetailEditModal";
import SupplierEditAddress from "./SupplierEditAddress";
import DeleteModal from "./SupplierModal/DeleteModal";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import usePagination from "../Pagination/Pagination";
import Pagination from "@mui/material/Pagination";
import { IconButton, Tooltip } from "@mui/material";
import noitems from "../../assets/images/onitem.png";
import NoRecord from "../common/NoRecord";
import CheckPermission from "../common/checkPermission/checkPermission";
import CreatePermission from "../Settings/SettingModal/CreatePermission";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDelete from "../common/ConfirmDelete";

export default function SupplierDetail(props) {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const params_id = useParams();
  // bank detail
  const [bank_name, setBank_name] = useState();
  const [account_number, setAccount] = useState();
  const [swift_code, setSwift] = useState();
  const [iban, setIban] = useState();
  const [bank_country, setBankcountry] = useState();
  const [bank_currency, setBankcurrency] = useState();
  const [supplier_id, setSupplierid] = useState(params_id.id);
  const [showaddress, setShowAddress] = useState(false);

  const [createRepresentatives, setRepresentative] = useState(false);
  function createRepresentativeHandler() {
    setRepresentative(true);
  }
  const [createBankinformation, setBankinformation] = useState(false);
  function createBankinformationHandler() {
    setBankinformation(true);
  }
  const [EditSupplierRepersentativeEditModal, setEditsupplierRepresetative] =
    useState(false);
  const [EditaddressModal, setEditaddressModal] = useState(false);
  function EditAddressHandler() {
    setEditaddressModal(true);
  }
  const [product_id, setManufacturerPostData] = useState(null);
  const dataPush = [];
  if (product_id !== null) {
    for (let id in product_id) {
      dataPush.push(product_id[id]?.value);
    }
  }
  console.log(dataPush, "output must be work");
  const { data: manufacturers } = useGetSupplierManufacterQuery(params.id);
  const manufaturerList = [];
  manufacturers?.data?.map((items) =>
    manufaturerList.push({
      value: items?.id,
      label: items?.manufacturer_name,
    })
  );

  const { data: supplierdetail } = useGetSupplierQuery(params.id);
  console.log(manufacturers, "manufacturersSSSS");
  const [createSupplierProduct, response] = useAddSupplierProductMutation();
  const [DeleteSupplierProduct, deleteresponse] =
    useDeleteSupplierProductMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    createSupplierProduct(supplierProduct)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK supplier");
        setMessages("Supplier product created successfully");

        setShow(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };

  let supplierProduct = {
    supplier_id,
    product_id: dataPush,
  };

  function createSupplierEditManufacturerHandler(items) {
    setCreatesupplierManufacturerEdit(true);
    setCreatesupplierManufacturerEdit(items);
  }
  const [
    createSupplierManufatcurerEditModal,
    setCreatesupplierManufacturerEdit,
  ] = useState(false);
  function editBankDetailHandler(items) {
    setEditsupplierRepresetative(true);
    setEditsupplierRepresetative(items);
  }
  const [showClientDeleteModal, toggelClientDeleteModal] = useState(false);
  const toggleClientDeleteHandler = (e) => {
    toggelClientDeleteModal(!showClientDeleteModal);
  };
  const [supplierdeleted_id, setSupplierDeleteid] = useState(null);

  const HandleDelete = (product) => {
    let deletesupplierProduct = {
      supplier: params.id,
      manufacturer: product,
    };
    console.log(deletesupplierProduct, "deletesupplierProduct");
    DeleteSupplierProduct(deletesupplierProduct)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK supplier");
        setMessages("Supplier product Deleted successfully");
        setShowdelete(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };

  // const [infoMessage, setInfoMessage] = useState(null);
  // const updateInfoMessageHandler = (infoMessageToUpdate) =>
  //   setInfoMessage(infoMessageToUpdate);

  // console.log(infoMessage, "we will see you");
  const [Messages, setMessages] = useState(null);
  let [page, setPage] = useState(1);
  let [pagebank, setPagebank] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(
    supplierdetail?.data?.supplier_representatives?.length / PER_PAGE
  );
  const countbank = Math.ceil(
    supplierdetail?.data?.supplier_bank?.length / PER_PAGE
  );
  const _DATA = usePagination(
    supplierdetail?.data?.supplier_representatives,
    PER_PAGE
  );
  const _DATABank = usePagination(
    supplierdetail?.data?.supplier_bank,
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleChangeBank = (e, p) => {
    setPagebank(p);
    _DATABank.jump(p);
  };
  console.log(supplierdetail?.data?.supplier_bank, "supplier detail message");
  const { data: userinfo } = useGetUserInfoQuery();
  const [id, setID] = useState();
  const [representative, setRepresentatives] = useState();
  const [deleteDialog, setDialog] = useState(false);
  const [deleteBankDialog, setBankDialog] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);
  const DeleteHandler = () => {
    setDialog(true);
  };
  const DeleteBankHandler = () => {
    setBankDialog(true);
  };
  const [deleteRepresentative, res] = useDeletesupplierRepersentativeMutation();
  const [deleteBank, ress] = useDeletesupplierBankMutation();
  console?.log(id, "hahah id");
  let deletedId = {
    id: id,
  };
  const actionRemove = () => {
    deleteRepresentative(deletedId)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        setDialog(false);
        setMessages("Representative deleted successfully");
        // window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
  };
  const actionRemoveBank = () => {
    deleteBank(deletedId)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK");
        setBankDialog(false);
        setMessages("Bank information deleted successfully");
        window.location.reload();
      })
      .then((error) => {
        console.log(error);
      });
  };

  const [is_sourcing, setSourcing] = useState(false);

  const checkSourcing = () => {
    setSourcing(!is_sourcing);
  };

  return (
    <Supplier
      name={supplierdetail?.data?.distributor_name}
      title={"Supplier"}
      navlink={"/supplier/suppliers"}
      Message={Messages}
    >
      <div className="page">
        <div className="detail-address-container">
          <div className="">
            <div className="card supplier-product-container bg-white shadow-sm mb-3">
              <div className="h5 mx-2 my-2">Manufacturers</div>
              <div className="card-body">
                {supplierdetail?.data?.product.length === 0 ? (
                  <NoRecord />
                ) : (
                  <div className="border rounded">
                    <div className="row">
                      {supplierdetail?.data?.product.map((items) => (
                        <div className="supplier-product-rounded my-2 mx-3 mb-3 col-3">
                          <div className="d-flex align-items-baseline">
                            {showdelete && (
                              <div
                                className="supplier-controller"
                                onClick={(e) => HandleDelete(items.id)}
                              >
                                <svg
                                  width="8"
                                  height="9"
                                  viewBox="0 0 8 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.2625 9C1.0625 9 0.8875 8.925 0.7375 8.775C0.5875 8.625 0.5125 8.45 0.5125 8.25V1.125H0.375C0.266667 1.125 0.177083 1.08958 0.10625 1.01875C0.0354167 0.947917 0 0.858333 0 0.75C0 0.641667 0.0354167 0.552083 0.10625 0.48125C0.177083 0.410417 0.266667 0.375 0.375 0.375H2.35C2.35 0.266667 2.38542 0.177083 2.45625 0.10625C2.52708 0.0354167 2.61667 0 2.725 0H5.275C5.38333 0 5.47292 0.0354167 5.54375 0.10625C5.61458 0.177083 5.65 0.266667 5.65 0.375H7.625C7.73333 0.375 7.82292 0.410417 7.89375 0.48125C7.96458 0.552083 8 0.641667 8 0.75C8 0.858333 7.96458 0.947917 7.89375 1.01875C7.82292 1.08958 7.73333 1.125 7.625 1.125H7.4875V8.25C7.4875 8.45 7.4125 8.625 7.2625 8.775C7.1125 8.925 6.9375 9 6.7375 9H1.2625ZM2.5875 6.8C2.5875 6.90833 2.62292 6.99792 2.69375 7.06875C2.76458 7.13958 2.85417 7.175 2.9625 7.175C3.07083 7.175 3.16042 7.13958 3.23125 7.06875C3.30208 6.99792 3.3375 6.90833 3.3375 6.8V2.5625C3.3375 2.45417 3.30208 2.36458 3.23125 2.29375C3.16042 2.22292 3.07083 2.1875 2.9625 2.1875C2.85417 2.1875 2.76458 2.22292 2.69375 2.29375C2.62292 2.36458 2.5875 2.45417 2.5875 2.5625V6.8ZM4.6625 6.8C4.6625 6.90833 4.69792 6.99792 4.76875 7.06875C4.83958 7.13958 4.92917 7.175 5.0375 7.175C5.14583 7.175 5.23542 7.13958 5.30625 7.06875C5.37708 6.99792 5.4125 6.90833 5.4125 6.8V2.5625C5.4125 2.45417 5.37708 2.36458 5.30625 2.29375C5.23542 2.22292 5.14583 2.1875 5.0375 2.1875C4.92917 2.1875 4.83958 2.22292 4.76875 2.29375C4.69792 2.36458 4.6625 2.45417 4.6625 2.5625V6.8Z"
                                    fill="#FF5252"
                                  />
                                </svg>
                              </div>
                            )}

                            <div className="supplier-product-rounded-text mx-2">
                              {items.manufacturer_name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-end gap-2">
                  {!show && (
                    <>
                      {!showdelete && (
                        <>
                          {CheckPermission("add_product", userinfo) ? (
                            <IconButton
                              variant="text"
                              size="medium"
                              className="float-end"
                              sx={{
                                backgroundColor: "#186569",
                                color: "#fff",
                                border: "1px solid #186569",

                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#186569",
                                  border: "1px solid #186569",
                                },
                                marginTop: "3%",
                              }}
                              onClick={() => setShow((prev) => !prev)}
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {CheckPermission("remove_product", userinfo) ? (
                            <IconButton
                              variant="text"
                              size="medium"
                              className="float-end"
                              sx={{
                                backgroundColor: "#186569",
                                color: "#fff",
                                border: "1px solid #186569",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#186569",
                                  border: "1px solid #186569",
                                },
                                marginTop: "3%",
                              }}
                              onClick={() => setShowdelete((prev) => !prev)}
                            >
                              <ModeEditOutlinedIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {showdelete && (
                        <>
                          <IconButton
                            variant="text"
                            size="medium"
                            className="float-end"
                            sx={{
                              backgroundColor: "#186569",
                              color: "#fff",
                              border: "1px solid #186569",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#186569",
                                border: "1px solid #186569",
                              },
                              marginTop: "3%",
                            }}
                            onClick={() => setShowdelete((prev) => !prev)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      )}
                    </>
                  )}
                </div>
                {show && (
                  <div className="d-flex align-items-baseline justify-content-center gap-3">
                    <div style={{ width: "199px" }}>
                      <Select
                        required
                        defaultValue={product_id}
                        options={manufaturerList}
                        isSearchable={true}
                        isMulti
                        onChange={(e) => {
                          setManufacturerPostData(e);
                        }}
                      />
                    </div>

                    <IconButton
                      variant="text"
                      size="medium"
                      sx={{
                        backgroundColor: "#186569",
                        color: "#fff",
                        border: "1px solid #186569",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#186569",
                          border: "1px solid #186569",
                        },
                        marginTop: "3%",
                      }}
                      onClick={HandleSubmit}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      variant="text"
                      size="medium"
                      sx={{
                        backgroundColor: "#186569",
                        color: "#fff",
                        border: "1px solid #186569",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#186569",
                          border: "1px solid #186569",
                        },
                        marginTop: "3%",
                      }}
                      onClick={() => setShow((prev) => !prev)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="supplier-card-address mb-3">
              <div className="text-center h5-pri mt-1 mb-3">Information</div>

              {!showaddress && (
                <>
                  <Tooltip title={supplierdetail?.data?.address}>
                    <div className="text-center h5 mt-1 mb-2 prg">
                      {supplierdetail?.data?.address?.length >= 20
                        ? supplierdetail?.data?.address?.slice(0, 10) + "..."
                        : supplierdetail?.data?.address}
                    </div>
                  </Tooltip>

                  <div className="text-center plg mt-1 mb-2">
                    P.O BOX {supplierdetail?.data?.postal_code}
                  </div>
                  <Tooltip title={supplierdetail?.data?.website}>
                    <div className="text-center plg mt-1 mb-2">
                      {supplierdetail?.data?.website?.length >= 20
                        ? supplierdetail?.data?.website?.slice(0, 30) + "..."
                        : supplierdetail?.data?.website}
                    </div>
                  </Tooltip>

                  <div className="text-center plg mt-1 mb-2">
                    {supplierdetail?.data?.origin}
                  </div>

                  <div className="d-flex justify-content-end p-2 mt-4">
                    {CheckPermission("edit_supplier_address", userinfo) ? (
                      <IconButton
                        variant="text"
                        size="medium"
                        className="float-end"
                        sx={{
                          backgroundColor: "#186569",
                          color: "#fff",
                          border: "1px solid #186569",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#186569",
                            border: "1px solid #186569",
                          },
                          marginTop: "3%",
                        }}
                        onClick={EditAddressHandler}
                      >
                        <ModeEditOutlinedIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="card supplier-card-representative bg-white shadow-sm mb-3">
            <div className="d-flex justify-content-between ">
              <div className="h5 mx-4 my-3">Supplier Representatives</div>
            </div>
            <div className="card-body">
              <div className="d-grid gap-3 ">
                {_DATA?.currentData()?.map((items) => (
                  <div className="supplier-representative">
                    <div className="d-flex justify-content-between supplier-representative-sm p-3">
                      <div className="prg supplier-representative-container">
                        <div className="representative-header">Full Name</div>
                        {items.full_name}
                      </div>
                      <div className="prg supplier-representative-container">
                        {" "}
                        <div className="representative-header">Email</div>
                        {items.email}
                      </div>
                      <div className="prg  supplier-representative-container">
                        <div className="representative-header">
                          Phone number
                        </div>
                        {items.s_r_phone_number.map((item) => (
                          <>
                            {item.phone_number}
                            <br />
                          </>
                        ))}
                      </div>
                      <div className="supplier-representative-container">
                        {CheckPermission("edit_supplier_address", userinfo) ? (
                          <>
                            <div className="prg representative-header">
                              Action
                            </div>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },
                                marginLeft: "-30px",
                                marginBottom: "3px",
                              }}
                              onClick={() =>
                                createSupplierEditManufacturerHandler(items)
                              }
                            >
                              <ModeEditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#ff5252",

                                "&:hover": {
                                  backgroundColor: "#ff5252",
                                  color: "#fff",
                                },
                                marginBottom: "3px",
                              }}
                              onClick={(e) => {
                                DeleteHandler();
                                setRepresentatives(items.full_name);
                                setID(items?.id);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                {count > 1 ? (
                  <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                  />
                ) : (
                  ""
                )}
                {CheckPermission("add_supplier_representative", userinfo) ? (
                  <IconButton
                    variant="text"
                    size="medium"
                    className=""
                    sx={{
                      backgroundColor: "#186569",
                      color: "#fff",
                      border: "1px solid #186569",

                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#186569",
                        border: "1px solid #186569",
                      },
                    }}
                    onClick={createRepresentativeHandler}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="card supplier-card-representative bg-white shadow-sm mb-3">
            <div className="h5-pri text-center my-2 ">Bank Detail</div>
            <div className="divider"></div>
            <div className="supplier-bank-header text-center p-3">
              <div className="prg fw-bold">Currency</div>
              <div className="prg fw-bold">Account Name</div>
              <div className="prg fw-bold">Account Number</div>
              <div className="prg fw-bold">Bank</div>
              <div className="prg fw-bold ">Swift Code</div>
              <div className="prg fw-bold">IBAN</div>
              <div className="prg fw-bold"></div>
            </div>
            <div className="card-body">
              <div className="d-grid gap-3 ">
                {_DATABank.currentData()?.map((items) =>
                  items?.isDeleted == 0 ? (
                    <div className="supplier-representative">
                      <div className="supplier-bank-body text-center p-3">
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">Currency</div>
                          {items.bank_currency}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">
                            Account Name
                          </div>
                          {items?.account_name === null
                            ? "Unknown"
                            : items?.account_name}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">
                            Account Number
                          </div>
                          {items.account_number}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">Bank Name</div>
                          {items.bank_name}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">
                            Swift code
                          </div>
                          {items.swift_code}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">IBAN</div>
                          {items.iban}
                        </div>
                        <div className="prg d-flex supplier-representative-container">
                          {CheckPermission(
                            "edit_supplier_bank_detail",
                            userinfo
                          ) ? (
                            <>
                              {" "}
                              <div className="representative-header">
                                Action
                              </div>
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },

                                  marginBottom: "3px",
                                }}
                                onClick={() => editBankDetailHandler(items)}
                              >
                                <ModeEditOutlinedIcon />
                              </IconButton>
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#ff5252",

                                  "&:hover": {
                                    backgroundColor: "#ff5252",
                                    color: "#fff",
                                  },
                                  marginBottom: "3px",
                                }}
                                onClick={(e) => {
                                  DeleteBankHandler();
                                  setRepresentatives(items.bank_name);
                                  setID(items?.id);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                {countbank > 1 ? (
                  <Pagination
                    count={countbank}
                    size="large"
                    page={pagebank}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangeBank}
                  />
                ) : (
                  ""
                )}
                {CheckPermission("add_supplier_bank_detail", userinfo) ? (
                  <IconButton
                    variant="text"
                    size="medium"
                    sx={{
                      backgroundColor: "#186569",
                      color: "#fff",
                      border: "1px solid #186569",

                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#186569",
                        border: "1px solid #186569",
                      },
                    }}
                    onClick={createBankinformationHandler}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {CheckPermission("remove_supplier", userinfo) ? (
          <div
            className="d-flex justify-content-center
          mb-3"
          >
            <button
              type="submit"
              className="supplier-remove-btn"
              onClick={(e) => {
                setSupplierDeleteid(params?.id);
                toggleClientDeleteHandler();
              }}
            >
              Remove Supplier
            </button>
          </div>
        ) : (
          ""
        )}
        {createRepresentatives ? (
          <RepresentativeModal
            modal={setRepresentative}
            // onUpdateInfoMessage={updateInfoMessageHandler}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createBankinformation ? (
          <BankinformationModal
            refresh={supplierdetail}
            modal={setBankinformation}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createSupplierManufatcurerEditModal ? (
          <SupplierRepresentativeEditModal
            modal={setCreatesupplierManufacturerEdit}
            value={createSupplierManufatcurerEditModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {EditSupplierRepersentativeEditModal ? (
          <SupplierBankDetailEditModal
            modal={setEditsupplierRepresetative}
            value={EditSupplierRepersentativeEditModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {EditaddressModal ? (
          <SupplierEditAddress
            value={supplierdetail}
            showaddress={setEditaddressModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {deleteDialog ? (
          <ConfirmDelete
            action={actionRemove}
            setters={setConfirmation}
            setDialog={setDialog}
            dialog={`Are You Sure to Delete ${representative} ?`}
          />
        ) : (
          ""
        )}
        {deleteBankDialog ? (
          <ConfirmDelete
            action={actionRemoveBank}
            setters={setConfirmation}
            setDialog={setBankDialog}
            dialog={`Are You Sure to Delete ${representative} ?`}
          />
        ) : (
          ""
        )}
        <DeleteModal
          show={showClientDeleteModal}
          handleDeleteModal={toggleClientDeleteHandler}
          name={"suppliers"}
          deletedId={supplierdeleted_id}
          navigationto={"smm/suppliers"}
        />
      </div>
    </Supplier>
  );
}
