import React from "react";
import { NavLink } from "react-router-dom";
import InitiateForexButton from "../../../components/Payment/ForexApproval/InitiateForexButton";
import ForexCard from "../../../components/Payment/ForexApproval/ForexCard";
import ".././forexPages.style.css";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";
import {
  useGetForexProjectQuery,
  useGetForexQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import CheckPermission from "../../../components/common/checkPermission/checkPermission";

export default function ForexClientIndex(props) {
  const { data: forexApprovalClient } = useGetForexQuery();
  const { data: forexProject } = useGetForexProjectQuery();
  const { data: userinfo } = useGetUserInfoQuery();

  let initiatedForexLists = [];
  forexApprovalClient?.data?.filter((forex) =>
    forex?.forex_project?.forex_resource == 1
      ? initiatedForexLists.push(forex)
      : ""
  );

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(initiatedForexLists?.length / PER_PAGE);
  const _DATA = usePagination(initiatedForexLists, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      {CheckPermission("initiate_forex", userinfo) ? (
        <div className="initiateForexButton">
          <InitiateForexButton
            name={"InitiateforexClient"}
            title={"Initiate Forex"}
            projectData={forexProject?.data}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="mt-4 forex-detail-container">
        {_DATA
          .currentData()
          .filter((items) => {
            if (props.search === "") {
              return items;
            } else if (items.forex_name?.toLowerCase().includes(props.search)) {
              return items;
            }
          })
          .map((forex) => (
            <NavLink to={`Detail/${forex?.id}`}>
              <ForexCard data={forex} />
            </NavLink>
          ))}
      </div>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
