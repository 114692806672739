import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import CreateCategory from "../UserAccess/CreateCategory";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from "../../../features/SCMApi";
export default function CreatePermission(props) {
  function HandleCancel() {
    props.modal(false);
  }
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#186569",
      "&:hover": {
        backgroundColor: alpha("#186569", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#186569",
    },
  }));
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const [createCategory, setCreateCategory] = React.useState(false);
  function createCategoryHandler() {
    setCreateCategory(true);
  }
  const [is_project, setisProject] = React.useState(true);
  const [role_name, setrole_name] = React.useState(props?.role?.role_name);
  const [createRole, res] = useCreateRoleMutation();
  const [updateRole, ress] = useUpdateRoleMutation();
  const roleUpdateOrCreate = props?.update ? updateRole : createRole;
  const handleChange = (event) => {
    setisProject(event.target.checked);
  };
  console.log(role_name, "checked");
  let role = {
    id: props?.role?.id,
    role_name,
    is_project: props?.isproject === true ? true : false,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();

    roleUpdateOrCreate(role)
      .unwrap()
      .then((response) => {
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props.modal(false);
        props.setMessages(
          `Role ${props?.update ? "updated" : "created"} successfully`
        );
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
  };
  return (
    <div id="myModal" className="modal" onClick={HandleCancel}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>{props?.update ? "Update Role" : "Add Role"}</div>
          <div onClick={HandleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="42"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </div>
        </div>

        <form onSubmit={HandleSubmit}>
          <div className="form-card-information">
            <div className="card-center">
              <div className="card-form">
                <div className="form-container">
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Role Name</div>
                    <input
                      value={role_name}
                      type="text"
                      required
                      onChange={(e) => setrole_name(e.target.value)}
                      className={`supplier-text-input`}
                    />
                  </div>
                  {props?.isproject === true ? (
                    <div className="d-flex gap-2 align-items-baseline">
                      <div className="supplier-input-label">For Project</div>
                      <GreenSwitch
                        {...label}
                        checked={is_project}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3">
              <button
                type="cancel"
                className="form-cancel"
                onClick={HandleCancel}
              >
                {" "}
                Cancel
              </button>
              <button type="submit" className="form-save">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
