import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreateVarianceAnalysisMutation,
  useGetProjectQuery,
} from "../../../features/SCMApi";

export default function VarianceAnalysisModal(props) {
  const [task_name, setTaskName] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [planned_cost, setPlannedCost] = useState();
  const [actual_cost, setActualCost] = useState();
  const params = useParams();
  const { data: projectid } = useGetProjectQuery(params.id);
  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    task_name,
    start_date,
    end_date,
    project_id: params?.id,
    planned_cost,
    actual_cost,
  };
  console.log(form, "fjjjjjj");
  const [CreateVarianceAnalysis] = useCreateVarianceAnalysisMutation();
  function handleSubmit(e) {
    e.preventDefault();
    CreateVarianceAnalysis(form)
      .unwrap()
      .then((response) => {
        props?.modal(false);
        console.log(response, "Response for created order from backend");
      })
      .then((error) => {
        console.log(error, "Response for created order from backend err");
      });
  }

  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Add Plan</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form
            className="create-order-form mt-4"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                <div className="supplier-input-label">Task Name</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setTaskName(e.target.value)}
                />
                <div className="supplier-input-label">Start Date</div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <div className="supplier-input-label">End Date</div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setEndDate(e.target.value)}
                />

                <div className="supplier-input-label">Planned Cost</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setPlannedCost(e.target.value)}
                />
                <div className="supplier-input-label">Actual Cost</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  onChange={(e) => setActualCost(e.target.value)}
                />
              </div>
            </div>
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button type="submit" class="form-save">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
