import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import ForexApprovalNavBar from "../../../components/Payment/ForexApproval/ForexApprovalNavBar";
import { useState } from "react";

const ForexApprovalIndex = () => {
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    console.log(search, "search");
  };
  return (
    <ForexSideBar
      handleSearchChange={handleSearchChange}
      placeholder={"search Forex..."}
      title={"Forex Approval"}
    >
      <ForexApprovalNavBar search={search} />
    </ForexSideBar>
  );
};

export default ForexApprovalIndex;
