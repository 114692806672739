import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "@mui/material";
import usePagination from "../Pagination/Pagination";
import "./supplierReport.css";
import { API_BASE_URL } from "../../api/endPoint";
import SupplierOffShoreFilter from "./SupplierOffShoreFilter";

export default function SupplierReportsOffShore() {
  const [supplierOffShore, setSupplierOffShore] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchSupplier, setSearchSupplier] = useState("");

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  let counter = 0;

  const _DATA = usePagination(supplierOffShore, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const totalFilteredItems =
    searchCountry || searchSupplier
      ? _DATA.currentData().length
      : supplierOffShore.length;

  const count = Math.ceil(totalFilteredItems / PER_PAGE);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/supplier/summery_supplier_category`)
      .then((response) => {
        setSupplierOffShore(
          response?.data?.data?.filter((items) => items?.is_offshore === 1)
        );
      })
      .catch((error) => console.log(error));
  }, []);

  console.log("supplierOffShore", supplierOffShore);
  console.log("searchSupplier", searchSupplier);
  return (
    <div className="supplier_category_container">
      <div className="prg supplier_category_Table_Container">
        <div style={{ margin: "20px" }}>
          <div className="payable-selector">
            <SupplierOffShoreFilter
              searchCountry={searchCountry}
              handleSearchSubmit={handleSearchSubmit}
              setSearchCountry={setSearchCountry}
              searchSupplier={searchSupplier}
              setSearchSupplier={setSearchSupplier}
            />
          </div>

          <div class="table-responsive m-[20px]">
            {!searchCountry && !searchSupplier ? (
              <table class="table table-bordered">
                <thead>
                  <tr style={{ backgroundColor: "#0070c0" }}>
                    <th colspan="6">Distributor Details</th>
                    <th colspan="4">Contact Details</th>
                  </tr>
                  <tr
                    colspan="10"
                    style={{ backgroundColor: "rgb(0, 112, 192, 0.2)" }}
                  >
                    <td>No.</td>
                    <td>Distributor</td>
                    <td>Product</td>
                    <td>Origin</td>
                    <td>Website</td>
                    <td>Address</td>
                    <td>Contact Person</td>
                    <td>Role/ Responsibility</td>
                    <td>Email</td>
                    <td>Phone Number</td>
                  </tr>
                </thead>
                {(_DATA?.currentData() || []).map((items, index) => {
                  counter = currentPage * PER_PAGE + index + 1;
                  console.log("Counter calculated:", counter);
                  if (items.is_offshore === 1) {
                    return (
                      <tbody
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f2f2f2" : "white",
                        }}
                        key={index}
                      >
                        <tr>
                          <td
                            rowSpan={items.contact_person.length + 1}
                            key={`counter-${index}`}
                          >
                            {counter}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.distributor_name}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.product
                              ?.map((prd) => prd?.manufacturer_name || "-")
                              .join(", ")}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.origin}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.website}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.address}
                          </td>
                          <td>
                            {items?.contact_person?.[0]?.full_name || "-"}
                          </td>
                          <td>{items?.contact_person?.[0]?.role || "-"}</td>
                          <td>{items?.contact_person?.[0]?.email || "-"}</td>
                          <td>
                            {items?.contact_person?.[0]?.phone_number || "-"}
                          </td>
                        </tr>
                        {items.contact_person
                          .slice(1)
                          .map((person, personIndex) => (
                            <tr key={personIndex}>
                              <td>{person?.full_name || "-"}</td>
                              <td>{person?.role || "-"}</td>
                              <td>{person?.email || "-"}</td>
                              <td>{person?.phone_number || "-"}</td>
                            </tr>
                          ))}
                      </tbody>
                    );
                  }
                  return null;
                })}
              </table>
            ) : (
              <table class="table table-bordered">
                <thead>
                  <tr style={{ backgroundColor: "#0070c0" }}>
                    <th colspan="6">Distributor Details</th>
                    <th colspan="4">Contact Details</th>
                  </tr>
                  <tr
                    colspan="10"
                    style={{ backgroundColor: "rgb(0, 112, 192, 0.2)" }}
                  >
                    <td>No.</td>
                    <td>Distributor</td>
                    <td>Product</td>
                    <td>Origin</td>
                    <td>Website</td>
                    <td>Address</td>
                    <td>Contact Person</td>
                    <td>Role/ Responsibility</td>
                    <td>Email</td>
                    <td>Phone Number</td>
                  </tr>
                </thead>
                {supplierOffShore
                  ?.filter(
                    (item) =>
                      (searchCountry ? item.origin === searchCountry : true) &&
                      (searchSupplier === "" ||
                        item?.distributor_name
                          .toLowerCase()
                          .includes(searchSupplier.toLowerCase()))
                  )

                  ?.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE)
                  .map((items, index) => {
                    counter = currentPage * PER_PAGE + index + 1;
                    if (items.is_offshore === 1) {
                      return (
                        <tbody
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f2f2f2" : "white",
                          }}
                          key={index}
                        >
                          <tr>
                            <td rowSpan={items.contact_person.length + 1}>
                              {counter}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.distributor_name}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.product
                                ?.map((prd) => prd?.manufacturer_name || "-")
                                .join(", ")}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.origin}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.website}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.address}
                            </td>
                            <td>
                              {items?.contact_person?.[0]?.full_name || "-"}
                            </td>
                            <td>{items?.contact_person?.[0]?.role || "-"}</td>
                            <td>{items?.contact_person?.[0]?.email || "-"}</td>
                            <td>
                              {items?.contact_person?.[0]?.phone_number || "-"}
                            </td>
                          </tr>
                          {items.contact_person
                            .slice(1)
                            .map((person, personIndex) => (
                              <tr key={personIndex}>
                                <td>{person?.full_name || "-"}</td>
                                <td>{person?.role || "-"}</td>
                                <td>{person?.email || "-"}</td>
                                <td>{person?.phone_number || "-"}</td>
                              </tr>
                            ))}
                        </tbody>
                      );
                    }
                    return null;
                  })}
              </table>
            )}
          </div>

          {count > 1 ? (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "20px",
              }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
