import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "@mui/material";
import usePagination from "../Pagination/Pagination";
import "./supplierReport.css";
import SupplierOnShoreFilter from "./SupplierOnShoreFilter";
import { API_BASE_URL } from "../../api/endPoint";

export default function SupplierReportsOnShore() {
  const [supplierOnShore, setSupplierOnShore] = useState([]);
  const [searchSupplierCategory, setSearchSupplierCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchSupplier, setSearchSupplier] = useState("");

  let [page, setPage] = useState(1);

  const PER_PAGE = 10;
  let counter = 0;

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };

  const _DATA = usePagination(supplierOnShore, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  console.log("_DATA", _DATA);

  const totalFilteredItems =
    searchSupplierCategory || searchSupplier
      ? _DATA.currentData().length
      : supplierOnShore.length;

  const count = Math.ceil(totalFilteredItems / PER_PAGE);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/supplier/summery_supplier_category`)
      .then((response) =>
        setSupplierOnShore(
          response?.data?.data?.filter((items) => items?.is_offshore === 0)
        )
      )
      .catch((error) => console.log(error, "supplierOnShore error"));
  }, []);
  console.log(supplierOnShore, "response for suppliers");

  console.log("supplierOnShorehsadjkf", supplierOnShore);
  return (
    <div className="supplier_category_container">
      <div className="prg supplier_category_Table_Container">
        <div style={{ margin: "20px" }}>
          <div className="payable-selector">
            <SupplierOnShoreFilter
              setSearchSupplierCategory={setSearchSupplierCategory}
              handleSearchSubmit={handleSearchSubmit}
              searchSupplierCategory={searchSupplierCategory}
              searchSupplier={searchSupplier}
              setSearchSupplier={setSearchSupplier}
            />
          </div>
          <div class="table-responsive border m-[20px]">
            {!searchSupplierCategory && !searchSupplier ? (
              <table class="table table-bordered">
                <thead>
                  <tr style={{ backgroundColor: "rgb(0, 112, 192, 0.2)" }}>
                    <th>No.</th>
                    <th>Supplier</th>
                    <th>List of products</th>
                    <th>Address</th>
                    <th>Contact Person</th>
                    <th>Email</th>
                    <th>Telephone</th>
                  </tr>
                </thead>
                {_DATA?.currentData()?.map((items, index) => {
                  if (items.is_offshore === 0) {
                    return (
                      <tbody
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f2f2f2" : "white",
                        }}
                        key={index}
                      >
                        <tr>
                          <td rowSpan={items.contact_person.length + 1}>
                            {(counter += 1)}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.distributor_name}
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.product
                                ?.map((prd) => prd?.manufacturer_name || "-")
                                .join(", ")}
                            </td>
                          </td>
                          <td rowSpan={items.contact_person.length + 1}>
                            {items.address}
                          </td>
                          <td>
                            {items?.contact_person?.[0]?.full_name || "-"}
                          </td>
                          <td>{items?.contact_person?.[0]?.email || "-"}</td>
                          <td>
                            {items?.contact_person?.[0]?.phone_number || "-"}
                          </td>{" "}
                        </tr>
                        {items.contact_person
                          .slice(1)
                          .map((person, personIndex) => (
                            <tr key={personIndex}>
                              <td>{person?.full_name || "-"}</td>
                              <td>{person?.email || "-"}</td>
                              <td>{person?.phone_number || "-"}</td>
                            </tr>
                          ))}
                      </tbody>
                    );
                  }
                  return null;
                })}
              </table>
            ) : (
              <table class="table table-bordered">
                <thead>
                  <tr style={{ backgroundColor: "rgb(0, 112, 192, 0.2)" }}>
                    <th>No.</th>
                    <th>Supplier</th>
                    <th>List of products</th>
                    <th>Address</th>
                    <th>Contact Person</th>
                    <th>Email</th>
                    <th>Telephone</th>
                  </tr>
                </thead>
                {supplierOnShore
                  ?.filter(
                    (item) =>
                      (searchSupplierCategory
                        ? item.category.some(
                            (cat) => cat.name === searchSupplierCategory
                          )
                        : true) &&
                      (searchSupplier === "" ||
                        item?.distributor_name
                          .toLowerCase()
                          .includes(searchSupplier.toLowerCase()))
                  )

                  ?.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE)
                  .map((items, index) => {
                    counter = currentPage * PER_PAGE + index + 1;
                    if (items.is_offshore === 0) {
                      return (
                        <tbody
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f2f2f2" : "#f2f2f2",
                          }}
                          key={index}
                        >
                          <tr>
                            <td
                              rowSpan={
                                items.contact_person
                                  ? items.contact_person.length + 1
                                  : 1
                              }
                            >
                              {counter}
                            </td>
                            <td
                              rowSpan={
                                items.contact_person
                                  ? items.contact_person.length + 1
                                  : 1
                              }
                            >
                              {items.distributor_name}
                            </td>
                            <td rowSpan={items.contact_person.length + 1}>
                              {items.product
                                ?.map((prd) => prd?.manufacturer_name || "-")
                                .join(", ")}
                            </td>
                            <td
                              rowSpan={
                                items.contact_person
                                  ? items.contact_person.length + 1
                                  : 1
                              }
                            >
                              {items.address}
                            </td>
                            <td>
                              {items?.contact_person?.[0]?.full_name || "-"}
                            </td>
                            <td>{items?.contact_person?.[0]?.email || "-"}</td>
                            <td>
                              {items?.contact_person?.[0]?.phone_number || "-"}
                            </td>{" "}
                          </tr>
                          {items.contact_person
                            .slice(1)
                            .map((person, personIndex) => (
                              <tr key={personIndex}>
                                <td>{person?.full_name || "-"}</td>
                                <td>{person?.email || "-"}</td>
                                <td>{person?.phone_number || "-"}</td>
                              </tr>
                            ))}
                        </tbody>
                      );
                    }
                    return null;
                  })}
              </table>
            )}
          </div>
          {count > 1 ? (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "20px",
              }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
