import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import loginSvg from "./login.svg";
import "./loginTempo.css";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { LOGIN_WITH_EMAIL } from "../../../api/endPoint";

const LoginPaper = styled(Grid)(({ theme }) => ({
  boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  height: "70vh",
  width: "800px",
  margin: "20px auto",
  display: "inline-flex",
  borderRadius: "0px !important",
  align: "center",
  borderShadow: "1px solid",
}));

const LeftPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(38, 121, 102, 0.3)",
  backgroundImage: `url(${loginSvg})`,
  height: "70vh",
  width: "400px",
  margin: "-20px",
  display: "inline-flex",
  justifyContent: "center",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "0px !important",
}));
const RightPaper = styled(Paper)(({ theme }) => ({
  height: "70vh",
  width: "400px",
  margin: "-20px",
  display: "inline-block",
  justifyContent: "center",
  marginLeft: "20px",
  borderRadius: "0px !important",
}));
const UnderLine = styled("hr")(({ theme }) => ({
  textAlign: "center",
  fontSize: "24px",
  marginTop: "15px",
  color: "#575757",
  // textDecoration: "underline"
}));
const Title = styled("h2")(({ theme }) => ({
  textAlign: "center",
  width: "0%",
  borderTop: "0px solid #00000938",
  width: "400px",
  marginTop: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "16px",
  letterSpacing: "0.105em",
  color: "#186569",
  fontFamily: `Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
}));
const ErrorDisplay = styled("h2")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "16px",
  letterSpacing: "0.105em",
  color: "#FF5252",
  fontFamily: `Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
}));

export const LoginTempo = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showpassword, setShowPassword] = useState(false);

  const login = () => {
    const data = { email: email, password: password };

    axios
      .post(`${LOGIN_WITH_EMAIL}`, data)
      .then((response) => {
        localStorage.setItem("accessToken", response?.data?.data?.token);
        window.location.replace("/");
        console.log(response, "thi is response");
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message?.message);
        // alert(message ? message : "Internal Error");
        console.log(
          err?.response?.data?.message?.message,
          "error from backend",
          err
        );
      });
  };
  const NewtogglePassword = () => {
    setShowPassword(!showpassword);
  };
  return (
    <div class="login-container">
      <LoginPaper>
        <LeftPaper />
        <RightPaper>
          <Title>
            Supply chain management
            <UnderLine />
          </Title>
          <div class="forms">
            <div class="form login">
              <div class="input-container">
                <ErrorDisplay>
                  {message && message.includes("User does not exist")
                    ? message
                    : ""}
                </ErrorDisplay>
                <div class="input-field">
                  <input
                    placeholder="Email"
                    type="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    required
                  />
                  <i class="uil uil-envelope icon"></i>
                </div>
                <ErrorDisplay>
                  {message && message.includes("Password") ? message : ""}
                </ErrorDisplay>
                <div class="input-field">
                  <input
                    required
                    placeholder="Password"
                    type={showpassword ? "text" : "password"}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                  {showpassword === false ? (
                    <div className="login-showpassword">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-eye-fill"
                        viewBox="0 0 16 16"
                        onClick={NewtogglePassword}
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </div>
                  ) : (
                    <div className="login-showpassword">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-eye-slash-fill"
                        viewBox="0 0 16 16"
                        onClick={NewtogglePassword}
                      >
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <div class="checkbox-text">
                <div class="checkbox-content">
                  <input type="checkbox" id="logCheck" />
                  <label for="logCheck" class="text">
                    Remember me
                  </label>
                </div>
              </div>
              <div class="button-container">
                <div class="input-field button">
                  {/* <button class="button" onClick={login}>Login</button> */}
                  <input type="button" value="Login Now" onClick={login} />
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </RightPaper>
      </LoginPaper>
    </div>
  );
};
