import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddBillToMutation,
  useDeleteOrderMutation,
  useGetEtaDetailQuery,
  useGetOrderPoItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetSuppliersOrdersQuery,
  useGetUserInfoQuery,
  useUpdateCurrentStatusMutation,
  useUpdateOrderSourceMutation,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import { Edit } from "@mui/icons-material";
import EditPi from "./EditPi";
import CheckPermission from "../common/checkPermission/checkPermission";
export default function CurrentStatus({ setMessages }) {
  const params = useParams();
  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  console.log(orderpo, "eta detail");
  const location = useLocation();
  const [current_status, setCurrentStatus] = useState(
    etaDetail?.data[0]?.current_status
  );

  const [showProduct, setShowProduct] = useState();
  const [updateCurrentStatus] = useUpdateCurrentStatusMutation();
  const HandleUpdateProduct = async (e) => {
    let updatecurrentstatus = {
      id: params?.id,
      current_status,
    };
    e.preventDefault();
    updateCurrentStatus(updatecurrentstatus)
      .unwrap()
      .then((response) => {
        console.log(response, "billhhhhhhhhhhhhhhhhhhhhhh");
        setMessages("Updated Successfully");
        setShowProduct(false);
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    console.log("lalalaa");
  };
  console.log(current_status, "description");
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate, "formattedDatem"); // Output: "Saturday, March 25, 2023"

    return formattedDate;
  }
  return (
    <div className="bill-card p-1 mb-2">
      {!showProduct && (
        <div>
          <div className="text-center">
            <div className="h5-pri  text-center">
              {etaDetail?.data[0]?.current_status == null
                ? "Unknown"
                : etaDetail?.data[0]?.current_status}
            </div>
            <div className="lc-label text-center">Current Status</div>
          </div>
          <div className="d-flex justify-content-center">
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={() => setShowProduct((prev) => !prev)}
            >
              <ModeEditOutlinedIcon />
            </IconButton>
          </div>
          <div className="text-end prg">
            {date(
              etaDetail?.data[0]?.updated_at == null
                ? etaDetail?.data[0]?.created_at
                : etaDetail?.data[0]?.updated_at
            )}
          </div>
        </div>
      )}
      {showProduct && (
        <div className="d-grid justify-content-center">
          <div className="lc-label text-start">Current Status</div>
          <textarea
            type="text"
            className="sourcing-text-input-textarea"
            rows={4}
            cols={50}
            onChange={(e) => setCurrentStatus(e.target.value)}
          >
            {current_status}
          </textarea>
          <div className="d-flex justify-content-center gap-3">
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",

                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={HandleUpdateProduct}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",

                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={() => setShowProduct((prev) => !prev)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
      {/* <div className="lc-label mb-2 text-center">Description</div> */}
    </div>
  );
}
