import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import { BatchAndOrderSelector } from "./BatchAndOrderSelector";
import BatchAndOrderNav from "./BatchAndOrderNav";
import {
  useGetOrderItemQuery,
  useGetProjectItemQuery,
} from "../../features/SCMApi";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const OrderDocument = () => {
  const params = useParams();
  const { data: orders } = useGetProjectItemQuery(params.id);
  console.log(orders, "useGetOrderItemQuery");
  const [SelectedOrder, setSelectedOrder] = useState(null);
  console.log(orders, "orders");
  let orderName;
  orders?.data?.order?.map((items) => {
    if (SelectedOrder === items.id) {
      orderName = items?.order_name;
    }
  });
  console.log(orders, "SelectedOrder");

  return orders?.data?.order?.length !== 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
          minHeight: 128,
          marginTop: "2%",
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          border: "1px #ECECEC solid",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ margin: "10px 30px" }}>
            <FolderIcon style={{ fill: "#186569", fontSize: "200%" }} />
            <span
              style={{
                color: "#353945",
                fontWeight: "500",
                fontSize: "16px",
                marginLeft: "10px",
                // marginTop: "10px",
              }}
            >
              Order Document - {orderName}
            </span>
          </div>
          <select
            name=""
            id=""
            className="batch-select-option"
            required
            onChange={(e) => setSelectedOrder(e.target.value)}
          >
            <option value="" disabled selected hidden>
              Select order
            </option>
            {orders?.data?.order?.map((items) => (
              <option value={items.id}>{items.order_name}</option>
            ))}
          </select>
        </div>
        {SelectedOrder !== null ? (
          <BatchAndOrderNav
            name={"order"}
            orderName={orderName}
            SelectedOrder={SelectedOrder}
          />
        ) : (
          <div className="text-center h5-pri">Please Select a Order</div>
        )}
      </Paper>
    </Box>
  ) : (
    ""
  );
};
