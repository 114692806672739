import React from "react";
import { useState } from "react";
import {
  useAddInventoryTypeSettingMutation,
  useAddToolMutation,
  useGetCurrenciesQuery,
  useGetItemCategorysQuery,
  useGetItemTypeQuery,
  useGetManufacturersQuery,
  useGetProductsQuery,
  useGetProjectsQuery,
  useGetStatusQuery,
  useGetStoreQuery,
  useGetToolTypeQuery,
  useGetUnitOfMeasurementQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import { Category } from "@mui/icons-material";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";

export default function ToolForm(props) {
  const [createTool, res] = useAddToolMutation();
  const [tool_type_id, setInventory_type] = useState(null);
  const [item_name, setItemName] = useState(null);
  const [model, setModel] = useState(null);
  const [serial_number, setSerialNumber] = useState(null);
  const [item_number, setItemNumber] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency_id, setCurrency] = useState(null);
  const [pi, setpi] = useState(null);
  const [store_id, setStore] = useState(null);
  const [department_id, setDepartment] = useState(null);
  const [category_id, setCategory] = useState(null);
  const [warranty, setWarranty] = useState(null);
  const [manufacturer_id, setManufacturer] = useState(null);
  const [image, setImage] = useState(null);
  const [status_id, setStatus] = useState(null);
  const [date_of_purchased, setDateofPurchased] = useState(null);
  const [amount, setAmount] = useState(null);
  const [uom_id, setUnit] = useState(null);
  const [is_group_item, setGroup] = useState(false);
  const [is_consumable, setConsumable] = useState(false);
  const [is_fixed_asset, setFixedAsset] = useState(
    props?.wisFixed == undefined ? false : true
  );
  const [description, setDescription] = useState(null);
  const [tool_project, setProject] = useState(null);
  const { data: response } = useGetProjectsQuery();

  let project_id = null;
  const { data: currency } = useGetCurrenciesQuery();
  const { data: store } = useGetStoreQuery();
  const { data: Departments } = useGetProductsQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const { data: manufacturer } = useGetManufacturersQuery();
  const { data: itemstype } = useGetToolTypeQuery();
  const { data: status } = useGetStatusQuery();
  const { data: unit } = useGetUnitOfMeasurementQuery();
  const { data: userinfo } = useGetUserInfoQuery();
  let created_by = userinfo?.id;
  const handleChange = (e) => {
    const img = {
      name: e?.target?.files[0].name,
      data: e?.target?.files[0],
    };
    setImage(img);
  };
  const handleChangePi = (e) => {
    const pis = {
      namepi: e?.target?.files[0].name,
      datapi: e?.target?.files[0],
    };
    setpi(pis);
  };
  const handleChangeWar = (e) => {
    const warrantys = {
      namewar: e?.target?.files[0].name,
      datawar: e?.target?.files[0],
    };
    setWarranty(warrantys);
  };
  store?.data?.map((items) =>
    items.id == store_id ? (project_id = items?.project_id) : null
  );
  let tools = {
    tool_type_id,
    item_name,
    model,
    serial_number,
    item_number,
    price,
    currency_id,
    pi_document: pi?.datapi,
    store_id,
    department_id,
    category_id,
    manufacturer_id,
    tool_image: image?.data,
    warranty_document: warranty?.datawar,
    status_id,
    date_of_purchased,
    description,
    amount,
    uom_id,
    is_group_item,
    is_consumable,
    is_fixed_asset: props?.isFixed == undefined ? false : true,
    project_id,
    created_by,
    tool_project,
  };
  console.log(tools, "toolstools");
  const HandleSubmit = async (e) => {
    e.preventDefault();
    // createTool(tools)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
    //     props.modal(false);
    //   })
    //   .then((error) => {
    //     console.log(error, "erorrrrr");
    //   });
    axios
      .post(`${API_BASE_URL}/scm/stock/tools/create`, tools, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log(response);
        props.modal(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "errorrrrrrrrrrrrrrr");
      });
  };
  function HandleCancel() {
    props.modal(false);
  }

  function HandleCancel() {
    props.modal(false);
  }

  console.log(tools, "tools form");
  const [checked, setChecked] = useState(false);
  const [is_consumableform, setForms] = useState(false);
  console.log(is_consumableform, is_consumable, "check consumable");
  const CheckBoxHandelGroup = () => {
    setGroup(!is_group_item);
  };
  const CheckBoxHandelConsumable = () => {
    setConsumable(!is_consumable);
    setForms(!is_consumableform);
  };
  const CheckBoxHandelFixedAsset = () => {
    setFixedAsset(!is_fixed_asset);
  };

  console.log(project_id, "haha project id");
  return (
    <form className="pi-form" onSubmit={HandleSubmit}>
      <div className="d-grid gap-3">
        <div className="d-flex gap-0 align-items-baseline justify-content-center mt-2">
          <label className="d-flex gap-2 ">
            <input
              type="checkbox"
              className="stock-checkmark"
              onChange={CheckBoxHandelGroup}
            />
            <span className="h6">Group Item</span>
          </label>
        </div>
        <div className="d-flex gap-0 align-items-baseline justify-content-center mt-2">
          <label className="d-flex gap-2 ">
            <input
              type="checkbox"
              className="stock-checkmark"
              onChange={CheckBoxHandelConsumable}
            />
            <span className="h6"> Consumable Item</span>
          </label>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Type</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setInventory_type(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Type
            </option>
            {itemstype?.data?.map((items) => (
              <option value={items.id}>{items?.tool_type}</option>
            ))}
          </select>
        </div>

        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Item Name</div>
          <input
            onChange={(e) => setItemName(e.target.value)}
            type="text"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Image</div>
          <div
            className="file-chooser cl-9 px-0"
            style={{ backgroundImage: `url(${attachmentIcon})` }}
          >
            <input
              id="chooser"
              required
              type={"file"}
              class="file-input"
              multiple
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label
              htmlFor="chooser"
              style={{
                position: "relative",
                top: "-28px",
                left: "5px",
                width: "10rem",
                // zIndex: "-1",
              }}
            >
              {image
                ? image?.name?.length > 16
                  ? image?.name?.slice(0, 15) + "..."
                  : image?.name
                : "Choose File"}
            </label>
          </div>
        </div>
        {is_consumableform == false ? (
          <div className="d-flex gap-2 align-items-baseline">
            <div className="supplier-input-label">Model</div>
            <input
              onChange={(e) => setModel(e.target.value)}
              type="text"
              className={`stock-text-input`}
              required
            />
          </div>
        ) : (
          ""
        )}

        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Status</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setStatus(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Status
            </option>
            {status?.data?.map((items) => (
              <option value={items.id}>{items?.status_name}</option>
            ))}
          </select>
        </div>
        {is_consumableform == false ? (
          <>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Serial Number</div>
              <input
                onChange={(e) => setSerialNumber(e.target.value)}
                type="text"
                className={`stock-text-input`}
                required
              />
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Item Number</div>
              <input
                onChange={(e) => setItemNumber(e.target.value)}
                type="text"
                className={`stock-text-input`}
                required
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Unit of Measurement</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setUnit(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose unit of Measurement
            </option>
            {unit?.data?.map((items) => (
              <option value={items.id}>{items?.name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Amount</div>
          <input
            onChange={(e) => setAmount(e.target.value)}
            type="text"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Price</div>
          <input
            onChange={(e) => setPrice(e.target.value)}
            type="text"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Date of Purchased</div>
          <input
            onChange={(e) => setDateofPurchased(e.target.value)}
            type="date"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Currency</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setCurrency(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Currency
            </option>
            {currency?.data?.map((items) => (
              <option value={items.id}>{items?.name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">PI Document</div>
          <div
            className="file-chooser cl-9 px-0"
            style={{ backgroundImage: `url(${attachmentIcon})` }}
          >
            <input
              id="chooser"
              type={"file"}
              class="file-input"
              multiple
              onChange={(e) => {
                console.log(e.target.files[0], "filesssss");
                handleChangePi(e);
              }}
            />
            <label
              htmlFor="chooser"
              style={{
                position: "relative",
                top: "-28px",
                left: "5px",
                width: "10rem",
                // zIndex: "-1",
              }}
            >
              {pi
                ? pi?.namepi?.length > 16
                  ? pi?.namepi?.slice(0, 15) + "..."
                  : pi?.namepi
                : "Choose File"}
            </label>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Store</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setStore(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Store
            </option>
            {store?.data?.map((items) => (
              <option value={items.id}>{items?.store_name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Project</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setProject(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Project
            </option>
            {response?.data?.map((items) => (
              <option value={items.id}>{items?.project_name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Department</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setDepartment(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Department
            </option>
            {Departments?.data?.map((items) => (
              <option value={items.id}>{items?.department_name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Category</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setCategory(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Category
            </option>
            {ItemCategory?.data?.map((items) => (
              <option value={items.id}>{items?.category}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Warranty</div>
          <div
            className="file-chooser cl-9 px-0"
            style={{ backgroundImage: `url(${attachmentIcon})` }}
          >
            <input
              id="chooser"
              type={"file"}
              class="file-input"
              multiple
              onChange={(e) => {
                console.log(e.target.files[0], "filesssss");
                handleChangeWar(e);
              }}
            />
            <label
              htmlFor="chooser"
              style={{
                position: "relative",
                top: "-28px",
                left: "5px",
                width: "10rem",
                // zIndex: "-1",
              }}
            >
              {warranty
                ? warranty?.namewar?.length > 16
                  ? warranty?.namewar?.slice(0, 15) + "..."
                  : warranty?.namewar
                : "Choose File"}
            </label>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">Manufacturer</div>
          <select
            name=""
            id=""
            className="select-option-create-order "
            onChange={(e) => setManufacturer(e.target.value)}
          >
            {" "}
            <option selected="true" disabled="disabled">
              Choose Manufacturer
            </option>
            {manufacturer?.data?.map((items) => (
              <option value={items.id}>{items?.manufacturer_name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-0 align-items-baseline">
          <div className="supplier-input-label">Description</div>
          <textarea
            cols="30"
            rows="5"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="d-flex gap-0 align-items-baseline justify-content-end mt-2">
          <label className="d-flex gap-2 ">
            <input
              type="checkbox"
              checked={is_fixed_asset}
              className="stock-checkmark"
              onChange={CheckBoxHandelFixedAsset}
            />
            <span className="h6">Fixed Asset</span>
          </label>
        </div>
      </div>
      <div className="pi-modal-footer">
        <button onClick={HandleCancel}>Cancel</button>
        <button type="submit">Save</button>
      </div>
    </form>
  );
}
