import React, { useState } from "react";
import {
  useAddClientMutaion,
  useCreateClientMutation,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import "../Settings.css";

export default function CreateClientSettingModal(props) {
  // const [addClient, response] = useAddClientMutaion();

  const [client_name, setClient_name] = useState();
  const [address, setAddress] = useState();
  const [postal_code, setPostal_code] = useState();
  const [telephone, setTelephone] = useState();
  const [createClient] = useCreateClientMutation();
  let params = {
    client_name: client_name,
    postal_code: postal_code,
    address: address,
    telephone: telephone,
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    createClient(params)
      .unwrap()
      .then((response) => {
        console.log(response, "RTK response");
        window.location.reload(true);
        HandleCancel();
      })
      .catch((error) => {
        console.log(error, "RTK error");
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/settings/clients`, params, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response, "response frome database");
    //     HandleCancel();
    //     props.refreshe();
    //   })
    //   .then((error) => {
    //     console.log(error, "erorr from backend");
    //   });
    // addClient(params)
    //   .unwrap()
    //   .then((response) => {
    //     console.log(response, "response frome database");
    //   })
    //   .then((error) => {
    //     console.log(error, "erorr from backend");
    //   });
  };

  return (
    <div>
      <div id="myModal" className="modal" onClick={(e) => HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title clientRepModal">
            <div className="addClientTitle">Add Client</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="">
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="input-label">Client Name</div>
                        <div className="input-label">Address</div>
                        <div className="input-label">P.O Box</div>
                        <div className="input-label">Telephone</div>
                      </div>
                      <div className="input-fields">
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setClient_name(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setPostal_code(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setTelephone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
