import React, { useState } from "react";
import "./modal.css";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";

export default function AddSupplierCategory(props) {
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  let form = {
    name,
    description,
  };
  if (!props.addSupplierCategory) {
    return null;
  }

  const handleAdd = (e) => {
    e.preventDefault();
    axios
      .post(`${API_BASE_URL}/scm/supplier/supplier_category`, form)
      .then((response) => console.log("first"), props?.onClose())

      .catch((error) => console.log("error", error));
  };

  return (
    <div className="pi-modal" onClick={props.onClose}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <h3>Add Supplier Category</h3>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form">
            <div className="pi-inputs">
              <section className="pi-inputs-modal">
                <label>Supplier category</label>
                <input
                  name="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </section>
            </div>
            <div className="pi-inputs">
              <section className="pi-inputs-modal">
                <label>Description</label>
                <textarea
                  name="description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </section>
            </div>
            <div className="pi-modal-footer">
              <button onClick={props.onClose}>Cancel</button>
              <button onClick={handleAdd}>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
