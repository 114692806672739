import React, { useEffect, useState } from "react";
import "./aging.css";
import ExcelJS from "exceljs";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
import { MdOutlineCloudDownload } from "react-icons/md";

function PayableExportButton() {
  const [summeryData, setSummeryData] = useState([]);
  const [payableFilteredData, setPayableFilteredData] = useState([]);
  const IE_NETWORK_SOLUTIONS_PL = "IE NETWORK SOLUTIONS PL";
  const ACCOUNTS_PAYABLE_LEDGER = "ACCOUNTS PAYABLE LEDGER";
  const AT_THE_DATE = `AT THE DATE: ${new Date().toLocaleDateString()}`;

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/sourcing/supplier/payable-aging_summary`)
      .then((response) => {
        setSummeryData(response.data.data);
      })
      .catch((err) => {
        console.error("Error fetching summary data:", err);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/sourcing/supplier/payable-aging_supplier`
        );
        setPayableFilteredData(response?.data?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const productData = payableFilteredData.flatMap((subArray) => subArray);

  const addDataToSheet = (
    sheet,
    header,
    rowData,
    isSummarySheet = false,
    range = null,
    currency = null
  ) => {
    if (sheet.rowCount === 0) {
      sheet.addRow([""]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([IE_NETWORK_SOLUTIONS_PL]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([ACCOUNTS_PAYABLE_LEDGER]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      sheet.addRow([AT_THE_DATE]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
    }

    if (!isSummarySheet) {
      if (sheet.rowCount <= 6) {
        const helloRow1 = sheet.addRow([""]);
        helloRow1.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
        };
        const helloRow2 = sheet.addRow([
          "S.No.",
          "Invoice/Transaction Date",
          "Reference/Invoice Number",
          "Amount",
          "Due Date",
          "Report Date",
          "Age of Payable",
          "Reason",
          "Status",
        ]);
        helloRow2.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "ffffff" },
          fgColor: { argb: "4db8ff" },
        };
        const helloRow3 = sheet.addRow([""]);
        helloRow3.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
        };
      }
    }

    if (isSummarySheet && currency) {
      console.log("Currency:", currency);

      const currencyRow = sheet.addRow([`Currency: ${currency}`]);
      currencyRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0099ff" },
      };
    }

    if (range) {
      const rangeRow = sheet.addRow([`Range: ${range}`]);

      if (range === "Current & Upcoming") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range === "1-30 Days") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range === "31-60 Days") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range === "61-90 Days") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range === "91-120 Days") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range === "121-150 Days") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      } else if (range >= "150 Days & above") {
        rangeRow.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "304569" },
          fgColor: { argb: "A0CD63" },
        };
        rangeRow.font = { color: { argb: "304569" } };
      }
    }
    let currencyTotal = 0;
    let count = 0;
    rowData.map((row) => {
      count++;

      currencyTotal += row.amount;

      sheet.addRow(
        isSummarySheet
          ? [
              row.Currency,
              row.supplier_name,
              row.currentAndUpcomingAmmount,
              row.DaysOverDue1_30,
              row.DaysOverDue31_60,
              row.DaysOverDue61_90,
              row.DaysOverDue91_120,
              row.DaysOverDue121_150,
              row.DaysOverDue151Above,
              row.Total,
            ]
          : [
              count,
              row.transaction_date,
              row.Reference_number,
              row.amount,
              row.payableDate,
              row.reportDate,
              row.aging,
              row.reason,
              row.status,
            ]
      );
    });

    if (!isSummarySheet && range) {
      const totalRow = sheet.addRow([
        "",
        `Total Amount for ${range}:`,
        rowData.reduce((acc, row) => acc + row.amount, 0).toFixed(2),
      ]);
      totalRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f2f2f2" },
      };
    }

    if (!isSummarySheet && !range) {
      sheet.addRow([]);
    }
  };

  const addSummaryDataToSheet = (sheet) => {
    let uniqueCurrencies = []; // To store unique currencies
    let currencyTotals = {}; // To store total amounts for each currency

    let counter = 0;
    let otherCounter = 0; // Counter for "Other"

    // Identify distinct currencies
    summeryData.forEach((row) => {
      if (!uniqueCurrencies.includes(row.Currency) && row.Currency !== "null") {
        uniqueCurrencies.push(row.Currency);
        currencyTotals[row.Currency] = 0;
      }
    });

    // Group by each unique currency
    uniqueCurrencies.forEach((currency) => {
      let counter = 0; // Counter for serial numbers

      // Add a row with the currency at the top of the group
      sheet.addRow([`Currency: ${currency}`]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4db8ff" },
      };

      summeryData.forEach((row) => {
        if (row.Currency === currency) {
          counter++;
          // Add the summary data row
          sheet.addRow([
            counter,
            row.supplier_name,
            row.current_upcoming,
            row.DaysOverDue1_30,
            row.DaysOverDue31_60,
            row.DaysOverDue61_90,
            row.DaysOverDue91_120,
            row.DaysOverDue121_150,
            row.DaysOverDue151Above,
            row.Total,
          ]);
          currencyTotals[currency] += parseFloat(row.Total);
        }
      });
      sheet.addRow([
        "Total",
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        "", // Leave other cells empty
        currencyTotals[currency], // Add the total amount
      ]);
      // Reset counter for the next currency group
      counter = 0;
    });
    if (uniqueCurrencies.includes("null")) {
      let otherCounter = 0; // Counter for "Other"

      // Group "Other" as a single currency
      sheet.addRow([`Currency: Other`]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4db8ff" },
      };

      summeryData.forEach((row) => {
        if (row.Currency === "null") {
          otherCounter++;
          // Add the summary data row for "Other"
          sheet.addRow([
            otherCounter,
            row.supplier_name,
            row.current_upcoming,
            row.DaysOverDue1_30,
            row.DaysOverDue31_60,
            row.DaysOverDue61_90,
            row.DaysOverDue91_120,
            row.DaysOverDue121_150,
            row.DaysOverDue151Above,
            row.Total,
          ]);
        }
      });
    }
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();

    const summarySheet = workbook.addWorksheet("Summary");

    if (summarySheet.rowCount === 0) {
      summarySheet.addRow([""]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([IE_NETWORK_SOLUTIONS_PL]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([ACCOUNTS_PAYABLE_LEDGER]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
      summarySheet.addRow([AT_THE_DATE]).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffff" },
      };
    }

    const byRow1 = summarySheet.addRow([""]);
    byRow1.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    const byRow2 = summarySheet.addRow([
      "NO",
      "supplier_name",
      "Current & upcoming",
      "1-30 days overdue",
      "31-60 days overdue",
      "61-90 days overdue",
      "91-120 days overdue",
      "121-150 days overdue",
      "Above 150 days overdue",
      "Total",
    ]);
    byRow2.fill = {
      type: "pattern",
      pattern: "solid",
      color: { argb: "ffffff" },
      fgColor: { argb: "4db8ff" },
    };
    const byRow3 = summarySheet.addRow([""]);
    byRow3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    addSummaryDataToSheet(summarySheet);

    const groupedByCurrency = productData.reduce((acc, item) => {
      const currency = item.currency_name;
      if (!acc[currency]) {
        acc[currency] = [];
      }
      acc[currency].push(item);
      return acc;
    }, {});

    Object.entries(groupedByCurrency).forEach(
      ([currency_name, currencyData]) => {
        const sheet = workbook.addWorksheet(`${currency_name}`);
        const filteredData = productData.filter(
          (item) => item.currency_name === currency_name
        );

        const idRanges = {
          "Current & Upcoming": filteredData.filter((item) => item.aging < 1),
          "1-30 Days": filteredData.filter(
            (item) => item.aging >= 1 && item.aging <= 30
          ),
          "31-60 Days": filteredData.filter(
            (item) => item.aging > 30 && item.aging <= 60
          ),
          "61-90 Days": filteredData.filter(
            (item) => item.aging > 60 && item.aging <= 90
          ),
          "91-120 Days": filteredData.filter(
            (item) => item.aging > 90 && item.aging <= 120
          ),
          "121-150 Days": filteredData.filter(
            (item) => item.aging > 120 && item.aging <= 150
          ),
          "150 Days & above": filteredData.filter((item) => item.aging > 150),
        };

        Object.entries(idRanges).forEach(([range, rangeData]) => {
          addDataToSheet(
            sheet,
            [
              "S.No.",
              "Invoice/Transaction Date",
              "Reference/Invoice Number",
              "Amount",
              "Due Date",
              "Report Date",
              "Age of Payable",
              "Reason",
              "Status",
            ],
            rangeData,
            false,
            range
          );
        });
      }
    );

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Aging_Payable_${new Date().toLocaleDateString()}.xlsx`;
      link.click();
    });
  };

  return (
    <div className="button-container">
      <button className="download-btn" onClick={handleDownload}>
        <MdOutlineCloudDownload />
        Export
      </button>
    </div>
  );
}

export default PayableExportButton;
