import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  height: "90px",
  color: theme.palette.text.secondary,
  alignItems: "center",
  border: "1px solid #AEB8C9",
}));

const MissedItemOrigin = (props) => {
  console.log(props?.data, "batch origin");
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={2}
        xl={2}
        xxl={2}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        {" "}
        <Item elevation={0}>
          <div className="origin-group rmaHeading">
            <div className="rmaValue" style={{ fontSize: "16px" }}>
              {props?.data && props?.data?.batch?.batch_origin !== null
                ? props?.data?.batch?.batch_origin?.name
                : "Not registered"}
            </div>
            <div
              className="origin-label"
              style={{ fontSize: "14px", marginTop: "10px" }}
            >
              Origin
            </div>
          </div>
        </Item>
      </Grid>
    </>
  );
};

export default MissedItemOrigin;
