import React from "react";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Pagination, Tooltip } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import {
  useGetApprovedItemsQuery,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
} from "../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import NoRecord from "../common/NoRecord";
import ConfirmDialog from "../common/ConfirmDialog";
import StockDialog from "../common/StockDialog";
import axios from "axios";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import { Toll } from "@mui/icons-material";
import usePagination from "./../Pagination/Pagination";
import InventoryReport from "./DownloadReports/InventoryReport";
import { date } from "../dateFormator";

export default function ApprovedItems(props) {
  function HandleRequest() {
    setConfirm(true);
  }
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    // props?.setFragment("detail");
    props?.setData(tool);
    console.log(tool, "toolssssss");
  };
  const { data: getApprovedItems } = useGetApprovedItemsQuery();
  console.log(getApprovedItems, "getApprovedItems");
  const [registerTool, setRegisterTool] = useState(false);
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  function HandleItem() {
    setRegisterTool(true);
    updateTool(null);
  }
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);
  console.log(dialog, "dialog");
  const [toolRequest, src] = useToolRequestMutation();
  const [requested_quantity, setQuantity] = useState(null);
  const [requestedProject, setRequestedProject] = useState("");

  const { data: userinfo } = useGetUserInfoQuery();

  function HandleToolRequest(e, id) {
    let requested = {
      id: id,
      requested_quantity,
      requester_id: userinfo?.id,
      requested_project: requestedProject,
    };
    toolRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        console.log(response, "hhhhhhhhhhhhhhhhhhhhhh");
        props?.setMessages("Tool Requested successfully");
      })
      .then((error) => {
        console.log(error, "erorrrrr");
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error, "errorrrrrrrrrrrrrrr");
    //   });
  }

  let notRequest = getApprovedItems;

  console.log(getApprovedItems, "notRequest tools");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();

  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  // userinfo?.role?.role_name == "Super Admin"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;

  const [updateTool, setupdateTool] = useState(null);
  const HandleEditTool = () => {
    setRegisterTool(true);
  };
  console.log(requestedProject, "in test tools");
  const [amount, setAmount] = useState();
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined && element?.requested_amount != "";
  });
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  console.log(notRequest, "confirmRequestItems");
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(count, "confirmRequestItems");
  console.log(_DATA?.currentData(), "ApprovedData");
  return (
    <div className="mb-3 mt-3">
      {_DATA?.currentData()?.length != 0 ? (
        <>
          <div className="inventory-header-container">
            <input
              onChange={handleSearchChange}
              placeholder="search"
              type="search"
              className="inventory-search-input"
            />
            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setStore_id(e.target.value)}
            >
              <option value="">Store</option>
              {store?.data?.map((items) => (
                <option value={items?.id}>{items?.store_name}</option>
              ))}
            </select>

            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Item Group</option>
              {ItemCategory?.data?.map((items) => (
                <option value={items?.id}>{items?.category}</option>
              ))}
            </select>

            <div className="items-counter">
              <div className="text-center">
                {filteredData(search, category, store_id)?.length}
              </div>
              <div>Items Count</div>
            </div>
          </div>

          <div className="finance-table-container">
            <table
              className="table table-striped table-bordered "
              style={{
                width: "auto",
                minWidth: "2000px",
                borderCollapse: "collapse",
              }}
            >
              <thead className="stock-table-header">
                <tr>
                  <th style={{ width: "9%" }}></th>
                  <th style={{ width: "9%" }}>Item Name</th>
                  <th style={{ width: "9%" }}>Model</th>
                  <th style={{ width: "9%" }}>Store</th>
                  <th style={{ width: "9%" }}>Requested Amount</th>
                  <th style={{ width: "9%" }}>Requester</th>
                  <th style={{ width: "9%" }}>Requested Date</th>
                  <th style={{ width: "9%" }}>Requested Project </th>
                  <th style={{ width: "9%" }}>Approved Date </th>
                  <th style={{ width: "9%" }}>Approval status </th>
                  <th style={{ width: "9%" }}>Recived status </th>
                  <th style={{ width: "4%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {_DATA?.currentData()?.map((tool, index) => (
                  <tr className="stock-table-row">
                    <td
                      className=""
                      onClick={() => {
                        HandleFragment(tool);
                        setupdateTool(null);
                      }}
                    >
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.item_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.model === null ? "-" : tool?.model}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {" "}
                      {tool?.store === null ? "-" : tool?.store}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {`${tool?.requested_quantity === null ? "-" : tool?.requested_quantity} `}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.tool_requester_name ||
                        tool?.items_requester_name ||
                        tool?.asset_requester_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {date(tool?.requested_date)}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.tool_project_name ||
                        tool?.items_project_name ||
                        tool?.asset_project_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {date(tool?.confirmed_date)}
                    </td>{" "}
                    <td className="text-start">Approved</td>
                    <td className="text-start">
                      {tool?.is_requester_received
                        ? "Approved"
                        : "Waiting for Approval"}
                    </td>
                    <td className="text-start">
                      <a
                        type="link"
                        href={`${IMG_BASE_URL}/gate-pass/${
                          tool?.inventory_id
                            ? "items"
                            : tool?.tool_id
                              ? "tools"
                              : "assets"
                        }/${tool?.id}`}
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                      >
                        <DownloadIcon
                          onClick={() => {
                            HandleEditTool(true);
                            setupdateTool(tool);
                          }}
                          sx={{
                            color: "#52FFAC",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#52FFAC",
                              border: "1px solid #52FFAC",
                              borderRadius: 25,
                            },
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <NoRecord />
      )}

      {confirm ? (
        <StockDialog
          setDialog={setConfirm}
          projectId={setRequestedProject}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          name="request"
          quantity={requested_quantity}
          action={(e) => HandleToolRequest(e, dialog?.id)}
          setQuantity={setQuantity}
          requestedProject={requestedProject}
        />
      ) : (
        ""
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
