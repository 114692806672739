import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import {
  useCheckedForexPlanningReceivableMutation,
  useGetSupplierCreditQuery,
  useUpdateForexNeedStatusMutation,
} from "../../../../features/SCMApi";
import Selector from "../../ForexApproval/Select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeletePaymentTrackingModal({
  show = false,
  handleDeleteModal,
  data,
  successMessage,
  message,
  width,
  name,
  receivable,
}) {
  const [receivableChecked] = useCheckedForexPlanningReceivableMutation();
  const [forexUpdateStatus] = useUpdateForexNeedStatusMutation();
  const [po, setPO] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState();
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const paramsReceivable = {
    id: data,
    receivedPayments: data,
    po,
    description,
  };

  const [accountType, setAccountType] = React.useState("");

  const accountTypes = ["birr", "retention"];

  const forexNeedId = data && data;
  const paramsForex = {
    account_type: accountType,
    id: forexNeedId,
    po,
    description,
  };
  const handleErrorMessage = () => {
    setShowErrorMessage(true);
  };

  const apiHandler =
    name === "forexStatus" ? forexUpdateStatus : receivableChecked;

  const params = name === "forexStatus" ? paramsForex : paramsReceivable;
  console.log(apiHandler, "this is parameter for forex need update status");
  const { data: suppliercredit } = useGetSupplierCreditQuery(
    receivable?.supplier_id
  );
  const filteredData = suppliercredit?.data
    ?.filter((items) => {
      if (items?.paid_amount != items?.credit_amount) {
        return items;
      }
    })
    .map((items) => items);

  console.log(suppliercredit, paramsReceivable, "filteredData");

  const handleSubmit = (e) => {
    console.log("paramsforcheckclick", params);
    e.preventDefault();
    apiHandler(params)
      .unwrap()
      .then((response) => {
        handleDeleteModal(false);
        name !== "forexStatus" && successMessage(true);
        name !== "forexStatus" && message("Forex received successfully!!!");
        setShowErrorMessage(false);
        console.log(response, "response from database");
      })
      .catch((error) => {
        setErrorMessage(error?.data?.message);
        setShowErrorMessage(true);
        console.log(error?.data?.message, "erorr from backend");
      });
  };

  return (
    <BootstrapDialog
      onClose={handleDeleteModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleDeleteModal}
        sx={{ width: width ? width : 400, color: "#575757" }}
      ></BootstrapDialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#FF5252",
                  marginBottom: "2%",
                }}
              >
                {name === "forexStatus"
                  ? "Confirm forex update"
                  : "Are you sure ?"}
                {name === "forexStatus" ? (
                  <div style={{ marginTop: "5px" }}>
                    <Selector
                      name={accountTypes}
                      onChange={setAccountType}
                      required={true}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showErrorMessage && (
                <div class="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {receivable?.payment_way == "To Supplier" ? (
                <div className="d-grid justify-content-center gap-2">
                  <div className="input-label text-start">Purchase Order</div>
                  <select
                    name=""
                    id=""
                    className="select-option-create-order "
                    onChange={(e) => setPO(e.target.value)}
                  >
                    <option selected="true" disabled="disabled">
                      Choose PO
                    </option>
                    {filteredData?.map((items) => (
                      <option value={items.po_id}>{items?.order_name}</option>
                    ))}
                  </select>
                  <div className="input-label">Description</div>
                  <textarea
                    type="text"
                    className="sourcing-text-input-textarea"
                    rows={4}
                    cols={50}
                    style={{ height: "48px" }}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "inherent",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                borderRadius: "25px",
                width: "104.5x",
                backgroundColor: "#064b4f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },

                textTransform: "none",
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              type="reset"
              sx={{
                backgroundColor: "#fff",
                color: "#575757",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor: "#ececec",
                  color: "#575757",
                },
                width: "84.5px",

                textTransform: "none",
              }}
              onClick={handleDeleteModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </BootstrapDialog>
  );
}
