import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import {
  useAssignPersonnelMutation,
  useAssignSolutionMutation,
  useGetProjectInputParamsQuery,
  useGetSubSolutionsPerProjectMutation,
  useGetSubSolutionsQuery,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import { useParams } from "react-router-dom";

export default function AddSolution(props) {
  const [getSolutions, res] = useGetSubSolutionsPerProjectMutation();
  const { projectId } = useParams();
  const [assignSolution, response] = useAssignSolutionMutation();
  const { data: inputPrams } = useGetProjectInputParamsQuery();

  const { data: subSolutions } = useGetSubSolutionsQuery();

  const [sol, setSol] = useState();
  const [project_department_id, setDepartment] = useState([]);
  let project_solution = [];
  const subSolutionList = [];
  for (let i = 0; i < subSolutions?.data.length; i++) {
    for (let j = 0; j < project_department_id?.length; j++) {
      if (project_department_id[j] == subSolutions?.data[i]?.department_id) {
        console.log(
          project_department_id[j] == subSolutions?.data[i]?.department_id,
          "condition"
        );
        subSolutionList.push(subSolutions?.data[i]);
      } else console.log(project_department_id[j], "false condition");
    }
  }
  const form = {
    project_id: projectId,
    project_department_id,
    project_solution,
  };
  useEffect(() => {
    getSolutions({ project_id: projectId })
      .unwrap()
      .then((response) => {
        const Departments = [];

        response?.data?.map((department) => {
          Departments.push({
            value: department?.id,
            label: department?.abbrivation,
          });
          setSol(Departments);
          console.log(Departments, "department");
        });
      })
      .then((error) => {
        console.log(error);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/project/projects/department`, form, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })

    //   .then(function (response) {
    //     console.log(response);
    //     props.controller(false);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, [getSolutions]);
  function handleChange(e) {
    // let condition = project_solution.filter(
    //   (items) => items?.id == project_solution
    // );
    // condition.length == 0
    //   ? project_solution.push(list?.id)
    //   : project_department_id.pop(list?.id);
    const ola = document.getElementById(e.target.id).checked;
    const condition = project_solution.filter((items) => {
      console.log(e.target.id, "e.target.id");
      return items == e.target.id;
    });
    condition.length != 0
      ? (project_solution = project_solution.filter(
          (items) => items != e.target.id
        ))
      : project_solution.push(e.target.id);
    console.log(condition, "conditionpop");
    console.log(project_solution.length, "project_solution");

    console.log(project_solution, "project_solution");
  }

  function handleSubmit(e) {
    e.preventDefault();
    assignSolution(form)
      .unwrap()
      .then((response) => {
        console.log(response, "add solution");
        props?.ModalToggle(false); // window.location.reload();
        props?.setMessage("solution added successfully");
        props?.setSuccessToggle(true);
      })
      .then((error) => {
        console.log(error);
      });
    console.log(form, "params");

    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/project/projects/add-solutions
    //   `,
    //     form,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then(function (response) {
    //     console.log(response);
    //     console.log(form, "inputs");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  return (
    <div>
      {" "}
      <div
        id="myModal"
        className="mini-modal"
        onClick={(e) => {
          e.preventDefault();
          props?.ModalToggle(false);
        }}
      >
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            Add Solution
            <div
              className="close-modal-button"
              onClick={(e) => props?.ModalToggle(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                  fill="#353945"
                />
              </svg>
            </div>
          </div>
          <form className="add-solution-form">
            <div className="form-container">
              <div className="form-card-information">
                <div className="client-information sub-solutions">
                  <div className="form-card-title">Involved Solutions</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-fields">
                        <MultiSelect
                          label="Departments"
                          list={sol}
                          setters={setDepartment}
                        />
                        {subSolutionList?.map((list) => (
                          <div className="check-box-field">
                            <input
                              type="checkbox"
                              id={list?.id}
                              className="checkbox-input"
                              onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor={list?.id} className="v">
                              {list?.solution_name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>{" "}
                  </div>
                  {/* */}
                </div>
              </div>

              <div className="inputs-group">
                <div class="form-buttons">
                  <button
                    type="cancel"
                    class="form-cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      props?.ModalToggle(false);
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value={"submit"}
                    class="form-save"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
