import React from "react";
import { NavLink } from "react-router-dom";
import {
  useGetEtasQuery,
  useGetProjectItemQuery,
  useGetProjectQuery,
} from "../../features/SCMApi";

export default function OrderNotice(props) {
  const { data: projectdetail } = useGetProjectItemQuery(props.value);
  const { data: projectid } = useGetProjectQuery(props.value);

  const { data: eta } = useGetEtasQuery(props.value);
  if (props.value !== undefined) {
    props.setMessage(null);
  }
  console.log(projectdetail, "projectdetailprojectdetail");

  return (
    <>
      {projectid?.data?.is_office == 0 ? (
        <div className="sourcing-dashboard-mini-card-pa">
          <div className="mini-card-label">PA</div>
          {props.value === undefined ? (
            <>
              <div className="button-pa-view" onClick={props.handler}>
                PA Summary
              </div>
              <div className="button-pa-view-white" onClick={props.handler}>
                View Orders
              </div>
            </>
          ) : (
            <>
              <NavLink
                to={`paSummary/${props.value}`}
                className="button-pa-view"
              >
                PA Summary
              </NavLink>
              <NavLink
                to={`paStage/${props.value}`}
                className="button-pa-view-white"
              >
                View Orders
              </NavLink>
            </>
          )}
        </div>
      ) : (
        <div className="sourcing-dashboard-mini-card-pa d-flex justify-content-between">
          <div className="mini-card-label">BC</div>
          <NavLink to={`bcSummary/${props.value}`} className="button-pa-view">
            BC Summary
          </NavLink>
          <NavLink
            to={`bcStage/${props.value}`}
            className="button-pa-view-white"
          >
            View Orders
          </NavLink>
        </div>
      )}
      <div className="sourcing-dashboard-mini-card">
        <div className="mini-card-label">Order</div>
        <div className="d-flex flex-column ">
          <div className="numberOf-order text-center">
            {projectdetail?.data?.order.length}
          </div>
          <div className="mini-card-controller-label">Order</div>
        </div>
        {props.value == undefined ? (
          <>
            <div className="view-more-po" onClick={props.handler}>
              View More
            </div>
          </>
        ) : (
          <NavLink to={`orders/${props.value}`} className="view-more-po">
            View More
          </NavLink>
        )}
      </div>
      <div className="sourcing-dashboard-mini-card">
        <div className="mini-card-label">PO Stage</div>
        <div className="d-flex flex-column">
          <div className="numberOf-order text-center">
            {projectdetail?.data?.po.length}
          </div>
          <div className="mini-card-controller-label">Order</div>
        </div>
        {props.value === undefined ? (
          <>
            <div className="view-more-po" onClick={props.handler}>
              View More
            </div>
          </>
        ) : (
          <NavLink to={`poStage/${props.value}`} className="view-more-po">
            View More
          </NavLink>
        )}
      </div>
      <div className="sourcing-dashboard-mini-card">
        <div className="mini-card-label">ETA Follow Up</div>
        <div className="d-flex flex-column">
          <div className="numberOf-order text-center">{eta?.data?.length}</div>
          <div className="mini-card-controller-label">Order</div>
        </div>
        {props.value == undefined ? (
          <>
            <div className="view-more-po" onClick={props.handler}>
              View More
            </div>
          </>
        ) : (
          <NavLink to={`eta/${props.value}`} className="view-more-po">
            View More
          </NavLink>
        )}
      </div>
    </>
  );
}
