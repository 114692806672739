import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  useGetBanksQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import CheckPermission from "../../common/checkPermission/checkPermission";
import EditPaymentButton from "./EditPaymentButton";
import DeletePaymentButton from "./DeletePaymentButton";

const ForexNeed = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "320px",
  width: "362px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginTop: "20px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
  position: "relative",
}));

const ReceivableMobileComponent = (props) => {
  const { data: userinfo } = useGetUserInfoQuery();
  console.log(props?.forexReceivable, "props?.forexReceivable");
  return (
    <>
      <ForexNeed elevation={0}>
        <div className="gap-analysis-mobile-view-detail-card receivable-container">
          <ul className="paymentTermAndConditionsLabel">
            <li>
              <span className="mobile-view-label">Vendor</span>
            </li>
            <li>
              <span className="mobile-view-label">Product/Service</span>
            </li>

            <li>
              <span className="mobile-view-label">Expected Date</span>
            </li>
            <li>
              <span className="mobile-view-label">Amount</span>
            </li>
          </ul>

          <ul className="paymentTermAndConditionsValue">
            <li>
              <span className="paymentTcValue">
                {props?.forexReceivable?.forex_receivable_manufacturer
                  ?.manufacturer_name !== null
                  ? props?.forexReceivable?.forex_receivable_manufacturer
                      ?.manufacturer_name?.length > 18
                    ? props?.forexReceivable?.forex_receivable_manufacturer?.manufacturer_name?.slice(
                        0,
                        18
                      ) + "..."
                    : props?.forexReceivable?.forex_receivable_manufacturer
                        ?.manufacturer_name
                  : "Not available"}
              </span>
            </li>
            {/* distributor_name */}
            <li>
              <span className="paymentTcValue">
                {props?.forexReceivable?.order_type !== null
                  ? props?.forexReceivable?.order_type?.length > 18
                    ? props?.forexReceivable?.order_type?.slice(0, 18) + "..."
                    : props?.forexReceivable?.order_type
                  : "Not available"}
              </span>
            </li>

            <li>
              <span className="paymentTcValue">
                {props?.forexReceivable?.expected_date !== null
                  ? props?.forexReceivable?.expected_date?.length > 18
                    ? props?.forexReceivable?.expected_date?.slice(0, 18) +
                      "..."
                    : props?.forexReceivable?.expected_date
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexReceivable?.amount
                  ? Number(props?.forexReceivable?.amount)?.toLocaleString()
                  : "Not available"}
              </span>
            </li>
          </ul>
        </div>
        <div className="gap-analysis-mobile-view-description-container">
          <span className="gap-analysis-mobile-view-description-title">
            Way of payment
          </span>
          <span className="paymentTcValue">
            <span className="paymentTcValue">
              {props?.forexReceivable?.payment_way !== null
                ? props?.forexReceivable?.payment_way?.includes("To Bank")
                  ? `Transfer to ${
                      props?.forexReceivable?.forex_receivable_bank?.bank_name
                        ?.length > 30
                        ? props?.forexReceivable?.forex_receivable_bank?.bank_name?.slice(
                            0,
                            30
                          ) + "..."
                        : props?.forexReceivable?.forex_receivable_bank
                            ?.bank_name
                    }`
                  : props?.forexReceivable?.payment_way?.includes("To Supplier")
                  ? `Transfer to ${
                      props?.forexReceivable?.forex_receivable_supplier
                        ?.distributor_name.length > 30
                        ? props?.forexReceivable?.forex_receivable_supplier?.distributor_name?.slice(
                            0,
                            30
                          ) + "..."
                        : props?.forexReceivable?.forex_receivable_supplier
                            ?.distributor_name
                    }`
                  : ""
                : "Not available"}
            </span>
          </span>
        </div>
        <div className="gap-analysis-mobile-view-description-container receivable-description">
          <span className="gap-analysis-mobile-view-description-title">
            Description
          </span>
          <span className="paymentTcValue receivable-description-value">
            {props?.forexReceivable?.description !== null
              ? props?.forexReceivable?.description.length > 230
                ? props?.forexReceivable?.description.slice(0, 220) + "..."
                : props?.forexReceivable?.description
              : "Not available"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "-10px",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <EditPaymentButton
            style={{
              color: "#fff",
              backgroundColor: "#064B4F",
              marginRight: "5px",
            }}
            editData={props?.forexReceivable}
            message={props?.message}
            successMessage={props?.successMessage}
            disabled={
              CheckPermission("edit_receivable", userinfo) ? false : true
            }
          />
          <DeletePaymentButton
            style={{
              color: "#fff",
              backgroundColor: "#064B4F",
              width: 320,
            }}
            disabled={
              CheckPermission("receive_receivable", userinfo) ? false : true
            }
            forexReceivableSupplier={props?.forexReceivable}
            deleteData={props?.id}
            message={props?.message}
            successMessage={props?.successMessage}
          />
        </div>
      </ForexNeed>
    </>
  );
};

export default ReceivableMobileComponent;
